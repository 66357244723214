{
  "en": {
    "activerecord": {
      "attributes": {
        "birth_registration": {
          "birth_country": "Country of birth",
          "birth_date": "Date of birth",
          "breed_category_id": "Type of Equidae",
          "breeder_data_addr1": "Address",
          "breeder_data_addr2": "Address 2",
          "breeder_data_asl": "Codice Azienda (ASL)",
          "breeder_data_box": "Box",
          "breeder_data_civility": "Civility",
          "breeder_data_company": "Title",
          "breeder_data_company_country": "Country",
          "breeder_data_company_number": "VAT number or company number",
          "breeder_data_company_vat_subject": "Subject to VAT",
          "breeder_data_country_code": "Country",
          "breeder_data_email": "Email",
          "breeder_data_fax": "Fax",
          "breeder_data_firstname": "First name",
          "breeder_data_lastname": "Last name",
          "breeder_data_legal_form_id": "Legal form",
          "breeder_data_locality": "City",
          "breeder_data_mobile": "Mobile",
          "breeder_data_national_number": "National number",
          "breeder_data_national_number_country": "Country",
          "breeder_data_number": "Number",
          "breeder_data_phone": "Phone",
          "breeder_data_vat": "VAT number or company number",
          "breeder_data_zip": "Zip code",
          "co_breeder_data_addr1": "Address",
          "co_breeder_data_addr2": "Address 2",
          "co_breeder_data_box": "Box",
          "co_breeder_data_civility": "Civility",
          "co_breeder_data_company": "Title",
          "co_breeder_data_company_country": "Country",
          "co_breeder_data_company_number": "VAT number or company number",
          "co_breeder_data_company_vat_subject": "Subject to VAT",
          "co_breeder_data_country_code": "Country",
          "co_breeder_data_email": "Email",
          "co_breeder_data_fax": "Fax",
          "co_breeder_data_firstname": "First name",
          "co_breeder_data_lastname": "Last name",
          "co_breeder_data_legal_form_id": "Legal form",
          "co_breeder_data_locality": "City",
          "co_breeder_data_mobile": "Mobile",
          "co_breeder_data_national_number": "National number",
          "co_breeder_data_national_number_country": "Country",
          "co_breeder_data_number": "Number",
          "co_breeder_data_phone": "Phone",
          "co_breeder_data_vat": "VAT number or company number",
          "co_breeder_data_zip": "Zip code",
          "co_owner_data_addr1": "Address",
          "co_owner_data_addr2": "Address 2",
          "co_owner_data_box": "Box",
          "co_owner_data_civility": "Civility",
          "co_owner_data_company": "Title",
          "co_owner_data_company_country": "Country",
          "co_owner_data_company_number": "VAT number or company number",
          "co_owner_data_company_vat_subject": "Subject to VAT",
          "co_owner_data_country_code": "Country",
          "co_owner_data_email": "Email",
          "co_owner_data_fax": "Fax",
          "co_owner_data_firstname": "First name",
          "co_owner_data_lastname": "Last name",
          "co_owner_data_legal_form_id": "Legal form",
          "co_owner_data_locality": "City",
          "co_owner_data_mobile": "Mobile",
          "co_owner_data_national_number": "National Number",
          "co_owner_data_national_number_country": "Country",
          "co_owner_data_number": "Number",
          "co_owner_data_phone": "Phone",
          "co_owner_data_vat": "VAT number or company number",
          "co_owner_data_zip": "Zip code",
          "coupling_card_access_token": "Link to the covering certificate",
          "coupling_card_copy": "Copy of the covering certificate",
          "coupling_card_number": "Number of covering ertificate",
          "coupling_card_studbook": "Name of the Studbook",
          "created_at": "Registration date",
          "embryo_transfer": "Embryo Transfer",
          "global_breeder": "Breeder",
          "global_co_breeder": "Co-breeder",
          "global_co_owner": "Co-owner",
          "global_holder": "Holder",
          "global_owner": "Owner",
          "holder_addr1": "Place of detention",
          "holder_data_addr1": "Address",
          "holder_data_asl": "Codice Azienda (ASL)",
          "holder_data_box": "Box",
          "holder_data_cbc_id": "CBC ID",
          "holder_data_civility": "Civility",
          "holder_data_country_code": "Country",
          "holder_data_email": "Email",
          "holder_data_firstname": "First name",
          "holder_data_lastname": "Last name",
          "holder_data_locality": "City",
          "holder_data_number": "Number",
          "holder_data_zip": "Zip code",
          "holder_firstname": "Last Name of the holder",
          "holder_lastname": "First name of the holder",
          "holding_place": "Detention place",
          "holding_place_data_addr1": "Address",
          "holding_place_data_box": "Box",
          "holding_place_data_country_code": "Country",
          "holding_place_data_locality": "City",
          "holding_place_data_number": "Number",
          "holding_place_data_zip": "Zip code",
          "horse_database": "Studbook",
          "horse_database_id": "Studbook",
          "horse_private_alias": "Code",
          "identificatio_online": "Procedure",
          "identification_dna": "Product's hair sample",
          "identification_mother_dna": "Mare's hair sample",
          "identification_type": "Identification request",
          "mare_birth_date": "Date of birth",
          "mare_code": "Mare code",
          "mare_father_name": "Sire of the mare",
          "mare_id": "Mare",
          "mare_microchip": "Mare microchip",
          "mare_mother_name": "Dam of the mare",
          "mare_name": "Name of the mare",
          "name": "Name of the foal",
          "no_coupling_card_data_agree": "General terms",
          "owner_data_addr1": "Address",
          "owner_data_addr2": "Address 2",
          "owner_data_asl": "Codice Azienda (ASL)",
          "owner_data_box": "Box",
          "owner_data_civility": "Civility",
          "owner_data_company": "Title",
          "owner_data_company_country": "Country",
          "owner_data_company_number": "VAT number or company number",
          "owner_data_company_vat_subject": "Subject to VAT",
          "owner_data_country_code": "Country",
          "owner_data_email": "Email",
          "owner_data_fax": "Fax",
          "owner_data_firstname": "First name",
          "owner_data_lastname": "Last name",
          "owner_data_legal_form_id": "Legal form",
          "owner_data_locality": "City",
          "owner_data_mobile": "Mobile",
          "owner_data_national_number": "National number",
          "owner_data_national_number_country": "Country",
          "owner_data_number": "Number",
          "owner_data_phone": "Phone",
          "owner_data_vat": "VAT number or company number",
          "owner_data_zip": "Zip code",
          "received_at": "Received on the",
          "slaughter_foal": "Slaughter-bound foal",
          "stalion_birth_date": "Date of birth",
          "stalion_code": "Stallion code",
          "stalion_father_name": "Sire of the stallion",
          "stalion_id": "Stallion",
          "stalion_microchip": "Stallion microchip",
          "stalion_mother_name": "Dam of the stallion",
          "stalion_name": "Name of the stallion",
          "station_be": "Country of detention",
          "stationed_in_belgium": "Is the product located in Belgium?",
          "validated_at": "Validated on the",
          "vet_id": "veterinarian chosen for identification"
        },
        "cbc_horse": {
          "breeder": "Breeder",
          "breeder_cbc_id": "Breeder's ID",
          "breeder_id": "Breeder",
          "co_breeder": "Co-breeder",
          "co_breeder_cbc_id": "Co-breeder's ID",
          "co_breeder_id": "Co-breeder",
          "global_breeder": "Breeder",
          "global_co_breeder": "Co-breeder",
          "global_holder": "Holder",
          "holder": "Holder",
          "holder_cbc_id": "Holder's ID",
          "holder_id": "Holder",
          "holder_selected_email": "Holder's email adress",
          "horseID_params_exploitationBox": "Box",
          "horseID_params_exploitationCountry": "Country",
          "horseID_params_exploitationLocality": "Locality",
          "horseID_params_exploitationNumber": "Number",
          "horseID_params_exploitationPostCode": "Zip code",
          "horseID_params_exploitationStreet": "Street",
          "horseID_params_foreignAlias": "Original alias",
          "horseID_params_horseDateHolding": "Begin date of holdership",
          "horseID_params_horse_birthCountry": "Country",
          "horseID_params_horse_birthDate": "Date of birth",
          "horseID_params_horse_breeders": "CBC ID of breeders",
          "horseID_params_horse_butcherFoal": "Slaughter-bound foal",
          "horseID_params_horse_castrated": "Castrated",
          "horseID_params_horse_commercialName": "Sport's name",
          "horseID_params_horse_detentionPlace": "Establishment number",
          "horseID_params_horse_detentionSince": "Date of arrival of the product at this establishment",
          "horseID_params_horse_dressColor": "Coat color",
          "horseID_params_horse_excludingFoodChain": "Excluded from the food chain",
          "horseID_params_horse_excludingFoodChainDate": "Exclusion date from the food chain",
          "horseID_params_horse_father_ueln": "Sire's UELN",
          "horseID_params_horse_gender": "Sex",
          "horseID_params_horse_holder": "CBC ID of the holder",
          "horseID_params_horse_holderTmp": "CBC ID (temp.) of the holder",
          "horseID_params_horse_mother_ueln": "Mare's UELN",
          "horseID_params_horse_name": "Name",
          "horseID_params_horse_origin": "Origin",
          "horseID_params_horse_privateRemark": "Remarks",
          "horseID_params_horse_race": "Breed",
          "horseID_params_horse_responsibleAgency": "Responsible agency's UELN",
          "horseID_params_horse_studbookId": "Equidae code",
          "horseID_params_horse_transponder": "Microchip n°",
          "horseID_params_horse_type": "Type",
          "horseID_params_horse_ueln": "UELN",
          "horseID_params_identification_textualReporting": "Textual description",
          "horseID_params_transmitterAgency": "UELN transmitter agency",
          "private_notes": "Private remarks",
          "transfered_at": "Transfer",
          "validated_at": "Validation"
        },
        "contact/fee": {
          "amount": "Amount",
          "payed_at": "Payment date",
          "year": "Year"
        },
        "contact_detail": {
          "detail_type": "Type"
        },
        "coupling_card": {
          "carrying_mare": "Surrogate mother of the unborn foal",
          "carrying_mare_code": "Code of the surrogate mare",
          "carrying_mare_microchip": "Microchip of the surrogate mare",
          "carrying_mare_name": "Name of the surrogate mare",
          "completed_at": "Closing date",
          "coupler": "Inseminator",
          "coupling_dates": {
            "one": "Breeding date",
            "other": "Breeding dates"
          },
          "coupling_mode": "Covering method",
          "embryo_transfer": "Embryo Transfer",
          "global_coupler": "inseminator",
          "issue_date": "Opening date",
          "mare": "Biological mother",
          "mare_birth_date": "Biological mother's date of birth",
          "mare_code": "Code of the biological mother",
          "mare_father_name": "Sire of the biological mother",
          "mare_holder": "Owner of the biological mother",
          "mare_holder_address_box": "Box",
          "mare_holder_address_country_code": "Country",
          "mare_holder_address_line1": "Address",
          "mare_holder_address_locality": "Locality",
          "mare_holder_address_locality_name": "Locality",
          "mare_holder_address_number": "N°",
          "mare_holder_address_vat": "VAT number (if subject to)",
          "mare_holder_address_zip": "Zip code",
          "mare_holder_civility": "Civility",
          "mare_holder_civility_id": "Civility",
          "mare_holder_company": "Company / Business name",
          "mare_holder_company_country": "Country",
          "mare_holder_company_number": "VAT number or company number",
          "mare_holder_company_vat_subject": "Subject to VAT",
          "mare_holder_contacts": "Contact details of the owner",
          "mare_holder_email": "E-mail",
          "mare_holder_fax": "Fax",
          "mare_holder_firstname": "First name",
          "mare_holder_lastname": "Last name",
          "mare_holder_legal_form_id": "Legal form",
          "mare_holder_mobile": "Mobile",
          "mare_holder_national_number": "National Registration number",
          "mare_holder_national_number_country": "Country",
          "mare_holder_phone": "Phone",
          "mare_id": "Mare",
          "mare_microchip": "Microchip of the biological mother",
          "mare_mother_name": "Dam of the biological mother",
          "mare_name": "Name of the biological mother",
          "number": "Number",
          "stallion": "Foal's sire",
          "stallion_id": "Stallion",
          "year": "Breeding season"
        },
        "coupling_card/coupling": {
          "date": {
            "one": "Date",
            "other": "Dates"
          }
        },
        "coupling_card/credit": {
          "amount": "Number of covering cards ordered",
          "ordered_at": "Granted on the",
          "payed_at": "Paid on the",
          "quantity": "Quantity",
          "requester": "Ordered by",
          "year": "Breeding season"
        },
        "delivrable": {
          "payed": "Paid",
          "payed_at": "Paid on the",
          "reference": "Reference",
          "subject": "Subject",
          "subject_id": "Subject"
        },
        "horse": {
          "active_passport": "Passport",
          "approbation_country": "Country of approval",
          "approved": "Approved",
          "birth_country": "Country of birth",
          "birth_date": "Date of birth",
          "blood_type": "Blood type",
          "blood_type_date": "Date",
          "breed": "Breed",
          "breed_category": "Type",
          "breed_category_id": "Type",
          "breed_id": "Breed",
          "breeder": {
            "one": "Breeder",
            "other": "Breeders"
          },
          "breeder_id": "Breeder",
          "breeding_card": "Covering certificate / declaration",
          "breeding_card_notes": "Notes",
          "breeding_card_num": "Strain number",
          "castrated": "Castrated",
          "cbc_id": "ID CBC",
          "cbc_id_final": "Definitive",
          "cbc_id_timestamp": "Date",
          "coupler": {
            "one": "Authorized inseminator",
            "other": "Authorized inseminators"
          },
          "current_owner": {
            "one": "Current owner",
            "other": "Current owners"
          },
          "database_book": "Section",
          "database_book_section": "subsection",
          "death_date": "Date of death",
          "deceased": "Deceased",
          "detention_place_code": "Establishment number",
          "detention_since": "Date of arrival of the product at this establishment",
          "dna": "DNA",
          "dna_date": "Date of the test",
          "dna_filiation_compatible": "Compatible",
          "dna_horse_hair": "Hair sample available",
          "dna_horse_hair_receival_date": "Hair sample received on",
          "dna_result": "Result",
          "dna_sent_date": "Sent date",
          "dress": "Color",
          "dress_id": "Color",
          "father": "Sire",
          "father_id": "Sire",
          "first_holder": "First holder",
          "food_chain_excluded": "Excluded from the food chain",
          "food_chain_exclusion_date": "Exclusion date",
          "graphic_signage": "Graphic description",
          "horse_databases_book_id": "Section",
          "horse_databases_book_section_id": "Subsection",
          "horse_id": "HorseID",
          "microchip": "Microchip",
          "mother": "Mother",
          "mother_id": "Mother",
          "name": "Name",
          "notes": "Note",
          "origin_alias": "Original code",
          "owner": {
            "one": "Owner",
            "other": "Owners"
          },
          "past_owner": {
            "one": "Previous owner",
            "other": "Previous owners"
          },
          "private_alias": "Code",
          "received_at": "Registration date",
          "registration_billed": "Invoiced",
          "registration_invoice_reference": "Ref",
          "registration_paid": "Paid",
          "registration_paid_at": "Date",
          "sexe": "Sex",
          "slaughter_foal": "Slaughter-bound foal",
          "structured_signage": "Codified description",
          "text_signage": "Description",
          "ueln": "UELN",
          "ueln_country": "Country",
          "ueln_database": "Org.",
          "ueln_national_id": "username",
          "ueln_type": "UELN generation"
        },
        "horse/companion/sbs": {
          "frbse_id": "FRBSE username"
        },
        "horse/labelling": {
          "end_at": "End date",
          "label": {
            "one": "Label",
            "other": "Labels"
          },
          "start_at": "Start date"
        },
        "horse/microchip": {
          "date": "Activation date",
          "number": {
            "one": "Microchip number",
            "other": "Microchip numbers"
          },
          "unreadability_date": "Unreadability date",
          "unreadable": "Unreadable"
        },
        "horse/ownership": {
          "from_date": "Start date",
          "is_main": "Principal owner",
          "to_date": "End date"
        },
        "horse/passport": {
          "cbc_id": "CBC ID",
          "exported_at": "Edition date",
          "global_recipient": "Recipient",
          "number": "Number",
          "passport_type": "Type",
          "recipient": "Recipient",
          "sent_at": "Sent date"
        },
        "identification": {
          "breeder": "Breeder",
          "breeder_cbc_id": "Breeder ID",
          "breeder_id": "Breeder",
          "co_breeder": "Co-breeder",
          "co_breeder_cbc_id": "Co-breeder ID",
          "co_breeder_id": "Co-breeder",
          "finalize_sent_at": "Finalization",
          "global_breeder": "Breeder",
          "global_co_breeder": "Co-breeder",
          "global_holder": "Holder",
          "holder": "Holder",
          "holder_cbc_id": "Holder ID",
          "holder_id": "Holder",
          "holder_selected_email": "Holder's email",
          "horseID_params_exploitationBox": "Box",
          "horseID_params_exploitationCountry": "Country",
          "horseID_params_exploitationLocality": "Locality",
          "horseID_params_exploitationNumber": "Number",
          "horseID_params_exploitationPostCode": "Zip code",
          "horseID_params_exploitationStreet": "Street",
          "horseID_params_horse_birthCountry": "Country",
          "horseID_params_horse_birthDate": "Date of birth",
          "horseID_params_horse_breeders": "CBC ID of breeders",
          "horseID_params_horse_butcherFoal": "Slaughter-bound foal",
          "horseID_params_horse_castrated": "Castrated",
          "horseID_params_horse_commercialName": "Sport's name",
          "horseID_params_horse_detentionPlace": "Establishment number",
          "horseID_params_horse_detentionSince": "Date of arrival of the product at this establishment",
          "horseID_params_horse_dressColor": "Coat color",
          "horseID_params_horse_excludingFoodChain": "Excluded from the food chain",
          "horseID_params_horse_excludingFoodChainDate": "Date of the exclusion from the food chain",
          "horseID_params_horse_father_ueln": "Sire's UELN",
          "horseID_params_horse_gender": "Gender",
          "horseID_params_horse_holder": "CBC ID of the holder",
          "horseID_params_horse_mother_ueln": "Mare's UELN",
          "horseID_params_horse_name": "Name",
          "horseID_params_horse_origin": "Origin",
          "horseID_params_horse_privateRemark": "Remark",
          "horseID_params_horse_race": "Breed",
          "horseID_params_horse_responsibleAgency": "Responsible agency's UELN",
          "horseID_params_horse_studbookId": "Equidae code",
          "horseID_params_horse_transponder": "Microchip n°",
          "horseID_params_horse_type": "Type",
          "horseID_params_horse_ueln": "UELN",
          "horseID_params_identificationType": "Request type",
          "horseID_params_identification_date": "Identification",
          "horseID_params_identification_dna": "Product's hair sample",
          "horseID_params_identification_identifier": "Veterinarian's licence number",
          "horseID_params_identification_identifierRemark": "Identifier's remark",
          "horseID_params_identification_motherDna": "Mare's hair sample",
          "horseID_params_identification_returnDate": "File's return date",
          "horseID_params_identification_textualReporting": "Textual description",
          "horseID_params_online": "Online request",
          "horseID_params_transmitterAgency": "UELN transmitter agency",
          "identification_date": "Identification",
          "identification_dna": "Product's hair sample",
          "identification_mother_dna": "Mare's hair sample",
          "identification_return_date": "Return date",
          "identifier_notes": "Identifier's remarks",
          "private_notes": "Private remark",
          "request_sent_at": "Send to HorseID",
          "transfered_at": "Transfer",
          "validated_at": "Validation",
          "veterinarian": "Identifier",
          "veterinarian_id": "Identifier"
        },
        "note": {
          "annotated": "Subject",
          "annotated_gid": "Subject",
          "author": "Author",
          "body": "Text",
          "note_type": "Type",
          "privacy": "Privacy",
          "written_at": "Written on the"
        },
        "organisation": {
          "bank_account": "IBAN (account number)",
          "bank_bic": "BIC code",
          "bank_name": "Bank",
          "cbc_id": "CBC ID",
          "horse_affix": "Affix",
          "horse_affix_protected": "Protected Affix",
          "horse_affix_protected_since": "Since the",
          "identity_complement": "Complement",
          "legal_form_id": "Form",
          "locale": "Language",
          "name": "Name",
          "received_at": "Registration date",
          "vat": "CBE number",
          "vat_subject": "Subject to VAT"
        },
        "person": {
          "adresses": "Addresses",
          "bank_account": "IBAN (account number)",
          "bank_bic": "BIC code",
          "bank_name": "Bank",
          "cbc_id": "CBC ID",
          "civility": "Civility",
          "civility_id": "Civility",
          "company": "Company",
          "company_legal_form": "Legal form",
          "company_vat_subject": "Subject to VAT",
          "contacts": "Contacts",
          "fee_payed": "Membership fee",
          "fees": "Membership fee",
          "firstname": "First name",
          "fonctions": "Functions",
          "horse_affix": "Affix",
          "horse_affix_protected": "Protected Affix",
          "horse_affix_protected_since": "Since the",
          "identity_complement": "Complement",
          "lastname": "Last name",
          "locale": "Language",
          "member_number": "Member N°",
          "national_number": "National Registration number",
          "national_number_country": "Country",
          "notes": "Notes",
          "received_at": "Registration date",
          "send_magazine": "Periodical",
          "statuts": "Status",
          "vat": "VAT"
        },
        "person/enrollment": {
          "end_at": "End date",
          "start_at": "Start date"
        },
        "person/organisation_position": {
          "main": "Main contact",
          "position": "Position / Role"
        },
        "user": {
          "email": "E-mail",
          "firstname": "First name",
          "name": "Last name",
          "password": "Password",
          "password_confirmation": "Confirm password",
          "username": "User name"
        }
      },
      "errors": {
        "messages": {
          "invalid_date": "is not a valid date",
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        },
        "models": {
          "horse/passport": {
            "attributes": {
              "number": {
                "number_changeable_only_if_new": "Passport number cannot be edited"
              },
              "origin": {
                "must_be_an_original": "The origin passport must be an original"
              },
              "passport_type": {
                "only_one_original": "This equidae already has an original passport"
              },
              "status": {
                "format": "%{message}",
                "only_one_new": "A new passport is already under production for this equidae"
              }
            }
          }
        }
      },
      "models": {
        "address": "Adress",
        "birth_registration": {
          "one": "Birth declaration",
          "other": "Birth declarations"
        },
        "cbc_horse": {
          "one": "Creation request",
          "other": "Creation requests"
        },
        "contact_detail": {
          "one": "Contact details",
          "other": "Contact details"
        },
        "coupling_card": {
          "one": "Covering card",
          "other": "Covering cards"
        },
        "coupling_card/coupling": {
          "one": "Covering",
          "other": "Coverings"
        },
        "coupling_card/credit": {
          "one": "Order of covering cards",
          "other": "Orders of covering cards"
        },
        "horse": {
          "one": "Horse",
          "other": "Horses"
        },
        "horse/passport": {
          "one": "Passport",
          "other": "Passports"
        },
        "horse_companion_sbs": "SBS",
        "identification": {
          "one": "Identification",
          "other": "Identifications"
        },
        "note": {
          "one": "Note",
          "other": "Notes"
        },
        "organisation": {
          "one": "Organization",
          "other": "Organizations"
        },
        "person": {
          "one": "Person",
          "other": "Persons"
        },
        "person/position": {
          "one": "Function",
          "other": "Functions"
        }
      }
    },
    "birth_mailer": {
      "new": {
        "intro": "A new birth has been declared on SireCloud",
        "subject": "Birth declaration"
      }
    },
    "birth_registrations": {
      "add_co_breeder": "Add a co-breeder",
      "add_co_owner": "Add co-owner",
      "breeder": "Breeder",
      "breeder_intro_html": "<p>The <strong>Breeder</strong> must be <u> a single natural person or organisation</u>.<br/>To register more than one person or organisation, <strong><a class=\"quiet\" href=\"#co-breeder-switch\">add a co-breeder !</a></strong></p>",
      "card_questions": {
        "no_coupling_card": "I do not have a covering certificate",
        "other_studbook": "I own a covering certificate from another Studbook",
        "studbook": "I own a covering certificate from the %{studbook} Studbook",
        "year": {
          "current": "Breeding season  %{year}",
          "older": "Breeding year %{year} or prior"
        }
      },
      "cbc_id": "CBC ID",
      "check_before_saving_html": "Please check birth declaration before saving",
      "choice_title": "In which Studbook would you like to register your product?",
      "chosen_identification_type": "Chosen identification type",
      "co_breeder": "Co-breeder",
      "co_owner": "Product's co-owner",
      "father": "Sire",
      "first_holder": "First holder",
      "flash": {
        "destroy": {
          "fail": "Birth declaration could not be deleted",
          "success": "Birth declaration deleted"
        }
      },
      "form": {
        "help": {
          "microchip": "If the foal is already chipped and if you know the microchip's number, please fill it in. If otherwise, leave blank.",
          "slaughter_foal": "Foal to be slaugthered within its first year."
        },
        "hint": {
          "name_sbs": "Attention ! : Initial letter of name : P in 2021 (O in 2020) – Maximum 35 characters and spaces"
        },
        "label_ext": {
          "microchip": "(if already chipped)"
        }
      },
      "general_conditions_agree_html": "I have read and approved the general terms",
      "general_conditions_html": "<p><strong>General conditions</strong></p><p> The semen belongs to the owner of the stallion unless otherwise agreed. The declarant is presumed to hold all useful rights on the semen for breeding.</p><p> The declarant irrevocably undertakes to guarantee the Studbook %{studbook} from any action in connection with the above, which could be brought against the Studbook %{studbook}, following the registration of the product in the stud book. </p><p>This guarantee concerns any claim of any kind whatsoever that would be made, in principal, interest and costs, including legal costs.</p><p> The declarant will intervene, at the first request of the Studbook %{studbook}, in any procedure, amicable or judicial, and will take up the cause for him.</p>.",
      "holding_place": "Detention place",
      "identification": "Identification",
      "identification_intro_html": "<p class=\"mb-0\"> To ensure the general identification, we still need 4 information : </p> <ul> <li>The procedure choice.</li> <li>The contact details of the holder (imperatively a natural person, in charge of the animal).</li> <li>The detention place of the product ( to ease the description of the horse.</li> <li>The vet chosen for the identification and description of the horse.</li> </ul>",
      "identification_is_not_in_belgium_html": "<p class=\"mb-0\">The equidae is not located in Belgium.</p>",
      "identification_not_in_belgium_html": "<p class=\"mb-0\">The equidae identification thus will not be registered in Belgium.</p>",
      "identification_stationed_in_belgium_html": "Is the product located in Belgium?",
      "identification_stationed_to_first_holder_address": "Is the product located at the holder's address?",
      "identification_type_html": "Which identification type would you like to choose?",
      "identification_type_tooltip_html": "<strong>Online procedure</strong> : Identification is exclusively done online. Information and documents are sent to you by email. Payment is also made online.<br/><strong>Paper procedure</strong> : Information and documents are sent to you by post. The payment is made by bank transfer.",
      "identifier": "Identifier",
      "index": {
        "search": {
          "placeholder": "Quick search: name of the product, sire or dam"
        }
      },
      "intro_html": "Register your foal in the studbook SBS and simultaneously in the national database in few clicks: easy, quick and cheaper.<br/> We guide you step by step...",
      "manual_encoding_html": "Manually enter <br/>the information",
      "mare": "mare",
      "mother": "Biological mare",
      "online_identification_type": "Online",
      "or": "OR",
      "owner": "Product's owner",
      "owner_intro_html": "<p>The <strong>Owner</strong> must be <u> a single natural person or organisation</u>.<br/>To register more than one person or organisation, <strong><a class=\"quiet\" href=\"#co-breeder-switch\">add a co-owner!</a></strong></p>",
      "paper_identification_type": "Paper",
      "parents_intro": "Search the father and mother of your foal or enter their details.",
      "product": "Product",
      "product_intro": "Choose your foal's name. Indicate the breeding affix (optional) and fill in the fields left blank.",
      "sections": {
        "birth_informations": "Birth information",
        "breeder_details": "Breeder's details",
        "breeder_identity": "Breeder's identity",
        "co_breeder_details": "Co-breeder's details",
        "co_breeder_identity": "Co-breeder's identity",
        "co_owner_details": "Co-owner's details",
        "co_owner_identity": "Co-owner's identity",
        "first_holder": "Holder",
        "holding_place": "Detention place",
        "identification_date": "Identification date",
        "identification_payment_html": "Procedure <small class=\"text-muted\">(48€ for the online procedure - 55€ for the paper procedure)</small>",
        "identification_return_date": "Return date",
        "identifier": "Identifier",
        "owner_details": "Owner's details",
        "owner_identity": "Owner's identity",
        "product_identity": "Foal identity",
        "product_informations": "Foal information",
        "remarks": "Indentifier's remarks"
      },
      "select_contact_placeholder": "Select %{type}",
      "select_mare_html": "Search and select <br/>mare",
      "select_stallion_html": "Search and select <br/>stallion",
      "show": {
        "congrat_html": "Congratulations!",
        "details_email_var": "email",
        "details_html": "<p> Your birth declaration has been successfully registered. </p> <p> you will shortly receive  %{var} inviting you to pay the registration fee of your foal in the national database (<a href=\"https://www.horseid.be\" target=\"_blank\">horseid.be</a>). </p> <p> Make sure to follow up quickly. The identification document will be sent to you as soon as we receive your payment. You will only have to present the document to your veterinarian. </p> <p> <strong> Warning:</strong> some mails may end up in your junk mail (spam). Check your mailbox. </p>",
        "details_paper_var": "mail",
        "end_btn": "I got it."
      },
      "stallion": "Stallion",
      "steps": {
        "breeder": "Breeder",
        "confirm": "Summary",
        "identification": "Identification",
        "microchip": "Microship of the foal",
        "owner": "Owner",
        "parents": "Origins",
        "product": "Foal"
      },
      "validation": {
        "back": "Back",
        "breeder": "Breeder",
        "click_to_add": "Click to add to selected contact",
        "co_breeder": "Co-breeder",
        "co_owner": "Co-owner",
        "contact_address_added": "Address added",
        "contact_created": "Contact created",
        "contact_detail_added": "Contact detail added",
        "continue": "Continue",
        "create_contact": "Create new contact",
        "create_horse": "Create new horse",
        "declaration": "Declaration",
        "holder": "Holder",
        "horse_created": "Horse created",
        "horse_creation_failed": "Equidae creation failed",
        "mare": "Mare",
        "no_contact_selected": "No contact selected",
        "no_horse_selected": "No horse selected",
        "or": "or",
        "owner": "Owner",
        "page_title": "Validation",
        "select_contact_label": "Search and select %{type} among contacts",
        "select_contact_placeholder": "Select a %{type}",
        "select_origin_label": "Search and select %{type} among equidae",
        "select_origin_placeholder": "Select a %{type}",
        "stalion": "Sire",
        "steps": {
          "contacts": "Contacts",
          "identification": "Identification",
          "origins": "Origins",
          "product": "Product"
        },
        "title": "Validate birth declaration",
        "validation": "Validation",
        "values": {
          "types": {
            "0": "Do not create an identification request",
            "1": "Create HorseID request without transfer",
            "2": "Create and transfer HorseID request",
            "3": "Send without identification",
            "4": "Send without identification and transfer"
          }
        }
      },
      "values": {
        "embryo_transfer": {
          "0": "No",
          "1": "Yes",
          "false": "No",
          "true": "Yes"
        },
        "station_be": {
          "0": "Other country",
          "1": "Belgium",
          "false": "Other country",
          "true": "Belgium"
        },
        "status": {
          "to_validate": {
            "one": "A valider",
            "other": "A valider"
          },
          "validated": {
            "one": "Validée",
            "other": "Validées"
          }
        }
      }
    },
    "boolean": {
      "0": "No",
      "1": "Yes",
      "False": "No",
      "True": "Yes",
      "false": "No",
      "no": "No",
      "true": "Yes",
      "yes": "Yes"
    },
    "btn": {
      "labels": {
        "add": "Add",
        "add_coupling_card": "Create covering card",
        "add_delivrable": "Add service",
        "add_note": "Add note",
        "back": "Back",
        "birth_registration_continue": "Continue",
        "cancel": "Cancel",
        "cancel_delivrable": "Cancel",
        "cancel_note": "Cancel",
        "continue": "Continue",
        "copy_data_from_breeder": "Copy breeder's data",
        "copy_data_from_co_breeder": "co-breeder's",
        "copy_data_from_co_owner": "of co-owner",
        "copy_data_from_owner": "of owner",
        "create_cbc_horse": "Transfer without identification",
        "create_identification": "Create a request",
        "create_on_horse_id": "Create on HorseID",
        "create_passport": "Request a new passport",
        "date_picker_ok": "Pick",
        "delete_delivrable": "Delete",
        "delete_note": "Delete",
        "delete_passports": "Delete",
        "delete_signage": "Delete the graphic description",
        "delete_studbook_document": "Delete",
        "dna_control_confirm": "Confirm",
        "dna_control_send": "Send",
        "dna_controls_confirm": "Validate DNA controls",
        "download_studbook_document": "Download",
        "edit_cbc_horse": "Edit",
        "edit_identification": "Edit",
        "edit_passport": "Edit this passport",
        "edit_signage": "Edit description",
        "editer_delivrable": "Edit",
        "export_passport": "Export",
        "finalize_identification": "Close",
        "grant_coupling_card": "Grant new cards",
        "ignore_update_contact_horse_id": "Ignore update",
        "mark_passport_as_sent": "Mark as sent",
        "ok": "Ok",
        "order_coupling_card": "Order new cards",
        "receive_identification": "Receive",
        "resend_to_horse_id": "Resend to HorseID",
        "save_card": "Save card",
        "save_card_credit": "Save order",
        "save_cbc_horse": "Save",
        "save_delivrable": "Save",
        "save_horse": "Save",
        "save_identification": "Save",
        "save_note": "Save",
        "send_birth_registration": "Confirm and submit",
        "show_more_notes": "Show more notes",
        "sign_out": "Logout",
        "sort": "Sort",
        "transfer_cbc_horse": "Transfer",
        "transfer_identification": "Transfer",
        "update_contact_horse_id": "Send update",
        "upload_signage": "Add a description",
        "upload_studbook_document": "Add documents",
        "validate_and_create_birth_registration": "Validate and create",
        "validate_birth_registration": "Validate",
        "validate_cbc_horse": "Confirm",
        "validate_identification": "Validate"
      },
      "tooltips": {
        "add_address": "Add adress",
        "add_contact": "Add contact",
        "add_contact_detail": "Add contact details",
        "add_coupling_date": "Add a covering date",
        "add_fee": "Add membership",
        "add_horse_owner": "Add a co-owner",
        "add_labelling": "Add a label",
        "add_microchip": "Add a microchip",
        "add_organisation": "Add an organisation",
        "add_person": "Add a person",
        "add_position": "Add a function",
        "cbc_horse_cannot_transfer": "This creation cannot be transferred",
        "cbc_horse_cannot_validate": "This creation cannot be validated",
        "complete_card": "Complete the covering certificate",
        "complete_card_and_birth": "Complete the covering certificate and declare birth",
        "delete_delivrable": "Delete service",
        "destroy_birth_registration": "Delete declaration",
        "destroy_card": "Delete card",
        "download_contacts": "Download %{count} contacts",
        "edit_card": "Edit card",
        "edit_delivrable": "Edit service",
        "edit_horse": "Edit Equidae",
        "edit_identification": "Edit identification request",
        "edit_organisation": "Edit organisation",
        "edit_person": "Edit person",
        "identification_cannot_finalize": "This identification cannot be closed",
        "identification_cannot_receive": "This identification cannot be received",
        "identification_cannot_transfer": "This identification cannot be transferred",
        "identification_cannot_validate": "This identification cannot be validated",
        "incomplete_pedigree": "Incomplete pedigree",
        "missing_cbc_horse_attributes": "Following information missing for identification : %{missing_attributes}",
        "missing_coupling_card_attributes": "Following information missing to close covering certificate : %{missing_attributes}",
        "missing_identification_attributes": "Following information missing for identification : %{missing_attributes}",
        "more_actions": "Other actions",
        "new_birth": "Declare birth",
        "new_contact": "Register new contact",
        "new_horse": "Register a new person",
        "new_identification": "New identification request",
        "new_organisation": "Register a new organisation",
        "new_person": "Register a new person",
        "print_birth": "Print birth declaration",
        "remove_address": "Remove address",
        "remove_contact": "Remove contact",
        "remove_contact_detail": "Remove contact details",
        "remove_coupling_date": "Delete the covering date",
        "remove_fee": "Delete membership fee",
        "remove_horse_owner": "Delete co-owner",
        "remove_labelling": "Delete this label",
        "remove_microchip": "Delete microship",
        "remove_organisation": "Delete this organisation",
        "remove_person": "Delete this person",
        "remove_position": "Remove function",
        "required": "required",
        "show_coupling_card_copy": "Show the covering certificate's copy",
        "show_identification": "Show identification request",
        "sign_out": "Quit SireCloud"
      }
    },
    "cbc_horses": {
      "edit": {
        "page_title": "Edit transfer without identification request",
        "title": "Edit transfer without identification request"
      },
      "flash": {
        "bulk": {
          "fail": "No creation selected"
        },
        "create": {
          "fail": "Creation has not been saved",
          "success": "Creation saved"
        },
        "transfered": {
          "fail": {
            "one": "Creation could not be transferred",
            "other": "Creations could not be transferred"
          },
          "success": {
            "one": "Creation transferred",
            "other": "Creations transferred"
          }
        },
        "transfering": {
          "fail": {
            "one": "Creation could not be transferred",
            "other": "Creations could not be transferred"
          },
          "success": {
            "one": "Creation transfering",
            "other": "Creations transfering"
          }
        },
        "update": {
          "fail": "Creation could not be updated",
          "fail_horse_id": "Creation could not be updated in HorseID",
          "success": "Creation updated",
          "success_horse_id": "Creation and update in HorseID"
        },
        "validated": {
          "fail": {
            "one": "Creation could not be validated",
            "other": "Creations could not be validated"
          },
          "success": {
            "one": "Creation validated",
            "other": "Creations validated"
          }
        },
        "validating": {
          "fail": {
            "one": "Creation could not be validated",
            "other": "Creations could not be validated"
          },
          "success": {
            "one": "Creation under validation",
            "other": "Creations under validation"
          }
        }
      },
      "holder_selected_email_no_email": "No email known for the holder",
      "holder_selected_email_no_holder": "Please select an holder",
      "horseid": {
        "attributes": {
          "exploitationBox": "Box",
          "exploitationCountry": "Country",
          "exploitationLocality": "Locality",
          "exploitationNumber": "Number",
          "exploitationPostCode": "Zip code",
          "exploitationStreet": "Street",
          "foreignAlias": "Original alias",
          "horse": {
            "birthCountry": "Country of birth",
            "birthDate": "Date of birth",
            "breeders": "Breeders",
            "butcherFoal": "Slaughter foal",
            "castrated": "Castrated",
            "castrationDate": "Castration date",
            "commercialName": "Commercial name",
            "detentionPlace": "Establishment number",
            "detentionSince": "Date of arrival of the product at this establishment",
            "dressColor": "Dress color",
            "excludingFoodChain": "Excluded from the food chain",
            "excludingFoodChainDate": "Date of exclusion from the food chain",
            "father": {
              "name": "Father's name",
              "transponder": "Father's transponder number",
              "ueln": "Father's UELN"
            },
            "gender": "Gender",
            "hasOrigin": "Has a pedigree",
            "holder": "CBC ID of holder",
            "holderTmp": "Temporary CBC ID of holder",
            "id": "Code",
            "mother": {
              "name": "Mother's name",
              "transponder": "Mother's transponder number",
              "ueln": "Mother's UELN"
            },
            "name": "Name",
            "privateRemark": "Private remarks",
            "race": "Breed",
            "responsibleAgency": "Responsible agency",
            "studbookId": "Code",
            "transponder": "Transponder number",
            "type": "Type",
            "ueln": "UELN"
          },
          "horseBirthCountry": "Country",
          "horseBirthdate": "Date of birth",
          "horseBreeder1": "CBC ID of breeder 1",
          "horseBreeder2": "CBC ID of breeder 2",
          "horseButcherFoal": "Slaughter-bound foal",
          "horseCastrated": "Castrated",
          "horseCommercialName": "Commercial name",
          "horseDateExcludingFoodChain": "Exclusion date from the food chain",
          "horseDateHolding": "Begin date of holdership",
          "horseDressColor": "Coat color",
          "horseExcludingFoodChain": "Excluded from the food chain",
          "horseFather": "Sire's UELN",
          "horseGender": "Sex",
          "horseHolder": "CBC ID of the holder",
          "horseMother": "Mare's UELN",
          "horseName": "Name",
          "horseOrigin": "Origin",
          "horseRace": "Breed",
          "horseTransponder1": "Microchip n°",
          "horseType": "Type",
          "horseUeln": "UELN",
          "id": "Equidae code",
          "privateRemark": "Remarks",
          "responsibleAgency": "Responsible agency's UELN",
          "transmitterAgency": "UELN transmitter agency"
        }
      },
      "index": {
        "search": {
          "placeholder": "Quick search: Name, UELN or Code"
        }
      },
      "new": {
        "horse_not_found": "Horse not found",
        "page_title": "Transfer without identification request",
        "title": "Transfer without identification request",
        "transfert_already_requested": "Transfer already requested for this Equidae"
      },
      "sections": {
        "birth_informations": "Birth's informations",
        "breeder_details": "Breeder's contact details",
        "breeder_identity": "Breeder's identity",
        "co_breeder_details": "Co-breeder's contact details",
        "co_breeder_identity": "Co-breeder's identity",
        "co_owner_details": "Co-owner's contact details",
        "co_owner_identity": "Co-owner's identity",
        "first_holder": "Holder",
        "followup": "Follow up",
        "holding_place": "Detention place",
        "owner_details": "Owner's contact details",
        "owner_identity": "Owner's identity",
        "product_identity": "Product's identity",
        "product_informations": "Information on the product"
      },
      "show": {
        "page_title": "Transfer without identification request",
        "title": "Transfer without identification request"
      },
      "values": {
        "boolean": {
          "0": "No",
          "1": "Yes",
          "false": "No",
          "true": "Yes"
        },
        "sexe": {
          "0": "Male",
          "1": "Unknown",
          "2": "Female"
        },
        "status": {
          "to_transfer": {
            "one": "To transfer",
            "other": "To transfer"
          },
          "to_validate": {
            "one": "Transfered",
            "other": "Transfered"
          },
          "transfering": {
            "one": "Transfering",
            "other": "Transfering"
          },
          "validated": {
            "one": "Closed",
            "other": "Closed"
          },
          "validating": {
            "one": "Under validation",
            "other": "Under validation"
          }
        }
      }
    },
    "clearance": {
      "models": {
        "clearance_mailer": {
          "change_password": "SireCloud.be -Password change"
        }
      }
    },
    "clearance_mailer": {
      "change_password": {
        "closing": "If you did not ask for it, ignore this e-mail. Your password has not been changed",
        "link_text": "Change my password",
        "opening": "Someone, hopefully you, asked us to send this e-mail to change your password:"
      }
    },
    "companies": {
      "legal_form": {
        "short_texts": {
          "1": "ASBL",
          "10": "SPRL",
          "11": "SRL",
          "12": "VZW",
          "13": "SCS",
          "2": "BVBA",
          "3": "CVA",
          "4": "LLC",
          "5": "N.V.",
          "6": "S.A.",
          "7": "SAGR",
          "8": "SARL",
          "9": "SNC"
        },
        "texts": {
          "1": "Nonprofit Organisation",
          "10": "SPRL",
          "11": "SRL",
          "12": "VZW",
          "13": "Limited Partnership",
          "2": "Besloten Vennootschap met Beperkte Aansprakelijkheid",
          "3": "Commanditaire Vennootschap op Aandelen",
          "4": "Limited Liability Company",
          "5": "Naamloze Vennootschap",
          "6": "Incorporated Company",
          "7": "SAGR",
          "8": "SARL",
          "9": "SNC"
        }
      }
    },
    "contact_details": {
      "type_suffixes": {
        "tel_home": "Home",
        "tel_work": "Work"
      }
    },
    "contacts": {
      "data_fields": {
        "breeder": {
          "addr1": "Street",
          "address": "Address",
          "adress": "Address of the breeder",
          "box": "Box",
          "civility": "Civility",
          "company": "Title",
          "company_country": "Country",
          "company_number": "VAT number or company number",
          "company_vat_subject": "Subject to VAT",
          "country_code": "Country",
          "firstname": "First name of the breeder",
          "lastname": "Last name of the breeder",
          "legal_form_id": "Legal Form",
          "locality": "City",
          "national_number": "National Registration number of the breeder",
          "national_number_country": "Country",
          "number": "N°",
          "zip": "Zip code"
        },
        "co_breeder": {
          "addr1": "street",
          "address": "Co-breeder's address",
          "box": "Box",
          "civility": "Civility",
          "company": "Title",
          "company_country": "Country",
          "company_number": "VAT number or company number",
          "company_vat_subject": "Subject to VAT",
          "country_code": "Country",
          "firstname": "Co-breeder's first name",
          "lastname": "Co-breeder's last name",
          "legal_form_id": "Legal form",
          "locality": "Locality",
          "national_number": "Co-breeder's national registration number",
          "national_number_country": "Country",
          "number": "N°",
          "zip": "Zip code"
        },
        "co_owner": {
          "addr1": "Street",
          "adress": "Address of the co-owner",
          "box": "Box",
          "civility": "Civility",
          "company": "Title",
          "company_country": "Country",
          "company_number": "VAT number or company number",
          "company_vat_subject": "Subject to VAT",
          "country_code": "Country",
          "firstname": "First name of the co-owner",
          "lastname": "Last name of the co-owner",
          "legal_form_id": "Legal form",
          "locality": "City",
          "national_number": "National Registration number of the co-owner",
          "national_number_country": "Country",
          "number": "N°",
          "zip": "Zip code"
        },
        "default": {
          "addr1": "Street",
          "address": "Address",
          "adress": "Address",
          "box": "Box",
          "cbc_id": "ID CBC",
          "civility": "Civility",
          "company": "Title",
          "company_country": "Country",
          "company_number": "VAT number or company number",
          "company_vat_subject": "Subject to VAT",
          "country_code": "Country",
          "email": "E-mail",
          "fax": "Fax",
          "firstname": "First name",
          "lastname": "Last name",
          "legal_form_id": "Legal form",
          "locality": "City",
          "mobile": "Mobile",
          "national_number": "National Registration number",
          "national_number_country": "Country",
          "national_register": "National number",
          "number": "N°",
          "phone": "Phone",
          "vat": "VAT number (if subject to)",
          "zip": "Zip code"
        },
        "owner": {
          "addr1": "Street",
          "address": "Address of the owner",
          "box": "Box",
          "civility": "Civility",
          "company": "Title",
          "company_country": "Country",
          "company_number": "VAT number or company number",
          "company_vat_subject": "Subject to VAT",
          "country_code": "Country",
          "email": "E-mail",
          "fax": "Fax",
          "firstname": "First name of the owner",
          "lastname": "Last name of the owner",
          "legal_form_id": "Legal form",
          "locality": "City",
          "mobile": "Mobile",
          "national_number": "National Registration number of the owner",
          "national_number_country": "Country",
          "number": "N°",
          "phone": "Phone",
          "zip": "Zip code"
        }
      },
      "index": {
        "search": {
          "placeholder": "Search for a person or a company"
        }
      },
      "membership_status": {
        "inactive": "Non-active",
        "inactive_member": "Non-active member"
      },
      "new": {
        "page_title": "New contact",
        "title": "Creation of a new contact"
      },
      "one": "Contact",
      "other": "Contacts",
      "personne_morale": "Company / Organisation",
      "personne_phyisque": "Natural Person",
      "positions": {
        "members": "Status",
        "others": "Functions"
      },
      "section-title": {
        "admin": "Admin",
        "contact_details": "Contacts",
        "identity": "Identity",
        "membership": "Membership",
        "org_people": {
          "one": "Organisation responsible",
          "other": "Organisation responsibles"
        }
      },
      "values": {
        "address_types": {
          "billing": "Biling",
          "main": "Main address",
          "post": "Postal address"
        }
      }
    },
    "coupling_cards": {
      "available": {
        "one": "Card available",
        "other": "Cards available"
      },
      "available_full": {
        "one": "%{count} Card available sur %{total}",
        "other": "%{count} Cards available sur %{total}"
      },
      "closed": {
        "one": "Card closed",
        "other": "Cards closed"
      },
      "credit_count": {
        "one": "Card available",
        "other": "Cards available"
      },
      "edit": {
        "page_title": "Editing covering certificate",
        "title": "Edit covering certificate"
      },
      "flash": {
        "create": {
          "fail": "Registration failure. The covering certificate has not been created",
          "success": "Card created."
        },
        "destroy": {
          "fail": "Card could not be deleted",
          "success": "Covering card deleted"
        },
        "update": {
          "fail": "Card could not be updated",
          "success": "Card updated"
        }
      },
      "form": {
        "confirm_help": "Check the data before saving the card"
      },
      "hints": {
        "mare_code": "Studbook or UELN code",
        "stalion_code": "Studbook code or UELN"
      },
      "index": {
        "my_cards": "My covering cards",
        "search": {
          "placeholder": "Search for a card by number or stallion"
        }
      },
      "mare": {
        "extend_label": "Biological mother of the unborn foal"
      },
      "new": {
        "page_title": "New covering certificate",
        "title": "Open a new covering certificate"
      },
      "open": {
        "one": "Card open",
        "other": "Cards open"
      },
      "paper": {
        "one": "Paper card opened",
        "other": "Paper cards opened"
      },
      "section-title": {
        "owner_identity": "Owner of the biological mother's identity"
      },
      "show": {
        "add_mare": "Add  mare",
        "add_stallion": "Add stallion",
        "can_not_close": "The card cannot be closed.",
        "missing_mare": "Mare missing",
        "missing_stallion": "Stallion missing"
      },
      "steps": {
        "confirm": "Summary",
        "coupling": "Breeding",
        "owner": "Owner",
        "parents": "Origins"
      },
      "used": {
        "one": "Card used",
        "other": "Cards used"
      },
      "values": {
        "coupling_mode": {
          "1": "Natural covering",
          "2": "I.A. fresh semen",
          "3": "I.A. cooled semen",
          "4": "I.A. frozen semen"
        },
        "status": {
          "0": "Open date",
          "1": "Closing date",
          "2": "Closing date",
          "3": "Closing date",
          "closed": {
            "one": "Closed",
            "other": "Closed"
          },
          "open": {
            "one": "Open",
            "other": "Open"
          }
        }
      }
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%m/%d/%Y",
        "long": "%B %d, %Y",
        "mat_datepicker": "mm/dd/yyyy",
        "real_short_date": "%m/%d/%y",
        "short": "%b %d",
        "short_date": "%m/%d/%Y"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes",
          "zero": "less than a minute"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds",
          "zero": "less than a second"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        },
        "x_years": {
          "one": "1 year",
          "other": "%{count} years"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Second",
        "year": "Year"
      }
    },
    "delivrable_subjects": {
      "Divers": "Miscellaneous",
      "Duplicata": "Duplicate",
      "Détermination ADN": "DNA testing",
      "Inscription": "Registration",
      "Transfert propriété": "Ownership transfer"
    },
    "delivrables": {
      "flash": {
        "create": {
          "fail": "Service could not be saved",
          "success": "Service saved"
        },
        "destroy": {
          "fail": "Service could not be deleted",
          "success": "Service deleted"
        },
        "edit": {
          "fail": "Service could not be edited",
          "success": "Service edited"
        }
      },
      "no_delivrable": "No service"
    },
    "documents": {
      "dias": {
        "documents_error": "An error occurred while displaying the documents. Please try again later.",
        "documents_unavailable": "The DIAS service is currently unavailable, documents cannot be displayed.",
        "errors": {
          "3003": "Sending impossible: the Equidae does not yet hold a file in DiAS"
        },
        "signages_error": "An error occurred during the display of the graphic descriptions. Please try again later.",
        "signages_unavailable": "The DiAS service is currently unavailable, graphic descriptions cannot be displayed."
      },
      "flash": {
        "create": {
          "signage": {
            "fail": "Graphic description couldn't be added",
            "success": "Graphic description added"
          }
        },
        "destroy": {
          "signage": {
            "fail": "Graphic description couldn't be deleted",
            "success": "Graphic description deleted"
          }
        },
        "update": {
          "signage": {
            "fail": "Graphic description couldn't be edited",
            "success": "Graphic description edited"
          }
        }
      }
    },
    "errors": {
      "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
      "file": {
        "extension_exclusion": "extension cannot be present in the list: %{list}",
        "extension_inclusion": "extension must be included in the list : %{list}",
        "max_dimensions": "dimensions must be inferior to %{dims}",
        "max_height": "height must be inferior to %{max}",
        "max_size": "size lust be inferior to %{max}",
        "max_width": "width must be inferior to %{max}",
        "mime_type_exclusion": "type cannot be included inside list : %{list}",
        "mime_type_inclusion": "type must be included inside list : %{list}",
        "min_dimensions": "dimensions must be superior to %{dims}",
        "min_height": "Height must be superior to %{min}",
        "min_size": "Size must be superior to %{min}",
        "min_width": "Width must be superior to %{min}"
      },
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "after": "must be after %{restriction}",
        "before": "must be before %{restriction}",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "has_duplicates": "contains duplicates",
        "improbable_phone": "is an invalid number",
        "in": "must be in %{count}",
        "in_the_future": "cannot be in the future",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "invalid_currency": "has invalid format (must be '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
        "invalid_date": "is not a valid date",
        "invalid_datetime": "is not a valid datetime",
        "invalid_time": "is not a valid time",
        "is_at": "must be at %{restriction}",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "odd": "must be odd",
        "on_or_after": "must be on or after %{restriction}",
        "on_or_before": "must be on or before %{restriction}",
        "other_than": "must be other than %{count}",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is 1 character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is 1 character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "wrong_length": {
          "one": "is the wrong length (should be 1 character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "template": {
        "body": "There were problems with the following fields:",
        "header": {
          "one": "1 error prohibited this %{model} from being saved",
          "other": "%{count} errors prohibited this %{model} from being saved"
        }
      },
      "unacceptable_request": "A supported version is expected in the Accept header.\n",
      "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
    },
    "file_input": {
      "placeholder": "Select file"
    },
    "filterable": {
      "filter": {
        "contact": {
          "function": "Function",
          "member": {
            "0": "Non-member",
            "1": "Member"
          },
          "status": "Status",
          "type": {
            "organisation": "Organisation",
            "person": "Person"
          }
        },
        "sex": {
          "1": "Males",
          "2": "Females"
        },
        "status": "Status",
        "studbook": "Studbook",
        "year": "Year"
      },
      "filter_by": {
        "sex": "Sort by sex",
        "year": "Sort by year"
      },
      "indiferrent": "All",
      "menu_title": "Filter the results",
      "no_filter": "No filter"
    },
    "flashes": {
      "failure_after_create": "wrong e-mail address or password",
      "failure_after_update": "Password cannot be empty.",
      "failure_when_access_denied": "You cannot access this feature",
      "failure_when_forbidden": "Please check the URL or retry to send the form.",
      "failure_when_missing_email": "Email can't be blank.",
      "failure_when_not_signed_in": "You have to log in to access SireCloud"
    },
    "food_chain": "Food chain",
    "food_chain_excluded_on": "Excluded on the %{date}",
    "form_message": {
      "flash": {
        "sent": "Request sent"
      }
    },
    "from_date_to_date": "From the %{from_date} to the %{to_date}",
    "help_texts": {
      "etalonnier": {
        "coupling_cards_index_html": "<p> You can here manage your open and closed covering cards. <br/>To register a birth, go to a closed card. </p>",
        "horses_index_html": "<p>To see a stallion's page and the information it contains, you can click on a stallion in the table below. <!--<br/> To register a birth, you can go to the page <a href=\"/cards\">My breeding cards</a>.--> </p>"
      }
    },
    "helpers": {
      "dates": {
        "from_to_date": "from %{start_date} to %{end_date}",
        "since_date": "Since the %{date}",
        "to_date": "until %{date}"
      },
      "label": {
        "password": {
          "email": "E-mail"
        },
        "password_reset": {
          "password": "Choose a password"
        },
        "session": {
          "password": "Password"
        },
        "user": {
          "password": "Password"
        }
      },
      "page_entries_info": {
        "entry": {
          "one": "entry",
          "other": "entries",
          "zero": "entries"
        },
        "more_pages": {
          "display_entries": "%{entry_name} %{first} to %{last} out of %{total}"
        },
        "one_page": {
          "display_entries": {
            "one": "1 %{entry_name}",
            "other": "%{count} %{entry_name}",
            "zero": "none %{entry_name}"
          }
        }
      },
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "password": {
          "submit": "Choose your password"
        },
        "password_reset": {
          "submit": "Save the password"
        },
        "session": {
          "submit": "Log in"
        },
        "submit": "Save %{model}",
        "update": "Update %{model}",
        "user": {
          "create": "Sign up"
        }
      }
    },
    "horse/passport": {
      "flash": {
        "create": {
          "fail": "Passport couldn't be created",
          "success": "Passport created"
        },
        "delete": {
          "fail": {
            "one": "Passport could not be deleted",
            "other": "Passports could not be deleted"
          },
          "success": {
            "one": "Passport has been deleted",
            "other": "Passports have been deleted"
          }
        },
        "export": {
          "fail": {
            "one": "Passport could not be exported",
            "other": "Passports could not be exported"
          },
          "success": {
            "one": "Passport exported",
            "other": "Passports exported"
          }
        },
        "mark_as_sent": {
          "fail": "Sending date not valid",
          "success": {
            "one": "Passport marked as sent",
            "other": "Passports marked as sent"
          }
        },
        "none_selected": "No passport selected",
        "update": {
          "fail": "Passport could not be updated",
          "success": "Passport updated"
        }
      },
      "values": {
        "passport_type": {
          "duplicata": "Duplicate",
          "original": "Original",
          "replacement": "Replacement"
        },
        "status": {
          "cancelled": {
            "one": "Cancelled",
            "other": "Cancelled"
          },
          "exported": {
            "one": "To send",
            "other": "To send"
          },
          "lost": {
            "one": "Lost",
            "other": "Lost"
          },
          "sent": {
            "one": "Sent",
            "other": "Sent"
          },
          "to_export": {
            "one": "New",
            "other": "To export"
          }
        }
      }
    },
    "horse_id": {
      "contact_pending_validation": "%{id} (temp.)"
    },
    "horses": {
      "actions": {
        "confirm_destroy": "Are you sure you want to permanently remove this Equidae",
        "confirm_destroy_studbook_document": "Are you sure you want to permanently remove this document",
        "destroy": "Delete this equidae"
      },
      "birth_country_unknown": "< Unknown >",
      "death_mutation_will_be_final": "Death cannot be reversed",
      "decorator": {
        "born_on": {
          "0_html": "<span class=\"born-on-intro\">born on</span>%{date}",
          "1_html": "<span class=\"born-on-intro\">born on</span>%{date}",
          "2_html": "<span class=\"born-on-intro\">born on</span>%{date}"
        },
        "born_on_no_date_html": "Date of birth unknown"
      },
      "dna_controls": {
        "page_title": "DNA controls",
        "search": {
          "placeholder": "Quick search: Name, UELN, Code"
        },
        "title": "DNA controls"
      },
      "dna_status": {
        "to_confirm": {
          "one": "Sent on the %{sent_date}",
          "other": "To confirm"
        },
        "to_send": {
          "one": "To send",
          "other": "To send"
        }
      },
      "edit": {
        "page_title": "Edit Equidae",
        "title": "Edit the registration of the Equidae"
      },
      "flash": {
        "create": {
          "fail": "Horse creation failed",
          "success": "New Equidae registered"
        },
        "destroy": {
          "not_with_chidlren": "An Equine with a registered production cannot be deleted.",
          "success": "Hors has been deleted."
        },
        "dna_control_confirmed": {
          "fail": {
            "one": "Control could not be confirmed",
            "other": "Controls could not be confirmed"
          },
          "success": {
            "one": "Control confirmed",
            "other": "Controls confirmed"
          }
        },
        "dna_control_sent": {
          "fail": {
            "one": "Control could not be sent",
            "other": "Controls could not be sent"
          },
          "success": {
            "one": "Control sent",
            "other": "Controls sent"
          }
        },
        "update": {
          "success": "The data of the Equidae have been updated"
        }
      },
      "food_chain_mutation_will_be_final": "Exclusion cannot be reversed",
      "hints": {
        "ueln_type": "A code is required"
      },
      "index": {
        "my_stallions": "My stallions",
        "search": {
          "advanced": {
            "title": "Advanced search"
          },
          "placeholder": "Quick search: Name, UELN, Code"
        }
      },
      "new": {
        "page_title": "New Equidae",
        "title": "Registration of a new Equidae"
      },
      "placeholders": {
        "search_mare": "Search for a mare by Name, Code or Microchip",
        "search_stallion": "Search for a stallion by Name, Code or Microchip",
        "select_mare": "Select mare",
        "select_stallion": "Select stallion"
      },
      "section-title": {
        "active_passport": "Active passport",
        "additional_info": "Additionnal information",
        "identification": "Identification",
        "numbering": "Numbering",
        "previous_passports": "Old passports",
        "registration": "Registration",
        "structured_signage": "Codified description"
      },
      "stallions": "Stallions",
      "tabs": {
        "delivrables": {
          "title": "Biling"
        },
        "documents": {
          "title": "Documents"
        },
        "generalites": {
          "Identification": "Identification",
          "title": "General points"
        },
        "identification": {
          "title": "Identification"
        },
        "labels": {
          "title": "Labels"
        },
        "passports": {
          "title": "Passports"
        },
        "pedigree": {
          "title": "Pedigree"
        },
        "people": {
          "title": "People"
        },
        "production": {
          "title": "Production"
        },
        "reproduction": {
          "title": "Reproduction"
        },
        "results": {
          "title": "Results"
        },
        "signalement": {
          "title": "Description"
        },
        "studbook_documents": {
          "card_title": "Documents",
          "created_by": "Created by",
          "date": "Date",
          "download": "Download",
          "name": "Name",
          "no_documents": "No document",
          "size": "Size",
          "title": "Documents %{studbook}"
        }
      },
      "unknown": "Unknown",
      "values": {
        "dna_filiation_compatible": {
          "ko": "Incompatible filiation",
          "ok": "Compatible filiation"
        },
        "food_chain": {
          "0": "Maintained",
          "1": "Excluded",
          "false": "Maintained",
          "true": "Excluded"
        },
        "sexe": {
          "0": "Unknown",
          "1": "Male",
          "2": "Female"
        },
        "sexe_short": {
          "0": "?",
          "1": "M",
          "2": "F"
        },
        "ueln_types": {
          "auto": "Yes, definitive n°",
          "manual": "No, manual input",
          "temp": "Yes, temporary n°"
        }
      }
    },
    "identifications": {
      "edit": {
        "page_title": "Edition identification request",
        "title": "Edit identification request"
      },
      "flash": {
        "bulk": {
          "fail": "No identification selected"
        },
        "create": {
          "fail": "Request has not been created",
          "success": "Identification request saved"
        },
        "finalized": {
          "fail": {
            "one": "Request could not be closed",
            "other": "Requests could not be closed"
          },
          "success": {
            "one": "Request closed",
            "other": "Requests closed"
          }
        },
        "finalizing": {
          "fail": {
            "one": "Request could not be closed",
            "other": "Requests could not be closed"
          },
          "success": {
            "one": "Request under closing",
            "other": "Requests under closing"
          }
        },
        "received": {
          "fail": {
            "one": "Request could not be received",
            "other": "Request could not be received"
          },
          "success": {
            "one": "Request received",
            "other": "Requests received"
          }
        },
        "receiving": {
          "fail": {
            "one": "Request could not be received",
            "other": "Requests could not be received"
          },
          "success": {
            "one": "Request under receiving",
            "other": "Request under receiving"
          }
        },
        "transfered": {
          "fail": {
            "one": "Request could not be transferred",
            "other": "Requests could not be transferred"
          },
          "success": {
            "one": "Request transferred",
            "other": "Requests transferred"
          }
        },
        "transfering": {
          "fail": {
            "one": "Request could not be transferred",
            "other": "Requests could not be transferred"
          },
          "success": {
            "one": "Request transfering",
            "other": "Request transfering"
          }
        },
        "update": {
          "fail": "Request couldn't be updated",
          "fail_horse_id": "Request couldn't be updated in HorseID",
          "success": "Request updated",
          "success_horse_id": "Request updated in both SireCloud and HorseID"
        },
        "validated": {
          "fail": {
            "one": "Request could not be validated",
            "other": "Requests could not be validated"
          },
          "success": {
            "one": "Request validated",
            "other": "Requests validated"
          }
        },
        "validating": {
          "fail": {
            "one": "Request could not be validated",
            "other": "Requests could not be validated"
          },
          "success": {
            "one": "Request under validation",
            "other": "Requests under validation"
          }
        }
      },
      "holder_selected_email_no_email": "No email known for the holder",
      "holder_selected_email_no_holder": "Please choose an holder",
      "horseid": {
        "attributes": {
          "exploitationBox": "Box",
          "exploitationCountry": "Country",
          "exploitationLocality": "Locality",
          "exploitationNumber": "Number",
          "exploitationPostCode": "Zip code",
          "exploitationStreet": "Street",
          "horse": {
            "birthCountry": "Country of birth",
            "birthDate": "Date of birth",
            "breeders": "Breeders",
            "butcherFoal": "Slaughter foal",
            "castrated": "Castrated",
            "castrationDate": "Castration date",
            "commercialName": "Commercial name",
            "detentionPlace": "Establishment number",
            "detentionSince": "Date of arrival of the product at this establishment",
            "dressColor": "Dress color",
            "excludingFoodChain": "Excluded from the food chain",
            "excludingFoodChainDate": "Date of exclusion from the food chain",
            "father": {
              "name": "Father's name",
              "transponder": "Father's transponder number",
              "ueln": "Father's UELN"
            },
            "gender": "Gender",
            "hasOrigin": "Has a pedigree",
            "holder": "CBC ID of holder",
            "holderTmp": "Temporary CBC ID of holder",
            "id": "Code",
            "identification": {
              "dna": "Product mane hairs",
              "identificationDate": "Identification date",
              "identificationReturnDate": "Date of return of the file",
              "identifier": "Veterinary order number",
              "identifierRemark": "Identifier's remark",
              "motherDna": "Mother's mane hairs",
              "online": "Procedure",
              "textualReporting": "Textual description"
            },
            "mother": {
              "name": "Mother's name",
              "transponder": "Mother's transponder number",
              "ueln": "Mother's UELN"
            },
            "name": "Name",
            "privateRemark": "Private remarks",
            "race": "Breed",
            "responsibleAgency": "Responsible agency",
            "studbookId": "Code",
            "transponder": "Transponder number",
            "type": "Type",
            "ueln": "UELN"
          },
          "horseBirthCountry": "Country",
          "horseBirthdate": "Date of birth",
          "horseBreeder1": "CBC ID of breeder 1",
          "horseBreeder2": "CBC ID of breeder 2",
          "horseButcherFoal": "Slaughter-bound foal",
          "horseCastrated": "Castrated",
          "horseCommercialName": "Sport's name",
          "horseDressColor": "Coat color",
          "horseExcludingFoodChain": "Excluded from the food chain",
          "horseExcludingFoodChainDate": "Exclusion date from the food chain",
          "horseFather": "Sire's UELN",
          "horseGender": "Sex",
          "horseHolder": "CBC ID of the holder",
          "horseMother": "Mare's UELN",
          "horseName": "Name",
          "horseOrigin": "Origin",
          "horseRace": "Breed",
          "horseReporting": "Textual description",
          "horseTransponder1": "Microchip n°",
          "horseType": "Type",
          "horseUeln": "UELN",
          "id": "Equidae code",
          "identificationDate": "Identification",
          "identificationDna": "Product's hair sample",
          "identificationMotherDna": "Mare's hair sample",
          "identificationReturnDate": "File's return date",
          "identificationType": "Request type",
          "identifier": "Veterinarian's licence number",
          "identifierRemark": "Identifier's remark",
          "online": "Online request",
          "privateRemark": "Remark",
          "responsibleAgency": "Responsible agency's UELN",
          "transmitterAgency": "UELN transmitter agency"
        },
        "contact_creation_error": "Contact couldn't be created : %{error}",
        "contact_creation_success": "Contact created in HorseID with ID %{cbc_id}",
        "contact_creation_unavailable": "Identification in HorseID unavailable, please try later",
        "contact_ignore_update_error": "Update couldn't be ignored: %{error}",
        "contact_ignore_update_success": "Update ignored",
        "contact_update_error": "Contact couldn't be updated: %{error}",
        "contact_update_success": "Contact updated in HorseID",
        "contact_update_unavailable": "Update in HorseID unavailable, please try later"
      },
      "index": {
        "search": {
          "placeholder": "Quick search: Name, UELN, Code"
        }
      },
      "new": {
        "horse_not_found": "Horse not found",
        "identification_already_requested": "Identification already requested for this Equidae",
        "page_title": "New identification request",
        "title": "Create new identification request"
      },
      "sections": {
        "additional_requests": "Additionnal requests",
        "birth_informations": "Birth information",
        "breeder_details": "Breeder's contact details",
        "breeder_identity": "Breeder's Identity",
        "co_breeder_details": "Co-breeder's contact details",
        "co_breeder_identity": "Co-breeder's identity",
        "co_owner_details": "Co-owner's contact details",
        "co_owner_identity": "Co-owner's identity",
        "first_holder": "Holder",
        "followup": "Follow up",
        "holding_place": "Detention place",
        "identification_date": "Identification date",
        "identification_payment_html": "Procedure",
        "identification_return_date": "Receiving date",
        "identifier": "Identifier",
        "owner_details": "Owner's contact details",
        "owner_identity": "Owner's identity",
        "product_identity": "Product's identity",
        "product_informations": "Product information",
        "remarks": "Identifier's remarks"
      },
      "show": {
        "page_title": "Identification request",
        "title": "Identification request"
      },
      "values": {
        "boolean": {
          "0": "No",
          "1": "Yes",
          "false": "No",
          "true": "Yes"
        },
        "sexe": {
          "0": "Male",
          "1": "Unknown",
          "2": "Female",
          "female": "Female",
          "male": "Male"
        },
        "status": {
          "finalized": {
            "one": "Closed",
            "other": "Closed"
          },
          "finalizing": {
            "one": "Under closing",
            "other": "Under closing"
          },
          "receiving": {
            "one": "Under receiving",
            "other": "Under receiving"
          },
          "to_finalize": {
            "one": "In progress",
            "other": "In progress"
          },
          "to_receive": {
            "one": "Validated",
            "other": "Validated"
          },
          "to_transfer": {
            "one": "To transfer",
            "other": "To transfer"
          },
          "to_validate": {
            "one": "Transferred",
            "other": "Transferred"
          },
          "transfering": {
            "one": "Transfering",
            "other": "Transfering"
          },
          "validating": {
            "one": "Under validation",
            "other": "Under validation"
          }
        }
      }
    },
    "layouts": {
      "application": {
        "sign_in": "Log in",
        "sign_out": "Log out"
      }
    },
    "locales": {
      "choose": "Choose your language",
      "de": "Deutsch",
      "en": "English",
      "fr": "Français",
      "it": "Italiano",
      "lang": "Language",
      "nl": "Nederlands"
    },
    "modal": {
      "card_closed": {
        "intro_html": "<p>The card can no longer be modified, the data has been transmitted to the studbook and the official covering document will soon be sent to you.<p>",
        "title": "Card has been closed"
      },
      "confirm_card_closing": {
        "intro_html": "<p>Are you sure you want to close the card? You will not be able to edit it afterwards.</p>",
        "title": "Are you sure?"
      },
      "confirm_convert_to_organisation": {
        "intro_html": "Are you sure? Conversion to Organization is irreversible.",
        "title": "Are you sure?"
      },
      "confirm_delete_birth_registration": {
        "intro_html": "<p>The birth declaration will be deleted. All information entered will be lost.</p>",
        "title": "Are you sure?"
      },
      "confirm_delete_card": {
        "intro_html": "<p>This covering certificate will be deleted. All encoded information will be lost.</p>",
        "title": "Are you sure?"
      },
      "confirm_delete_delivrable": {
        "intro_html": "<p>This service will be deleted. You will lose all the information encoded.<p>",
        "title": "Are you sure?"
      },
      "confirm_delete_passports": {
        "intro_html": "<p>Confirm deletion of the passports?</p>",
        "title": "Are you sure?"
      },
      "confirm_delete_signage": {
        "intro_html": "<p>This description will be deleted.</p>",
        "title": "Are you sure?"
      },
      "confirm_delete_studbook_document": {
        "intro_html": "\"<p>This document will be permanently deleted.</p>\"",
        "title": "Are you sure?"
      },
      "connexion_expired": {
        "intro_html": "<The connection has expired, please reload the page to continue.<br />Please contact us if the problem persists.</p>",
        "title": "Connexion expired"
      },
      "dna_controls_confirm": {
        "intro_html": "Beware: you are going to validate one or more DNA tests !<br>The date of the DNA test will be added and the parentage compatibility validated.<br> You will still have to encode the reference of the laboratory test.",
        "title": "Are you sure?"
      },
      "identifications_received_date": {
        "title": "Please fill in the receiving date"
      },
      "passport": {
        "title": "Passport's details"
      },
      "passports_sent_date": {
        "title": "Please fill in the sending date"
      },
      "upload_signage": {
        "title": "Add graphic description",
        "title_edit": "Edit graphic description"
      },
      "upload_studbook_document": {
        "title": "Add documents"
      },
      "welcome": {
        "etalonnier_html": "<p> Simplify your life by accessing a maximum of services and information in a minimum of time, at any time.</p><p>View your stallions and their production, order and manage your breeding cards, consult your breeding and birth history, edit data... These online services - and many others - are directly accessible via your personal space.</p>",
        "intro_html": "<p>Hello %{username},</p><p>You are now connected to your SireCloud personal space.</p>",
        "title": "Welcome to SireCloud"
      }
    },
    "notes": {
      "flash": {
        "create": {
          "fail": "Note could not be saved",
          "success": "Note saved"
        },
        "destroy": {
          "fail": "Note could not be deleted",
          "success": "Note deleted"
        },
        "list": {
          "fail": "Notes could not be loaded",
          "no_more_notes": "No other note to show",
          "success": "Notes loaded"
        }
      },
      "values": {
        "privacy": {
          "0": "Public",
          "1": "Shared",
          "2": "Intern",
          "3": "Private"
        }
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "nth": {
        "ordinalized": "#<Proc:0x00007fbc0609b6d0 /home/olivier/.rbenv/versions/3.1.2/lib/ruby/gems/3.1.0/gems/activesupport-7.0.4/lib/active_support/locale/en.rb:26 (lambda)>",
        "ordinals": "#<Proc:0x00007fbc0609b6f8 /home/olivier/.rbenv/versions/3.1.2/lib/ruby/gems/3.1.0/gems/activesupport-7.0.4/lib/active_support/locale/en.rb:7 (lambda)>"
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organisations": {
      "actions": {
        "confirm_destroy": "Are you sure you want to deleted this organization?",
        "destroy": "Delete tghis organization"
      },
      "flash": {
        "create": {
          "fail": "Organisation could not be saved.",
          "success": "New organisation saved."
        },
        "destroy": {
          "success": "Organization has been deleted."
        },
        "update": {
          "fail": "Information could not be updated",
          "success": "Organisation Information updated"
        }
      },
      "horseid": {
        "attributes": {
          "organisation": {
            "address": {
              "box": "Box",
              "country": "Country",
              "locality": "City",
              "number": "Number",
              "postalCode": "Zip code",
              "street": "Street"
            },
            "bceNumber": "ECB number",
            "email": "Email",
            "fax": "Fax",
            "gsm": "Mobile",
            "language": "Language",
            "legalForm": "Legal form",
            "name": "Name",
            "office1": "Tel. office 1",
            "office2": "Tel. office 2",
            "phone1": "Tel. 1",
            "phone2": "Tel. 2",
            "responsible": "Responsible",
            "studbookId": "ID studbook",
            "subjectToVat": "Subject to VAT"
          }
        }
      },
      "new": {
        "page_title": "New organisation",
        "title": "Save a new organisation"
      }
    },
    "pages": {
      "access_request": {
        "fields": {
          "firstname_label": "First name",
          "name_label": "Last name",
          "stallions_label": "Stallions",
          "stallions_placeholder": "Type here the stallions (name or UELN) you want to manage in your Sirecloud space.",
          "studbook_ids_label": "Studbook"
        },
        "intro_html": "<p>Complete the form and submit it. </p><p>You will receive an email shortly after to the address mentioned in the form. It includes a link to activate your account. </p><p>Click on the link in the email. Then, enter the password you want to use. </p>",
        "is_company": "User represents a company",
        "page_title": "SireCloud - Access request",
        "subtitle": "Personal access request",
        "title": "New SireCloud user"
      },
      "errors": {
        "404": {
          "back_link": "Back",
          "current_database_warning_html": "You are currently connected to the %{current_database}. This id may belong to another database:",
          "header": "Error 404",
          "home_link": "SireCloud homepage",
          "model_text_html": "The ID %{id} is incorrect or does not exist inside database.",
          "model_title": "%{model} cannot be found",
          "page_title": "SireCloud - Error 404",
          "text_html": "The page you are looking for does not exist or the URL is incorrect.",
          "title": "Page not found"
        },
        "500": {
          "back_link": "Back",
          "header": "Error 500",
          "home_link": "SireCloud homepage",
          "page_title": "SireCloud - Error 500",
          "text_html": "An error occurred and the requested page could not be displayed.<br /><br /> We have been notified of this error and will correct it as soon as possible.<br /><br /> If the problem persists or you need assistance, please do not hesitate to contact us.",
          "title": "Error - This page is not working"
        }
      },
      "order_coupling": {
        "before_form_html": "<p> To order extra covering cards, fill-in each field of the form below and confirm. </p>",
        "fields": {
          "quantity_label_html": "Number of cards <small>( price without VAT)</small>",
          "terms_label_html": "I have read and approved the <a data-toggle=\"modal\" data-target=\"#cguModal\" href=\"#\">general terms</a> regarding the ordering of breeding cards."
        },
        "intro_html": "<!--<p> The SBS covering cards (electronic form since 2019) are delivered to the stallions' owners if they meet the following conditions: </p> <ul> <li> Being up-to-date with the membership fee\r\n</li> <li> Holding all relevant rights over the stallion's semen.</li> <li>Having paid the yearly fee related to the stallion.</li> </ul> <p> The first 25 covering certificates are included in the yearly fee and are freely delivered. </p>-->",
        "section-title": {
          "order": "Order",
          "owner": "Stallion's owner identity",
          "owner_details": "Stallion's owner details",
          "stallion": "Stallion",
          "terms": "General terms"
        },
        "subtitle": "Order extra cards"
      }
    },
    "passports": {
      "actions": {
        "confirm_destroy": "Confirm definitive deletion of the passport.",
        "destroy": "Delete this passport"
      },
      "edit": {
        "page_title": "Edit passport"
      },
      "index": {
        "search": {
          "placeholder": "Quick search: Name, UELN, Code"
        }
      },
      "new": {
        "page_title": "New passport"
      }
    },
    "passwords": {
      "create": {
        "description": "You will soon receive an e-mail with the instructions to choose your password.",
        "description_cont": "Follow these instructions to log in to Sirecloud",
        "title": "Password creation"
      },
      "edit": {
        "description": "Your password will be reinitiated. Please choose a new one.",
        "title": "New password"
      },
      "new": {
        "description": "In order to receive an e-mail with the instructions to choose a password, please fill in your e-mail adress.",
        "title": "New password"
      }
    },
    "people": {
      "actions": {
        "confirm_destroy": "Confirm definitive deletion of the person.",
        "destroy": "Delete people"
      },
      "civility": {
        "short_texts": {
          "1": "Mrs.",
          "2": "Ms.",
          "3": "Mr.",
          "4": "Dr.",
          "5": "Prof.",
          "6": "Mr.",
          "7": "Mgr."
        },
        "texts": {
          "1": "Madam",
          "2": "Miss",
          "3": "Mister",
          "4": "Doctor",
          "5": "Professor",
          "6": "Maître",
          "7": "Monseigneur"
        }
      },
      "edit": {
        "page_title": "Edit a person",
        "title": "Edit person"
      },
      "fee": {
        "ko": "Unpaid",
        "ok": "Ok",
        "payed_on": "Paid on the %{date}"
      },
      "flash": {
        "create": {
          "success": "New person saved"
        },
        "destroy": {
          "success": "People has been deleted"
        },
        "update": {
          "success": "The data of the person have been updated."
        }
      },
      "horseid": {
        "attributes": {
          "person": {
            "address": {
              "box": "Box",
              "country": "Country",
              "locality": "City",
              "number": "Number",
              "postalCode": "Zip code",
              "street": "Street"
            },
            "bceNumber": "ECB number",
            "birthDate": "Date of birth",
            "civility": "Civility",
            "email": "Email",
            "fax": "Fax",
            "firstName": "First name",
            "gsm": "Mobile",
            "language": "Language",
            "lastName": "Last name",
            "nationalRegister": "National number",
            "office1": "Tel. office 1",
            "office2": "Tel. office 2",
            "phone1": "Tel. 1",
            "phone2": "Tel. 2",
            "studbookId": "ID studbook",
            "subjectToVat": "Subject to VAT"
          }
        }
      },
      "index": {
        "search": {
          "placeholder": "Search people"
        }
      },
      "new": {
        "page_title": "New person",
        "title": "Register a new person"
      },
      "tabs": {
        "addresses": {
          "title": "Addresses"
        },
        "contacts": {
          "title": "Contact details"
        }
      },
      "unknown": "Unkown"
    },
    "per_pagable": {
      "menu_title": "Paginate per",
      "title": "Results"
    },
    "popover": {
      "birth_registration": {
        "access_token_html": "Fill in here the access code of the covering certificate mentioned on the document.",
        "identification_type_html": "<strong>Online procedure</strong>: Identification is exclusively done online. Information and documents are sent to you by email. Payment is also made online.<br/><strong>Paper procedure</strong>: Information and documents are sent to you by post. The payment is made by bank transfer."
      },
      "cbc_horse": {
        "statuses": "<strong>In preparation</strong> : creation being prepared by the Studbook.<br /><strong> Closed</strong> : creation transferred and closed in HorseID."
      },
      "contact": {
        "type_html": "Any group of people is considered to be an 'association'."
      },
      "identification": {
        "statuses": "<strong>In preparation</strong> : request being prepared by the Studbook.<br/><strong>In progress</strong>: request transferred and in the process of being closed in HorseID.<br /><strong>Finalized</strong>: request closed in HorseID.<br /><strong>Canceled</strong>: request canceled (e.g. deceased equine, etc.)."
      }
    },
    "ppl_and_org": {
      "tabs": {
        "contact_details": {
          "title": "Contact details"
        },
        "generalites": {
          "title": "General points"
        }
      },
      "user_access": {
        "no_access": "No user access",
        "title": "SireCloud access"
      }
    },
    "replace_by": "Replace by",
    "sessions": {
      "form": {
        "forgot_password": "Forgot your password?",
        "sign_up": "Sign up"
      },
      "new": {
        "description": "Connect to SireCloud with your email address and password.",
        "new_user": {
          "cta": "Request your personnal access",
          "intro": "New user ?"
        },
        "title": "Log in"
      }
    },
    "shrine": {
      "max_size_extensions": "The file should be %{max_size} or less. Authorized extensions :  %{extensions}."
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Please review the problems below:"
      },
      "no": "No",
      "required": {
        "mark": "*",
        "text": "required"
      },
      "yes": "Yes"
    },
    "since_date": "since the %{date}",
    "sortable": {
      "menu_title": "Sort results"
    },
    "studbook_documents": {
      "flash": {
        "create": {
          "document": {
            "fail": "Failed to add document",
            "success": "Document added"
          }
        },
        "destroy": {
          "document": {
            "fail": "Failed to delete document",
            "success": "Document deleted"
          }
        },
        "index": {
          "fail": "Failed to load documents",
          "success": "Documents loaded"
        }
      },
      "note": "Maximum %{max_size} per file",
      "s3_unavailable": "The S3 service is currently unavailable, documents cannot be displayed."
    },
    "studbook_mailer": {
      "horse_has_no_identification_to_finalize": {
        "subject": "Horse %{horse_name} (%{horse_alias}) has no identification to finalize"
      }
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "real_short_date": "%y-%m-%d",
        "s3": "%Y-%m-%d %H:%M",
        "short": "%d %b %H:%M",
        "short_date": "%Y-%m-%d"
      },
      "pm": "pm"
    },
    "to_date": "until %{date}",
    "user": {
      "edit": {
        "page_title": "Edit user",
        "title": "Edit SireCloud user access"
      },
      "flash": {
        "connection_expired": "Connexion expired, please try again.",
        "create": {
          "success": "User access created."
        },
        "reset_password": {
          "success": "Email sent to reset password."
        },
        "update": {
          "fail": "Updates could not be saved",
          "success": "User updated."
        }
      }
    },
    "user_mailer": {
      "welcome": {
        "cta": "Entering SireCloud",
        "cta_help": "If the link is not working, you could also copy/paste the following address on your browser:",
        "intro_html": "<p>Hello %{username},</p><p>Your personnal access to SireCloud has just been created.</p><p>Click on the link below to activate your access and choose your password.</p>",
        "subject": "Your personal access to SireCloud",
        "title": "Welcome to SireCloud"
      }
    },
    "users": {
      "new": {
        "sign_in": "Log in",
        "title": "Sign up"
      }
    },
    "validates_timeliness": {
      "error_value_formats": {
        "date": "%Y-%m-%d",
        "datetime": "%Y-%m-%d %H:%M:%S",
        "time": "%H:%M:%S"
      }
    },
    "views": {
      "form_warning": "The form's data are not yet saved.",
      "pagination": {
        "first": "First page",
        "last": "Last page",
        "next": "Next page",
        "previous": "Previous page",
        "truncate": "&hellip;"
      }
    }
  },
  "fr": {
    "activerecord": {
      "attributes": {
        "birth_registration": {
          "birth_country": "Pays de naissance",
          "birth_date": "Date de naissance",
          "breed_category_id": "Type d'équidé",
          "breeder_data_addr1": "Adresse",
          "breeder_data_addr2": "Adresse 2",
          "breeder_data_asl": "Codice Azienda (ASL)",
          "breeder_data_box": "Boite",
          "breeder_data_civility": "Civilité",
          "breeder_data_company": "Intitulé",
          "breeder_data_company_country": "Pays",
          "breeder_data_company_number": "Numéro de TVA ou Numéro d'entreprise",
          "breeder_data_company_vat_subject": "Assujetti",
          "breeder_data_country_code": "Pays",
          "breeder_data_email": "Email",
          "breeder_data_fax": "Fax",
          "breeder_data_firstname": "Prénom",
          "breeder_data_lastname": "Nom",
          "breeder_data_legal_form_id": "Forme juridique",
          "breeder_data_legal_form_other": "Forme (autre)",
          "breeder_data_locality": "Ville",
          "breeder_data_mobile": "GSM",
          "breeder_data_national_number": "Numéro national",
          "breeder_data_national_number_country": "Pays",
          "breeder_data_number": "Numéro",
          "breeder_data_phone": "Téléphone",
          "breeder_data_vat": "Numéro de TVA ou Numéro d'entreprise",
          "breeder_data_vat_number": "Numéro de TVA",
          "breeder_data_zip": "Code postal",
          "co_breeder_data_addr1": "Adresse",
          "co_breeder_data_addr2": "Adresse 2",
          "co_breeder_data_asl": "Codice Azienda (ASL)",
          "co_breeder_data_box": "Boite",
          "co_breeder_data_civility": "Civilité",
          "co_breeder_data_company": "Intitulé",
          "co_breeder_data_company_country": "Pays",
          "co_breeder_data_company_number": "Numéro de TVA ou Numéro d'entreprise",
          "co_breeder_data_company_vat_subject": "Assujetti",
          "co_breeder_data_country_code": "Pays",
          "co_breeder_data_email": "Email",
          "co_breeder_data_fax": "Fax",
          "co_breeder_data_firstname": "Prénom",
          "co_breeder_data_lastname": "Nom",
          "co_breeder_data_legal_form_id": "Forme juridique",
          "co_breeder_data_legal_form_other": "Forme (autre)",
          "co_breeder_data_locality": "Ville",
          "co_breeder_data_mobile": "GSM",
          "co_breeder_data_national_number": "Numéro national",
          "co_breeder_data_national_number_country": "Pays",
          "co_breeder_data_number": "Numéro",
          "co_breeder_data_phone": "Téléphone",
          "co_breeder_data_vat": "Numéro de TVA ou Numéro d'entreprise",
          "co_breeder_data_vat_number": "Numéro de TVA",
          "co_breeder_data_zip": "Code postal",
          "co_owner_data_addr1": "Adresse",
          "co_owner_data_addr2": "Adresse 2",
          "co_owner_data_asl": "Codice Azienda (ASL)",
          "co_owner_data_box": "Boite",
          "co_owner_data_civility": "Civilité",
          "co_owner_data_company": "Intitulé",
          "co_owner_data_company_country": "Pays",
          "co_owner_data_company_number": "Numéro de TVA ou Numéro d'entreprise",
          "co_owner_data_company_vat_subject": "Assujetti",
          "co_owner_data_country_code": "Pays",
          "co_owner_data_email": "Email",
          "co_owner_data_fax": "Fax",
          "co_owner_data_firstname": "Prénom",
          "co_owner_data_lastname": "Nom",
          "co_owner_data_legal_form_id": "Forme juridique",
          "co_owner_data_legal_form_other": "Forme (autre)",
          "co_owner_data_locality": "Ville",
          "co_owner_data_mobile": "GSM",
          "co_owner_data_national_number": "Numéro national",
          "co_owner_data_national_number_country": "Pays",
          "co_owner_data_number": "Numéro",
          "co_owner_data_phone": "Téléphone",
          "co_owner_data_vat": "Numéro de TVA ou Numéro d'entreprise",
          "co_owner_data_vat_number": "Numéro de TVA",
          "co_owner_data_zip": "Code postal",
          "coupling_card_access_token": "URL d'accès à la carte",
          "coupling_card_copy": "Copie de la carte de saillie",
          "coupling_card_number": "Numéro de carte",
          "coupling_card_studbook": "Nom du studbook",
          "created_at": "Date d'enregistrement",
          "detention_place_code": "Numéro d'établissement",
          "detention_since": "Date d'arrivée du produit à cet établissement",
          "dress_name": "Robe",
          "embryo_transfer": "Transfert d'embryon",
          "global_breeder": "Naisseur",
          "global_co_breeder": "Co-naisseur",
          "global_co_owner": "Co-propriétaire",
          "global_holder": "Détenteur",
          "global_owner": "Propriétaire",
          "holder_addr1": "Adresse de stationement",
          "holder_data_addr1": "Adresse",
          "holder_data_addr2": "Adresse 2",
          "holder_data_asl": "Codice Azienda (ASL)",
          "holder_data_box": "Boite",
          "holder_data_cbc_id": "ID CBC",
          "holder_data_civility": "Civilité",
          "holder_data_company": "Intitulé",
          "holder_data_company_country": "Pays",
          "holder_data_company_number": "Numéro de TVA ou Numéro d'entreprise",
          "holder_data_company_vat_subject": "Assujetti",
          "holder_data_country_code": "Pays",
          "holder_data_email": "Email",
          "holder_data_fax": "Fax",
          "holder_data_firstname": "Prénom",
          "holder_data_lastname": "Nom",
          "holder_data_legal_form_id": "Forme juridique",
          "holder_data_legal_form_other": "Forme (autre)",
          "holder_data_locality": "Ville",
          "holder_data_mobile": "GSM",
          "holder_data_national_number": "Numéro national",
          "holder_data_national_number_country": "Pays",
          "holder_data_number": "Numéro",
          "holder_data_phone": "Téléphone",
          "holder_data_vat": "Numéro de TVA ou Numéro d'entreprise",
          "holder_data_vat_number": "Numéro de TVA",
          "holder_data_zip": "Code postal",
          "holder_firstname": "Nom du détenteur",
          "holder_lastname": "Prénom du détenteur",
          "holding_place": "Lieu de détention",
          "holding_place_data_addr1": "Adresse",
          "holding_place_data_box": "Boite",
          "holding_place_data_capacity": "Capacité",
          "holding_place_data_category": "Catégorie",
          "holding_place_data_country_code": "Pays",
          "holding_place_data_locality": "Ville",
          "holding_place_data_number": "Numéro",
          "holding_place_data_type": "Type",
          "holding_place_data_zip": "Code postal",
          "horse_database": "Studbook",
          "horse_database_id": "Studbook",
          "horse_private_alias": "Code",
          "icsi": "Intra-cytoplasmic sperm injection (ICSI)",
          "identificatio_online": "Procédure",
          "identification_dna": "Crins produit",
          "identification_mother_dna": "Crins mère",
          "identification_type": "Demande d'identification",
          "is_detention_place_operator": "Êtes-vous l'opérateur de l'établissement ?",
          "mare_birth_date": "Date de naissance",
          "mare_code": "Code de la jument",
          "mare_father_name": "Père de la jument",
          "mare_id": "Jument",
          "mare_microchip": "Microchip de la jument",
          "mare_mother_name": "Mère de la jument",
          "mare_name": "Nom de la jument",
          "name": "Nom du produit",
          "no_coupling_card_data_agree": "Conditions générales",
          "operator_data_addr1": "Adresse",
          "operator_data_addr2": "Adresse 2",
          "operator_data_box": "Boite",
          "operator_data_cbc_id": "ID CBC",
          "operator_data_civility": "Civilité",
          "operator_data_company": "Intitulé",
          "operator_data_company_country": "Pays",
          "operator_data_company_number": "Numéro de TVA ou Numéro d'entreprise",
          "operator_data_company_vat_subject": "Assujetti",
          "operator_data_country_code": "Pays",
          "operator_data_email": "Email",
          "operator_data_fax": "Fax",
          "operator_data_firstname": "Prénom",
          "operator_data_lastname": "Nom",
          "operator_data_legal_form_id": "Forme juridique",
          "operator_data_legal_form_other": "Forme (autre)",
          "operator_data_locality": "Ville",
          "operator_data_mobile": "GSM",
          "operator_data_national_number": "Numéro national",
          "operator_data_national_number_country": "Pays",
          "operator_data_number": "Numéro",
          "operator_data_phone": "Téléphone",
          "operator_data_vat": "Numéro de TVA ou Numéro d'entreprise",
          "operator_data_vat_number": "Numéro de TVA",
          "operator_data_zip": "Code postal",
          "owner_data_addr1": "Adresse",
          "owner_data_addr2": "Adresse 2",
          "owner_data_asl": "Codice Azienda (ASL)",
          "owner_data_box": "Boite",
          "owner_data_civility": "Civilité",
          "owner_data_company": "Intitulé",
          "owner_data_company_country": "Pays",
          "owner_data_company_number": "Numéro de TVA ou Numéro d'entreprise",
          "owner_data_company_vat_subject": "Assujetti",
          "owner_data_country_code": "Pays",
          "owner_data_email": "Email",
          "owner_data_fax": "Fax",
          "owner_data_firstname": "Prénom",
          "owner_data_lastname": "Nom",
          "owner_data_legal_form_id": "Forme juridique",
          "owner_data_legal_form_other": "Forme (autre)",
          "owner_data_locality": "Ville",
          "owner_data_mobile": "GSM",
          "owner_data_national_number": "Numéro national",
          "owner_data_national_number_country": "Pays",
          "owner_data_number": "Numéro",
          "owner_data_phone": "Téléphone",
          "owner_data_vat": "Numéro de TVA ou Numéro d'entreprise",
          "owner_data_vat_number": "Numéro de TVA",
          "owner_data_zip": "Code postal",
          "received_at": "Reçue le",
          "sexe": "Sexe",
          "slaughter_foal": "Poulain d'abattage",
          "stalion_birth_date": "Date de naissance",
          "stalion_code": "Code de l'étalon",
          "stalion_father_name": "Père de l'étalon",
          "stalion_id": "Etalon",
          "stalion_microchip": "Microchip de l'étalon",
          "stalion_mother_name": "Mère de l'étalon",
          "stalion_name": "Nom de l'étalon",
          "station_be": "Pays de stationnement",
          "stationed_in_belgium": "Le produit est-il stationné en Belgique ?",
          "validated_at": "Validée le",
          "vet_id": "vétérinaire choisi pour l'identification."
        },
        "cbc_horse": {
          "breeder": "Naisseur",
          "breeder_cbc_id": "ID du naisseur",
          "breeder_id": "Naisseur",
          "co_breeder": "Co-naisseur",
          "co_breeder_cbc_id": "ID du co-naisseur",
          "co_breeder_id": "Co-naisseur",
          "exploitation_locality_id": "Localité",
          "global_breeder": "Naisseur",
          "global_co_breeder": "Co-naisseur",
          "global_holder": "Détenteur",
          "holder": "Détenteur",
          "holder_cbc_id": "ID du détenteur",
          "holder_id": "Détenteur",
          "holder_selected_email": "Email du détenteur",
          "horseID_params_exploitationBox": "Boîte",
          "horseID_params_exploitationCountry": "Pays",
          "horseID_params_exploitationLocality": "Localité",
          "horseID_params_exploitationNumber": "Numéro",
          "horseID_params_exploitationPostCode": "Code postal",
          "horseID_params_exploitationStreet": "Rue",
          "horseID_params_foreignAlias": "Alias d'origine",
          "horseID_params_horseDateHolding": "Date du début de détention",
          "horseID_params_horse_birthCountry": "Pays",
          "horseID_params_horse_birthDate": "Date de naissance",
          "horseID_params_horse_breeders": "Id CBC des naisseurs",
          "horseID_params_horse_butcherFoal": "Poulain d'abattage",
          "horseID_params_horse_castrated": "Castré",
          "horseID_params_horse_commercialName": "Nom commercial",
          "horseID_params_horse_dateExcludingFoodChain": "Date d’exclusion de la chaîne alimentaire",
          "horseID_params_horse_detentionPlace": "Numéro d'établissement",
          "horseID_params_horse_detentionSince": "Date d'arrivée du produit à cet établissement",
          "horseID_params_horse_dressColor": "Couleur de la robe",
          "horseID_params_horse_excludingFoodChain": "Exclu de la chaîne alimentaire",
          "horseID_params_horse_father_ueln": "UELN père",
          "horseID_params_horse_gender": "Sexe",
          "horseID_params_horse_holder": "Id CBC du détenteur",
          "horseID_params_horse_holderTmp": "Id CBC (temp.) du détenteur",
          "horseID_params_horse_mother_ueln": "UELN mère",
          "horseID_params_horse_name": "Nom",
          "horseID_params_horse_origin": "Origine",
          "horseID_params_horse_privateRemark": "Remarques",
          "horseID_params_horse_race": "Race",
          "horseID_params_horse_responsibleAgency": "UELN agence responsable",
          "horseID_params_horse_studbookId": "Code équidé",
          "horseID_params_horse_transponder": "N° transpondeur",
          "horseID_params_horse_type": "Type",
          "horseID_params_horse_ueln": "UELN",
          "horseID_params_identification_textualReporting": "Signalement textuel",
          "horseID_params_transmitterAgency": "UELN agence émettrice",
          "private_notes": "Remarques privées",
          "transfered_at": "Transfert",
          "validated_at": "Validation"
        },
        "contact/fee": {
          "amount": "Montant",
          "payed_at": "Date paiement",
          "year": "Année"
        },
        "contact_detail": {
          "detail_type": "Type"
        },
        "coupling_card": {
          "carrying_mare": "Mère porteuse du produit à naître",
          "carrying_mare_code": "Code de la jument porteuse",
          "carrying_mare_microchip": "Microchip de la jument porteuse",
          "carrying_mare_name": "Nom de la jument porteuse",
          "completed_at": "Date de clôture",
          "coupler": "Inséminateur",
          "coupling_dates": {
            "one": "Date de saillie",
            "other": "Dates de saillie"
          },
          "coupling_mode": "Mode de saillie",
          "embryo_transfer": "Transfert d'embryon",
          "global_coupler": "Inséminateur",
          "issue_date": "Date d'ouverture",
          "mare": "Mère biologique",
          "mare_birth_date": "Date de naissance",
          "mare_code": "Code de la mère biologique",
          "mare_father_name": "Père de la mère biologique",
          "mare_holder": "Propriétaire de la mère biologique",
          "mare_holder_address_box": "Bte",
          "mare_holder_address_country_code": "Pays",
          "mare_holder_address_line1": "Rue",
          "mare_holder_address_locality": "Localité",
          "mare_holder_address_locality_name": "Localité",
          "mare_holder_address_number": "N°",
          "mare_holder_address_vat": "Numéro de TVA (si assujetti)",
          "mare_holder_address_zip": "Code postal",
          "mare_holder_asl": "Codice Azienda (ASL)",
          "mare_holder_civility": "Civilité",
          "mare_holder_civility_id": "Civilité",
          "mare_holder_company": "Intitulé",
          "mare_holder_company_country": "Pays",
          "mare_holder_company_number": "Numéro de TVA ou Numéro d'entreprise",
          "mare_holder_company_vat_subject": "Assujetti",
          "mare_holder_contacts": "Coordonnées du propriétaire",
          "mare_holder_email": "email",
          "mare_holder_fax": "Fax",
          "mare_holder_firstname": "Prénom",
          "mare_holder_lastname": "Nom",
          "mare_holder_legal_form_id": "Forme juridique",
          "mare_holder_legal_form_other": "Forme (autre)",
          "mare_holder_mobile": "GSM",
          "mare_holder_national_number": "Numéro national",
          "mare_holder_national_number_country": "Pays",
          "mare_holder_phone": "Téléphone",
          "mare_id": "Jument",
          "mare_microchip": "Microchip de la mère biologique",
          "mare_mother_name": "Mère de la mère biologique",
          "mare_name": "Nom de la mère biologique",
          "number": "Numéro",
          "stallion": "Père du produit à naître",
          "stallion_id": "Etalon",
          "year": "Saison de monte"
        },
        "coupling_card/coupling": {
          "date": {
            "one": "Date",
            "other": "Dates"
          }
        },
        "coupling_card/credit": {
          "amount": "Nombre de cartes octroyées",
          "ordered_at": "Octroyées le",
          "payed_at": "Payées le",
          "quantity": "Quantité",
          "requester": "Octroyées par",
          "year": "Saison de monte"
        },
        "delivrable": {
          "payed": "Payée",
          "payed_at": "Payée le",
          "reference": "Référence",
          "subject": "Objet",
          "subject_id": "Objet"
        },
        "export_list": {
          "created_at": "Créée le",
          "description": "Description",
          "horses": "Equidés",
          "name": "Nom",
          "size": "Nombre d'éléments",
          "size_contacts": "Nombre de contacts",
          "size_horses": "Nombre d'équidés",
          "updated_at": "Modifiée le"
        },
        "horse": {
          "active_passport": "Passeport",
          "approbation_country": "Pays d'approbation",
          "approvals": {
            "one": "Approbation",
            "other": "Approbations"
          },
          "approved": "Autorisé SireCloud",
          "approved_stallion": "Etalon Approuvé",
          "birth_country": "Pays de naissance",
          "birth_date": "Date de naissance",
          "blood_type": "Groupe sanguin",
          "blood_type_date": "Date",
          "book_class": "Book class",
          "breed": "Studbook / Race",
          "breed_category": "Type d'équidé",
          "breed_category_id": "Type d'équidé",
          "breed_id": "Studbook / Race",
          "breeder": {
            "one": "Naisseur",
            "other": "Naisseurs"
          },
          "breeder_id": "Naisseur",
          "breeders": "Naisseurs",
          "breeding_card": "Carte de saillie / déclaration",
          "breeding_card_notes": "Notes",
          "breeding_card_num": "Numéro de souche",
          "castrated": "Castré",
          "cbc_id": "ID CBC",
          "cbc_id_final": "Définitif",
          "cbc_id_timestamp": "Date",
          "coupler": {
            "one": "Inséminateur autorisé",
            "other": "Inséminateurs autorisés"
          },
          "coupling_card_id": "Carte de saillie",
          "coupling_card_number": "Numéro de carte de saillie",
          "coupling_card_studbook": "Studbook de carte de saillie",
          "creator_id": "Enregistrement",
          "current_owner": {
            "one": "Propriétaire actuel",
            "other": "Propriétaires actuels"
          },
          "database_book": "Sous-section",
          "database_book_section": "Section",
          "death_date": "Date de décès",
          "deceased": "Décédé",
          "detention_place_code": "Numéro d'établissement",
          "detention_since": "Date d'arrivée du produit à cet établissement",
          "dna": "ADN",
          "dna_control_required": "Contrôle ADN requis",
          "dna_date": "Date du test",
          "dna_filiation_compatible": "Compatible",
          "dna_horse_hair": "Crins disponibles",
          "dna_horse_hair_receival_date": "Date de réception des crins",
          "dna_result": "Référence",
          "dna_sent_date": "Date d'envoi",
          "dress": "Robe",
          "dress_id": "Robe",
          "father": "Père",
          "father_id": "Père",
          "first_holder": "Premier détenteur",
          "food_chain_excluded": "Exclu de la chaîne alimentaire",
          "food_chain_exclusion_date": "Date d'exclusion",
          "graphic_signage": "Signalement graphique",
          "graphic_signage_chestnusts": "Signalement graphique chestnuts",
          "horse_databases_book_id": "Sous-section",
          "horse_databases_book_section_id": "Section",
          "horse_databases_id": "Studbook",
          "horse_id": "HorseID",
          "microchip": "Microchip",
          "microchip_1": "Microchip 1",
          "microchip_2": "Microchip 2",
          "microchip_3": "Microchip 2",
          "mother": "Mère",
          "mother_id": "Mère",
          "name": "Nom",
          "notes": "Memo",
          "origin_alias": "Code originel",
          "owner": {
            "one": "Propriétaire",
            "other": "Propriétaires"
          },
          "owners": "Propriétaires",
          "past_owner": {
            "one": "Ancien propriétaire",
            "other": "Anciens propriétaires"
          },
          "private_alias": "Code",
          "received_at": "Date d'enregistrement",
          "registration_billed": "Facturée",
          "registration_invoice_reference": "Ref",
          "registration_paid": "Payée",
          "registration_paid_at": "Date",
          "sexe": "Sexe",
          "slaughter_foal": "Poulain d’abattage",
          "structured_signage": "Signalement codifié",
          "structured_signage_fr": "Signalement codifié FR",
          "structured_signage_nl": "Signalement codifié NL",
          "text_signage": "Signalement textuel",
          "ueln": "UELN",
          "ueln_country": "Pays",
          "ueln_database": "Org.",
          "ueln_national_id": "Identifiant",
          "ueln_type": "Génération de l'UELN"
        },
        "horse/approval": {
          "approval_end_date": "Fin",
          "approval_start_date": "Début",
          "country_code": "Pays",
          "discipline_id": "Discipline",
          "expertise_end_date": "Fin d'expertise",
          "expertise_start_date": "Début d'expertise",
          "expertise_year": "Année",
          "horse_id": "Equidé",
          "name": "Nom",
          "refusal_note": "Raison du refus",
          "reviews": "Avis",
          "status": "Statut",
          "years": "Années"
        },
        "horse/approval/review": {
          "positive": "Avis",
          "type_id": "Type"
        },
        "horse/approval/year": {
          "annual_fee": "Redevance annuelle",
          "conditions": "Conditions",
          "note": "Notes",
          "year": "Année"
        },
        "horse/breedership": {
          "is_main": "Naisseur principal"
        },
        "horse/companion/bcpa": {
          "control_required": "Contrôle HWSD requis",
          "control_send_date": "Date d'envoi",
          "control_test_date": "Date du test",
          "status": "Statut HWSD"
        },
        "horse/companion/sbs": {
          "frbse_id": "Identifiant FRBSE"
        },
        "horse/labelling": {
          "end_at": "Date de fin",
          "label": {
            "one": "Label",
            "other": "Labels"
          },
          "start_at": "Date de début"
        },
        "horse/microchip": {
          "date": "Date d'activation",
          "number": {
            "one": "Numéro de puce",
            "other": "Numéros de puce"
          },
          "unreadability_date": "Date d'illisibilité",
          "unreadable": "Inactif"
        },
        "horse/ownership": {
          "from_date": "Date début",
          "is_main": "Propriétaire principal",
          "to_date": "Date fin"
        },
        "horse/passport": {
          "cbc_id": "ID CBC",
          "created_in_horse_id_at": "Transfert HorseID",
          "exported_at": "Date d'édition",
          "global_recipient": "Destinataire",
          "number": "Numéro",
          "passport_type": "Type",
          "recipient": "Destinataire",
          "recipient_name": "Destinataire",
          "sent_at": "Date d'envoi",
          "version": "Version"
        },
        "horse/prime_request": {
          "created_at": "Date de création",
          "exported_date": "Date d'export",
          "first_breeder_name": "Naisseur",
          "sent_date": "Date d'envoi"
        },
        "horse_database": {
          "main_horse_breed": "Race principale",
          "main_horse_breed_id": "Race principale",
          "name": "Nom",
          "short_name": "Nom court",
          "ueln": "UELN"
        },
        "horse_database/book": {
          "active": "Archive"
        },
        "horse_database/book/section": {
          "active": "Archive"
        },
        "horse_id/detention_place": {
          "address": "Adresse",
          "capacity": "Capacité",
          "category": "Catégorie",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "operator": "Opérateur",
          "operator_cbc_id": "ID CBC de l'opérateur",
          "type": "Type"
        },
        "identification": {
          "breeder": "Naisseur",
          "breeder_cbc_id": "ID du naisseur",
          "breeder_id": "Naisseur",
          "cancelled_at": "Annulation",
          "co_breeder": "Co-naisseur",
          "co_breeder_cbc_id": "ID du co-naisseur",
          "co_breeder_id": "Co-naisseur",
          "exploitation_locality_id": "Localité",
          "finalize_sent_at": "Clôture",
          "global_breeder": "Naisseur",
          "global_co_breeder": "Co-naisseur",
          "global_holder": "Détenteur",
          "holder": "Détenteur",
          "holder_cbc_id": "ID du détenteur",
          "holder_id": "Détenteur",
          "holder_selected_email": "Email du détenteur",
          "horseID_params_exploitationBox": "Boîte",
          "horseID_params_exploitationCountry": "Pays",
          "horseID_params_exploitationLocality": "Localité",
          "horseID_params_exploitationNumber": "Numéro",
          "horseID_params_exploitationPostCode": "Code postal",
          "horseID_params_exploitationStreet": "Rue",
          "horseID_params_horse_birthCountry": "Pays",
          "horseID_params_horse_birthDate": "Date de naissance",
          "horseID_params_horse_breeders": "Id CBC des naisseurs",
          "horseID_params_horse_butcherFoal": "Poulain d'abattage",
          "horseID_params_horse_castrated": "Castré",
          "horseID_params_horse_commercialName": "Nom commercial",
          "horseID_params_horse_detentionPlace": "Numéro d'établissement",
          "horseID_params_horse_detentionSince": "Date d'arrivée du produit à cet établissement",
          "horseID_params_horse_dressColor": "Couleur de la robe",
          "horseID_params_horse_excludingFoodChain": "Exclu de la chaîne alimentaire",
          "horseID_params_horse_excludingFoodChainDate": "Date d’exclusion de la chaîne alimentaire",
          "horseID_params_horse_father_ueln": "UELN père",
          "horseID_params_horse_gender": "Sexe",
          "horseID_params_horse_holder": "Id CBC du détenteur",
          "horseID_params_horse_holderTmp": "Id CBC (temp.) du détenteur",
          "horseID_params_horse_mother_ueln": "UELN mère",
          "horseID_params_horse_name": "Nom",
          "horseID_params_horse_origin": "Origine",
          "horseID_params_horse_privateRemark": "Remarques",
          "horseID_params_horse_race": "Race",
          "horseID_params_horse_responsibleAgency": "UELN agence responsable",
          "horseID_params_horse_studbookId": "Code équidé",
          "horseID_params_horse_transponder": "N° transpondeur",
          "horseID_params_horse_type": "Type",
          "horseID_params_horse_ueln": "UELN",
          "horseID_params_identificationType": "Type de demande",
          "horseID_params_identification_date": "Date d'identification",
          "horseID_params_identification_dna": "Crins produit",
          "horseID_params_identification_identifier": "N° d'ordre vétérinaire",
          "horseID_params_identification_identifierRemark": "Remarque de l’identificateur",
          "horseID_params_identification_motherDna": "Crins mère",
          "horseID_params_identification_returnDate": "Date de retour du dossier",
          "horseID_params_identification_textualReporting": "Signalement textuel",
          "horseID_params_online": "Demande online",
          "horseID_params_transmitterAgency": "UELN agence émettrice",
          "identification_date": "Identification",
          "identification_dna": "Crins produit",
          "identification_mother_dna": "Crins mère",
          "identification_return_date": "Réception",
          "identifier_notes": "Remarques identificateur",
          "online": "Procédure",
          "private_notes": "Remarques privées",
          "request_sent_at": "Envoi Horse ID",
          "transfered_at": "Transfert",
          "validated_at": "Validation",
          "veterinarian": "Identificateur",
          "veterinarian_id": "Identificateur"
        },
        "note": {
          "annotated": "Objet",
          "annotated_gid": "Objet",
          "author": "Auteur",
          "body": "Texte",
          "note_type": "Type",
          "privacy": "Confidentialité",
          "written_at": "Ecrit le"
        },
        "organisation": {
          "bank_account": "IBAN (numéro de compte)",
          "bank_bic": "Code BIC",
          "bank_name": "Banque",
          "cbc_id": "ID CBC",
          "horse_affix": "Affixe",
          "horse_affix_protected": "Affixe Protégé",
          "horse_affix_protected_since": "Depuis le",
          "identity_complement": "Complément",
          "legal_form_id": "Forme",
          "legal_form_other": "Forme (autre)",
          "locale": "Langue",
          "name": "Nom",
          "received_at": "Date d'enregistrement",
          "vat": "Numéro BCE",
          "vat_subject": "Assujetti TVA"
        },
        "person": {
          "_score": "Pertinence",
          "adresses": "Adresses",
          "bank_account": "IBAN (numéro de compte)",
          "bank_bic": "Code BIC",
          "bank_name": "Banque",
          "cbc_id": "ID CBC",
          "civility": "Civilité",
          "civility_id": "Civilité",
          "company": "Société",
          "company_legal_form": "Forme juridique",
          "company_vat_subject": "Assujetti",
          "contacts": "Contacts",
          "death_date": "Date de décès",
          "deceased": "Décédé",
          "fee_payed": "Cotisation",
          "fees": "Cotisations",
          "firstname": "Prénom",
          "fonctions": "Fonctions",
          "horse_affix": "Affixe",
          "horse_affix_protected": "Affixe Protégé",
          "horse_affix_protected_since": "Depuis le",
          "identity_complement": "Complément",
          "lastname": "Nom",
          "locale": "Langue",
          "member_number": "Numéro de membre",
          "national_number": "N° National",
          "national_number_country": "Pays",
          "notes": "Memo",
          "received_at": "Date d'enregistrement",
          "send_magazine": "Périodique",
          "statuts": "Statut",
          "to_s": "Nom",
          "to_s_lowercase": "Nom",
          "vat": "TVA",
          "zip": "Code postal"
        },
        "person/enrollment": {
          "end_at": "Date fin",
          "start_at": "Date début"
        },
        "person/organisation_position": {
          "main": "Contact principal",
          "position": "Fonction / Rôle"
        },
        "user": {
          "email": "Email",
          "firstname": "Prénom",
          "lastname": "Nom",
          "name": "Nom",
          "password": "Mot de passe",
          "password_confirmation": "Confirmez le mot de passe",
          "username": "Nom d'utilisateur"
        }
      },
      "errors": {
        "messages": {
          "after": "doit être après %{restriction}",
          "before": "doit être avant %{restriction}",
          "invalid_date": "n'est pas une date valide",
          "invalid_datetime": "n'est pas une date/heure valide",
          "invalid_time": "n'est pas une heure valide",
          "is_at": "doit être %{restriction}",
          "on_or_after": "ne peut pas être avant %{restriction}",
          "on_or_before": "ne peut pas être après %{restriction}",
          "record_invalid": "La validation a échoué : %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Vous ne pouvez pas supprimer l'enregistrement parce que les %{record} dépendants existent",
            "has_one": "Vous ne pouvez pas supprimer l'enregistrement car un(e) %{record} dépendant(e) existe"
          }
        },
        "models": {
          "birth_registration": {
            "attributes": {
              "identificatio_online": {
                "holder_has_no_email": "ne peut être choisi car le détenteur n'a pas d'adresse email"
              }
            }
          },
          "cart_item": {
            "attributes": {
              "item": {
                "not_in_cart_database": "Doit faire partie du studbook du panier"
              }
            }
          },
          "cbc_horse": {
            "attributes": {
              "database": {
                "equal_to_horse_database": "doit correspondre au studbook de l'équidé"
              },
              "horse_id": {
                "has_final_cbc_id": "a déjà un ID CBC définitif"
              }
            }
          },
          "coupling_card": {
            "attributes": {
              "coupling_mode": {
                "conflict_with_embryo_transfer": "Conflit avec transfert d'embryon"
              },
              "year": {
                "credit_required": "Cet étalon n'a plus de carte disponible"
              }
            }
          },
          "coupling_card/coupling": {
            "attributes": {
              "date": {
                "must_be_equal_to_card_year": "doit correspondre à l'année de monte"
              }
            }
          },
          "export_list_item": {
            "attributes": {
              "item": {
                "not_in_list_database": "Doit faire partie du studbook de la liste"
              }
            }
          },
          "horse": {
            "attributes": {
              "breederships": {
                "at_least_one_main": "Veuillez sélectionner un naisseur principal",
                "highest_percentage": "Un autre naisseur a un pourcentage plus élevé que le naisseur principal coché",
                "only_one_main": "Il ne peut y avoir qu'un seul naisseur principal",
                "total_percentage": "La somme des pourcentages doit être comprise entre 90 et 100"
              },
              "ownerships": {
                "at_least_one_main": "Veuillez sélectionner un propriétaire principal",
                "highest_percentage": "Un autre propriétaire a un pourcentage de propriété plus élevé que le propriétaire principal coché",
                "only_one": "Il ne peut y avoir qu'un seul propriétaire principal",
                "total_percentage": "La somme des pourcentages doit être comprise entre 90 et 100"
              }
            }
          },
          "horse/passport": {
            "attributes": {
              "number": {
                "number_changeable_only_if_new": "Le numéro de ce passeport ne peux plus être modifié"
              },
              "origin": {
                "must_be_an_original": "Le passeport d'origine doit être un original"
              },
              "passport_type": {
                "only_one_original": "Cet équidé a déjà un passeport original"
              },
              "status": {
                "format": "%{message}",
                "only_one_new": "Il y a déjà un nouveau passeport en cours pour cet équidé"
              }
            }
          },
          "identification": {
            "attributes": {
              "database": {
                "equal_to_horse_database": "doit correspondre au studbook de l'équidé"
              },
              "horse_id": {
                "has_final_cbc_id": "a déjà un ID CBC définitif"
              },
              "online": {
                "holder_has_no_email": "ne peut être choisi car le détenteur n'a pas d'adresse email"
              }
            }
          }
        }
      },
      "models": {
        "address": "Adresse",
        "birth_registration": {
          "one": "Déclaration de naissance",
          "other": "Déclarations de naissance"
        },
        "cbc_horse": {
          "one": "Demande de création",
          "other": "Demandes de création"
        },
        "contact_detail": {
          "one": "Coordonnée de contact",
          "other": "Coordonnées de contact"
        },
        "coupling_card": {
          "one": "Carte de saillie",
          "other": "Cartes de saillie"
        },
        "coupling_card/coupling": {
          "one": "Saillie",
          "other": "Saillies"
        },
        "coupling_card/credit": {
          "one": "Commande de cartes de saillie",
          "other": "Commandes de cartes de saillie"
        },
        "export_list": {
          "one": "Liste d'export",
          "other": "Listes d'export"
        },
        "horse": {
          "one": "Équidé",
          "other": "Équidés",
          "zero": "Équidé"
        },
        "horse/approval": {
          "one": "Approbation",
          "other": "Approbations"
        },
        "horse/passport": {
          "one": "Passeport",
          "other": "Passeports"
        },
        "horse/prime_request": {
          "one": "Demande de prime",
          "other": "Demandes de prime"
        },
        "horse_companion_sbs": "SBS",
        "identification": {
          "one": "Identification",
          "other": "Identifications"
        },
        "note": {
          "one": "Note",
          "other": "Notes"
        },
        "organisation": {
          "one": "Organisation",
          "other": "Organisations"
        },
        "person": {
          "one": "Personne",
          "other": "Personnes"
        },
        "person/position": {
          "one": "Fonction",
          "other": "Fonctions"
        },
        "studbook_document": {
          "one": "Document",
          "other": "Documents",
          "zero": "Aucun document"
        },
        "studbook_document_type": {
          "one": "Type de document",
          "other": "Types de document"
        }
      }
    },
    "addresses": {
      "locality_invalid_for_horse_id": "Cette localité n'est pas une localité valide pour HorseID.",
      "placeholders": {
        "box": "Boîte",
        "country": "Pays",
        "line1": "Rue",
        "line2": "Complément",
        "locality": "Localité",
        "number": "N°",
        "zip": "Code postal"
      },
      "select_placeholders": {
        "locality": "Sélectionner une localité"
      }
    },
    "admin_tools": {
      "closed_coupling_cards_html": "<strong>Carte cloturées : </strong> Export CSV de toutes les cartes de saillie clôturées.",
      "open_coupling_cards_html": "<strong>Carte ouvertes : </strong> Export CSV des cartes de saillie ouvertes.<br/><small>exclus les cartes mises à jour il y a moins d'une semaine.</small>",
      "title": "Admin tools"
    },
    "admin_users": {
      "actions": {
        "confirm_destroy": "Êtes-vous sûr de vouloir supprimer cet utilisateur admin ?"
      },
      "edit": {
        "page_title": "Modifier l'admin",
        "title": "Modification d'un utilisateur admin"
      },
      "flash": {
        "create": {
          "fail": "L'utilisateur admin n'a pas pu être créé",
          "success": "L'utilisateur admin a été créé"
        },
        "destroy": {
          "success": "L'utilisateur admin a été supprimé"
        },
        "reset_password": {
          "success": "Le mot de passe a été ré-envoyé"
        },
        "update": {
          "fail": "L'utilisateur admin n'a pas pu être mis à jour",
          "success": "L'utilisateur admin a été mis à jour"
        }
      },
      "index": {
        "page_title": "Utilisateurs admins",
        "title": "Utilisateurs admins"
      },
      "new": {
        "page_title": "Nouvel utilisateur admin",
        "title": "Création d'un nouvel utilisateur admin"
      }
    },
    "api": {
      "authentication": {
        "expired_token": "Authentication token is expired",
        "failed": "Authentication failed",
        "invalid_token": "Authentication token is invalid",
        "missing_token": "Authentication token is missing"
      },
      "horse": {
        "created": "Horse successfully created",
        "deleted": "Horse successfully deleted",
        "ignored_params_as_finalized_identification_present": "Horse has a closed identification, so the following parameters have been ignored",
        "invalid": "Invalid horse data",
        "not_found": "Horse not found",
        "updated": "Horse successfully updated"
      },
      "person": {
        "created": "Person successfully created",
        "deleted": "Person successfully deleted",
        "invalid": "Invalid person data",
        "not_found": "Person not found",
        "updated": "Person successfully updated"
      }
    },
    "banner": {
      "check_birth_registration": "Veuillez corriger la déclaration de naissance."
    },
    "birth_mailer": {
      "new": {
        "subject": "Félicitations !"
      }
    },
    "birth_registrations": {
      "add_co_breeder": "Ajouter un co-naisseur",
      "add_co_owner": "Ajouter co-propriétaire",
      "breeder": "Naisseur",
      "breeder_intro_html": "<p>Le <strong>Naisseur</strong> doit être <u>une seule personne physique ou organisation</u>.<br/>Pour encoder plus d'une personne ou organisation, <strong><a class=\"quiet\" href=\"#co-breeder-switch\">ajoutez un Co-naisseur !</a></strong></p>",
      "card_questions": {
        "coupling_card_copy": "Je joins une copie de la carte de saillie",
        "coupling_card_copy_tip": "En joignant une copie de la carte de saillie (scan), le dossier sera traité plus rapidement et plus facilement.",
        "no_coupling_card": "Je ne possède pas de carte de saillie",
        "no_coupling_card_confirmation": "Êtes-vous certain(e) de ne pas posséder ce document ?",
        "other_studbook": "Je possède une carte de saillie émise par un autre Studbook",
        "studbook": "Je possède une carte de saillie émise par le Studbook %{studbook}",
        "year": {
          "before": "Année de monte avant %{year}",
          "current": "Année de monte %{year}",
          "older": "Année de monte %{year} ou antérieure",
          "same_or_after": "Année de monte à partir de %{year}"
        }
      },
      "cbc_id": "ID CBC",
      "check_before_saving_html": "Vérifiez la déclaration avant de l'enregistrer",
      "choice_title": "Dans quel Studbook voulez-vous inscrire votre produit ?",
      "chosen_identification_type": "Procédure choisie",
      "co_breeder": "Co-naisseur",
      "co_owner": "Co-propriétaire du produit",
      "contact_detention_place_for_number_first_part": "Vous devez contacter le lieu de détention du produit pour obtenir son numéro.",
      "contact_detention_place_for_number_second_part": "D'ici peu, cette information sera légalement obligatoire pour toute nouvelle naissance. En attendant, vous pouvez encoder l'adresse du lieu ci-dessous",
      "father": "Père",
      "first_holder": "Premier détenteur",
      "flash": {
        "destroy": {
          "fail": "La déclaration n'a pas pu être supprimée",
          "success": "Déclaration de naissance supprimée"
        }
      },
      "form": {
        "help": {
          "microchip": "Si le produit est déjà porteur d'un microchip et que vous en connaissez le numéro, indiquez-le ici. Dans le cas contraire, laissez ce champ vide",
          "slaughter_foal": "Produit destiné à l'abattage durant son année de naissance."
        },
        "hint": {
          "name_sbs": "Attention ! Lettre initiale du nom : S en 2024 (R en 2023) – Maximum 35 caractères et espaces"
        },
        "label_ext": {
          "microchip": "(si déjà porteur)"
        }
      },
      "general_conditions_agree_html": "J’ai lu et j’accepte les conditions générales",
      "general_conditions_html": "<p><strong>Conditions générales</strong></p><p>La semence appartient au propriétaire de l’étalon sauf convention particulière. Le déclarant est présumé détenir tous droits utiles sur la semence pour la saillie.</p><p>Le déclarant s’engage irrévocablement à garantir le Studbook %{studbook} de toute action en lien avec ce qui précède, qui pourrait être introduite contre le Studbook %{studbook}, en suite de l’inscription du produit au livre généalogique.</p><p>Cette garantie concerne toute réclamation généralement quelconque qui serait formulée, en principal, intérêts et frais, en ce compris les frais de justice.</p><p>Le déclarant interviendra, à première demande du Studbook %{studbook}, dans toute procédure, amiable ou judiciaire, et prendra fait et cause pour lui.</p>",
      "get_detention_place_code": "<a target=\"_blank\" href=\"https://www.horseid.be/extranet\">Enregistrez votre établissement sur HorseID pour obtenir son numéro <i class=\"t small material-icons\">open_in_new</i></a>",
      "holding_place": "Lieu de détention",
      "identification": "Identification",
      "identification_intro_html": "<p class=\"mb-0\"> Un équidé stationné en Belgique doit faire l'objet d'une identification centrale pour laquelle nous avons encore besoin de 4 informations : </p> <ul> <li>Le choix de la procédure.</li> <li>Les coordonnées du détenteur (obligatoirement une seule personne physique, responsable de l’animal).</li> <li>Le lieu d'exploitation du produit (pour faciliter le relevé du signalement).</li> <li>Le vétérinaire choisi pour l’identification et le relevé du signalement.</li> </ul>",
      "identification_is_not_in_belgium_html": "<p class=\"mb-0\">L'équidé n'est pas stationné en Belgique.</p>",
      "identification_not_in_belgium_html": "<p class=\"mb-0\">L'équidé ne fera donc pas l’objet d'une identification centrale en Belgique.</p>",
      "identification_simplified_html": "Procédure Studbook ?",
      "identification_simplified_tooltip_html": "Procédure Studbook simplifiée",
      "identification_stationed_in_belgium_html": "Le produit est-il stationné en Belgique ?",
      "identification_stationed_to_first_holder_address": "Le produit est-il stationné à l’adresse du détenteur ?",
      "identification_type_html": "Quelle procédure d'identification souhaitez-vous utiliser ?",
      "identification_type_tooltip_html": "<strong>Procédure online</strong> : L'identification se fait exclusivement en ligne. Les informations et les documents vous sont envoyés par emails. Le paiement se fait également en ligne.<br/><strong>Procédure papier</strong> : Les informations et les documents vous sont envoyés par la poste. Le paiement se fait par virement bancaire.",
      "identifier": "Identificateur",
      "index": {
        "search": {
          "placeholder": "Recherche rapide : Nom du produit, du père ou de la mère"
        }
      },
      "intro_html": "Inscrire votre produit dans le livre généalogique %{studbook} et simultanément dans la base de données nationale en quelques clics : c'est facile, rapide et moins cher.<br/>Nous vous guidons pas à pas...",
      "is_detention_place_operator": "Êtes-vous l'opérateur de l'établissement ?",
      "manual_encoding_html": "Encoder manuellement <br/>les informations",
      "mare": "Jument",
      "mother": "Mère biologique",
      "no_detention_place_code": "Si vous ne disposez pas du numéro d'établissement, <a href=\"#\">cliquez-ici</a>",
      "online_identification_type": "Online",
      "or": "OU",
      "owner": "Propriétaire du produit",
      "owner_intro_html": "<p>Le <strong>Proriétaire</strong> doit être <u>une seule personne physique ou organisation</u>.<br/>Pour encoder plus d'une personne ou organisation, <strong><a class=\"quiet\" href=\"#co-owner-switch\">ajoutez un Co-propriétaire !</a></strong></p>",
      "paper_identification_type": "Papier",
      "parents_intro": "Recherchez le père et la mère de votre produit ou encodez leurs informations.",
      "product": "Produit",
      "product_intro": "Choisissez le nom de votre poulain ou de votre pouliche. Indiquez l'affixe de votre élevage (facultatif) et complétez les champs vides.",
      "sections": {
        "birth_informations": "Informations sur la naissance",
        "breeder_details": "Coordonnées du naisseur",
        "breeder_identity": "Identité du naisseur",
        "co_breeder_details": "Coordonnées du co-naisseur",
        "co_breeder_identity": "Identité du co-naisseur",
        "co_owner_details": "Coordonnées du co-propriétaire",
        "co_owner_identity": "Identité du co-propriétaire",
        "first_holder": "Détenteur",
        "holder_details": "Coordonnées du détenteur",
        "holder_identity": "Identité du détenteur",
        "holding_place": "Lieu de détention",
        "identification_date": "Date d'identification",
        "identification_detention_place_data": "Détails de l'établissement",
        "identification_detention_place_info": "Enregistrez votre établissement",
        "identification_detention_place_operator_data": "Vos coordonnées",
        "identification_payment_html": "Procédure",
        "identification_return_date": "Date de réception",
        "identifier": "Identificateur",
        "operator_details": "Coordonnées de l'opérateur",
        "owner_details": "Coordonnées du propriétaire",
        "owner_identity": "Identité du propriétaire",
        "product_identity": "Identité du produit",
        "product_informations": "Informations sur le produit",
        "remarks": "Remarques identificateur"
      },
      "select_contact_placeholder": "Sélectionner un %{type}",
      "select_mare_html": "Chercher et selectionner <br/>une jument",
      "select_stallion_html": "Chercher et selectionner <br/>un étalon",
      "show": {
        "congrat_html": "Félicitations !",
        "details_email_var": "un email",
        "details_html": " <p> Votre déclaration de naissance a bien été enregistrée. </p> <p> Vous recevrez sous peu %{var} vous invitant à acquitter le montant de l'enregistrement de votre produit dans la base de données nationale (<a href=\"https://www.horseid.be\" target=\"_blank\">horseid.be</a>). </p> <p> Réservez-y une suite rapide. Le dossier d'identification vous parviendra dès réception de votre paiement. Il vous restera à présenter le document à votre vétérinaire. </p> <p> <strong>Attention :</strong> certains envois peuvent se trouver dans votre courrier indésirable (spams). Vérifiez votre messagerie. </p> ",
        "details_paper_var": "un courrier",
        "end_btn": "J'ai compris"
      },
      "stallion": "Etalon",
      "steps": {
        "breeder": "Naisseur",
        "confirm": "Récapitulatif",
        "identification": "Identification",
        "microchip": "Microchip / Numéro de puce du produit",
        "owner": "Propriétaire",
        "parents": "Origines",
        "product": "Produit"
      },
      "validation": {
        "back": "Retour",
        "breeder": "Naisseur",
        "click_to_add": "Cliquer pour ajouter au contact sélectionné",
        "co_breeder": "Co-naisseur",
        "co_owner": "Co-propriétaire",
        "contact_address_added": "Adresse ajoutée",
        "contact_created": "Contact créé",
        "contact_detail_added": "Coordonnée ajoutée",
        "continue": "Continuer",
        "create_contact": "Créer un nouveau contact.",
        "create_horse": "Créer un nouvel équidé.",
        "declaration": "Déclaration",
        "holder": "Détenteur",
        "horse_created": "Equidé créé",
        "horse_creation_failed": "La création de l'équidé a échouée",
        "identification_detention_place_data": "Détails de l'établissement",
        "identification_detention_place_info": "Enregistrez l'établissement",
        "identification_detention_place_operator_data": "Coordonnées de l'opérateur",
        "is_detention_place_operator": "Le déclarant est-il l'opérateur de l'établissement ?",
        "mare": "Mère",
        "no_contact_selected": "Pas de contact sélectionné",
        "no_horse_selected": "Pas d'équidé sélectionné",
        "operator": "Opérateur",
        "operator_must_be_the_declarant": "L'opérateur doit être le déclarant",
        "operator_title": "Opérateur du lieu de détention",
        "or": "ou",
        "owner": "Propriétaire",
        "page_title": "Validation",
        "product_name_hint": "Nom du produit et affixe d'élévage",
        "select_contact_label": "Chercher et sélectionner un %{type} parmi les contacts",
        "select_contact_placeholder": "Sélectionner un %{type}",
        "select_origin_label": "Chercher et sélectionner un(e) %{type} parmi les équidés",
        "select_origin_placeholder": "Sélectionner un(e) %{type}",
        "stalion": "Père",
        "steps": {
          "contacts": "Contacts",
          "identification": "Identification",
          "origins": "Origines",
          "product": "Produit"
        },
        "title": "Valider une déclaration de naissance",
        "unknown_horse_name": "Inconnu ?",
        "validation": "Validation",
        "values": {
          "types": {
            "0": "Ne pas créer de demande d'identification",
            "1": "Créer une demande HorseID sans la transférer",
            "2": "Créer et transférer une demande HorseID",
            "3": "Envoyer sans identification",
            "4": "Envoyer sans identification et transférer"
          }
        }
      },
      "values": {
        "embryo_transfer": {
          "0": "Non",
          "1": "Oui",
          "false": "Non",
          "true": "Oui"
        },
        "icsi": {
          "0": "Non",
          "1": "Oui",
          "false": "Non",
          "true": "Oui"
        },
        "identification_simplified": {
          "0": "Non",
          "1": "Oui",
          "false": "Non",
          "true": "Oui"
        },
        "station_be": {
          "0": "Autre pays",
          "1": "Belgique",
          "false": "Autre pays",
          "true": "Belgique"
        },
        "status": {
          "to_validate": {
            "one": "A valider",
            "other": "A valider"
          },
          "validated": {
            "one": "Validée",
            "other": "Validées"
          }
        }
      },
      "with_detention_place_code": "Si vous disposez du numéro d'établissement, <a href=\"#\">cliquez-ici</a>"
    },
    "boolean": {
      "0": "Non",
      "1": "Oui",
      "False": "Non",
      "True": "Oui",
      "false": "Non",
      "no": "Non",
      "true": "Oui",
      "yes": "Oui"
    },
    "btn": {
      "labels": {
        "accept_and_complete_card": "Accepter et Clôturer la carte",
        "actions": "Actions",
        "add": "Ajouter",
        "add_cart_contacts_to_export_list": "Exporter <span data-cart-target=\"itemContactsCount\">%{count}</span> contacts",
        "add_cart_horses_to_export_list": "Exporter <span data-cart-target=\"itemHorsesCount\">%{count}</span> équidés",
        "add_cart_to_export_list": "Ajouter à un export",
        "add_coupling_card": "Créer une carte de saillie",
        "add_delivrable": "Ajouter une prestation",
        "add_horse_to_contact": "Ajouter un équidé",
        "add_note": "Ajouter une note",
        "back": "Retour",
        "birth_registration_continue": "Continuer",
        "cancel": "Annuler",
        "cancel_delivrable": "Annuler",
        "cancel_identification": "Annuler",
        "cancel_note": "Annuler",
        "choose_detention_place": "Choisir",
        "close": "Fermer",
        "confirm": "Confirmer",
        "confirm_etalonnier_to_admin": "Confirmer",
        "continue": "Continuer",
        "convert_person_to_organisation": "Convertir en Organisation",
        "copy_breeder": "Copier le naisseur.",
        "copy_co_breeder": "Copier le co-naisseur.",
        "copy_co_owner": "Copier le co-propriétaire.",
        "copy_data_from_breeder": "copier les information du naisseur",
        "copy_data_from_co_breeder": "du co-naisseur",
        "copy_data_from_co_owner": "du co-proprietaire",
        "copy_data_from_holder": "copier les informations du détenteur",
        "copy_data_from_operator_address": "copier l'adresse de l'opérateur",
        "copy_data_from_owner": "du proprietaire",
        "copy_holder": "Copier le détenteur.",
        "copy_holder_address": "Copier l'adresse du détenteur",
        "copy_owner": "Copier le propriétaire.",
        "create_another_birth_registration": "Déclarer une autre naissance",
        "create_approval": "Ajouter une expertise",
        "create_cbc_horse": "Transférer sans identification",
        "create_detention_place": "Créer",
        "create_identification": "Créer une demande",
        "create_on_horse_id": "Créer sur HorseID",
        "create_passport": "Créer un passeport",
        "date_picker_ok": "Choisir",
        "delete_delivrable": "Supprimer",
        "delete_export_list": "Supprimer la liste",
        "delete_note": "Supprimer",
        "delete_passports": "Supprimer",
        "delete_signage": "Supprimer le signalement",
        "delete_studbook_document": "Supprimer",
        "destroy_admin_user": "Supprimer",
        "dna_control_confirm": "Confirmer",
        "dna_control_send": "Envoyer",
        "dna_controls_confirm": "Valider les contrôles",
        "download": "Télécharger",
        "download_dna_controls": "Télécharger",
        "download_studbook_document": "Télécharger",
        "edit_cbc_horse": "Modifier",
        "edit_identification": "Modifier",
        "edit_passport": "Modifier ce passeport",
        "edit_signage": "Modifier le signalement",
        "editer_delivrable": "Modifier",
        "empty_cart": "Vider le panier",
        "empty_contacts_list_first": "Vider la liste avant d'y ajouter les contacts",
        "empty_horses_list_first": "Vider la liste avant d'y ajouter les équidés",
        "export_cards": "Exporter les cartes",
        "export_export_list": "Export CSV",
        "export_passport": "Exporter",
        "export_prime_request": "Exporter",
        "export_prime_request_list": "Exporter la sélection",
        "export_whole_prime_request_list": "Exporter la liste",
        "finalize_identification": "Clôturer",
        "grant_coupling_card": "Octroyer de nouvelles cartes",
        "ignore": "Ignorer",
        "ignore_update_contact_horse_id": "Ignorer la mise à jour",
        "mark_passport_as_sent": "Marquer comme envoyé(s)",
        "ok": "Ok",
        "order_coupling_card": "Commander de nouvelles cartes",
        "other_actions": "Autres actions",
        "please_wait": "Patientez...",
        "print_cards": "Imprimer les cartes",
        "receive_identification": "Recevoir",
        "refuse_approval": "Refuser l'expertise",
        "reload_page": "Recharger",
        "resend_to_horse_id": "Réenvoyer à HorseID",
        "save_admin_user": "Enregistrer",
        "save_approval": "Enregistrer",
        "save_card": "Enregistrer les données",
        "save_card_credit": "Enregistrer la commande",
        "save_cbc_horse": "Enregistrer",
        "save_delivrable": "Enregistrer",
        "save_export_list": "Enregistrer",
        "save_horse": "Enregistrer",
        "save_horse_database": "Enregistrer",
        "save_identification": "Enregistrer",
        "save_note": "Enregistrer",
        "search_detention_place": "Chercher l'établissement",
        "search_detention_place_short": "Chercher",
        "see_cart": "Voir le panier",
        "see_horse": "Voir l'équidé",
        "see_horse_on_sbsnet": "Voir sur SBSNet",
        "select_detention_place": "Choisir (%{count})",
        "send": "Envoyer",
        "send_birth_registration": "Valider et envoyer",
        "send_contact_to_horse_id": "Envoyer à HorseID",
        "send_prime_request_list": "Clôturer la liste",
        "show_contact": "Ouvrir la fiche",
        "show_horse": "Ouvrir la fiche",
        "show_more_notes": "Afficher les autres notes",
        "show_pedigree": "Voir le pédigrée",
        "sign_out": "Déconnexion",
        "sort": "Tri",
        "transfer_cbc_horse": "Transférer",
        "transfer_identification": "Transférer",
        "transfert_passport": "Transférer",
        "update_contact_horse_id": "Envoyer la mise à jour",
        "update_created_on_horseid_at": "Valider",
        "upload_signage": "Ajouter un signalement",
        "upload_studbook_document": "Ajouter des documents",
        "validate_and": "Valider &",
        "validate_and_create_birth_registration": "Valider et créer",
        "validate_approval_infinitely": "Valider",
        "validate_approval_temporary": "Valider",
        "validate_birth_registration": "Valider",
        "validate_cbc_horse": "Valider",
        "validate_identification": "Valider"
      },
      "tooltips": {
        "add_address": "Ajouter une adresse",
        "add_approvals_country_codes": "Ajouter un pays",
        "add_approvals_disciplines": "Ajouter une discipline",
        "add_approvals_review_types": "Ajouter un type",
        "add_book": "Ajouter une sous-section",
        "add_book_section": "Ajouter une section",
        "add_contact": "Ajouter une personne",
        "add_contact_detail": "Ajouter Coordonnées",
        "add_coupling_date": "Ajouter une date de saillie",
        "add_email": "Ajouter un email",
        "add_fee": "Ajouter une cotisation",
        "add_horse_owner": "Ajouter un copropriétaire",
        "add_label": "Ajouter un label",
        "add_labelling": "Ajouter un label",
        "add_mare": "Ajouter une jument",
        "add_microchip": "Ajouter un microchip",
        "add_organisation": "Ajouter une organisation",
        "add_person": "Ajouter une personne",
        "add_position": "Ajouter une fonction",
        "add_review": "Ajouter un avis",
        "add_stallion": "Ajouter un étalon",
        "add_to_cart": "Ajouter au panier",
        "add_type": "Ajouter un type",
        "add_year": "Ajouter une année",
        "cancel_expertise": "Annuler l'expertise",
        "cbc_horse_cannot_transfer": "Cette création ne peut pas être transférée",
        "cbc_horse_cannot_validate": "Cette création ne peut pas être validée",
        "cbc_id_final": "Définitif",
        "cbc_id_non_final": "Non définitif",
        "complete_card": "Clôturer la carte",
        "complete_card_and_birth": "Clôturer la carte et déclarer une naissance",
        "delete_approval": "Supprimer",
        "delete_delivrable": "Supprimer la prestation",
        "destroy_birth_registration": "Supprimer la déclaration",
        "destroy_card": "Supprimer la carte",
        "download_as_csv": "Télécharger en CSV",
        "download_contacts": "Télécharger les %{count} contacts",
        "download_horses": "Télécharger les %{count} équidés",
        "edit_admin_user": "Modifier cet utilisateur",
        "edit_approval": "Modifier",
        "edit_card": "Modifier la carte",
        "edit_delivrable": "Modifier la prestation",
        "edit_export_list": "Modifier la liste",
        "edit_horse": "Modifier cet équidé",
        "edit_identification": "Modifier la demande d'identification",
        "edit_organisation": "Modifier cette organisation",
        "edit_person": "Modifier cette personne",
        "generate_private_alias": "Genérer le code",
        "identification_cannot_cancel": "Cette identification ne peut pas être annulée",
        "identification_cannot_finalize": "Cette identification ne peut pas être clôturée",
        "identification_cannot_receive": "Cette identification ne peut pas être réceptionnée",
        "identification_cannot_transfer": "Cette identification ne peut pas être transférée",
        "identification_cannot_validate": "Cette identification ne peut pas être validée",
        "incomplete_pedigree": "Pedigrée incomplet",
        "missing_cbc_horse_attributes": "Les informations suivantes sont nécessaires pour l'identification : %{missing_attributes}",
        "missing_coupling_card_attributes": "Les informations suivantes sont nécessaires pour clôturer la carte: %{missing_attributes}",
        "missing_identification_attributes": "Les informations suivantes sont nécessaires pour l'identification : %{missing_attributes}",
        "more_actions": "Autre actions",
        "new_admin_user": "Enregistrer un nouvel utilisateur admin",
        "new_birth": "Déclarer la naissance",
        "new_contact": "Enregistrer un nouveau contact",
        "new_horse": "Créer un nouvel équidé",
        "new_identification": "Nouvelle demande d'identification",
        "new_organisation": "Enregistrer une nouvelle organisation",
        "new_person": "Enregistrer une nouvelle personne",
        "next_horse": "Equidé suivant",
        "non_subject_to_vat": "Non-assujetti TVA",
        "owner_only_can_order_coupling_card": "Seul le propriétaire d'un étalon peut commande des cartes",
        "passport_cannot_send_to_horse_id": "Ce passeport n'a pas pu être envoyé à HorseID",
        "passport_wont_send_to_horse_id": "Ce passeport ne pourra pas être envoyé à HorseID",
        "people_regrouping_as_organisation": "Tout regroupement de personnes est considéré comme une 'association'",
        "previous_horse": "Equidé précédent",
        "print_birth": "Imprimer la déclaration de naissance",
        "print_summary": "Imprimer le récapitulatif",
        "refuse_approval": "Refuser",
        "remove_address": "Supprimer une adresse",
        "remove_approvals_country_codes": "Supprimer un pays",
        "remove_approvals_disciplines": "Supprimer une discipline",
        "remove_approvals_review_types": "Supprimer un type",
        "remove_book": "Supprimer une sous-section",
        "remove_book_section": "Supprimer une section",
        "remove_contact": "Supprimer une personne",
        "remove_contact_detail": "Supprimer Coordonnées",
        "remove_coupling_date": "Supprimer la date de saillie",
        "remove_email": "Supprimer un email",
        "remove_fee": "Supprimer une cotisation",
        "remove_from_cart": "Retirer du panier",
        "remove_from_list": "Retirer de la liste",
        "remove_horse_owner": "Supprimer ce copropriétaire",
        "remove_label": "Supprimer un label",
        "remove_labelling": "Supprimer ce label",
        "remove_microchip": "Supprimer ce microchip",
        "remove_organisation": "Supprimer cette organisation",
        "remove_person": "Supprimer cette personne",
        "remove_position": "Supprimer une fonction",
        "remove_review": "Supprimer cet avis",
        "remove_type": "Supprimer un type",
        "remove_year": "Supprimer cette année",
        "reprint_as_csv": "Réimprimer (CSV)",
        "required": "obligatoire",
        "resend_password_admin": "Ré-envoyer mot de passe",
        "see_horse_history": "Afficher l'historique",
        "show_coupling_card_copy": "Afficher la copie de la carte de saillie",
        "show_identification": "Voir la demande d'identification",
        "sign_out": "Quitter SireCloud",
        "subject_to_vat": "Assujetti TVA",
        "to_order_coupling_card": "Il faut commander de nouvelles cartes",
        "validate_expertise_infinitely": "Valider",
        "validate_expertise_temporary": "Valider l'expertise",
        "view_birth_registration": "Voir la déclaration de naissance"
      }
    },
    "cart_items": {
      "flash": {
        "add_contact_to_cart": {
          "fail": "Le contact n'a pas pu être ajouté au panier",
          "success": "Le contact a été ajouté au panier"
        },
        "add_horse_to_cart": {
          "fail": "L'équidé n'a pas pu être ajouté au panier",
          "success": "L'équidé a été ajouté au panier"
        },
        "add_to_cart": {
          "fail": "L'élément n'a pas pu être ajouté au panier",
          "success": "L'élément a été ajouté au panier"
        },
        "remove_contact_from_cart": {
          "fail": "Le contact n'a pas pu être retiré du panier",
          "success": "Le contact a été retiré du panier"
        },
        "remove_from_cart": {
          "fail": "L'élément n'a pas pu être retiré du panier",
          "success": "L'élément a été retiré du panier"
        },
        "remove_horse_from_cart": {
          "fail": "L'équidé n'a pas pu être retiré du panier",
          "success": "L'équidé a été retiré du panier"
        }
      }
    },
    "carts": {
      "flash": {
        "add_contacts_to_export_list": {
          "fail": "Les contacts n'ont pas pu être ajoutés à la liste",
          "partial_success": "Certains contacts n'ont pas pu être ajoutés à la liste",
          "success": "Les contacts ont été ajoutés à la liste"
        },
        "add_horses_to_export_list": {
          "fail": "Les équidés n'ont pas pu être ajoutés à la liste",
          "partial_success": "Certains équidés n'ont pas pu être ajoutés à la liste",
          "success": "Les équidés ont été ajoutés à la liste"
        },
        "add_to_export_list": {
          "fail": "Les éléments n'ont pas pu être ajoutés à la liste"
        }
      },
      "show": {
        "existing_export_list": "Liste existante",
        "is_empty": "Votre panier est vide",
        "is_empty_contacts": "Votre panier ne contient pas de contacts",
        "is_empty_horses": "Votre panier ne contient pas d'équidés",
        "new_export_list": "Nouvelle liste",
        "page_title": "Panier",
        "page_title_contacts": "Panier de contacts",
        "page_title_horses": "Panier d'équidés",
        "title": "Modifier votre panier"
      }
    },
    "cbc_horses": {
      "actions": {
        "cancel_status": "Revenir au statut précédent"
      },
      "changing_status_dates": "Attention : modifier ces dates peut faire changer le statut du transfert sans que ce nouveau statut ne soit signifié à HorseID.",
      "data_missing_for_validation": "Informations manquantes pour le transfert",
      "detention_place_existing": "Etablissement existant",
      "detention_place_missing": "Etablissement manquant",
      "detention_place_new": "Nouvel Établissement",
      "detention_place_unknown": "Etablissement inconnu",
      "edit": {
        "page_title": "Modification du transfert sans demande d'identification",
        "title": "Modifier le transfert sans demande d'identification"
      },
      "flash": {
        "bulk": {
          "fail": "Aucune création sélectionnée"
        },
        "cancel_status": {
          "fail": "Le statut précédent n'a pas pu être rétabli",
          "success": "Statut précédent rétabli"
        },
        "create": {
          "fail": "La création n'a pas été enregistrée",
          "success": "Création enregistrée"
        },
        "transfered": {
          "fail": {
            "one": "La création n'a pas pu être transférée",
            "other": "Les créations n'ont pas pu être transférées"
          },
          "success": {
            "one": "Création transférée",
            "other": "Créations transférés"
          }
        },
        "transfering": {
          "fail": {
            "one": "La création n'a pas pu être transférée",
            "other": "Les créations n'ont pas pu être transférées"
          },
          "success": {
            "one": "Création en cours de transfert",
            "other": "Créations en cours de transfert"
          }
        },
        "update": {
          "fail": "La création n'a pas pu être mise à jour",
          "fail_horse_id": "La création n'a pas pu être mise à jour dans HorseID",
          "success": "Création mise à jour",
          "success_horse_id": "Création mise à jour et mise à jour dans HorseID"
        },
        "validated": {
          "fail": {
            "one": "La création n'a pas pu être validée",
            "other": "Les créations n'ont pas pu être validées"
          },
          "success": {
            "one": "Création validée",
            "other": "Créations validés"
          }
        },
        "validating": {
          "fail": {
            "one": "La création n'a pas pu être validée",
            "other": "Les créations n'ont pas pu être validées"
          },
          "success": {
            "one": "Création en cours de validation",
            "other": "Créations en cours de validation"
          }
        }
      },
      "holder_selected_email_no_email": "aucun email connu pour le détenteur",
      "holder_selected_email_no_holder": "veuillez choisir un détenteur",
      "horse_id/detention_places": {
        "new_place": "nouveau lieu",
        "update_not_supported": "mise à jour non supportée"
      },
      "horse_id_errors": "Erreur(s) HorseID",
      "horse_id_warnings": "Avertissement(s) HorseID",
      "horseid": {
        "attributes": {
          "detentionPlace": "Numéro d'établissement",
          "exploitationBox": "Boîte",
          "exploitationCountry": "Pays",
          "exploitationLocality": "Localité",
          "exploitationNumber": "Numéro",
          "exploitationPostCode": "Code postal",
          "exploitationStreet": "Rue",
          "foreignAlias": "Alias d'origine",
          "horse": {
            "birthCountry": "Pays de naissance",
            "birthDate": "Date de naissance",
            "breeders": "Naisseurs",
            "butcherFoal": "Poulain d'abattage",
            "castrated": "Castré",
            "castrationDate": "Date castration",
            "commercialName": "Nom commercial",
            "detentionPlace": "Numéro d'établissement",
            "detentionSince": "Date d'arrivée du produit à cet établissement",
            "dressColor": "Couleur de la robe",
            "excludingFoodChain": "Exclu de la chaîne alimentaire",
            "excludingFoodChainDate": "Date d’exclusion de la chaîne alimentaire",
            "father": {
              "name": "Nom père",
              "transponder": "N° transpondeur père",
              "ueln": "UELN père"
            },
            "gender": "Sexe",
            "hasOrigin": "A un pédigrée",
            "holder": "Id CBC du détenteur",
            "holderTmp": "Id CBC temporaire du détenteur",
            "id": "Code équidé",
            "mother": {
              "name": "Nom mère",
              "transponder": "N° transpondeur mère",
              "ueln": "UELN mère"
            },
            "name": "Nom",
            "privateRemark": "Remarques privées",
            "race": "Race",
            "responsibleAgency": "Agence responsable",
            "studbookId": "Code",
            "transponder": "N° transpondeur",
            "type": "Type",
            "ueln": "UELN"
          },
          "horseBirthCountry": "Pays",
          "horseBirthdate": "Date de naissance",
          "horseBreeder1": "Id CBC du naisseur 1",
          "horseBreeder2": "Id CBC du naisseur 2",
          "horseButcherFoal": "Poulain d'abattage",
          "horseCastrated": "Castré",
          "horseCommercialName": "Nom commercial",
          "horseDateExcludingFoodChain": "Date d’exclusion de la chaîne alimentaire",
          "horseDateHolding": "Date du début de détention",
          "horseDressColor": "Couleur de la robe",
          "horseExcludingFoodChain": "Exclu de la chaîne alimentaire",
          "horseFather": "UELN père",
          "horseGender": "Sexe",
          "horseHolder": "Id CBC du détenteur",
          "horseMother": "UELN mère",
          "horseName": "Nom",
          "horseOrigin": "Origine",
          "horseRace": "Race",
          "horseTransponder1": "N° transpondeur",
          "horseType": "Type",
          "horseUeln": "UELN",
          "id": "Code équidé",
          "privateRemark": "Remarques",
          "responsibleAgency": "UELN agence responsable",
          "transmitterAgency": "UELN agence émettrice"
        }
      },
      "index": {
        "search": {
          "placeholder": "Recherche rapide : Nom, UELN ou Code"
        }
      },
      "new": {
        "horse_not_found": "Pas d'équidé trouvé",
        "page_title": "Transfert sans demande d'identification",
        "title": "Transférer sans demande d'identification",
        "transfert_already_requested": "Cet équidé fait déjà l'objet d'un transfert"
      },
      "placeholders": {
        "holding_place_country": "Pays",
        "holding_place_locality": "Localité",
        "holding_place_zip": "Code postal"
      },
      "section-title": {
        "cbc_horse": "Transfert sans demande d'identification"
      },
      "sections": {
        "birth_informations": "Informations sur la naissance",
        "breeder_details": "Coordonnées du naisseur",
        "breeder_identity": "Identité du naisseur",
        "co_breeder_details": "Coordonnées du co-naisseur",
        "co_breeder_identity": "Identité du co-naisseur",
        "co_owner_details": "Coordonnées du co-propriétaire",
        "co_owner_identity": "Identité du co-propriétaire",
        "first_holder": "Détenteur",
        "followup": "Suivi",
        "holding_place": "Lieu de détention",
        "owner_details": "Coordonnées du propriétaire",
        "owner_identity": "Identité du propriétaire",
        "product_identity": "Identité du produit",
        "product_informations": "Informations sur le produit"
      },
      "show": {
        "page_title": "Transfert sans demande d'identification",
        "title": "Transfert sans demande d'identification"
      },
      "values": {
        "boolean": {
          "0": "Non",
          "1": "Oui",
          "false": "Non",
          "true": "Oui"
        },
        "sexe": {
          "0": "Mâle",
          "1": "Inconnu",
          "2": "Femelle"
        },
        "status": {
          "to_transfer": {
            "one": "En préparation",
            "other": "En préparation"
          },
          "to_validate": {
            "one": "Transférée",
            "other": "Transférées"
          },
          "transfering": {
            "one": "En cours de transfert",
            "other": "En cours de transfert"
          },
          "validated": {
            "one": "Clôturée",
            "other": "Clôturées"
          },
          "validating": {
            "one": "En cours de validation",
            "other": "En cours de validation"
          }
        }
      }
    },
    "clearance": {
      "models": {
        "clearance_mailer": {
          "change_password": "SireCloud.be - Changement de mot de passe"
        }
      }
    },
    "clearance_mailer": {
      "change_password": {
        "closing": "Si vous n'avez rien demandé, vous pouvez ignorer cet e-mail. Votre mot de passe n'a pas été modifié.",
        "opening": "Quelqu'un, en espérant que ce soit vous, nous a demandé de vous envoyer cet e-mail afin de modifier votre mot de passe:"
      }
    },
    "companies": {
      "legal_form": {
        "short_texts": {
          "1": "ASBL",
          "10": "SPRL",
          "11": "SRL",
          "12": "VZW",
          "13": "SCS",
          "2": "BVBA",
          "3": "CVA",
          "4": "LLC",
          "5": "N.V.",
          "6": "S.A.",
          "7": "SAGR",
          "8": "SARL",
          "9": "SNC"
        },
        "texts": {
          "1": "Association Sans But Lucratif",
          "10": "SPRL",
          "11": "SRL",
          "12": "VZW",
          "13": "Societe en Commandite Simple",
          "2": "Besloten Vennootschap met Beperkte Aansprakelijkheid",
          "3": "Commanditaire Vennootschap op Aandelen",
          "4": "Limited Liability Company",
          "5": "Naamloze Vennootschap",
          "6": "Société Anonyme ",
          "7": "SAGR",
          "8": "SARL",
          "9": "SNC"
        }
      }
    },
    "contact_details": {
      "id_cbc": "ID CBC",
      "type_suffixes": {
        "tel_home": "domicile",
        "tel_work": "travail"
      }
    },
    "contacts": {
      "an_active_status_is_required": "Un statut actif est obligatoire",
      "dashboard_labels": {
        "contacts_count": "Contacts",
        "contacts_members_count": "Membres",
        "contacts_members_fees_paid_count": "Cotisations payées",
        "contacts_members_send_magazine_count": "Périodique",
        "see_all": "+ Voir les %{count} contacts"
      },
      "data_fields": {
        "breeder": {
          "addr1": "Rue",
          "address": "Adresse",
          "adress": "Adresse du naisseur",
          "asl": "Codice Azienda (ASL)",
          "box": "Bte",
          "civility": "Civilité",
          "company": "Intitulé",
          "company_country": "Pays",
          "company_number": "Numéro de TVA ou Numéro d'entreprise",
          "company_vat_subject": "Assujetti",
          "country_code": "Pays",
          "firstname": "Prénom du naisseur",
          "lastname": "Nom du naisseur",
          "legal_form_id": "Forme juridique",
          "legal_form_other": "Forme (autre)",
          "locality": "Ville",
          "national_number": "Numéro national du naisseur",
          "national_number_country": "Pays",
          "number": "N°",
          "zip": "Code postal"
        },
        "co_breeder": {
          "addr1": "Rue",
          "address": "Adresse du co-naisseur",
          "asl": "Codice Azienda (ASL)",
          "box": "Bte",
          "civility": "Civilité",
          "company": "Intitulé",
          "company_country": "Pays",
          "company_number": "Numéro de TVA ou Numéro d'entreprise",
          "company_vat_subject": "Assujetti",
          "country_code": "Pays",
          "firstname": "Prénom du co-naisseur",
          "lastname": "Nom du co-naisseur",
          "legal_form_id": "Forme juridique",
          "legal_form_other": "Forme (autre)",
          "locality": "Ville",
          "national_number": "Numéro national du co-naisseur",
          "national_number_country": "Pays",
          "number": "N°",
          "zip": "Code postal"
        },
        "co_owner": {
          "addr1": "Rue",
          "adress": "Adresse du co-propriétaire",
          "asl": "Codice Azienda (ASL)",
          "box": "Bte",
          "civility": "Civilité",
          "company": "Intitulé",
          "company_country": "Pays",
          "company_number": "Numéro de TVA ou Numéro d'entreprise",
          "company_vat_subject": "Assujetti",
          "country_code": "Pays",
          "firstname": "Prénom du co-propriétaire",
          "lastname": "Nom du co-propriétaire",
          "legal_form_id": "Forme juridique",
          "legal_form_other": "Forme (autre)",
          "locality": "Ville",
          "national_number": "Numéro national du co-propriétaire",
          "national_number_country": "Pays",
          "number": "N°",
          "zip": "Code postal"
        },
        "default": {
          "addr1": "Rue",
          "address": "Adresse",
          "adress": "Adresse",
          "asl": "Codice Azienda (ASL)",
          "box": "Bte",
          "cbc_id": "ID CBC",
          "civility": "Civilité",
          "company": "Intitulé",
          "company_country": "Pays",
          "company_number": "Numéro de TVA ou Numéro d'entreprise",
          "company_vat_subject": "Assujetti",
          "country_code": "Pays",
          "email": "Email",
          "fax": "Fax",
          "firstname": "Prénom",
          "horse_database": "Studbook",
          "lastname": "Nom",
          "legal_form_id": "Forme juridique",
          "legal_form_other": "Forme (autre)",
          "locality": "Ville",
          "mobile": "GSM",
          "national_number": "Numéro national",
          "national_number_country": "Pays",
          "national_register": "Registre national",
          "number": "N°",
          "phone": "Téléphone",
          "vat": "Numéro de TVA (si assujetti)",
          "zip": "Code postal"
        },
        "holder": {
          "addr1": "Rue",
          "adress": "Adresse du détenteur",
          "asl": "Codice Azienda (ASL)",
          "box": "Bte",
          "cbc_id": "ID CBC",
          "civility": "Civilité",
          "company": "Intitulé",
          "company_country": "Pays",
          "company_number": "Numéro de TVA ou Numéro d'entreprise",
          "company_vat_subject": "Assujetti",
          "country_code": "Pays",
          "firstname": "Prénom du détenteur",
          "lastname": "Nom du détenteur",
          "legal_form_id": "Forme juridique",
          "legal_form_other": "Forme (autre)",
          "locality": "Ville",
          "national_number": "Numéro national du détenteur",
          "national_number_country": "Pays",
          "number": "N°",
          "zip": "Code postal"
        },
        "operator": {
          "addr1": "Rue",
          "adress": "Adresse du détenteur",
          "box": "Bte",
          "cbc_id": "ID CBC",
          "civility": "Civilité",
          "company": "Intitulé",
          "company_country": "Pays",
          "company_number": "Numéro de TVA ou Numéro d'entreprise",
          "company_vat_subject": "Assujetti",
          "country_code": "Pays",
          "firstname": "Prénom du détenteur",
          "lastname": "Nom du détenteur",
          "legal_form_id": "Forme juridique",
          "legal_form_other": "Forme (autre)",
          "locality": "Ville",
          "national_number": "Numéro national du détenteur",
          "national_number_country": "Pays",
          "number": "N°",
          "zip": "Code postal"
        },
        "owner": {
          "addr1": "Rue",
          "address": "Adresse du propriétaire",
          "asl": "Codice Azienda (ASL)",
          "box": "Bte",
          "civility": "Civilité",
          "company": "Intitulé",
          "company_country": "Pays",
          "company_number": "Numéro de TVA ou Numéro d'entreprise",
          "company_vat_subject": "Assujetti",
          "country_code": "Pays",
          "email": "Email",
          "fax": "Fax",
          "firstname": "Prénom du propriétaire",
          "lastname": "Nom du propriétaire",
          "legal_form_id": "Forme juridique",
          "legal_form_other": "Forme (autre)",
          "locality": "Ville",
          "mobile": "Gsm",
          "national_number": "Numéro national du propriétaire",
          "national_number_country": "Pays",
          "number": "N°",
          "phone": "Téléphone",
          "zip": "Code postal"
        }
      },
      "hide_old_functions": "masquer anciennes fonctions",
      "hide_old_statuses": "masquer anciens statuts",
      "horseid_update_needed": "Les champs suivants ont été modifiés et devraient être mis à jour sur HorseID",
      "horseid_update_needed_without_history": "Le contact a été modifié et devrait être mis à jour sur HorseID (historique des modifications indisponible)",
      "index": {
        "no_contact_found": "Aucun contact trouvé",
        "search": {
          "placeholder": "Rechercher une personne ou une organisation",
          "placeholders": {
            "name": "Nom ou prénom",
            "quick": "Recherche rapide",
            "street": "Rue",
            "zip": "CP ou localité"
          }
        }
      },
      "legal_form_other": "Autre",
      "membership_status": {
        "inactive_member": "Membre omis",
        "non_member": "Non-membre"
      },
      "missing_cbc_id": "ID CBC Manquants",
      "new": {
        "choose_type": "Choisissez le type de contact à créer",
        "page_title": "Nouveau contact",
        "title": "Création d'un nouveau contact"
      },
      "one": "Contact",
      "other": "Contacts",
      "personne_morale": "Societé / Organisation / Association",
      "personne_phyisque": "Personne Physique",
      "placeholders": {
        "select_placeholder": "Sélectionner un %{type}"
      },
      "positions": {
        "members": "Statut",
        "others": "Fonctions"
      },
      "rejected": "Refusés par HorseID",
      "section-title": {
        "admin": "Banque",
        "contact_details": "Coordonnées",
        "horse_id_update": "Mise à jour HorseID",
        "identity": "Identité",
        "membership": "Affiliation",
        "org_people": {
          "one": "Responsable",
          "other": "Responsables"
        }
      },
      "select_contact_placeholder": "Sélectionner un(e) %{type}",
      "show_old_functions": "voir anciennes fonctions",
      "show_old_statuses": "voir anciens statuts",
      "to_update": "Mises à jour",
      "values": {
        "address_types": {
          "billing": "Facturation",
          "main": "Adresse principale",
          "post": "Adresse postale"
        }
      }
    },
    "coupling_card_mailer": {
      "card_created": {
        "subject": "Carte de saillie %{number} créée"
      },
      "card_to_studbook_by_email": {
        "subject": "Carte de saillie %{number} clôturée"
      }
    },
    "coupling_cards": {
      "available": {
        "one": "Carte disponible",
        "other": "Cartes disponibles"
      },
      "available_full": {
        "one": "%{count} carte disponible sur %{total}",
        "other": "%{count} cartes disponibles sur %{total}"
      },
      "breeding_dates": "Date(s) de saillie",
      "breeding_information": "Information sur la saillie",
      "card_type": {
        "label": "Type de cartes",
        "online": "Online",
        "paper": "Papier"
      },
      "card_type_invalid": "Type de cartes est invalide",
      "carrying_mare": "Mère porteuse",
      "closed": {
        "one": "Carte clôturée",
        "other": "Cartes clôturées"
      },
      "credit_count": {
        "one": "Carte disponible",
        "other": "Cartes disponibles",
        "zero": "Pas de carte disponible"
      },
      "direct_access": "Accès direct",
      "edit": {
        "page_title": "Modification carte de saillie",
        "title": "Modifier la carte de saillie"
      },
      "email": {
        "send": "Envoyer",
        "send_card_to": "Envoyer la carte de saillie à",
        "title": "Envois par email"
      },
      "export": {
        "filter": {
          "placeholder": "Filtre"
        },
        "list_title": "Cartes à exporter",
        "title": "Exportation de cartes"
      },
      "flash": {
        "create": {
          "fail": "Erreur d'enregistrement. La carte n'a pas été créée",
          "success": "Carte créée"
        },
        "destroy": {
          "fail": "La carte n'a pas pu être supprimée",
          "success": "Carte de saillie supprimée"
        },
        "export": {
          "success": "Cartes exportées"
        },
        "new": {
          "fail_for_mare": "La carte de saillie doit être créée pour un étalon",
          "fail_no_card_available": "Cet étalon n'a plus de carte disponible"
        },
        "order": {
          "fail": "Les cartes doivent être commandées pour un étalon"
        },
        "print": {
          "success": "Cartes imprimées"
        },
        "to_export": {
          "fail": "Il n'y a pas de carte à exporter"
        },
        "to_print": {
          "fail": "Il n'y a pas de carte à imprimer"
        },
        "update": {
          "fail": "La carte n'a pas pu être mise à jour",
          "success": "Carte mise à jour"
        }
      },
      "form": {
        "confirm_help": "Vérifiez les données avant d'enregistrer la carte"
      },
      "hints": {
        "coupling_card_year": "Choisissez d'abord un étalon pour définir l'année de monte",
        "mare_code": "Code Studbook ou UELN",
        "stalion_code": "Code Studbook ou UELN"
      },
      "index": {
        "my_cards": "Mes cartes de saillie",
        "search": {
          "placeholder": "Rechercher une carte par numéro ou étalon"
        }
      },
      "mare": {
        "extend_label": "Mère biologique du produit à naître"
      },
      "new": {
        "page_title": "Nouvelle carte de saillie",
        "title": "Créer une nouvelle carte de saillie"
      },
      "no_carrying_mare": "Pas de mère porteuse",
      "open": {
        "one": "Carte ouverte",
        "other": "Cartes ouvertes"
      },
      "order": {
        "cards_to_be_used_by_couplers": "Ces cartes pourront être utilisées par les inséminateurs sélectionnés pour cet étalon.",
        "currently_selected_couplers": "Il y a actuellement %{count} %{couplers}",
        "no_coupler_selected_currently": "Il n'y a actuellement aucun inséminateur sélectionné.",
        "title": "Commande de nouvelles cartes de saillie"
      },
      "paper": {
        "one": "Carte papier ouverte",
        "other": "Cartes papier ouvertes"
      },
      "print": {
        "filter": {
          "placeholder": "Filtre"
        },
        "list_title": "Cartes à imprimer",
        "title": "Impression de cartes papier"
      },
      "saved_horse_html": "La naissance de <strong>%{name}</strong> a été enregistrée sur base de cette carte de saillie.",
      "section-title": {
        "owner_identity": "Identité du propriétaire de la mère biologique"
      },
      "show": {
        "add_mare": "Ajouter une jument",
        "add_stallion": "Ajouter un étalon",
        "can_not_close": "La carte ne peut pas être clôturée.",
        "missing_mare": "Pas de jument encodée",
        "missing_stallion": "Pas d'étalon selectionné",
        "no_card_found": "Aucune carte de saillie trouvée",
        "summary_not_a_valid_certificate": "Ce récapitulatif ne constitue pas un certificat de saillie valable !"
      },
      "steps": {
        "confirm": "Récapitulatif",
        "coupling": "Saillie",
        "owner": "Propriétaire",
        "parents": "Origines"
      },
      "used": {
        "one": "Carte utilisée",
        "other": "Cartes utilisées"
      },
      "values": {
        "coupling_mode": {
          "1": "Monte naturelle",
          "2": "I.A. semence fraiche",
          "3": "I.A. semence réfrigérée",
          "4": "I.A. semence congelée",
          "5": "ICSI"
        },
        "status": {
          "0": "Date d'ouverture",
          "1": "Date de clôture",
          "2": "Date de conversion",
          "closed": {
            "one": "Clôturée",
            "other": "Clôturées"
          },
          "open": {
            "one": "Ouverte",
            "other": "Ouvertes"
          },
          "with_birth": {
            "one": "Convertie",
            "other": "Converties"
          }
        }
      }
    },
    "dashboards": {
      "cwbc": {
        "badges": {
          "cbc_horses_to_transfer": "Demandes de création à transférer",
          "identifications_to_finalize": "Demandes en cours",
          "identifications_to_transfer": "Demandes à transférer",
          "missing_cbc_id": "ID CBC manquants",
          "prime_requests_to_send": "Demandes à envoyer",
          "rejected": "Refusés par HorseID",
          "toupdate": "Mises à jour"
        },
        "cards": {
          "contacts": "Contacts",
          "identifications": "Identifications",
          "prime_requests": "Demandes de prime"
        }
      },
      "main": {
        "title": "Dashboard"
      },
      "reproduction": {
        "coupling_year": "Saison %{year}",
        "stallions": "Etalons",
        "title": "Saison de monte %{year}",
        "to_print_summary": "Il y a %{amount} cartes papier en attente d'impression."
      },
      "studbook": {
        "badges": {
          "birth_registrations_to_validate": "Déclarations de naissance à valider",
          "cbc_horses_to_transfer": "Demandes de création à transférer",
          "coupling_cards_to_export": "Cartes de saillies à exporter",
          "coupling_cards_to_print": "Cartes de saillies à imprimer",
          "dna_controls_to_confirm": "Contrôles à valider",
          "dna_controls_to_send": "Contrôles à envoyer",
          "identifications_finalized": "Demandes d'identification clôturées",
          "identifications_to_finalize": "Demandes d'identification en cours",
          "identifications_to_transfer": "Demandes d'identification à transférer",
          "passports_to_export": "Passeports à créer",
          "passports_to_send": "Passeports à envoyer",
          "passports_to_transfer": "Passeports à transférer"
        },
        "cards": {
          "birth_registrations_and_identifications": "Naissances & identifications",
          "coupling_cards": "Cartes de Saillies",
          "dna_controls": "Contrôles génétiques",
          "passports": "Passeports"
        },
        "title": "Tâches en attente"
      }
    },
    "date": {
      "abbr_day_names": [
        "dim",
        "lun",
        "mar",
        "mer",
        "jeu",
        "ven",
        "sam"
      ],
      "abbr_month_names": [
        null,
        "jan.",
        "fév.",
        "mar.",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "août",
        "sept.",
        "oct.",
        "nov.",
        "déc."
      ],
      "day_names": [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi"
      ],
      "formats": {
        "default": "%d/%m/%Y",
        "long": "%e %B %Y",
        "mat_datepicker": "dd/mm/yyyy",
        "real_short_date": "%d/%m/%y",
        "short": "%e %b",
        "short_date": "%d/%m/%Y"
      },
      "month_names": [
        null,
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "environ une heure",
          "other": "environ %{count} heures"
        },
        "about_x_months": {
          "one": "environ un mois",
          "other": "environ %{count} mois"
        },
        "about_x_years": {
          "one": "environ un an",
          "other": "environ %{count} ans"
        },
        "almost_x_years": {
          "one": "presqu'un an",
          "other": "presque %{count} ans"
        },
        "half_a_minute": "une demi-minute",
        "less_than_x_minutes": {
          "one": "moins d'une minute",
          "other": "moins de %{count} minutes",
          "zero": "moins d'une minute"
        },
        "less_than_x_seconds": {
          "one": "moins d'une seconde",
          "other": "moins de %{count} secondes",
          "zero": "moins d'une seconde"
        },
        "over_x_years": {
          "one": "plus d'un an",
          "other": "plus de %{count} ans"
        },
        "x_days": {
          "one": "1 jour",
          "other": "%{count} jours"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 mois",
          "other": "%{count} mois"
        },
        "x_seconds": {
          "one": "1 seconde",
          "other": "%{count} secondes"
        },
        "x_years": {
          "one": "un an",
          "other": "%{count} ans"
        }
      },
      "prompts": {
        "day": "Jour",
        "hour": "Heure",
        "minute": "Minute",
        "month": "Mois",
        "second": "Seconde",
        "year": "Année"
      }
    },
    "delivrable_subjects": {
      "Divers": "Divers",
      "Duplicata": "Duplicata",
      "Détermination ADN": "Détermination ADN",
      "Inscription": "Inscription",
      "Transfert propriété": "Transfert propriété"
    },
    "delivrables": {
      "flash": {
        "create": {
          "fail": "La Prestation n'a pas pu être enregistrée",
          "success": "Prestation enregistrée"
        },
        "destroy": {
          "fail": "La Prestation n'a pas pu être supprimée",
          "success": "Prestation supprimée"
        },
        "edit": {
          "fail": "La Prestation n'a pas pu être modifiée",
          "success": "Prestation modifiée"
        }
      },
      "no_delivrable": "Aucune prestation"
    },
    "detention_places": {
      "invalid_number": "Numéro invalide"
    },
    "documents": {
      "dias": {
        "documents_error": "Une erreur s'est produite pendant l'affichage des documents. Veuillez réessayer plus tard.",
        "documents_unavailable": "Le service DiAS est indisponible pour le moment, les documents ne peuvent être affichés.",
        "error": "Erreur",
        "errors": {
          "3003": "Envoi impossible : l'équidé n'a pas encore de dossier dans DiAS"
        },
        "loading": "Connexion à DiAS en cours",
        "not_found": "Le document ne peut pas être affiché, il a peut-être été supprimé",
        "signages_error": "Une erreur s'est produite pendant l'affichage des signalements graphiques. Veuillez réessayer plus tard.",
        "signages_unavailable": "Le service DiAS est indisponible pour le moment, les signalements graphiques ne peuvent être affichés.",
        "unknown_type": "Inconnu",
        "warning": "Avertissement"
      },
      "flash": {
        "create": {
          "signage": {
            "fail": "Le signalement graphique n'a pas pu être ajouté",
            "success": "Signalement graphique ajouté"
          }
        },
        "destroy": {
          "signage": {
            "fail": "Le signalement graphique n'a pas pu être supprimé",
            "success": "Signalement graphique supprimé"
          }
        },
        "update": {
          "signage": {
            "fail": "Le signalement graphique n'a pas pu être modifié",
            "success": "Signalement graphique modifié"
          }
        }
      }
    },
    "errors": {
      "file": {
        "extension_exclusion": "extension ne peut pas être présente dans la liste : %{list}",
        "extension_inclusion": "extension doit être présente dans la liste : %{list}",
        "max_dimensions": "dimensions doivent être inférieures à %{dims}",
        "max_height": "hauteur doit être inférieure à %{max}",
        "max_size": "taille doit être inférieure à %{max}",
        "max_width": "largeur doit être inférieure à %{max}",
        "mime_type_exclusion": "type ne peut pas être présent dans la liste : %{list}",
        "mime_type_inclusion": "type doit être présent dans la liste : %{list}",
        "min_dimensions": "dimensions doivent être supérieures à %{dims}",
        "min_height": "hauteur doit être supérieure à %{min}",
        "min_size": "taille doit être supérieure à %{min}",
        "min_width": "largeur doit être supérieure à %{min}"
      },
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "doit être accepté(e)",
        "blank": "doit être rempli(e)",
        "confirmation": "ne concorde pas avec %{attribute}",
        "empty": "doit être rempli(e)",
        "equal_to": "doit être égal à %{count}",
        "even": "doit être pair",
        "exclusion": "n'est pas disponible",
        "greater_than": "doit être supérieur à %{count}",
        "greater_than_or_equal_to": "doit être supérieur ou égal à %{count}",
        "has_duplicates": "contient des doublons",
        "improbable_phone": "est un numéro invalide",
        "in_the_future": "ne peut pas être dans le futur",
        "inclusion": "n'est pas inclus(e) dans la liste",
        "invalid": "n'est pas valide",
        "less_than": "doit être inférieur à %{count}",
        "less_than_or_equal_to": "doit être inférieur ou égal à %{count}",
        "model_invalid": "Validation échouée : %{errors}",
        "not_a_number": "n'est pas un nombre",
        "not_an_integer": "doit être un nombre entier",
        "odd": "doit être impair",
        "other_than": "doit être différent de %{count}",
        "present": "doit être vide",
        "required": "doit exister",
        "taken": "n'est pas disponible",
        "too_long": {
          "one": "est trop long (pas plus d'un caractère)",
          "other": "est trop long (pas plus de %{count} caractères)"
        },
        "too_short": {
          "one": "est trop court (au moins un caractère)",
          "other": "est trop court (au moins %{count} caractères)"
        },
        "unmatching_passwords": "ne correspond pas au mot de passe",
        "wrong_length": {
          "one": "ne fait pas la bonne longueur (doit comporter un seul caractère)",
          "other": "ne fait pas la bonne longueur (doit comporter %{count} caractères)"
        }
      },
      "template": {
        "body": "Veuillez vérifier les champs suivants : ",
        "header": {
          "one": "Impossible d'enregistrer ce(tte) %{model} : 1 erreur",
          "other": "Impossible d'enregistrer ce(tte) %{model} : %{count} erreurs"
        }
      }
    },
    "expandable": {
      "show_less": "Voir moins",
      "show_more": "Voir plus"
    },
    "export_list_items": {
      "flash": {
        "remove_contact_from_list": {
          "fail": "Le contact n'a pas pu être retiré de la liste d'export",
          "success": "Le contact a été retiré de la liste d'export"
        },
        "remove_from_list": {
          "fail": "L'élément n'a pas pu être retiré de la liste d'export",
          "success": "L'élément a été retiré de la liste d'export"
        },
        "remove_horse_from_list": {
          "fail": "L'équidé n'a pas pu être retiré de la liste d'export",
          "success": "L'équidé a été retiré de la liste d'export"
        }
      }
    },
    "export_lists": {
      "flash": {
        "destroy": {
          "success": "La liste d'export a été supprimée"
        }
      },
      "index": {
        "is_empty": "Aucune liste à afficher",
        "page_title": "Listes d'export",
        "page_title_contacts": "Listes d'export de contacts",
        "page_title_horses": "Listes d'export d'équidés",
        "title": "Listes d'export existantes"
      },
      "show": {
        "is_empty": "La liste est vide"
      }
    },
    "favorable": "Favorable",
    "file_input": {
      "placeholder": "Choisir un fichier"
    },
    "filterable": {
      "filter": {
        "approved": "Reprod. SC",
        "contact": {
          "fee_payed": "Cotisation payée",
          "function": "Fonction",
          "member": {
            "0": "Non-membre",
            "1": "Membre"
          },
          "send_magazine": "Périodique",
          "status": "Statut",
          "type": {
            "organisation": "Organisation",
            "person": "Personne"
          }
        },
        "filter": "Filtrer",
        "first_breeder": "Naisseur",
        "old_approval": "Approuvés",
        "recipient": "Destinataire",
        "sex": {
          "1": "Mâles",
          "2": "Femelles"
        },
        "status": "Statut",
        "studbook": "Studbook",
        "studbook_document_type_id": "Type de document",
        "year": "Année"
      },
      "filter_by": {
        "sex": "Filtrer par sexe",
        "year": "Filtrer par année"
      },
      "indiferrent": "Tous",
      "menu_title": "Filtrer les résulats",
      "no_filter": "Aucun filtre"
    },
    "flashes": {
      "failure_after_create": "Adresse e-mail ou mot de passe incorrect.",
      "failure_after_update": "Le mot de passe doit contenir au moins 8 caractères; dont une majuscule, un chiffre, et un caractère spécial parmi: @$!%*#?&",
      "failure_when_access_denied": "Vous ne pouvez pas accéder à cette fonctionalité",
      "failure_when_forbidden": "Merci de vérifier l'URL ou réessayez de soumettre le formulaire.",
      "failure_when_not_signed_in": "Vous devez vous connecter pour utiliser SireCloud"
    },
    "food_chain": "Chaîne Alimentaire",
    "food_chain_excluded_on": "Exclu le %{date}",
    "form_message": {
      "access_request_title": "Demande d'accès SireCloud",
      "flash": {
        "sent": "Demande envoyée."
      },
      "order_coupling_title": "Commande de cartes de saillie"
    },
    "from_date_to_date": "du %{from_date} au %{to_date}",
    "help_texts": {
      "etalonnier": {
        "coupling_cards_index_html": " <p> Vous pouvez ici gérer vos cartes de saillie ouvertes et clôturées. <br/> Pour déclarer une naissance, rendez vous sur une carte clôturée. </p>",
        "horses_index_html": " <p> Pour voir la fiche d'un étalon et les informations qui la composent, vous pouvez cliquer sur un étalon dans le tableau ci-dessous. <!--<br/> Pour délcarer une naissance, vous pouvez vous rendre sur la page <a href=\"/cards\">Mes cartes de saillie</a>.--> </p> "
      }
    },
    "helpers": {
      "dates": {
        "from_to_date": "du %{start_date} au %{end_date}",
        "since_date": "depuis le %{date}",
        "to_date": "jusqu'au %{date}"
      },
      "label": {
        "password": {
          "email": "Email"
        },
        "password_reset": {
          "password": "Mot de passe",
          "password_confirmation": "Confirmation"
        }
      },
      "page_entries_info": {
        "more_pages": {
          "display_entries": "%{entry_name} %{first} à %{last} sur %{total}"
        },
        "one_page": {
          "display_entries": {
            "one": "1 %{entry_name}",
            "other": "%{count} %{entry_name}",
            "zero": "Aucun %{entry_name}"
          }
        }
      },
      "select": {
        "prompt": "Veuillez sélectionner"
      },
      "submit": {
        "create": "Créer un(e) %{model}",
        "password": {
          "submit": "Choisir votre mot de passe"
        },
        "password_reset": {
          "submit": "Enregistrer le mot de passe"
        },
        "session": {
          "submit": "Se connecter"
        },
        "submit": "Enregistrer ce(tte) %{model}",
        "update": "Modifier ce(tte) %{model}",
        "user": {
          "create": "S'inscrire"
        }
      }
    },
    "horse/approval": {
      "actions": {
        "confirm_destroy": "Sûr ?",
        "destroy": "Supprimer"
      },
      "add_coupling_card": "Créer une carte de saillie",
      "approval_end_date": "Date d'approbation définitive",
      "candidate": "Candidat",
      "edit": {
        "page_title": "Modification demande d'expertise",
        "title": "Modifier l'approbation"
      },
      "expertise_end_date": "Date d'approbation",
      "flash": {
        "cancel_expertise": {
          "fail": "L'expertise n'a pas pu être annulée",
          "success": "Expertise annulée"
        },
        "create": {
          "fail": "Erreur d'enregistrement. La demande d'expertise n'a pas été créée",
          "success": "Demande d'expertise créée"
        },
        "destroy": {
          "fail": "L'approbation n'a pas pu être supprimée",
          "success": "Approbation supprimée"
        },
        "refuse": {
          "fail": "L'expertise n'a pas pu être refusée",
          "success": "Expertise refusée"
        },
        "update": {
          "fail": "L'approbation n'a pas pu être mise à jour",
          "success": "Approbation mise à jour"
        },
        "validate_infinitely": {
          "fail": "L'approbation n'a pas pu être validée définitivement",
          "success": "Approbation validée définitivement"
        },
        "validate_temporary": {
          "fail": "L'approbation n'a pas pu être validée",
          "success": "Approbation validée"
        }
      },
      "new": {
        "page_title": "Nouvelle demande d'approbation",
        "title": "Nouvelle demande d'approbation"
      },
      "no_approvals_for_horse": "Il n'y a pas d'approbation ou d'expertise enregistrée dans SireCloud pour cet équidé",
      "no_available_coupling_card": "Il n'y a pas de carte de saillie disponible pour cet équidé. Il faut en commander.",
      "no_open_coupling_card": "Il n'y a pas de carte de saillie ouverte pour cet équidé",
      "none_fem": "Aucune",
      "none_masc": "Aucun",
      "order_coupling_card": "Commande de nouvelles cartes de saillie",
      "sections": {
        "expertise": "Expertise",
        "refusal_note": "Notes de refus",
        "reviews": "Avis",
        "years": "Années"
      },
      "values": {
        "disciplines": {
          "1": "Complet",
          "2": "Dressage",
          "3": "Obstacle"
        },
        "status": {
          "approved_infinitely": "Approbation définitive",
          "approved_temporary": "Approuvé",
          "expertise_ongoing": "En cours d'expertise",
          "refused": "Expertise refusée"
        }
      }
    },
    "horse/approval/review": {
      "flash": {
        "create": {
          "fail": "L'avis n'a pas pu être créé",
          "success": "Avis créé"
        }
      },
      "values": {
        "positive": {
          "false": "Défavorable",
          "nil": "Ne se prononce pas",
          "true": "Favorable"
        },
        "types": {
          "1": "CVIF",
          "2": "Comité de Juges"
        }
      }
    },
    "horse/approval/year": {
      "flash": {
        "create": {
          "fail": "L'année n'a pas pu être créée",
          "success": "Année créée"
        }
      },
      "values": {
        "annual_fee": {
          "false": "Redevance annuelle non payée",
          "true": "Redevance annuelle payée"
        },
        "conditions": {
          "false": "Conditions non remplies",
          "true": "Conditions remplies"
        }
      }
    },
    "horse/breedership": {
      "at": "à"
    },
    "horse/ownership": {
      "at": "à"
    },
    "horse/passport": {
      "flash": {
        "cancel_status": {
          "fail": "Le statut précédent n'a pas pu être rétabli",
          "success": "Statut précédent rétabli"
        },
        "create": {
          "fail": "Le passeport n'a pas pu être créé",
          "success": "Passeport créé"
        },
        "delete": {
          "fail": {
            "one": "Le passeport n'a pas pu être supprimé",
            "other": "Les passeports n'ont pas pu être supprimés"
          },
          "success": {
            "one": "Le passeport a été supprimé",
            "other": "Les passeports ont été supprimés"
          }
        },
        "export": {
          "fail": {
            "one": "Le passeport n'a pas pu être exporté",
            "other": "Les passeports n'ont pas pu être exportés"
          },
          "success": {
            "one": "Passeport exporté",
            "other": "Passeports exportés"
          }
        },
        "mark_as_sent": {
          "fail": "Date d'envoi invalide",
          "success": {
            "one": "Passeport marqué comme envoyé",
            "other": "Passeports marqués comme envoyés"
          }
        },
        "new": {
          "fail": "Un équidé est requis"
        },
        "none_selected": "Aucun passeport sélectionné",
        "transfer": {
          "fail": {
            "one": "Le passeport n'a pas pu être transféré",
            "other": "Les passeports n'ont pas pu être transférés"
          },
          "success": {
            "one": "Passeport transféré",
            "other": "Passeports transférés"
          }
        },
        "update": {
          "fail": "Le passeport n'a pas pu être mis à jour",
          "success": "Passeport mis à jour"
        },
        "update_export": {
          "fail": "L'export n'a pas pu être mis à jour",
          "success": "Export mis à jour"
        }
      },
      "no_passport_for_horse": "Il n'y a pas de passeport enregistré dans SireCloud pour cet équidé.",
      "values": {
        "passport_type": {
          "duplicata": "Duplicata",
          "original": "Original",
          "replacement": "Remplacement",
          "sirewin": "Sirewin"
        },
        "status": {
          "cancelled": {
            "one": "Annulé",
            "other": "Annulés"
          },
          "exported": {
            "one": "A envoyer",
            "other": "A envoyer"
          },
          "lost": {
            "one": "Perdu",
            "other": "Perdus"
          },
          "sent": {
            "one": "Envoyé",
            "other": "Envoyés"
          },
          "to_export": {
            "one": "Nouveau",
            "other": "A exporter"
          },
          "to_transfer": {
            "one": "A transférer",
            "other": "A transférer"
          }
        }
      }
    },
    "horse/prime_request": {
      "actions": {
        "confirm_close_list": "Êtes-vous sûr de vouloir cloturer la liste ?",
        "csv_export": "Export CSV"
      },
      "current_list": "Liste en cours: %{year}",
      "flash": {
        "close": {
          "fail": "La liste n'a pas pu être cloturée",
          "success": "Liste cloturée"
        },
        "export": {
          "fail": {
            "one": "La demande de prime n'a pas pu être exporté",
            "other": "Les demandes de prime n'ont pas pu être exportées"
          },
          "success": {
            "one": "Demande de prime exportée",
            "other": "Demandes de prime exportées"
          }
        },
        "none_selected": "Aucune demande sélectionnée"
      },
      "index": {
        "search": {
          "placeholder": "Recherche rapide : Nom, UELN ou Code"
        }
      },
      "labels": {
        "short": {
          "created_at": "Edition",
          "sent_date": "Envoyée"
        }
      },
      "section-title": {
        "prime": "Demande de prime"
      },
      "values": {
        "status": {
          "sent": {
            "one": "Envoyée",
            "other": "Envoyées"
          },
          "to_send": {
            "one": "A envoyer",
            "other": "A envoyer"
          }
        }
      }
    },
    "horse_databases": {
      "configuration_options": {
        "approvals_country_codes": "Pays d'approbation",
        "approvals_country_codes_placeholder": "Sélectionner un pays",
        "approvals_disciplines": "Disciplines d'approbation",
        "approvals_disciplines_placeholder": "Sélectionner une discipline",
        "approvals_review_types": "Types d'avis",
        "approvals_review_types_placeholder": "Sélectionner un type",
        "identifications_simplified_type_by_default": "Procédure Studbook simplifiée par défaut",
        "online_birth_registration": "Déclaration de naissance en ligne",
        "passports_require_dna_validation": "Validation ADN requise"
      },
      "edit": {
        "page_title": "Modifier le studbook",
        "title": "Modifier le studbook"
      },
      "flash": {
        "update": {
          "fail": "La mise à jour du studbook a échouée",
          "success": "Studbook mis à jour"
        }
      },
      "placeholders": {
        "select_label": "Sélectionner un label"
      },
      "section-title": {
        "approvals": "Approbations",
        "book_sections": "Sections",
        "books": "Sous-sections",
        "emails": "Emails",
        "general": "Général",
        "identifications": "Identifications",
        "registrations": "Inscriptions",
        "studbook_document_types": "Types",
        "studbook_document_types_contacts": "Types contacts",
        "studbook_document_types_horses": "Types équidés"
      },
      "tabs": {
        "books": {
          "title": "Sections"
        },
        "labels": {
          "title": "Labels"
        },
        "options": {
          "title": "Options"
        },
        "sections": {
          "title": "Sous-sections"
        },
        "studbook_documents": {
          "title": "Documents"
        }
      }
    },
    "horse_id": {
      "contact_pending_validation": "%{id} (temp.)",
      "unavailable": "HorseID n'est pas disponible pour l'instant, veuillez réessayer plus tard"
    },
    "horse_id/detention_places": {
      "info": {
        "address": "Adresse",
        "operator_cbc_id": "ID CBC de l'opérateur"
      },
      "no_result_found": "Aucun établissement trouvé",
      "results_found": "%{count} établissement(s) trouvé(s) - sélectionnez-en un",
      "values": {
        "capacities": {
          "11-20": "11-20",
          "5-10": "5-10",
          "<5": "<5",
          ">20": ">20"
        },
        "categories": {
          "boxes": "Boxes",
          "meadow": "Meadow",
          "mixed": "Mixed"
        },
        "types": {
          "natural_reserve": "Natural reserve",
          "others_horses": "Others horses",
          "self_horses": "Self horses",
          "shelter": "Shelter"
        }
      }
    },
    "horses": {
      "actions": {
        "confirm_destroy": "Êtes-vous sûr de vouloir supprimer définitivement cet équidé ?",
        "confirm_destroy_studbook_document": "Êtes-vous sûr de vouloir supprimer définitivement ce document ?",
        "destroy": "Supprimer cet équidé"
      },
      "and": "et",
      "birth_country_unknown": "< Inconnu / Indéterminé >",
      "by": "par",
      "cbc_id_required_for_graphic_signage": "L'équidé doit d'abord obtenir un ID CBC pour pouvoir avoir un signalement graphique",
      "created_outside_sirecloud": "La demande d'identification CBC a été introduite en dehors de SireCloud.",
      "dashboard_labels": {
        "horses_approved_stallions_count": "Etalons approuvés",
        "horses_count": "Equidés",
        "horses_main_race_count": "Equidés %{db_main_race}",
        "see_all": "+ Voir les %{count} équidés"
      },
      "death_mutation_will_be_final": "Le décès ne pourra pas être retiré",
      "decorator": {
        "born_on": {
          "0_html": "<span class=\"born-on-intro\">né le </span>%{date}",
          "1_html": "<span class=\"born-on-intro\">né le </span>%{date}",
          "2_html": "<span class=\"born-on-intro\">née le </span>%{date}"
        },
        "born_on_no_date_html": "Date de naissance inconnue"
      },
      "dna_controls": {
        "page_title": "Contrôles génétiques",
        "search": {
          "placeholder": "Recherche rapide : Nom, UELN ou Code"
        },
        "title": "Contrôles génétiques"
      },
      "dna_status": {
        "to_confirm": {
          "one": "Envoyé le %{sent_date}",
          "other": "A confirmer"
        },
        "to_send": {
          "one": "A envoyer",
          "other": "A envoyer"
        }
      },
      "edit": {
        "coupling_card": {
          "no_card": "Pas de carte",
          "other_card": "Autre carte",
          "sirecloud_card": "Carte SireCloud"
        },
        "page_title": "Modifier un équidé",
        "title": "Modifier l'enregistrement de l'équidé"
      },
      "flash": {
        "create": {
          "fail": "La création de l'équidé a échouée.",
          "success": "Nouvel équidé enregistré."
        },
        "destroy": {
          "not_with_chidlren": "Un équidé avec une production enregistré ne peut pas être supprimé.",
          "success": "L'équidé a été supprimé."
        },
        "dna_control_confirmed": {
          "fail": {
            "one": "Le contrôle n'a pas pu être confirmé",
            "other": "Les contrôles n'ont pas pu être confirmés"
          },
          "success": {
            "one": "Contrôle confirmé",
            "other": "Contrôles confirmé"
          }
        },
        "dna_control_sent": {
          "fail": {
            "one": "Le contrôle n'a pas pu être envoyé",
            "other": "Les contrôles n'ont pas pu être envoyés"
          },
          "success": {
            "one": "Contrôle envoyé",
            "other": "Contrôles envoyés"
          }
        },
        "update": {
          "success": "Les informations de l'équidé ont été mises à jour."
        }
      },
      "food_chain_mutation_will_be_final": "L'exclusion ne pourra pas être retirée",
      "hints": {
        "blood_type": "Groupe",
        "ueln_type": "Un code est requis"
      },
      "history": {
        "birth_registration_created": "Réception déclaration de naissance",
        "birth_registration_validated": "Validation de la déclaration",
        "born_on": "Naissance",
        "cbc_horse_transfered": "Transfert et clôture (HorseID)",
        "dna_horse_hair_received": "Réception des crins",
        "dna_sent": "Envoi ADN labo",
        "dna_tested": "Test ADN labo",
        "identification_document_sent": "Envoi dossier identification (HorseID)",
        "identification_finalized": "Clôture du dossier identification (HorseID)",
        "identification_received": "Réception dossier identification (HorseID)",
        "identification_transfered": "Transfert de la demande d'identification (HorseID)",
        "identification_validated": "Validation de la demande d'identification (HorseID)",
        "invoice_paid": "Paiement inscription Studbook",
        "passport_cancelled": "Annulation passeport",
        "passport_edited": "Edition passeport",
        "passport_sent": "Envoi passeport",
        "received_at": "Enregistrement"
      },
      "horse_cbc_id_is": "L'ID CBC de l'équidé est %{cbc_id}",
      "index": {
        "my_stallions": "Mes étalons",
        "no_horse_found": "Aucun équidé trouvé",
        "search": {
          "advanced": {
            "title": "Recherche avancée"
          },
          "placeholder": "Recherche rapide : Nom, UELN ou Code"
        }
      },
      "new": {
        "page_title": "Nouvel équidé",
        "title": "Enregistrement d'un nouvel équidé"
      },
      "not_yet_created": "La demande d'identification n'a pas encore été créée",
      "or": "OU",
      "placeholders": {
        "search_mare": "Rechercher une jument par Nom, Code ou Microchip",
        "search_stallion": "Rechercher un étalon par Nom, Code ou Microchip",
        "select_coupling_card_placeholder": "Sélectionner une carte de saillie",
        "select_mare": "Sélectionner une jument",
        "select_stallion": "Sélectionner un étalon"
      },
      "section-title": {
        "active_passport": "Passeport actif",
        "additional_info": "Informations complémentaires",
        "identification": "Identification",
        "numbering": "Numérotation",
        "origins": "Origines",
        "previous_passports": "Anciens passeports",
        "registration": "Inscription",
        "structured_signage": "Signalement codifié"
      },
      "stallions": {
        "no_horse_found": "Aucun équidé trouvé"
      },
      "tabs": {
        "delivrables": {
          "title": "Facturation"
        },
        "documents": {
          "created_at": "Date de création",
          "name": "Nom",
          "none": "Aucun document",
          "title": "Documents",
          "type": "Type"
        },
        "generalites": {
          "Identification": "Identification",
          "title": "Généralités"
        },
        "identification": {
          "title": "Identification"
        },
        "labels": {
          "title": "Labels"
        },
        "notes": {
          "title": "Notes"
        },
        "passports": {
          "title": "Passeports"
        },
        "pedigree": {
          "title": "Pédigrée"
        },
        "people": {
          "title": "Personnes"
        },
        "prime": {
          "title": "Prime"
        },
        "production": {
          "title": "Production"
        },
        "reproduction": {
          "title": "Reproduction"
        },
        "results": {
          "title": "Résultats"
        },
        "signalement": {
          "title": "Signalement"
        }
      },
      "unknown": "Pédigrée en développement",
      "values": {
        "companions": {
          "bcpa": {
            "status": {
              "HWSD/HWSD": "HWSD/HWSD - 2 copies de la mutation HWSD",
              "N/HWSD": "N/HWSD - 1 copie de la mutation HWSD",
              "N/N": "N/N - Aucune copie de la mutation HWSD"
            }
          }
        },
        "dna_filiation_compatible": {
          "ko": "Filiation incompatible",
          "ok": "Filiation compatible"
        },
        "food_chain": {
          "0": "Maintenu",
          "1": "Exclu",
          "false": "Maintenu",
          "true": "Exclu"
        },
        "sexe": {
          "0": "Inconnu",
          "1": "Mâle",
          "2": "Femelle"
        },
        "sexe_short": {
          "0": "?",
          "1": "M",
          "2": "F"
        },
        "ueln_types": {
          "auto": "Oui, n° définitif",
          "manual": "Non, saisie manuel",
          "temp": "Oui, n° temporaire"
        }
      }
    },
    "identifications": {
      "actions": {
        "cancel_status": "Revenir au statut précédent",
        "confirm_destroy": "Êtes-vous sûr de vouloir supprimer définitivement cette demande d'identification ?",
        "destroy": "Supprimer cette demande"
      },
      "changing_status_dates": "Attention : modifier ces dates peut faire changer le statut de l'identification sans que ce nouveau statut ne soit signifié à HorseID.",
      "data_missing_for_finalization": "Informations manquantes pour la clôture",
      "data_missing_for_reception": "Informations manquantes pour la réception",
      "data_missing_for_validation": "Informations manquantes pour le transfert",
      "detention_place_existing": "Etablissement existant",
      "detention_place_missing": "Etablissement manquant",
      "detention_place_new": "Nouvel Établissement",
      "detention_place_unknown": "Etablissement inconnu",
      "edit": {
        "page_title": "Modification demande d'identification",
        "title": "Modifier la demande d'identification"
      },
      "flash": {
        "bulk": {
          "fail": "Aucune identification sélectionnée"
        },
        "cancel_status": {
          "fail": "Le statut précédent n'a pas pu être rétabli",
          "success": "Statut précédent rétabli"
        },
        "cancelled": {
          "fail": {
            "one": "La demande n'a pas pu être annulée",
            "other": "Les demandes n'ont pas pu être annulées"
          },
          "success": {
            "one": "Demande annulée",
            "other": "Demandes annulées"
          }
        },
        "create": {
          "fail": "La demande n'a pas été créée",
          "success": "Demande d'identification enregistrée"
        },
        "destroy": {
          "fail": "La demande n'a pas pu être supprimée",
          "success": "La demande d'identification a été supprimée"
        },
        "finalized": {
          "fail": {
            "one": "La demande n'a pas pu être clôturée",
            "other": "Les demandes n'ont pas pu être clôturées"
          },
          "success": {
            "one": "Demande clôturée",
            "other": "Demandes clôturées"
          }
        },
        "finalizing": {
          "fail": {
            "one": "La demande n'a pas pu être clôturée",
            "other": "Les demandes n'ont pas pu être clôturées"
          },
          "success": {
            "one": "Demande en cours de clôture",
            "other": "Demandes en cours de clôture"
          }
        },
        "received": {
          "fail": {
            "one": "La demande n'a pas pu être réceptionée",
            "other": "Les demandes n'ont pas pu être réceptionées"
          },
          "success": {
            "one": "Demande réceptionée",
            "other": "Demandes réceptionées"
          }
        },
        "receiving": {
          "fail": {
            "one": "La demande n'a pas pu être réceptionée",
            "other": "Les demandes n'ont pas pu être réceptionées"
          },
          "success": {
            "one": "Demande en cours de réception",
            "other": "Demandes en cours de réception"
          }
        },
        "set_detention_place_code": {
          "fail": "Le lieu de détention n'a pas pu être assigné à la demande",
          "success": "Le lieu de détention a été assigné à la demande"
        },
        "transfered": {
          "fail": {
            "one": "La demande n'a pas pu être transférée",
            "other": "Les demandes n'ont pas pu être transférées"
          },
          "success": {
            "one": "Demande transférée",
            "other": "Demandes transférées"
          }
        },
        "transfering": {
          "fail": {
            "one": "La demande n'a pas pu être transférée",
            "other": "Les demandes n'ont pas pu être transférées"
          },
          "success": {
            "one": "Demande en cours de transfert",
            "other": "Demandes en cours de transfert"
          }
        },
        "update": {
          "fail": "La demande n'a pas pu être mise à jour",
          "fail_horse_id": "La demande n'a pas pu être mise à jour dans HorseID",
          "success": "Demande mise à jour",
          "success_horse_id": "Demande mise à jour et mise à jour dans HorseID"
        },
        "validated": {
          "fail": {
            "one": "La demande n'a pas pu être validée",
            "other": "Les demandes n'ont pas pu être validées"
          },
          "success": {
            "one": "Demande validée",
            "other": "Demandes validées"
          }
        },
        "validating": {
          "fail": {
            "one": "La demande n'a pas pu être validée",
            "other": "Les demandes n'ont pas pu être validées"
          },
          "success": {
            "one": "Demande en cours de validation",
            "other": "Demandes en cours de validation"
          }
        }
      },
      "holder_selected_email_no_email": "aucun email connu pour le détenteur",
      "holder_selected_email_no_holder": "veuillez choisir un détenteur",
      "horse_cbc_id_is_missing": "L'ID CBC de l'équidé est manquant.",
      "horse_id_errors": "Erreur(s) HorseID",
      "horse_id_warnings": "Avertissement(s) HorseID",
      "horseid": {
        "attributes": {
          "exploitationBox": "Boîte",
          "exploitationCountry": "Pays",
          "exploitationLocality": "Localité",
          "exploitationNumber": "Numéro",
          "exploitationPostCode": "Code postal",
          "exploitationStreet": "Rue",
          "horse": {
            "birthCountry": "Pays de naissance",
            "birthDate": "Date de naissance",
            "breeders": "Naisseurs",
            "butcherFoal": "Poulain d'abattage",
            "castrated": "Castré",
            "castrationDate": "Date castration",
            "commercialName": "Nom commercial",
            "detentionPlace": "Numéro d'établissement",
            "detentionSince": "Date d'arrivée du produit à cet établissement",
            "dressColor": "Couleur de la robe",
            "excludingFoodChain": "Exclu de la chaîne alimentaire",
            "excludingFoodChainDate": "Date d’exclusion de la chaîne alimentaire",
            "father": {
              "name": "Nom père",
              "transponder": "N° transpondeur père",
              "ueln": "UELN père"
            },
            "gender": "Sexe",
            "hasOrigin": "A un pédigrée",
            "holder": "Id CBC du détenteur",
            "holderTmp": "Id CBC temporaire du détenteur",
            "id": "Code équidé",
            "mother": {
              "name": "Nom mère",
              "transponder": "N° transpondeur mère",
              "ueln": "UELN mère"
            },
            "name": "Nom",
            "privateRemark": "Remarques privées",
            "race": "Race",
            "responsibleAgency": "Agence responsable",
            "studbookId": "Code",
            "transponder": "N° transpondeur",
            "type": "Type",
            "ueln": "UELN"
          },
          "horseBirthCountry": "Pays",
          "horseBirthdate": "Date de naissance",
          "horseBreeder1": "Id CBC du naisseur 1",
          "horseBreeder2": "Id CBC du naisseur 2",
          "horseButcherFoal": "Poulain d'abattage",
          "horseCastrated": "Castré",
          "horseCommercialName": "Nom commercial",
          "horseDressColor": "Couleur de la robe",
          "horseExcludingFoodChain": "Exclu de la chaîne alimentaire",
          "horseExcludingFoodChainDate": "Date d’exclusion de la chaîne alimentaire",
          "horseFather": "UELN père",
          "horseGender": "Sexe",
          "horseHolder": "Id CBC du détenteur",
          "horseMother": "UELN mère",
          "horseName": "Nom",
          "horseOrigin": "Origine",
          "horseRace": "Race",
          "horseReporting": "Signalement textuel",
          "horseTransponder1": "N° transpondeur",
          "horseType": "Type",
          "horseUeln": "UELN",
          "id": "Code équidé",
          "identification": {
            "dna": "Crins produit",
            "identificationDate": "Identification",
            "identificationReturnDate": "Date de retour du dossier",
            "identifier": "N° d'ordre vétérinaire",
            "identifierRemark": "Remarque de l’identificateur",
            "motherDna": "Crins mère",
            "online": "Procédure",
            "textualReporting": "Signalement textuel"
          },
          "identificationDate": "Identification",
          "identificationDna": "Crins produit",
          "identificationMotherDna": "Crins mère",
          "identificationReturnDate": "Date de retour du dossier",
          "identificationType": "Type de demande",
          "identifier": "N° d'ordre vétérinaire",
          "identifierRemark": "Remarque de l’identificateur",
          "online": "Demande online",
          "privateRemark": "Remarques",
          "responsibleAgency": "UELN agence responsable",
          "transmitterAgency": "UELN agence émettrice"
        },
        "contact_creation_error": "Le contact n'a pas pu être créé : %{error}",
        "contact_creation_success": "Contact créé dans HorseID avec l'id %{cbc_id}",
        "contact_creation_unavailable": "Ajout dans HorseID indisponible veuillez réessayer plus tard",
        "contact_ignore_update_error": "La mise à jour n'a pas pu être ignorée : %{error}",
        "contact_ignore_update_success": "Mise à jour ignorée",
        "contact_update_error": "Le contact n'a pas pu être mis à jour : %{error}",
        "contact_update_success": "Contact mis à jour dans HorseID",
        "contact_update_unavailable": "Mise à jour dans HorseID indisponible veuillez réessayer plus tard"
      },
      "identification_type_choice": "Quelle procédure d'identification souhaitez-vous utiliser ?",
      "index": {
        "search": {
          "placeholder": "Recherche rapide : Nom, UELN ou Code"
        }
      },
      "new": {
        "horse_not_found": "Pas d'équidé trouvé",
        "identification_already_requested": "Cet équidé fait déjà l'objet d'une demande d'identification",
        "page_title": "Nouvelle demande d'identification",
        "title": "Créer une nouvelle demande d'identification"
      },
      "section-title": {
        "identification": "Demande d'identification"
      },
      "sections": {
        "additional_requests": "Demandes supplémentaires",
        "birth_informations": "Informations sur la naissance",
        "breeder_details": "Coordonnées du naisseur",
        "breeder_identity": "Identité du naisseur",
        "co_breeder_details": "Coordonnées du co-naisseur",
        "co_breeder_identity": "Identité du co-naisseur",
        "co_owner_details": "Coordonnées du co-propriétaire",
        "co_owner_identity": "Identité du co-propriétaire",
        "first_holder": "Détenteur",
        "followup": "Suivi",
        "holding_place": "Lieu de détention",
        "identification_date": "Date d'identification",
        "identification_payment_html": "Procédure",
        "identification_return_date": "Date de réception",
        "identifier": "Identificateur",
        "owner_details": "Coordonnées du propriétaire",
        "owner_identity": "Identité du propriétaire",
        "product_identity": "Identité du produit",
        "product_informations": "Informations sur le produit",
        "remarks": "Remarques identificateur"
      },
      "show": {
        "page_title": "Demande d'identification",
        "title": "Demande d'identification"
      },
      "values": {
        "boolean": {
          "0": "Non",
          "1": "Oui",
          "false": "Non",
          "true": "Oui"
        },
        "sexe": {
          "0": "Mâle",
          "1": "Inconnu",
          "2": "Femelle",
          "female": "Femelle",
          "male": "Mâle"
        },
        "status": {
          "cancelled": {
            "one": "Annulée",
            "other": "Annulées"
          },
          "finalized": {
            "one": "Clôturée",
            "other": "Clôturées"
          },
          "finalizing": {
            "one": "En cours de clôture",
            "other": "En cours de clôture"
          },
          "receiving": {
            "one": "En cours de réception",
            "other": "En cours de réception"
          },
          "to_finalize": {
            "one": "En cours",
            "other": "En cours"
          },
          "to_receive": {
            "one": "Validée",
            "other": "Validées"
          },
          "to_transfer": {
            "one": "En préparation",
            "other": "En préparation"
          },
          "to_validate": {
            "one": "Transférée",
            "other": "Transférées"
          },
          "transfering": {
            "one": "En cours de transfert",
            "other": "En cours de transfert"
          },
          "validating": {
            "one": "En cours de validation",
            "other": "En cours de validation"
          }
        }
      }
    },
    "layouts": {
      "application": {
        "sign_in": "Se connecter",
        "sign_out": "Se déconnecter"
      }
    },
    "list": {
      "select_all": "Tout sélectionner"
    },
    "locales": {
      "choose": "Choisissez votre langue",
      "de": "Deutsch",
      "en": "English",
      "fr": "Français",
      "it": "Italiano",
      "lang": "langue",
      "nl": "Nederlands"
    },
    "mare": "Jument",
    "modal": {
      "add_cart_to_export_list": {
        "title_contacts": "Ajouter à une liste d'export de contacts",
        "title_horses": "Ajouter à une liste d'export d'équidés"
      },
      "birth_registration_terms": {
        "terms_html": "<p>Conformément à l’article 14 de l’Arrêté Ministériel du 23 décembre 1992 relatif à l’amélioration des équidés, la semence appartient au propriétaire de l’étalon sauf convention particulière. Le déclarant est présumé détenir tous droits utiles sur la semence pour la saillie.</p><p>Le déclarant s’engage irrévocablement à garantir le Studbook de toute action en lien avec ce qui précède, qui pourrait être introduite contre le Studbook, en suite de l’inscription du produit au livre généalogique.</p><p>Cette garantie concerne toute réclamation généralement quelconque qui serait formulée, en principal, intérêts et frais, en ce compris les frais de justice.</p><p>Le déclarant interviendra, à la première demande du Studbook, dans toute procédure, amiable ou judiciaire, et prendra fait et cause pour lui.</p>",
        "title": "Conditions générales"
      },
      "card_closed": {
        "intro_html": "<p>La carte n'est désormais plus modifiable, les données ont été transmises au studbook et le document officiel d'attestation de saillie vous sera prochainement remis par le studbook.</p>",
        "title": "La carte a été clôturée"
      },
      "cbc_horses_cancel_status": {
        "intro_html": "<p>Cette action n'annulera pas les éventuels envois et mises à jour déjà effectuées dans HorseID.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_card_closing": {
        "intro_html": "<p>Etes-vous certain de vouloir clôturer la carte ? Celle-ci ne sera plus modifiable par la suite.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_convert_to_organisation": {
        "intro_html": "<p>Etes-vous sûr ? La conversion en Organisation est irréversible.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_delete_birth_registration": {
        "intro_html": "<p>Cette déclaration de naissance sera supprimée. Vous perdrez toutes les informations encodées.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_delete_card": {
        "intro_html": "<p>Ce certificat de saillie sera supprimé. Vous perdrez toutes les informations encodées.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_delete_delivrable": {
        "intro_html": "<p>Cette prestation sera supprimée. Vous perdrez toutes les informations encodées.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_delete_export_list": {
        "intro_html": "<p>Cette liste d'export sera supprimée</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_delete_passports": {
        "intro_html": "<p>Êtes-vous sûr de vouloir supprimer tous ces passeports ?</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_delete_signage": {
        "intro_html": "<p>Ce signalement sera supprimé.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_delete_studbook_document": {
        "intro_html": "<p>Ce document sera définitivement supprimé.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_edit_passport": {
        "intro_html": "<p>Modifier le passeport annulera son export. Merci de vérifier que les informations précédemment exportées ne sont pas déjà utilisées ou en cours d'utilisation.</p>",
        "title": "Etes-vous certain ?"
      },
      "confirm_etalonnier_to_admin": {
        "intro_html": "<p>Un utilisateur étalonnier existe déjà avec cette adresse email.<br />L'utilisateur perdra son accès étalonnier lors de la création d'un utilisateur admin avec cette adresse email.</p>",
        "title": "Etes-vous certain ?"
      },
      "connexion_expired": {
        "intro_html": "<p>La connexion a expirée, veuillez recharger la page pour continuer.<br />Veuillez nous contacter si le problème persiste.</p>",
        "title": "Connexion expirée"
      },
      "create_detention_place": {
        "title": "Créer un lieu de détention"
      },
      "dna_controls_confirm": {
        "intro_html": "Attention : vous allez valider un ou plusieurs contrôles génétiques !<br>La date du contrôle ADN sera ajoutée et la compatibilité de filiation validée.<br>Il vous restera à encoder la référence de l’examen de laboratoire.",
        "title": "Etes-vous certain ?"
      },
      "edit_passport_created_on_horseid_at": {
        "title": "Modifier la date et le statut de transfert HorseID"
      },
      "general_conditions": {
        "title": "Conditions générales"
      },
      "horse_history": {
        "title": "Historique de l'équidé"
      },
      "identifications_cancel_status": {
        "intro_html": "<p>Cette action n'annulera pas les éventuels envois et mises à jour déjà effectuées dans HorseID.</p>",
        "title": "Etes-vous certain ?"
      },
      "identifications_received_date": {
        "title": "Veuillez indiquer la date de réception"
      },
      "passport": {
        "title": "Détails du passeport"
      },
      "passports_cancel_status": {
        "intro_html": "<p>Cette action n'annulera pas les éventuels envois et mises à jour déjà effectuées dans HorseID.</p>",
        "title": "Etes-vous certain ?"
      },
      "passports_sent_date": {
        "title": "Veuillez indiquer la date d'envoi"
      },
      "refuse_approval": {
        "title": "Veuillez indiquer la raison du refus"
      },
      "search_detention_place": {
        "title": "Rechercher un lieu de détention"
      },
      "select_detention_place": {
        "title": "Choisir un lieu de détention"
      },
      "upload_signage": {
        "title": "Ajouter un signalement graphique",
        "title_edit": "Modifier un signalement graphique"
      },
      "upload_studbook_document": {
        "title": "Ajouter des documents"
      },
      "validate_approval_infinitely": {
        "title": "Veuillez indiquer la date d'approbation définitive"
      },
      "validate_approval_temporary": {
        "title": "Veuillez indiquer la date d'approbation"
      },
      "welcome": {
        "etalonnier_html": "<p>Simplifiez-vous la vie en accédant à un maximum de services et d’informations en un minimum de temps, à tout moment.</p><p>Voir vos étalons et leur production, commander et gérer vos cartes de saillie, consulter votre historique de saillies et de naissances, modifier les données... Ces services en ligne - et bien d’autres - sont directement accessibles via votre espace personnel.</p>",
        "intro_html": "<p>Bonjour %{username},</p><p>Vous êtes maintenant connecté à votre espace personnel sur SireCloud.</p>",
        "title": "Bienvenue sur SireCloud"
      }
    },
    "navigation": {
      "coupling_year": "Année de monte",
      "new_birth_registration": "Déclarer une naissance",
      "reproduction": "Reproduction"
    },
    "notes": {
      "flash": {
        "create": {
          "fail": "La note n'a pas pu être enregistrée",
          "success": "Note enregistrée"
        },
        "destroy": {
          "fail": "La note n'a pas pu être supprimée",
          "success": "Note supprimée"
        },
        "list": {
          "fail": "Les notes n'ont pas pu être chargées",
          "no_more_notes": "Pas d'autres notes à afficher",
          "success": "Notes chargées"
        }
      },
      "values": {
        "privacy": {
          "0": "Publique",
          "1": "Partagée",
          "2": "Interne",
          "3": "Privée"
        }
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 3,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "milliard",
            "million": {
              "one": "million",
              "other": "millions"
            },
            "quadrillion": "million de milliards",
            "thousand": "millier",
            "trillion": "billion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "octet",
              "other": "octets"
            },
            "gb": "Go",
            "kb": "ko",
            "mb": "Mo",
            "tb": "To"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organisations": {
      "actions": {
        "confirm_destroy": "Êtes vous surs de vouloir supprimer définitivement cette organisation ",
        "destroy": "Supprimer cette organisation"
      },
      "flash": {
        "create": {
          "fail": "L'organisation ne peut pas être enregistrée.",
          "success": "Nouvelle organisation enregistrée."
        },
        "destroy": {
          "success": "L'organisation a été supprimée."
        },
        "update": {
          "fail": "Les informations n'ont pas pu être mise à jour.",
          "success": "Les informations de l'organisation ont été mises à jour."
        }
      },
      "horseid": {
        "attributes": {
          "organisation": {
            "address": {
              "box": "Boite",
              "country": "Pays",
              "locality": "Localité",
              "number": "Numéro",
              "postalCode": "Code postal",
              "street": "Rue"
            },
            "bceNumber": "Numéro BCE",
            "email": "Email",
            "fax": "Fax",
            "gsm": "GSM",
            "language": "Langue",
            "legalForm": "Forme juridique",
            "name": "Nom",
            "office1": "Tél. bureau 1",
            "office2": "Tél. bureau 2",
            "phone1": "Tél. 1",
            "phone2": "Tél. 2",
            "responsible": "Reponsable",
            "studbookId": "ID studbook",
            "subjectToVat": "Assujetti TVA"
          }
        }
      },
      "new": {
        "page_title": "Nouvelle Organisation",
        "title": "Création d'une nouvelle organisation"
      }
    },
    "pages": {
      "access_request": {
        "fields": {
          "firstname_label": "Prénom",
          "name_label": "Nom",
          "stallions_label": "Etalons",
          "stallions_placeholder": "Indiquez ici les étalons (nom et/ou UELN) que vous souhaitez gérer dans votre espace SireCloud",
          "studbook_ids_label": "Studbook"
        },
        "flash": {
          "error": "Formulaire invalide, merci de vérifier les champs en rouge et envoyer à nouveau"
        },
        "intro_html": "<p>Complétez le formulaire puis validez-le.</p><p>Vous recevrez peu après un e-mail à l'adresse mentionnée dans le formulaire. Il contient un lien qui permet d'activer votre compte.</p><p>Cliquez alors sur le lien mentionné dans l'e-mail. Ensuite, introduisez le mot de passe que vous souhaitez utiliser.</p>",
        "is_company": "L’utilisateur représente une société",
        "page_title": "SireCloud - Demande d'accès",
        "subtitle": "Demande d'accès personnel",
        "title": "Nouvel utilisateur SireCloud"
      },
      "browser_update": {
        "intro_html": "<p>Votre navigateur (Internet Explorer) n'est pas supporté par SireCloud qui requiert une version à jour d'un navigateur moderne.</p><p>Nous vous invitons à télécharger et installer l'un de ces navigateurs gratuits :</p>",
        "page_title": "Navigateur incompatible",
        "title": "Navigateur incompatible"
      },
      "errors": {
        "404": {
          "back_link": "Revenir en arrière",
          "current_database_warning_html": "Vous êtes actuellement connecté à la base de données %{current_database}. Cet id appartient peut-être à une autre base de données : ",
          "header": "Erreur 404",
          "home_link": "Accueil SireCloud",
          "model_text_html": "L'id %{id} est incorrect ou n'existe pas dans la base de données.",
          "model_title": "%{model} introuvable",
          "page_title": "SireCloud - Erreur 404",
          "text_html": "La page que vous cherchez n'existe pas ou l'URL est incorrecte.",
          "title": "Page introuvable"
        },
        "500": {
          "back_link": "Revenir en arrière",
          "header": "Erreur 500",
          "home_link": "Accueil SireCloud",
          "page_title": "SireCloud - Erreur 500",
          "text_html": "Une erreur s'est produite et la page demandée n'a pas pu être affichée.<br /><br /> Nous avons été prevenu de cette erreur et la corrigerons dans les meilleurs délais.<br /><br /> Si le problème persiste ou que vous avez besoin d'assistance, n'hésitez pas à nous contacter.",
          "title": "Erreur - Cette page ne fonctionne pas"
        }
      },
      "order_coupling": {
        "attributes": {
          "card_type": "Type de carte",
          "coupling_year": "Année de monte",
          "owner_data_addr1": "Rue",
          "owner_data_country_code": "Pays",
          "owner_data_email": "Email",
          "owner_data_fax": "Fax",
          "owner_data_firstname": "Prénom",
          "owner_data_lastname": "Nom",
          "owner_data_locality": "Ville",
          "owner_data_mobile": "GSM",
          "owner_data_national_number": "Numéro national",
          "owner_data_national_number_country": "Pays (numéro national)",
          "owner_data_number": "N°",
          "owner_data_phone": "Téléphone",
          "owner_data_vat": "Numéro de TVA",
          "owner_data_zip": "Code postal",
          "quantity": "Nombre de cartes",
          "stalion_id": "Etalon",
          "terms_accepted": "Conditions générales acceptées"
        },
        "before_form_html": " <p> Pour commander des cartes supplémentaires, complétez tous les champs du formulaire ci-dessous et validez-le. </p> ",
        "card": {
          "one": "carte",
          "other": "cartes"
        },
        "card_type": {
          "online": "Electronique",
          "paper": "Papier"
        },
        "fields": {
          "terms_label_html": "J’ai lu et j’approuvé les <a data-toggle=\"modal\" data-target=\"#cguModal\" href=\"#\">conditions générales</a> concernant la commande de cartes de saillie."
        },
        "flash": {
          "error": "Formulaire invalide, merci de vérifier les champs en rouge et envoyer à nouveau"
        },
        "intro_html": " <!--<p> Les cartes de saillie SBS (au format électronique à partir 2019) sont délivrées aux propriétaires des étalons s’ils remplissent les conditions suivantes : </p> <ul> <li>Être en ordre de cotisation pour l’année en cours.</li> <li>Détenir les droits d’utilisation de la semence de l’étalon.</li> <li>Avoir acquitté au Studbook SBS la redevance annuelle pour l’étalon concerné.</li> </ul> <p> Les 25 premières cartes sont comprises dans la redevance annuelle et sont délivrées gratuitement. </p>--> ",
        "section-title": {
          "order": "Commande",
          "owner": "Identité du propriétaire de l'étalon",
          "owner_details": "Coordonnées du propriétaire de l'étalon",
          "stallion": "Etalon ",
          "terms": "Conditions générales"
        },
        "subtitle": "Commander des cartes supplémentaires"
      }
    },
    "passports": {
      "actions": {
        "cancel_status": "Revenir au statut précédent",
        "confirm_destroy": "Êtes vous surs de vouloir supprimer définitivement ce passeport",
        "csv_export": "Export CSV",
        "destroy": "Supprimer ce passeport",
        "resend_to_horse_id": "(Ré) envoyer le passeport à HorseID",
        "update_export": "Mettre à jour l'export"
      },
      "data_missing_for_horse_id": "Informations manquantes pour l'envoi vers HorseID",
      "edit": {
        "page_title": "Modifier le passeport"
      },
      "hints": {
        "update_created_on_horseid_at": "Laissez la date vide pour passer le passeport en 'A transférer'"
      },
      "horse_id_errors": "Erreur(s) HorseID",
      "horse_id_warnings": "Avertissement(s) HorseID",
      "horseid": {
        "attributes": {
          "exportDate": "Date d'édition du passport",
          "horseId": "ID CBC de l'équidé",
          "id": "ID du passeport",
          "issuer": "UELN organisme émetteur",
          "recipient": "ID CBC du destinataire du passeport",
          "responsibleAgency": "UELN agence responsable",
          "sendDate": "Date d'envoi du passport",
          "transmitterAgency": "UELN agence émettrice",
          "type": "Type de passport"
        },
        "resend_already_exists_success": "Le passeport existe déjà dans HorseID avec l'id %{cbc_id}",
        "resend_error": "Le passeport n'a pas pu être créé : %{error}",
        "resend_success": "Passeport créé dans HorseID avec l'id %{cbc_id}",
        "resend_unavailable": "HorseID indisponible, veuillez réessayer plus tard"
      },
      "index": {
        "search": {
          "placeholder": "Recherche rapide : Nom, UELN ou Code"
        }
      },
      "labels": {
        "short": {
          "created_in_horse_id_at": "Transfert HorseID",
          "exported_at": "Edition",
          "sent_at": "Envoyé"
        }
      },
      "new": {
        "page_title": "Nouveau passeport"
      }
    },
    "passwords": {
      "create": {
        "description": "Vous allez prochainement recevoir un e-mail contenant les instructions afin de choisir votre mot de passe.",
        "description_cont": "Suivez ces instructions pour vous connecter à SireCloud.",
        "title": "Choix du mot de passe"
      },
      "edit": {
        "description": "Créer votre nouveau mot de passe pour accéder à votre espace personnel SireCloud.",
        "title": "Nouveau mot de passe"
      },
      "new": {
        "description": "Afin de recevoir par mail les informations nécessaires pour choisir votre mot de passe, merci de nous renseigner votre adresse e-mail.",
        "title": "Nouveau mot de passe"
      }
    },
    "people": {
      "actions": {
        "confirm_destroy": "Êtes-vous sûr de vouloir supprimer définitivement cette personne",
        "destroy": "Supprimer cette personne"
      },
      "civility": {
        "short_texts": {
          "1": "Mme.",
          "2": "Mlle.",
          "3": "M.",
          "4": "Dr.",
          "5": "Pr.",
          "6": "Me.",
          "7": "Mgr."
        },
        "texts": {
          "1": "Madame",
          "2": "Mademoiselle",
          "3": "Monsieur",
          "4": "Docteur",
          "5": "Professeur",
          "6": "Maître",
          "7": "Monseigneur"
        }
      },
      "edit": {
        "page_title": "Modifier une personne",
        "title": "Modifier la personne"
      },
      "fee": {
        "ko": "Non payée",
        "ok": "En ordre",
        "payed_on": "Payée le %{date}"
      },
      "flash": {
        "create": {
          "success": "Nouvelle personne enregistrée."
        },
        "destroy": {
          "success": "La personne a été supprimée."
        },
        "to_org": {
          "fail": "La conversion en Organisation a échoué",
          "success": "%{organisation_name} converti en Organisation"
        },
        "update": {
          "success": "Les informations de la personne ont été mises à jour."
        }
      },
      "horseid": {
        "attributes": {
          "person": {
            "address": {
              "box": "Boite",
              "country": "Pays",
              "locality": "Localité",
              "number": "Numéro",
              "postalCode": "Code postal",
              "street": "Rue"
            },
            "bceNumber": "Numéro BCE",
            "birthDate": "Date de naissance",
            "civility": "Civilité",
            "email": "Email",
            "fax": "Fax",
            "firstName": "Prénom",
            "gsm": "GSM",
            "language": "Langue",
            "lastName": "Nom",
            "nationalRegister": "Numéro national",
            "office1": "Tél. bureau 1",
            "office2": "Tél. bureau 2",
            "phone1": "Tél. 1",
            "phone2": "Tél. 2",
            "studbookId": "ID studbook",
            "subjectToVat": "Assujetti TVA"
          }
        }
      },
      "index": {
        "search": {
          "placeholder": "Recherche de personnes"
        }
      },
      "new": {
        "page_title": "Nouvelle Personne",
        "title": "Création d'une nouvelle personne"
      },
      "tabs": {
        "addresses": {
          "title": "Adresses"
        },
        "contacts": {
          "title": "Coordonnées"
        }
      },
      "unknown": "Inconnu"
    },
    "per_pagable": {
      "menu_title": "Paginer par",
      "title": "Résultats"
    },
    "popover": {
      "birth_registration": {
        "access_token_html": "Indiquez ici le code d'accès de la carte de saillie repris sur le document.",
        "identification_type_html": "<strong>Procédure online</strong> : L'identification se fait exclusivement en ligne. Les informations et les documents vous sont envoyés par emails. Le paiement se fait également en ligne.<br/><strong>Procédure papier</strong> : Les informations et les documents vous sont envoyés par la poste. Le paiement se fait par virement bancaire."
      },
      "cbc_horse": {
        "statuses": "<strong>En préparation</strong> : création en cours de préparation auprès du Studbook.<br /><strong>Clôturée</strong> : création transférée et clôturée dans HorseID."
      },
      "contact": {
        "type_html": "Tout regroupement de personnes est considéré comme une 'association'."
      },
      "identification": {
        "statuses": "<strong>En préparation</strong> : demande en cours de préparation auprès du Studbook.<br /><strong>En cours</strong> : demande transférée en cours de clôture sur HorseID.<br /><strong>Cloturée</strong> : demande cloturée dans HorseID.<br /><strong>Annulée</strong> : demande annulée (ex: équidé décédé, etc.)",
        "types": "<strong>Procédure online</strong> : L'identification se fait exclusivement en ligne. Les informations et les documents vous sont envoyés par emails. Le paiement se fait également en ligne.<br/><strong>Procédure papier</strong> : Les informations et les documents vous sont envoyés par la poste. Le paiement se fait par virement bancaire."
      }
    },
    "ppl_and_org": {
      "tabs": {
        "contact_details": {
          "title": "Coordonnées"
        },
        "generalites": {
          "title": "Généralités"
        },
        "horses": {
          "breeder_filter": "Naisseur",
          "coupler_filter": "Inséminateur",
          "holder_filter": "Détenteur",
          "horses_list": "Liste des équidés pour %{name} en qualité de",
          "owner_filter": "Propriétaire"
        }
      },
      "user_access": {
        "create": "Créer",
        "edit": "Modifier",
        "no_access": "Pas de compte utilisateur",
        "resend_password": "Ré-envoyer mot de passe",
        "title": "Accès sirecloud"
      }
    },
    "replace_by": "Remplacer par",
    "request_password_change": "Pour des raisons de sécurité, il est important de modifier régulièrement votre mot de passe. Veuillez mettre à jour votre mot de passe pour continuer.",
    "sessions": {
      "form": {
        "forgot_password": "Mot de passe oublié ?",
        "sign_in": "Se connecter",
        "sign_up": "S'incrire"
      },
      "new": {
        "description": "Connectez-vous à votre espace personnel SireCloud avec votre adresse email et votre mot de passe.",
        "new_user": {
          "cta": "Demandez vos codes d'accès",
          "intro": "Vous êtes un nouvel utilisateur ?"
        },
        "title": "Se connecter"
      }
    },
    "shrine": {
      "max_size_extensions": "Le fichier doit peser moins de %{max_size}. Extensions autorisées : %{extensions}."
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Merci e vérifier les erreurs suivantes :"
      },
      "no": "Non",
      "required": {
        "mark": "*",
        "text": "obligatoire"
      },
      "yes": "Oui"
    },
    "since_date": "depuis le %{date}",
    "sirecloud_logo_alt": "SireCloud - Solution Informatique répertoriant les équidés dans le cloud",
    "sortable": {
      "menu_title": "Trier les résulats",
      "sort": {
        "ascending": "Croissant",
        "descending": "Décroissant",
        "sort": "Trier"
      }
    },
    "stalion": "Etalon",
    "studbook_document_types": {
      "errors": {
        "in_use": "Ce type de document est utilisé dans des documents"
      }
    },
    "studbook_documents": {
      "errors": {
        "file_too_large": "%{filename}: fichier trop volumineux",
        "invalid_content_type": "%{filename}: type de fichier invalide",
        "no_files": "Aucun fichier n'a été sélectionné"
      },
      "flash": {
        "create": {
          "error": {
            "one": "Le document n'a pas pu être ajouté",
            "other": "Les documents n'ont pas pu être ajoutés"
          },
          "success": {
            "one": "Document ajouté",
            "other": "Documents ajoutés"
          }
        },
        "destroy": {
          "error": "Le document n'a pas pu être supprimé",
          "success": "Document supprimé"
        },
        "index": {
          "error": "Les documents n'ont pas pu être chargés",
          "success": "Documents chargés"
        }
      },
      "note": "Maximum %{max_size} par fichier. Seuls les PDF, images et vidéos sont acceptés.",
      "s3_unavailable": "Le service S3 est indisponible pour le moment, les documents ne peuvent être affichés.",
      "tab": {
        "card_title": "Documents",
        "created_by": "Créé par",
        "date": "Date",
        "download": "Télécharger",
        "name": "Nom",
        "no_documents": "Aucun document",
        "size": "Taille",
        "title": "Documents %{studbook}"
      }
    },
    "studbook_mailer": {
      "horse_has_no_identification_to_finalize": {
        "subject": "L'équidé %{horse_name} (%{horse_alias}) n'a pas d'identification à finaliser"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " et ",
        "two_words_connector": " et ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%d %B %Y %Hh %Mmin %Ss",
        "long": "%A %d %B %Y %Hh%M",
        "real_short_date": "%d/%m/%y",
        "s3": "%d/%m/%Y %Hh%M",
        "short": "%d %b %Hh%M",
        "short_date": "%d/%m/%Y"
      },
      "pm": "pm"
    },
    "to_date": "jusqu'au %{date}",
    "unfavorable": "Défavorable",
    "uppy": {
      "fields": {
        "caption": {
          "name": "Description",
          "placeholder": "Décrivez l'image"
        },
        "name": {
          "name": "Nom",
          "placeholder": "nom du fichier"
        }
      },
      "note": "Images seulement, %{max_files} fichier(s) maximum, jusqu'à %{max_size}"
    },
    "user": {
      "already_exists": "L'utilisateur existe déjà",
      "edit": {
        "page_title": "Modifier l'utilisateur",
        "title": "Modifier l'accès utilisateur SireCloud"
      },
      "flash": {
        "connection_expired": "Connexion expirée, veuillez réessayer.",
        "create": {
          "success": "Accès utilisateur créé."
        },
        "reset_password": {
          "success": "Email envoyé pour le changement de mot de passe."
        },
        "update": {
          "fail": "Les modifications n'ont pas pu être sauvegardée",
          "success": "Utilisateur mis à jour."
        }
      },
      "hints": {
        "password": "Il doit contenir au moins 8 caractères; dont une majuscule, un chiffre, et un caractère spécial parmi: @$!%*#?&"
      },
      "new": {
        "title": "Créer un accès utilisateur sur SireCloud"
      }
    },
    "user_mailer": {
      "welcome": {
        "cta": "Accéder à SireCloud",
        "cta_help": "Si le lien ne fonctionne pas, vous pouvez également copier/coller l'adresse suivante dans votre navigateur :",
        "intro_html": "<p>Bonjour %{username},</p><p>Votre accès personnel sur SireCloud vient d'être créé.</p><p>Cliquez sur le lien ci-dessous pour activer vore accès et choisir votre mot de passe.</p>",
        "subject": "Votre accès personnel SireCloud",
        "title": "Bienvenue sur SireCloud"
      }
    },
    "user_not_authorized": "Vous n'êtes pas autorisé à accéder à cette fonctionnalité.",
    "users": {
      "new": {
        "sign_in": "Se connecter",
        "title": "S'incrire"
      }
    },
    "views": {
      "form_warning": "Les données du formulaire ne sont pas encore enregistrées.",
      "pagination": {
        "first": "Première page",
        "last": "Dernière page",
        "next": "Page suivante",
        "previous": "Page précédente",
        "truncate": "&hellip;"
      }
    }
  },
  "it": {
    "activerecord": {
      "attributes": {
        "birth_registration": {
          "birth_country": "Paese di nascita",
          "birth_date": "Data di Nascita",
          "breed_category_id": "Tipo di equide",
          "breeder_data_addr1": "indirizzo",
          "breeder_data_addr2": "indirizzo 2",
          "breeder_data_box": "Casella Postale",
          "breeder_data_civility": "Stato Civile",
          "breeder_data_company": "Intestazione",
          "breeder_data_company_country": "Paese",
          "breeder_data_company_number": "Numero di Partita IVA",
          "breeder_data_company_vat_subject": "assoggettato ad IVA",
          "breeder_data_country_code": "Paese",
          "breeder_data_email": "Email",
          "breeder_data_fax": "Fax",
          "breeder_data_firstname": "Nome",
          "breeder_data_lastname": "Cognome",
          "breeder_data_legal_form_id": "Forma Giuridica",
          "breeder_data_locality": "Citta",
          "breeder_data_mobile": "Cellulare",
          "breeder_data_national_number": "Codice Fiscale",
          "breeder_data_national_number_country": "Paese",
          "breeder_data_number": "Numero",
          "breeder_data_phone": "Telefono",
          "breeder_data_vat": "Numero Partita IVA",
          "breeder_data_zip": "Codice Postale",
          "co_breeder_data_addr1": "indirizzo",
          "co_breeder_data_addr2": "Indirizzo 2",
          "co_breeder_data_box": "Casalla Postale",
          "co_breeder_data_civility": "Stato Civile",
          "co_breeder_data_company": "Titolo",
          "co_breeder_data_company_country": "Paese",
          "co_breeder_data_company_number": "Numero di Partita IVA o numero di impresa",
          "co_breeder_data_company_vat_subject": "Assoggettato ad IVA",
          "co_breeder_data_country_code": "Paese",
          "co_breeder_data_email": "Email",
          "co_breeder_data_fax": "Fax",
          "co_breeder_data_firstname": "Nome",
          "co_breeder_data_lastname": "Cognome",
          "co_breeder_data_legal_form_id": "Forma giuridica",
          "co_breeder_data_locality": "Città",
          "co_breeder_data_mobile": "Cellulare",
          "co_breeder_data_national_number": "Codice Fiscale",
          "co_breeder_data_national_number_country": "Paese",
          "co_breeder_data_number": "Numero",
          "co_breeder_data_phone": "Telefono",
          "co_breeder_data_vat": "Numero di Partita IVA o Numero d' Impresa",
          "co_breeder_data_zip": "Codice Postale",
          "co_owner_data_addr1": "Indirizzo",
          "co_owner_data_addr2": "Indirizzo 2",
          "co_owner_data_box": "Casella Postale",
          "co_owner_data_civility": "Stato Civile",
          "co_owner_data_company": "Titolo",
          "co_owner_data_company_country": "Paese",
          "co_owner_data_company_number": "Numero di Partita IVA o numero d' impresa",
          "co_owner_data_company_vat_subject": "Assoggettato ad IVA",
          "co_owner_data_country_code": "Paese",
          "co_owner_data_email": "Email",
          "co_owner_data_fax": "Fax",
          "co_owner_data_firstname": "Nome",
          "co_owner_data_lastname": "Cognome",
          "co_owner_data_legal_form_id": "Forma Giuridica",
          "co_owner_data_locality": "Citta",
          "co_owner_data_mobile": "Cellulare",
          "co_owner_data_national_number": "Codice Fiscale",
          "co_owner_data_national_number_country": "Paese",
          "co_owner_data_number": "Numero",
          "co_owner_data_phone": "Telefono",
          "co_owner_data_vat": "Numero di Partita IVA o Numero d' impresa",
          "co_owner_data_zip": "Codice Postale",
          "coupling_card_access_token": "URL di accesso alla carta",
          "coupling_card_copy": "Copia della carta di monta",
          "coupling_card_number": "Numero della carta",
          "coupling_card_studbook": "Nome dello Studbook",
          "created_at": "Data di registrazione",
          "embryo_transfer": "Trasferimento d' embrione",
          "global_breeder": "Allevatore",
          "global_co_breeder": "Co - Allevatore",
          "global_co_owner": "Co-proprietario",
          "global_holder": "Detentore",
          "global_owner": "Proprietario",
          "holder_addr1": "Indirizzo di stazionamento",
          "holder_data_addr1": "Indirizzo",
          "holder_data_box": "Casella Postale",
          "holder_data_cbc_id": "ID CBC",
          "holder_data_civility": "Stato Civile",
          "holder_data_country_code": "Paese",
          "holder_data_email": "Email",
          "holder_data_firstname": "Nome",
          "holder_data_lastname": "Cognome",
          "holder_data_locality": "Città",
          "holder_data_number": "Numero",
          "holder_data_zip": "Codice Postale",
          "holder_firstname": "Nome del detentore",
          "holder_lastname": "Cognome del detentore",
          "holding_place": "Luogo di esercizio",
          "holding_place_data_addr1": "Indirizzo",
          "holding_place_data_box": "Casella Postale",
          "holding_place_data_country_code": "Paese",
          "holding_place_data_locality": "Citta",
          "holding_place_data_number": "Numero",
          "holding_place_data_zip": "Codice Postale",
          "horse_database": "Studbook",
          "horse_database_id": "Studbook",
          "horse_private_alias": "Codice",
          "identificatio_online": "Procedura",
          "identification_dna": "Crini prodotto",
          "identification_mother_dna": "Crini madre",
          "identification_type": "Domanda d' identificazione",
          "mare_birth_date": "Data di nascita",
          "mare_code": "Codice della giumenta",
          "mare_father_name": "Padre della giumenta",
          "mare_id": "Giumenta",
          "mare_microchip": "Microchip della giumenta",
          "mare_mother_name": "Madre della giumenta",
          "mare_name": "Nome della giumenta",
          "name": "Nome del prodotto",
          "no_coupling_card_data_agree": "Condizioni generali",
          "owner_data_addr1": "Indirizzo",
          "owner_data_addr2": "Indirizzo 2",
          "owner_data_box": "Casella Postale",
          "owner_data_civility": "Stato Civile",
          "owner_data_company": "Titolo",
          "owner_data_company_country": "Paese",
          "owner_data_company_number": "Numero di Partita IVA o Numero d' impresa",
          "owner_data_company_vat_subject": "Assoggettato ad IVA",
          "owner_data_country_code": "Paese",
          "owner_data_email": "Email",
          "owner_data_fax": "Fax",
          "owner_data_firstname": "Nome",
          "owner_data_lastname": "Cognome",
          "owner_data_legal_form_id": "Forma giuridica",
          "owner_data_locality": "Città",
          "owner_data_mobile": "Cellulare",
          "owner_data_national_number": "Codice Fiscale",
          "owner_data_national_number_country": "Paese",
          "owner_data_number": "Numero",
          "owner_data_phone": "Telefono",
          "owner_data_vat": "Numero di Partita IVA o Numero d' impresa",
          "owner_data_zip": "Codice postale",
          "received_at": "Ricevuto il",
          "slaughter_foal": "Puledro da macello",
          "stalion_birth_date": "Data di nascita",
          "stalion_code": "Codice dello stallone",
          "stalion_father_name": "Padre dello stallone",
          "stalion_id": "Stallone",
          "stalion_microchip": "Microchip dello stallone",
          "stalion_mother_name": "Madre dello stallone",
          "stalion_name": "Nome dello stallone",
          "station_be": "Paese di residenza",
          "stationed_in_belgium": "Il prodotto risiede in Belgio",
          "validated_at": "Convalidato il",
          "vet_id": "veterinario scelto per l' identificazione"
        },
        "cbc_horse": {
          "breeder": "Allevatore",
          "breeder_cbc_id": "ID dell' allevatore",
          "breeder_id": "Allevatore",
          "co_breeder": "Co-allevatore",
          "co_breeder_cbc_id": "ID del co-allevatore",
          "co_breeder_id": "Co-allevatore",
          "global_breeder": "Allevatore",
          "global_co_breeder": "Co-allevatore",
          "global_holder": "Detentore",
          "holder": "Detentore",
          "holder_cbc_id": "ID del detentore",
          "holder_id": "Detentore",
          "holder_selected_email": "Email del detentore",
          "horseID_params_exploitationBox": "Casella Postale",
          "horseID_params_exploitationCountry": "Paese",
          "horseID_params_exploitationLocality": "Località",
          "horseID_params_exploitationNumber": "Numero",
          "horseID_params_exploitationPostCode": "Codice postale",
          "horseID_params_exploitationStreet": "Via",
          "horseID_params_foreignAlias": "Alias d' origine",
          "horseID_params_horseDateHolding": "Data d' inizio del possesso",
          "horseID_params_horse_birthCountry": "Paese",
          "horseID_params_horse_birthDate": "Data di nascita",
          "horseID_params_horse_breeders": "iD CBC degli allevatori",
          "horseID_params_horse_butcherFoal": "Puledro da macello",
          "horseID_params_horse_castrated": "Castrato",
          "horseID_params_horse_commercialName": "Nome commerciale",
          "horseID_params_horse_detentionPlace": "Numero di stabilimento",
          "horseID_params_horse_detentionSince": "Data di arrivo del prodotto presso lo stabilimento",
          "horseID_params_horse_dressColor": "Colore del mantello",
          "horseID_params_horse_excludingFoodChain": "Escluso dalla catena alimentare",
          "horseID_params_horse_excludingFoodChainDate": "Data d' esclusione dalla catena alimentare",
          "horseID_params_horse_father_ueln": "UELN padre",
          "horseID_params_horse_gender": "sesso",
          "horseID_params_horse_holder": "Id CBC del detentore",
          "horseID_params_horse_holderTmp": "Id CBC (temp.) del detentore",
          "horseID_params_horse_mother_ueln": "UELN madre",
          "horseID_params_horse_name": "Nome",
          "horseID_params_horse_origin": "Origine",
          "horseID_params_horse_privateRemark": "Segni",
          "horseID_params_horse_race": "Razza",
          "horseID_params_horse_responsibleAgency": "UELN agenzia responsabile",
          "horseID_params_horse_studbookId": "Codice equide",
          "horseID_params_horse_transponder": "N° transponder",
          "horseID_params_horse_type": "Tipo",
          "horseID_params_horse_ueln": "UELN",
          "horseID_params_identification_textualReporting": "Segnalamento testuale",
          "horseID_params_transmitterAgency": "UELN agenzia emittente",
          "private_notes": "Segni privati",
          "transfered_at": "Transfert",
          "validated_at": "Convalida"
        },
        "contact/fee": {
          "amount": "Montante",
          "payed_at": "Data di pagamento",
          "year": "Anno"
        },
        "contact_detail": {
          "detail_type": "Tipo"
        },
        "coupling_card": {
          "carrying_mare": "Madre ricevente del nascituro",
          "carrying_mare_code": "Codice della giumenta ricevente",
          "carrying_mare_microchip": "Microchip della giumenta ricevente",
          "carrying_mare_name": "Nome della giumenta ricevente",
          "completed_at": "Data di chiusura",
          "coupler": "inseminatore",
          "coupling_dates": {
            "one": "Data di copertura",
            "other": "Data di copertura"
          },
          "coupling_mode": "Tipo di monta",
          "embryo_transfer": "Trasferimento d'embrione",
          "global_coupler": "Inseminatore",
          "issue_date": "Data d' apertura",
          "mare": "Madre biologica",
          "mare_birth_date": "Data di nascita",
          "mare_code": "Codice della madre biologica",
          "mare_father_name": "Padre della madre biologica",
          "mare_holder": "Proprietario della madre biologica",
          "mare_holder_address_box": "Interno",
          "mare_holder_address_country_code": "Paese",
          "mare_holder_address_line1": "via",
          "mare_holder_address_locality": "Località",
          "mare_holder_address_locality_name": "Località",
          "mare_holder_address_number": "N°",
          "mare_holder_address_vat": "Numero di Partita IVA (se assoggettato)",
          "mare_holder_address_zip": "Codice Postale",
          "mare_holder_civility": "Stato Civile",
          "mare_holder_civility_id": "Stato Civile",
          "mare_holder_company": "Titolo",
          "mare_holder_company_country": "Paese",
          "mare_holder_company_number": "Numero di Partita IVA o Numero d' impresa",
          "mare_holder_company_vat_subject": "Assoggettato ad IVA",
          "mare_holder_contacts": "Coordinate del proprietario",
          "mare_holder_email": "email",
          "mare_holder_fax": "Fax",
          "mare_holder_firstname": "Nome",
          "mare_holder_lastname": "Cognome",
          "mare_holder_legal_form_id": "Forma giuridica",
          "mare_holder_mobile": "Cellulare",
          "mare_holder_national_number": "Codice Fiscale",
          "mare_holder_national_number_country": "Paese",
          "mare_holder_phone": "Telefono",
          "mare_id": "Giumenta",
          "mare_microchip": "Microchip della madre biologica",
          "mare_mother_name": "Madre della madre biologica",
          "mare_name": "Nome della madre biologica",
          "number": "Numero",
          "stallion": "Padre del nascituro",
          "stallion_id": "Stallone",
          "year": "Stagione di monta"
        },
        "coupling_card/coupling": {
          "date": {
            "one": "Data",
            "other": "Data"
          }
        },
        "coupling_card/credit": {
          "amount": "Numero delle carte concesse",
          "ordered_at": "Cocesse il",
          "payed_at": "Pagate il",
          "quantity": "Quantità",
          "requester": "Concesse tramite",
          "year": "Stagione di Monta"
        },
        "delivrable": {
          "payed": "Pagato",
          "payed_at": "Pagato il",
          "reference": "Riferimento",
          "subject": "Oggetto",
          "subject_id": "Oggetto"
        },
        "horse": {
          "active_passport": "Passaporto",
          "approbation_country": "Paese di approvazione",
          "approved": "Autorizzato SireCloud",
          "birth_country": "Paese di nascita",
          "birth_date": "Data di nascita",
          "blood_type": "Gruppo sanguigno",
          "blood_type_date": "Data",
          "breed": "Studbook / Razza",
          "breed_category": "Tipo di equide",
          "breed_category_id": "Tipo di equide",
          "breed_id": "Studbook / Razza",
          "breeder": {
            "one": "Allevatore",
            "other": "Allevatore"
          },
          "breeder_id": "Allevatore",
          "breeding_card": "Carta si monta / dichiarazione",
          "breeding_card_notes": "Note",
          "breeding_card_num": "Numero di famiglia",
          "castrated": "Castrato",
          "cbc_id": "ID CBC",
          "cbc_id_final": "Definitivo",
          "cbc_id_timestamp": "Data",
          "coupler": {
            "one": "Inseminatore autorizzato",
            "other": "Inseminatore autorizzato"
          },
          "current_owner": {
            "one": "Proprietario attuale",
            "other": "Proprietario attuale"
          },
          "database_book": "Sottosezione",
          "database_book_section": "Sezione",
          "death_date": "Data del decesso",
          "deceased": "Deceduto",
          "detention_place_code": "Numero di stabilimento",
          "detention_since": "Data di arrivo del prodotto presso lo stabilimento",
          "dna": "DNA",
          "dna_date": "Data del test",
          "dna_filiation_compatible": "Compatibile",
          "dna_horse_hair": "Crini disponibili",
          "dna_horse_hair_receival_date": "Data di ricevimento dei crini",
          "dna_result": "Riferimento",
          "dna_sent_date": "Data d' invio",
          "dress": "Mantello",
          "dress_id": "Mantello",
          "father": "Padre",
          "father_id": "Padre",
          "first_holder": "Primo detentore",
          "food_chain_excluded": "Escluso dalla catena alimentare",
          "food_chain_exclusion_date": "Data di esclusione",
          "graphic_signage": "Segnalamento grafico",
          "horse_databases_book_id": "Sottosezione",
          "horse_databases_book_section_id": "Sezione",
          "horse_id": "HorseID",
          "microchip": "Microchip",
          "mother": "Madre",
          "mother_id": "Madre",
          "name": "Nome",
          "notes": "Memo",
          "origin_alias": "Codice originale",
          "owner": {
            "one": "Proprietario",
            "other": "Proprietario"
          },
          "past_owner": {
            "one": "Precedente proprietario",
            "other": "Precedente proprietario"
          },
          "private_alias": "Codice",
          "received_at": "Data di registrazione",
          "registration_billed": "Fatturato",
          "registration_invoice_reference": "Rif",
          "registration_paid": "Pagato",
          "registration_paid_at": "Data",
          "sexe": "Sesso",
          "slaughter_foal": "Puledro da macello",
          "structured_signage": "Segnalamento codificato",
          "text_signage": "Segnalamento descrittivo",
          "ueln": "UELN",
          "ueln_country": "Paese",
          "ueln_database": "Org.",
          "ueln_national_id": "Identificante",
          "ueln_type": "Creazione dell' UELN"
        },
        "horse/companion/sbs": {
          "frbse_id": "Identificante FRBSE"
        },
        "horse/labelling": {
          "end_at": "data della fine",
          "label": {
            "one": "Etichetta",
            "other": "Etichette"
          },
          "start_at": "Data di inizio"
        },
        "horse/microchip": {
          "date": "Data di attivazione",
          "number": {
            "one": "Numero di Microchip",
            "other": "Numero di Microchip"
          },
          "unreadability_date": "Data di illeggibilità",
          "unreadable": "inattivo"
        },
        "horse/ownership": {
          "from_date": "Data di inizio",
          "is_main": "Proprietario principale",
          "to_date": "Data della fine"
        },
        "horse/passport": {
          "cbc_id": "ID CBC",
          "exported_at": "Data di pubblicazione",
          "global_recipient": "Destinatario",
          "number": "Numero",
          "passport_type": "Tipo",
          "recipient": "Destinatario",
          "sent_at": "Data di invio"
        },
        "identification": {
          "breeder": "Allevatore",
          "breeder_cbc_id": "ID dell' allevatore",
          "breeder_id": "Allevatore",
          "co_breeder": "Co - allevatore",
          "co_breeder_cbc_id": "ID del co - allevatore",
          "co_breeder_id": "Co - allevatore",
          "finalize_sent_at": "Chiusura",
          "global_breeder": "Allevatore",
          "global_co_breeder": "Co - allevatore",
          "global_holder": "Detentore",
          "holder": "Detentore",
          "holder_cbc_id": "ID del detentore",
          "holder_id": "Detentore",
          "holder_selected_email": "Email del detentore",
          "horseID_params_exploitationBox": "Casella postale",
          "horseID_params_exploitationCountry": "Paese",
          "horseID_params_exploitationLocality": "Località",
          "horseID_params_exploitationNumber": "Numero",
          "horseID_params_exploitationPostCode": "Codice postale",
          "horseID_params_exploitationStreet": "Via",
          "horseID_params_horse_birthCountry": "Paese",
          "horseID_params_horse_birthDate": "Data di nascita",
          "horseID_params_horse_breeders": "iD CBC degli allevatori",
          "horseID_params_horse_butcherFoal": "Puledro da macello",
          "horseID_params_horse_castrated": "Castrato",
          "horseID_params_horse_commercialName": "Nome commerciale",
          "horseID_params_horse_detentionPlace": "Vestigingsnummer",
          "horseID_params_horse_detentionSince": "Datum van aankomst van het product in deze inrichting",
          "horseID_params_horse_dressColor": "Colore del mantello",
          "horseID_params_horse_excludingFoodChain": "Escluso dalla catena alimentare",
          "horseID_params_horse_excludingFoodChainDate": "Data di esclusione dalla catena alimentare",
          "horseID_params_horse_father_ueln": "UELN padre",
          "horseID_params_horse_gender": "Sesso",
          "horseID_params_horse_holder": "Id CBC del detentore",
          "horseID_params_horse_mother_ueln": "UELN madre",
          "horseID_params_horse_name": "Nome",
          "horseID_params_horse_origin": "Origine",
          "horseID_params_horse_privateRemark": "Note",
          "horseID_params_horse_race": "Razza",
          "horseID_params_horse_responsibleAgency": "UELN agenzia responsabile",
          "horseID_params_horse_studbookId": "Codice equide",
          "horseID_params_horse_transponder": "N° Microchip",
          "horseID_params_horse_type": "Tipo",
          "horseID_params_horse_ueln": "UELN",
          "horseID_params_identificationType": "tipo di domanda",
          "horseID_params_identification_date": "Identificazione",
          "horseID_params_identification_dna": "Crini prodotto",
          "horseID_params_identification_identifier": "N° d'ordine veterinario",
          "horseID_params_identification_identifierRemark": "Nota dell' identificatore",
          "horseID_params_identification_motherDna": "Crini madre",
          "horseID_params_identification_returnDate": "Data di ritorno della pratica",
          "horseID_params_identification_textualReporting": "Segnalamento descrittivo",
          "horseID_params_online": "Domanda online",
          "horseID_params_transmitterAgency": "UELN agenzia emittente",
          "identification_date": "Identificazione",
          "identification_dna": "Crini prodotto",
          "identification_mother_dna": "Crini madre",
          "identification_return_date": "Ricevimento",
          "identifier_notes": "Note identificatore",
          "private_notes": "Note private",
          "request_sent_at": "Invio Horse ID",
          "transfered_at": "Trasferimento",
          "validated_at": "Convalida",
          "veterinarian": "Identificatore",
          "veterinarian_id": "Identificatore"
        },
        "note": {
          "annotated": "Oggetto",
          "annotated_gid": "Oggetto",
          "author": "Autore",
          "body": "Testo",
          "note_type": "Tipo",
          "privacy": "Riservatezza",
          "written_at": "Scritto il"
        },
        "organisation": {
          "bank_account": "IBAN (numero di conto)",
          "bank_bic": "Codice BIC",
          "bank_name": "Banca",
          "cbc_id": "ID CBC",
          "horse_affix": "Affisso",
          "horse_affix_protected": "Affisso Protetto",
          "horse_affix_protected_since": "A partire da",
          "identity_complement": "Complemento",
          "legal_form_id": "Forma",
          "locale": "Lingua",
          "name": "Nome",
          "received_at": "Data di registrazione",
          "vat": "Numero BCE",
          "vat_subject": "Assoggettato ad IVA"
        },
        "person": {
          "adresses": "Indirizzo",
          "bank_account": "IBAN (numero di conto)",
          "bank_bic": "Codice BIC",
          "bank_name": "Banca",
          "cbc_id": "ID CBC",
          "civility": "Stato Civile",
          "civility_id": "Stato civile",
          "company": "Società",
          "company_legal_form": "Forma giuridica",
          "company_vat_subject": "Assoggettato",
          "contacts": "Contatti",
          "fee_payed": "Quota associativa",
          "fees": "Quota associativa",
          "firstname": "Nome",
          "fonctions": "Funzione",
          "horse_affix": "Affisso",
          "horse_affix_protected": "Affisso Protetto",
          "horse_affix_protected_since": "A partire da",
          "identity_complement": "Complemento",
          "lastname": "Nome",
          "locale": "Lingua",
          "member_number": "Numero di socio",
          "national_number": "N° Nazionale",
          "national_number_country": "Paese",
          "notes": "Memo",
          "received_at": "Data di registrazione",
          "send_magazine": "Periodico",
          "statuts": "Statuto",
          "vat": "Partita IVA"
        },
        "person/enrollment": {
          "end_at": "Data Fine",
          "start_at": "Data inizio"
        },
        "person/organisation_position": {
          "main": "Contatto principale",
          "position": "Funzione / Ruolo"
        },
        "user": {
          "email": "email",
          "firstname": "Nome",
          "name": "Cognome",
          "password": "password",
          "password_confirmation": "Confermate la password",
          "username": "Nome dell' utilizzatore"
        }
      },
      "errors": {
        "messages": {
          "invalid_date": "non è una data valida",
          "record_invalid": "Validazione fallita: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Il record non può essere cancellato perchè esistono %{record} dipendenti",
            "has_one": "Il record non può essere cancellato perchè esiste un %{record} dipendente"
          }
        },
        "models": {
          "horse/passport": {
            "attributes": {
              "number": {
                "number_changeable_only_if_new": "Il numero di questo passaporto non può più essere modificato"
              },
              "origin": {
                "must_be_an_original": "Il passaporto d' origine deve essere un originale"
              },
              "passport_type": {
                "only_one_original": "Questo equide ha già un passaporto originale"
              },
              "status": {
                "format": "%{message}",
                "only_one_new": "C' è già un nuovo passaporto in corso per questo equide"
              }
            }
          }
        }
      },
      "models": {
        "address": "Indirizzo",
        "birth_registration": {
          "one": "Dichiarazione di nascita",
          "other": "Dichiarazione di nascita"
        },
        "cbc_horse": {
          "one": "Richiesta di realizzazione",
          "other": "Richiesta di realizzazione"
        },
        "contact_detail": {
          "one": "Coordinate per contatto",
          "other": "Coordinate per contatto"
        },
        "coupling_card": {
          "one": "Carta di monta",
          "other": "Carta di monta"
        },
        "coupling_card/coupling": {
          "one": "Monta",
          "other": "Monte"
        },
        "coupling_card/credit": {
          "one": "Ordine carte di monta",
          "other": "Ordine carte di monta"
        },
        "horse": {
          "one": "Equide",
          "other": "Equidi"
        },
        "horse/passport": {
          "one": "Passaporto",
          "other": "Passaporti"
        },
        "horse_companion_sbs": "SBS",
        "identification": {
          "one": "Identificazione",
          "other": "Identificazioni"
        },
        "note": {
          "one": "Nota",
          "other": "Note"
        },
        "organisation": {
          "one": "Organizzazione",
          "other": "Organizzazioni"
        },
        "person": {
          "one": "Persona",
          "other": "Persone"
        },
        "person/position": {
          "one": "Funzione",
          "other": "Funzione"
        }
      }
    },
    "birth_mailer": {
      "new": {
        "intro": "Una nuova nascita è stata dichiarata su SireCloud",
        "subject": "Dichiarazione di nascita"
      }
    },
    "birth_registrations": {
      "add_co_breeder": "Aggiungere un co-allevatore",
      "add_co_owner": "Aggiungere un co-proprietario",
      "breeder": "Allevatore",
      "breeder_intro_html": "<p>L' <strong>Allevatore</strong> deve essere <u>una sola persona fisica od organizzazione</u>.<br/>Per codificare più di una persona od organizzazione, <strong><a class=\"quiet\" href=\"#co-breeder-switch\">aggiungere un Co-allevatore !</a></strong></p>",
      "card_questions": {
        "no_coupling_card": "Non possiedo una carta di monta",
        "other_studbook": "Possiedo una carta di monta emessa da un altro Studbook",
        "studbook": "Possiedo una carta di monta rilasciata dallo Studbook %{studbook}",
        "year": {
          "current": "Anno di monta %{year}",
          "older": "Anno di monta %{year} o precedente"
        }
      },
      "cbc_id": "ID CBC",
      "check_before_saving_html": "Verificate la dichiarazione prima di registrarla",
      "choice_title": "In quale Studbook volete iscrivere il vostro prodotto",
      "chosen_identification_type": "Procedura scelta",
      "co_breeder": "Co-allevatore",
      "co_owner": "Co-proprietario del prodotto",
      "father": "Padre",
      "first_holder": "Primo detentore",
      "flash": {
        "destroy": {
          "fail": "La dichiarazione non ha potuto essere eliminata",
          "success": "Dichiarazione di nascita eliminata"
        }
      },
      "form": {
        "help": {
          "microchip": "Se il prodotto è già portatore di un microchip di cui conoscete il numero, indicatelo qui. In caso contrario lasciate il campo vuoto",
          "slaughter_foal": "Prodotto destinato al macello durante il suo anno di nascita"
        },
        "hint": {
          "name_sbs": "Avvertimento ! : Lettera iniziale di nome: R nel 2023 (Q nel 2022) – Massimo 35 caratteri e spazi"
        },
        "label_ext": {
          "microchip": "(se già portatore)"
        }
      },
      "general_conditions_agree_html": "Ho letto ed accetto le condizioni generali",
      "general_conditions_html": "<p><strong>Condizioni generali</strong></p><p>Il seme appartiene al proprietario dello stallone salvo convenzioni particolari. Si presume che il dichiarante tutti i diritti necessari sul seme per la monta</p><p>Il dichiarante si impegna irrevocabilmente a garantire lo Stusbook %{studbook} da ogni azione in relazione a quanto precede, che potrebbe essere intentata contro lo Studbook %{studbook},in seguito alla iscrizione del prodotto al libro genealogico.</p><p>Tale garanzia si riferisce a qualsiasi pretesa generalmente possa essere  formulata , in capitali, interessi e costi,comprese le spese legali .</p><p>Il dichiarante interverrà su domanda dello Studbook %{studbook}, in ogni procedimento, amichevole o giudiziario ,  e se ne farà carico un sua vece.</p>",
      "holding_place": "Luogo di identificazione",
      "identification": "Identificazione",
      "identification_intro_html": "<p class=\"mb-0\"> Un equide residente in Belgio deve essere oggetto di una identificazione centrale per cui abbiamo ancora bisogno di 4 informazioni : </p> <ul> <li>La scelta della procedura.</li> <li>Le coordinate del detentore (obbligatoriamente una sola persona fisica, responsabile dell' animale).</li> <li>Il luogo di identificazione del prodotto (per facilitare il rilevamento segnaletico).</li> <li>Il veterinario scelto per l' identificazione ed il rilevamento segnaletico.</li> </ul>",
      "identification_is_not_in_belgium_html": "<p class=\"mb-0\">L'equide non è residente in Belgio.</p>",
      "identification_not_in_belgium_html": "<p class=\"mb-0\">L' equide non sarà quindi oggetto di una identificazione centrale in Belgio.</p>",
      "identification_stationed_in_belgium_html": "Il prodotto è residente in Belgio",
      "identification_stationed_to_first_holder_address": "Il prodotto è residente all' indirizzo del detentore ?",
      "identification_type_html": "Che procedura di identificazione desiderate utilizzare ?",
      "identification_type_tooltip_html": "<strong>Procedura online</strong> : L' identificazione si effettua esclusivamente in linea. Le informazioni ed i documenti vi vengono inviati per email. I pagamenti si fanno egualmente in linea.<br/><strong>Procedura cartacea</strong> :Le informazioni ed i documenti vi sono inviati per posta. Il pagamento viene effettuato tramite bonifico bancario.",
      "identifier": "Idebtificatore",
      "index": {
        "search": {
          "placeholder": "Ricerca rapida : Nome del prodotto, del padre o della madre"
        }
      },
      "intro_html": "Iscrivere il vostro prodotto nel libro genealogico SBS e simultaneamente nella banca dati equidi nazionale con qualche click: è facile,rapido e meno caro.<br/>Noi vi guidiamo passo per passo...",
      "manual_encoding_html": "Codificare manualmente<br/>le informazioni",
      "mare": "Giumenta",
      "mother": "Madre biologica",
      "online_identification_type": "Online",
      "or": "dove",
      "owner": "Proprietario del prodotto",
      "owner_intro_html": "<p>Le <strong>Proprietario</strong> deve essere <u>una sola persona fisica od organizzazione</u>.<br/>Per codificare più di una persona pod organizzazione, <strong><a class=\"quiet\" href=\"#co-owner-switch\">aggiungete un Co-proprietario !</a></strong></p>",
      "paper_identification_type": "Documento",
      "parents_intro": "Ricercare il padre e la madre del vostro prodotto o inserire le loro informazioni",
      "product": "Prodotto",
      "product_intro": "Scegliete il nome del vostro puledro o della vostra puledra. Indicate l' affisso del vostro allevamento (facoltativo) e completate i campi vuoti",
      "sections": {
        "birth_informations": "Informazioni sulla nascita",
        "breeder_details": "Coordinate dell' allevatore",
        "breeder_identity": "Identità dell' allevatore",
        "co_breeder_details": "Coordinate del co-allevatore",
        "co_breeder_identity": "Identità del co-allevatore",
        "co_owner_details": "Coordinate del co-proprietario",
        "co_owner_identity": "Identità del co-proprietario",
        "first_holder": "Detentore",
        "holding_place": "Luogo dell' identificazione",
        "identification_date": "Data dell' identificazione",
        "identification_payment_html": "Procedura",
        "identification_return_date": "Data di ricevimento",
        "identifier": "Identificatore",
        "owner_details": "Coordinate del proprietario",
        "owner_identity": "Identità del proprietario",
        "product_identity": "Identità del prodotto",
        "product_informations": "Informazioni sul prodotto",
        "remarks": "Note dell' identificatore"
      },
      "select_contact_placeholder": "Selezionare un %{type}",
      "select_mare_html": "Cercare e selezionare <br/>una giumenta",
      "select_stallion_html": "Cercare e selezionare <br/>uno stallone",
      "show": {
        "congrat_html": "Complimenti !",
        "details_email_var": "una email",
        "details_html": "<p> La Vostra dichiarazione di nascita è stata registrata. </p> <p> A breve riceverete %{var} l' invito a pagare l' importo per la registrazione del vostro prodotto nella banca dati nazionale (<a href=\"https://www.horseid.be\" target=\"_blank\">horseid.be</a>). </p> <p> Assicuratevi una procedura rapida. Il dossier di' identificazione vi perverrà al ricevimento del vostro pagamento. Dovrete presentare la documentazione al vostro veterinario. </p> <p> <strong>Attenzione :</strong> Certi invii potrebbero finire nella posta indesiderata (spams). Verificate nelle vostre cartelle. </p>",
        "details_paper_var": "una lettera",
        "end_btn": "Ho capito"
      },
      "stallion": "Stallone",
      "steps": {
        "breeder": "Allevatore",
        "confirm": "Riassunto",
        "identification": "Identificazione",
        "microchip": "Microchip",
        "owner": "Proprietario",
        "parents": "Origine",
        "product": "Prodotto"
      },
      "validation": {
        "back": "Ritorno",
        "breeder": "Allevatore",
        "click_to_add": "Cliccare per aggiungere al contatto selezionato",
        "co_breeder": "Co-allevatore",
        "co_owner": "Co-proprietario",
        "contact_address_added": "Indirizzo aggiunto",
        "contact_created": "Contatto creato",
        "contact_detail_added": "Coordinate aggiunte",
        "continue": "Continuare",
        "create_contact": "Creare un nuovo contatto",
        "create_horse": "Creare un nuovo equide",
        "declaration": "Dichiarazione",
        "holder": "Detentore",
        "horse_created": "Equide creato",
        "horse_creation_failed": "La creazione dell' equide è fallita",
        "mare": "Madre",
        "no_contact_selected": "Nessun contatto selezionato",
        "no_horse_selected": "Nessun equide selezionato",
        "or": "o",
        "owner": "Proprietario",
        "page_title": "Convalida",
        "select_contact_label": "Cercare e selezionare un %{type} tra i contatti",
        "select_contact_placeholder": "Selezionare un %{type}",
        "select_origin_label": "Cercare e selezionare un(a) %{type} tra gli equidi",
        "select_origin_placeholder": "Selezionare uno(a) %{type}",
        "stalion": "Padre",
        "steps": {
          "contacts": "Contatti",
          "identification": "Identificazione",
          "origins": "Origini",
          "product": "Prodotto"
        },
        "title": "convalidare una dichiarazione di mascita",
        "validation": "Convalida",
        "values": {
          "types": {
            "0": "Non creare domanda di identificazione",
            "1": "Creare una domanda HorseID senza caricarla",
            "2": "Creare e caricare una domanda HorseID",
            "3": "Inviare senza identificazione",
            "4": "Inviare senza identificazione e caricare"
          }
        }
      },
      "values": {
        "embryo_transfer": {
          "0": "No",
          "1": "Si",
          "false": "No",
          "true": "Si"
        },
        "station_be": {
          "0": "Altri paesi",
          "1": "Belgio",
          "false": "Altri Paesi",
          "true": "Belgio"
        },
        "status": {
          "to_validate": {
            "one": "Da convalidare",
            "other": "Da convalidare"
          },
          "validated": {
            "one": "Convalidata",
            "other": "Convalidata"
          }
        }
      }
    },
    "boolean": {
      "0": "No",
      "1": "Si",
      "False": "No",
      "True": "Si",
      "false": "No",
      "no": "No",
      "true": "Si",
      "yes": "Si"
    },
    "btn": {
      "labels": {
        "add": "Aggiungere",
        "add_coupling_card": "Creare una carta di monta",
        "add_delivrable": "Aggiungere una prestazione",
        "add_note": "Aggiungere una nota",
        "back": "Indietro",
        "birth_registration_continue": "Continuare",
        "cancel": "Annullare",
        "cancel_delivrable": "Annullare",
        "cancel_note": "Annullare",
        "continue": "Continuare",
        "copy_data_from_breeder": "copiare le informazioni dell' allevatore",
        "copy_data_from_co_breeder": "del co-allevatore",
        "copy_data_from_co_owner": "del co-proprietario",
        "copy_data_from_owner": "del proprietario",
        "create_cbc_horse": "Trasmettere senza identificazione",
        "create_identification": "Creare una domanda",
        "create_on_horse_id": "Creare su HordeID",
        "create_passport": "Richiedere un nuovo passaporto",
        "date_picker_ok": "Scegliere",
        "delete_delivrable": "Eliminare",
        "delete_note": "Eliminare",
        "delete_passports": "Eliminare",
        "delete_signage": "Eliminare il segnalamento",
        "dna_control_confirm": "Confermare",
        "dna_control_send": "Inviare",
        "dna_controls_confirm": "Convalidare i controlli",
        "edit_cbc_horse": "Modificare",
        "edit_identification": "Modificare",
        "edit_passport": "Modificare questo passaporto",
        "edit_signage": "Modificare il segnalamento",
        "editer_delivrable": "Modificare",
        "export_passport": "Esportare",
        "finalize_identification": "Chiudere",
        "grant_coupling_card": "Assegnare delle nuove carte",
        "ignore_update_contact_horse_id": "Ignorare l' aggiornamento",
        "mark_passport_as_sent": "Segnare come inviato(i)",
        "ok": "Ok",
        "order_coupling_card": "Richiedere delle nuove carte",
        "receive_identification": "Ricevere",
        "resend_to_horse_id": "Reinvio a HorseID",
        "save_card": "Registrare i dati",
        "save_card_credit": "Registrare l' ordine",
        "save_cbc_horse": "Registrare",
        "save_delivrable": "Registrare",
        "save_horse": "Registrare",
        "save_identification": "Registrare",
        "save_note": "Registrare",
        "send_birth_registration": "Convalidare ed inviare",
        "show_more_notes": "Visualizzare le altre note",
        "sign_out": "Disconnessione",
        "sort": "Tri",
        "transfer_cbc_horse": "Trasmettere",
        "transfer_identification": "Trasmettere",
        "update_contact_horse_id": "Avviare l' aggiornamento",
        "upload_signage": "Aggiungere un segnalamento",
        "validate_and_create_birth_registration": "Convalidare e creare",
        "validate_birth_registration": "Convalidare",
        "validate_cbc_horse": "Convalidare",
        "validate_identification": "Convalidare"
      },
      "tooltips": {
        "add_address": "Aggiungere un indirizzo",
        "add_contact": "Aggiungere una persona",
        "add_contact_detail": "Aggiungere Coordinate",
        "add_coupling_date": "Aggiungere una data di monta",
        "add_fee": "Aggiungere un contributo",
        "add_horse_owner": "Aggiungere un conproprietario",
        "add_labelling": "Aggiungere un' etichetta",
        "add_microchip": "Aggiungere un microchip",
        "add_organisation": "Aggiungere una organizzazione",
        "add_person": "Aggiungere una persona",
        "add_position": "Aggiungere una funzione",
        "cbc_horse_cannot_transfer": "Questa creazione non può essere trasmessa",
        "cbc_horse_cannot_validate": "Questa creazione non può essere convalidata",
        "complete_card": "Chiudere la carta",
        "complete_card_and_birth": "Chiudere la carta e dichiarare una nascita",
        "delete_delivrable": "Eliminare la prestazione",
        "destroy_birth_registration": "Eliminare la dichiarazione",
        "destroy_card": "Eliminare la carta",
        "download_contacts": "Scaricare i  %{count} contatti",
        "edit_card": "Modificare la carta",
        "edit_delivrable": "Modificare la prestazione",
        "edit_horse": "Modificare questo equide",
        "edit_identification": "Modificare la domanda di identificazione",
        "edit_organisation": "Modificare questa organizzazione",
        "edit_person": "Modificare questa persona",
        "identification_cannot_finalize": "Questa identificazione non può essere chiusa",
        "identification_cannot_receive": "Questa identificazione non può essere accettata",
        "identification_cannot_transfer": "Questa identificazione non può essere trasmessa",
        "identification_cannot_validate": "Questa identificazione non può essere convalidata",
        "incomplete_pedigree": "Pedigree incompleto",
        "missing_cbc_horse_attributes": "Le informazioni seguenti sono necessarie per l'identificazione : %{missing_attributes}",
        "missing_coupling_card_attributes": "Le informazioni seguenti sono necessarie per chiudere la carta: %{missing_attributes}",
        "missing_identification_attributes": "Le informazioni seguenti sono necessarie per l'identificazione : %{missing_attributes}",
        "more_actions": "Altre azioni",
        "new_birth": "Dichiarare la nascita",
        "new_contact": "Registrare un nuovo contatto",
        "new_horse": "Creare un  nuovo equide",
        "new_identification": "Nuova domanda di identificazione",
        "new_organisation": "Registrare una nuova organizzazione",
        "new_person": "Registrare una nuova persona",
        "print_birth": "Stampare la dichiarazione di nascita",
        "remove_address": "Eliminare un indirizzo",
        "remove_contact": "Eliminare una persona",
        "remove_contact_detail": "Eliminare Coordinate",
        "remove_coupling_date": "Eliminare la data di monta",
        "remove_fee": "Eliminare una quota",
        "remove_horse_owner": "Eliminare un conproprietario",
        "remove_labelling": "Eliminare questa etichetta",
        "remove_microchip": "Eliminare questo microchip",
        "remove_organisation": "Eliminare questa organizzazione",
        "remove_person": "Eliminare questa persona",
        "remove_position": "Eliminare una funzione",
        "required": "obbligatorio",
        "show_coupling_card_copy": "Allegare copia della carta di monta",
        "show_identification": "Vedere la domanda di identificazione",
        "sign_out": "Abbandonare SireCloud"
      }
    },
    "cbc_horses": {
      "edit": {
        "page_title": "Modifica della trasmissione senza domanda di identificazione",
        "title": "Modificare il trasferimento senza domanda di identificazione"
      },
      "flash": {
        "bulk": {
          "fail": "Nessuna creazione selezionata"
        },
        "create": {
          "fail": "La creazione non è stata registrata",
          "success": "Creazione registrata"
        },
        "transfered": {
          "fail": {
            "one": "La Creazione non ha potuto essere trasmessa",
            "other": "Le Creazioni non si sono potute trasmettere"
          },
          "success": {
            "one": "Creazione trasmessa",
            "other": "Creazioni trasmesse"
          }
        },
        "transfering": {
          "fail": {
            "one": "La Creazione non ha potuto essere trasmessa",
            "other": "Le Creazioni non si sono potute trasmettere"
          },
          "success": {
            "one": "Creazione in via di trasferimento",
            "other": "Creazioni in via di trasferimento"
          }
        },
        "update": {
          "fail": "La creazione non ha potuto essere aggiornata",
          "fail_horse_id": "La creazione non ha potuto essere aggiornata in HorseID",
          "success": "Creazione aggiornata",
          "success_horse_id": "Creazione aggiornata in HorseID"
        },
        "validated": {
          "fail": {
            "one": "La creazione non ha potuto essere convalidata",
            "other": "Le creazioni non si sono potute convalidare"
          },
          "success": {
            "one": "Creazione convalidata",
            "other": "Creazioni convalidate"
          }
        },
        "validating": {
          "fail": {
            "one": "La creazione non ha potuto essere convalidata",
            "other": "Le creazioni non si sono potute convalidare"
          },
          "success": {
            "one": "Creazione in via di convalida",
            "other": "Creazioni in via di convalida"
          }
        }
      },
      "holder_selected_email_no_email": "Nessuna email conosciuta del detentore",
      "holder_selected_email_no_holder": "scegliete un detentore",
      "horseid": {
        "attributes": {
          "exploitationBox": "Casella Postale",
          "exploitationCountry": "Paese",
          "exploitationLocality": "Lovalità",
          "exploitationNumber": "Numero",
          "exploitationPostCode": "Codice postale",
          "exploitationStreet": "Via",
          "foreignAlias": "Alias d' origine",
          "horse": {
            "birthCountry": "Paese di nascita",
            "birthDate": "Data di nascita",
            "breeders": "Allevatori",
            "butcherFoal": "Puledro da macello",
            "castrated": "Castrato",
            "castrationDate": "Data della castrazione",
            "commercialName": "Nome commerciale",
            "detentionPlace": "Numero dell'istituto di detenzione",
            "detentionSince": "Data di arrivo del prodotto presso questo istituto",
            "dressColor": "Colore del mantello",
            "excludingFoodChain": "Escluso dalla catena alimentare",
            "excludingFoodChainDate": "Data di esclusione dalla catena alimentare",
            "father": {
              "name": "Nome del padre",
              "transponder": "Numero del transponder del padre",
              "ueln": "UELN del padre"
            },
            "gender": "Sesso",
            "hasOrigin": "Ha un pedigree",
            "holder": "ID CBC del proprietario",
            "holderTmp": "ID CBC temporaneo del proprietario",
            "id": "Codice",
            "mother": {
              "name": "Nome della madre",
              "transponder": "Numero del transponder della madre",
              "ueln": "UELN della madre"
            },
            "name": "Nome",
            "privateRemark": "Note private",
            "race": "Razza",
            "responsibleAgency": "Agenzia responsabile",
            "studbookId": "Codice",
            "transponder": "Numero del transponder",
            "type": "Tipo",
            "ueln": "UELN"
          },
          "horseBirthCountry": "Paese",
          "horseBirthdate": "Data di nascita",
          "horseBreeder1": "Id CBC dell' allevatore 1",
          "horseBreeder2": "Id CBC dell' allevatore 2",
          "horseButcherFoal": "Puledro da macello",
          "horseCastrated": "Castrato",
          "horseCommercialName": "Nome commerciale",
          "horseDateExcludingFoodChain": "Data di esclusione dalla catena alimentare",
          "horseDateHolding": "Data di inizio della detenzione",
          "horseDressColor": "Colore del mantello",
          "horseExcludingFoodChain": "Escluso dalla catena alimentare",
          "horseFather": "UELN padre",
          "horseGender": "Sesso",
          "horseHolder": "Id CBC del detentore",
          "horseMother": "UELN madre",
          "horseName": "Nome",
          "horseOrigin": "Origine",
          "horseRace": "Razza",
          "horseTransponder1": "N° microchip",
          "horseType": "Tipo",
          "horseUeln": "UELN",
          "id": "Codice equide",
          "privateRemark": "Osservazioni",
          "responsibleAgency": "UELN agenzia responsabile",
          "transmitterAgency": "UELN agenzia emittente"
        }
      },
      "index": {
        "search": {
          "placeholder": "Ricerca rapida : Nome, UELN o codice"
        }
      },
      "new": {
        "horse_not_found": "Nessun equide trovato",
        "page_title": "Trasmissione senza domanda di identificazione",
        "title": "Trasmissione senza domanda di identificazione",
        "transfert_already_requested": "Questo equide è già stato oggetto di trasmissione"
      },
      "sections": {
        "birth_informations": "Informazioni sulla nascita",
        "breeder_details": "Coordinate dell' allevatore",
        "breeder_identity": "Identità dell' allevatore",
        "co_breeder_details": "Coordinate del co-allevatore",
        "co_breeder_identity": "Identità del co-allevatore",
        "co_owner_details": "Coordinate del co-proprietario",
        "co_owner_identity": "Identità del co-proprietario",
        "first_holder": "Detentore",
        "followup": "Proseguimento",
        "holding_place": "Luogo d' esecuzione",
        "owner_details": "Coordinate del proprietario",
        "owner_identity": "Identità del proprietario",
        "product_identity": "Identità del prodotto",
        "product_informations": "Informazioni sul prodotto"
      },
      "show": {
        "page_title": "Trasmissione senza domanda di identificazione",
        "title": "Trasmissione senza domanda di identificazione"
      },
      "values": {
        "boolean": {
          "0": "No",
          "1": "Si",
          "false": "No",
          "true": "Si"
        },
        "sexe": {
          "0": "Maschio",
          "1": "Sconosciuto",
          "2": "Femmina"
        },
        "status": {
          "to_transfer": {
            "one": "In preparazione",
            "other": "In preparazione"
          },
          "to_validate": {
            "one": "Trasmessa",
            "other": "Trasmesse"
          },
          "transfering": {
            "one": "In via di trasferimento",
            "other": "In via di trasferimento"
          },
          "validated": {
            "one": "Chiusa",
            "other": "Chiuse"
          },
          "validating": {
            "one": "In corso di convalida",
            "other": "In corso di convalida"
          }
        }
      }
    },
    "clearance": {
      "models": {
        "clearance_mailer": {
          "change_password": "SireCloud.be - Cambiamento della password"
        }
      }
    },
    "clearance_mailer": {
      "change_password": {
        "closing": "Se non avete fatto richiesta, potete ignorare questa mail. La vostra password non è stata modificata",
        "opening": "Qualcuno, augurandoci si tratti di lei, ci ha chiesto di inviarvi questa e-mail per modificare la vostra password :"
      }
    },
    "companies": {
      "legal_form": {
        "short_texts": {
          "1": "ASBL",
          "10": "SPRL",
          "11": "SRL",
          "12": "VZW",
          "13": "SCS",
          "2": "BVBA",
          "3": "CVA",
          "4": "LLC",
          "5": "N.V.",
          "6": "S.A.",
          "7": "SAGR",
          "8": "SARL",
          "9": "SNC"
        },
        "texts": {
          "1": "Associazione senza scopo di lucro",
          "10": "SPRL",
          "11": "SRL",
          "12": "VZW",
          "13": "Società a comandita semplice",
          "2": "Besloten Vennootschap met Beperkte Aansprakelijkheid",
          "3": "Commanditaire Vennootschap op Aandelen",
          "4": "Limited Liability Company",
          "5": "Naamloze Vennootschap",
          "6": "Società Anonima",
          "7": "SAGR",
          "8": "SARL",
          "9": "SNC"
        }
      }
    },
    "contact_details": {
      "type_suffixes": {
        "tel_home": "Domicilio",
        "tel_work": "Lavoro"
      }
    },
    "contacts": {
      "data_fields": {
        "breeder": {
          "addr1": "via",
          "address": "indirizzo",
          "adress": "Indirizzo dell' allevatore",
          "box": "Edificio",
          "civility": "Stato civile",
          "company": "Intestazione",
          "company_country": "Paese",
          "company_number": "Numero partita IVA o Numero di impresa",
          "company_vat_subject": "Assogettato IVA",
          "country_code": "Paese",
          "firstname": "Nome dell' allevatore",
          "lastname": "Cognome dell' allevatore",
          "legal_form_id": "Forma giuridica",
          "locality": "Citta",
          "national_number": "Numero nazionale dell' allevatore",
          "national_number_country": "Paese",
          "number": "N°",
          "zip": "Codice Postale"
        },
        "co_breeder": {
          "addr1": "Via",
          "address": "Indirizzo del co-allevatore",
          "box": "Edificio",
          "civility": "Stato civile",
          "company": "Intestazione",
          "company_country": "Paese",
          "company_number": "Numero di Partita IVA o Numero d' impresa",
          "company_vat_subject": "Assogettato ad IVA",
          "country_code": "Paese",
          "firstname": "Nome del co-allevatore",
          "lastname": "Cognome del co-allevatore",
          "legal_form_id": "forma giuridica",
          "locality": "Citta",
          "national_number": "Numero nazionale del co-allevatore",
          "national_number_country": "Paese",
          "number": "N°",
          "zip": "Codice postale"
        },
        "co_owner": {
          "addr1": "Via",
          "adress": "Indirizzo del co-proprietario",
          "box": "Edificio",
          "civility": "Stato civile",
          "company": "Intestazione",
          "company_country": "Paese",
          "company_number": "Numero di Partita IVA o Numero d' impresa",
          "company_vat_subject": "Assoggettato",
          "country_code": "Paese",
          "firstname": "Nome del proprietario",
          "lastname": "Cognome del proprietario",
          "legal_form_id": "Forma giuridica",
          "locality": "citta",
          "national_number": "Numero nazionale del co-proprietario",
          "national_number_country": "Paese",
          "number": "N°",
          "zip": "Codice Postale"
        },
        "default": {
          "addr1": "Via",
          "address": "Indirizzo",
          "adress": "Indirizzo",
          "box": "Edificio",
          "cbc_id": "ID CBC",
          "civility": "Stato civile",
          "company": "Intestazione",
          "company_country": "Paese",
          "company_number": "Numero di Partita IVA o Numero d' impresa",
          "company_vat_subject": "Assoggettato",
          "country_code": "Paese",
          "email": "Email",
          "fax": "Fax",
          "firstname": "Nome",
          "lastname": "Cognome",
          "legal_form_id": "Forma giuridica",
          "locality": "Città",
          "mobile": "Cellulare",
          "national_number": "Codice Fiscale",
          "national_number_country": "Paese",
          "national_register": "Registro nazionale",
          "number": "N°",
          "phone": "Telefono",
          "vat": "Numero di partita IVA (se assoggettato)",
          "zip": "Codice postale"
        },
        "owner": {
          "addr1": "Via",
          "address": "Indirizzo del proprietario",
          "box": "Edificio",
          "civility": "Stato Civile",
          "company": "Intestazione",
          "company_country": "Paese",
          "company_number": "Numero di Partita IVA o numero d' impresa",
          "company_vat_subject": "Assoggettato",
          "country_code": "Paese",
          "email": "Email",
          "fax": "Fax",
          "firstname": "Nome del proprietario",
          "lastname": "Cognome del proprietario",
          "legal_form_id": "Forma giuridica",
          "locality": "Città",
          "mobile": "Cellulare",
          "national_number": "Numero nazionale del proprietario",
          "national_number_country": "Paese",
          "number": "N°",
          "phone": "Telefono",
          "zip": "Codice postale"
        }
      },
      "index": {
        "search": {
          "placeholder": "Cercare una persona od una organizzazione"
        }
      },
      "membership_status": {
        "inactive": "Inattivo",
        "inactive_member": "Membro inattivo"
      },
      "new": {
        "page_title": "Nuovo contatto",
        "title": "Creazione di in nuovo contatto"
      },
      "one": "Contatto",
      "other": "Contatti",
      "personne_morale": "Società / organizzazione / Associazione",
      "personne_phyisque": "Persona Fisica",
      "positions": {
        "members": "Statuto",
        "others": "Funzione"
      },
      "section-title": {
        "admin": "Banca",
        "contact_details": "Coordinate",
        "identity": "Identità",
        "membership": "Adesione",
        "org_people": {
          "one": "Responsabile",
          "other": "Responsabili"
        }
      },
      "values": {
        "address_types": {
          "billing": "Fatturazione",
          "main": "Indirizzo principale",
          "post": "Indirizzo postale"
        }
      }
    },
    "coupling_cards": {
      "available": {
        "one": "Carta disponibile",
        "other": "Carte disponibli"
      },
      "available_full": {
        "one": "%{count} carta disponibile su %{total}",
        "other": "%{count} carte disponibili su %{total}"
      },
      "closed": {
        "one": "Carta chiusa",
        "other": "Carte chiuse"
      },
      "credit_count": {
        "one": "Carta disponibile",
        "other": "Carte disponibili",
        "zero": "Nessuna carta disponibile"
      },
      "edit": {
        "page_title": "Modifica della carta di monta",
        "title": "Modificare la carta di monta"
      },
      "flash": {
        "create": {
          "fail": "Errore di registrazione. La carta non è stata creata",
          "success": "Carta creata"
        },
        "destroy": {
          "fail": "Non si è potuto eliminare la carta",
          "success": "Carta di monta eliminata"
        },
        "update": {
          "fail": "Non si è potuto aggiornare la carta",
          "success": "Carta aggiornata"
        }
      },
      "form": {
        "confirm_help": "Verificate i dati prima di aggiornare la carta"
      },
      "hints": {
        "mare_code": "Codice Studbook o UELN",
        "stalion_code": "Codice Studbook o UELN"
      },
      "index": {
        "my_cards": "Le mie carte di monta",
        "search": {
          "placeholder": "Ricercare una carta per numero o per stallone"
        }
      },
      "mare": {
        "extend_label": "Madre biologica del prodotto nascituro"
      },
      "new": {
        "page_title": "Nuova carta di monta",
        "title": "Creare una nuova carta di monta"
      },
      "open": {
        "one": "Carta aperta",
        "other": "Carte aperte"
      },
      "paper": {
        "one": "Documento cartaceo aperto",
        "other": "Documenti cartacei aperti"
      },
      "section-title": {
        "owner_identity": "Identità del proprietario della madre biologica"
      },
      "show": {
        "add_mare": "Aggiungere una giumenta",
        "add_stallion": "Aggiungere uno stallone",
        "can_not_close": "La carta non può essere chiusa",
        "missing_mare": "Nessuna giumenta codificata",
        "missing_stallion": "Nessuno stallone selezionato"
      },
      "steps": {
        "confirm": "Riassunto",
        "coupling": "Monta",
        "owner": "Proprietario",
        "parents": "Origine"
      },
      "used": {
        "one": "Carta utilizzata",
        "other": "Carte utilizzate"
      },
      "values": {
        "coupling_mode": {
          "1": "Monta naturale",
          "2": "I.A. seme fresco",
          "3": "I.A. seme refrigerato",
          "4": "I.A. seme congelato"
        },
        "status": {
          "0": "Data di apertura",
          "1": "Data di chiusura",
          "2": "Data di chiusura",
          "3": "Data di chiusura",
          "closed": {
            "one": "Chiusa",
            "other": "Chiuse"
          },
          "open": {
            "one": "Aperta",
            "other": "Aperte"
          }
        }
      }
    },
    "date": {
      "abbr_day_names": [
        "dom",
        "lun",
        "mar",
        "mer",
        "gio",
        "ven",
        "sab"
      ],
      "abbr_month_names": [
        null,
        "gen",
        "feb",
        "mar",
        "apr",
        "mag",
        "giu",
        "lug",
        "ago",
        "set",
        "ott",
        "nov",
        "dic"
      ],
      "day_names": [
        "domenica",
        "lunedì",
        "martedì",
        "mercoledì",
        "giovedì",
        "venerdì",
        "sabato"
      ],
      "formats": {
        "default": "%d/%m/%Y",
        "long": "%e %B %Y",
        "mat_datepicker": "dd/mm/yyyy",
        "real_short_date": "%d/%m/%y",
        "short": "%e %b",
        "short_date": "%d/%m/%Y"
      },
      "month_names": [
        null,
        "gennaio",
        "febbraio",
        "marzo",
        "aprile",
        "maggio",
        "giugno",
        "luglio",
        "agosto",
        "settembre",
        "ottobre",
        "novembre",
        "dicembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "circa un'ora",
          "other": "circa %{count} ore"
        },
        "about_x_months": {
          "one": "circa un mese",
          "other": "circa %{count} mesi"
        },
        "about_x_years": {
          "one": "circa un anno",
          "other": "circa %{count} anni"
        },
        "almost_x_years": {
          "one": "circa 1 anno",
          "other": "circa %{count} anni"
        },
        "half_a_minute": "mezzo minuto",
        "less_than_x_minutes": {
          "one": "meno di un minuto",
          "other": "meno di %{count} minuti",
          "zero": "meno di un minuto"
        },
        "less_than_x_seconds": {
          "one": "meno di un secondo",
          "other": "meno di %{count} secondi",
          "zero": "meno d' un secondo"
        },
        "over_x_years": {
          "one": "oltre un anno",
          "other": "oltre %{count} anni"
        },
        "x_days": {
          "one": "1 giorno",
          "other": "%{count} giorni"
        },
        "x_minutes": {
          "one": "1 minuto",
          "other": "%{count} minuti"
        },
        "x_months": {
          "one": "1 mese",
          "other": "%{count} mesi"
        },
        "x_seconds": {
          "one": "1 secondo",
          "other": "%{count} secondi"
        },
        "x_years": {
          "one": "1 anno",
          "other": "%{count} anni"
        }
      },
      "prompts": {
        "day": "Giorno",
        "hour": "Ora",
        "minute": "Minuto",
        "month": "Mese",
        "second": "Secondi",
        "year": "Anno"
      }
    },
    "delivrable_subjects": {
      "Divers": "Varie",
      "Duplicata": "Duplicato",
      "Détermination ADN": "Determinazione DND",
      "Inscription": "Iscrizione",
      "Transfert propriété": "Trasferimento di proprietà"
    },
    "delivrables": {
      "flash": {
        "create": {
          "fail": "Non si è potuto registrare la prestazione",
          "success": "Prestazione registrata"
        },
        "destroy": {
          "fail": "Non si è potuto eliminare la prestazione",
          "success": "Prestazione eliminata"
        },
        "edit": {
          "fail": "Non si è potuto modificare la prestazione",
          "success": "Prestazione modificata"
        }
      },
      "no_delivrable": "Nessuna prestazione"
    },
    "documents": {
      "dias": {
        "documents_error": "Si è prodotto un errore durante la pubblicazione dei documento. Riprovate più tardi.",
        "documents_unavailable": "Il servizio DiAS non è disponibile al momento, i documenti non possono essere inseriti.",
        "errors": {
          "3003": "Invio impossibile : l' equide non ha ancora un dossier al DiAS"
        },
        "signages_error": "Si è verificato un errore durante l' inserimento dei segnalamenti grafici. Vogliate riprovare più tardi",
        "signages_unavailable": "Il servizio DiAS non è disponibile al momento, il segnalamento grafico non può essere inserito"
      },
      "flash": {
        "create": {
          "signage": {
            "fail": "Il segnalamento grafico non è stato aggiunto",
            "success": "Segnalamento grafico aggiunto"
          }
        },
        "destroy": {
          "signage": {
            "fail": "Il segnalamento grafico non si è potuto eliminare",
            "success": "Segnalamento grafico eliminato"
          }
        },
        "update": {
          "signage": {
            "fail": "Non si è potuto modificare il segnalamento grafico",
            "success": "Segnalamento grafico modificato"
          }
        }
      }
    },
    "errors": {
      "file": {
        "extension_exclusion": "l' estensione non può essere presente nella lista: %{list}",
        "extension_inclusion": "l'estensuione deve essere presente nella lista : %{list}",
        "max_dimensions": "le dimensioni devono essere inferiori a %{dims}",
        "max_height": "l' altezza deve essere inferiore a  %{max}",
        "max_size": "la taglia deve essere inferiore a %{max}",
        "max_width": "la larghezza deve essere inferiore a  %{max}",
        "mime_type_exclusion": "il modello non può essere presente nell'elenco : %{list}",
        "mime_type_inclusion": "il modello deve essere presente nell'elenco : %{list}",
        "min_dimensions": "le dimensioni devono essere superiori a  %{dims}",
        "min_height": "l' altezza deve essere superiore a %{min}",
        "min_size": "la taglia deve essere superiore a  %{min}",
        "min_width": "la larghezza deve essere superiore a %{min}"
      },
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "deve essere accettata",
        "blank": "non può essere lasciato in bianco",
        "confirmation": "non coincide con %{attribute}",
        "empty": "non può essere vuoto",
        "equal_to": "deve essere uguale a %{count}",
        "even": "deve essere pari",
        "exclusion": "è riservato",
        "greater_than": "deve essere maggiore di %{count}",
        "greater_than_or_equal_to": "deve essere maggiore o uguale a %{count}",
        "has_duplicates": "contiene duplicati",
        "improbable_phone": "non è un numero valido",
        "in_the_future": "non può essere nel futuro",
        "inclusion": "non è compreso tra le opzioni disponibili",
        "invalid": "non è valido",
        "less_than": "deve essere minore di %{count}",
        "less_than_or_equal_to": "deve essere minore o uguale a %{count}",
        "model_invalid": "Validazione fallita : %{errors}",
        "not_a_number": "non è un numero",
        "not_an_integer": "non è un numero intero",
        "odd": "deve essere dispari",
        "other_than": "devono essere di numero diverso da %{count}",
        "present": "deve essere lasciato in bianco",
        "required": "deve esistere",
        "taken": "è già presente",
        "too_long": {
          "one": "è troppo lungo (il massimo è 1 carattere)",
          "other": "è troppo lungo (il massimo è %{count} caratteri)"
        },
        "too_short": {
          "one": "è troppo corto (il minimo è 1 carattere)",
          "other": "è troppo corto (il minimo è %{count} caratteri)"
        },
        "wrong_length": {
          "one": "è della lunghezza sbagliata (deve essere di 1 carattere)",
          "other": "è della lunghezza sbagliata (deve essere di %{count} caratteri)"
        }
      },
      "template": {
        "body": "Ricontrolla i seguenti campi:",
        "header": {
          "one": "Non posso salvare questo %{model}: 1 errore",
          "other": "Non posso salvare questo %{model}: %{count} errori."
        }
      }
    },
    "file_input": {
      "placeholder": "Scegliere un file"
    },
    "filterable": {
      "filter": {
        "contact": {
          "function": "Funzione",
          "member": {
            "0": "Non-membro",
            "1": "Membro"
          },
          "status": "Posizione",
          "type": {
            "organisation": "Organizzazione",
            "person": "Persona"
          }
        },
        "sex": {
          "1": "Maschio",
          "2": "Femmina"
        },
        "status": "Posizione",
        "studbook": "Studbook",
        "year": "Anno"
      },
      "filter_by": {
        "sex": "Filtrare per sesso",
        "year": "Filtrare per anno"
      },
      "indiferrent": "Tutti",
      "menu_title": "Filtrare i risultati",
      "no_filter": "Nessun filtro"
    },
    "flashes": {
      "failure_after_create": "Indirizzo e-mail o password errati",
      "failure_after_update": "La password non può essere vuota",
      "failure_when_access_denied": "Non può accedere a questa funzionalità",
      "failure_when_forbidden": "Verificare l' URL o provare a rinviare il modulo",
      "failure_when_not_signed_in": "E' necessario connettersi per utilizzare SireCloud"
    },
    "food_chain": "Catena alimentare",
    "food_chain_excluded_on": "Escluso il  %{date}",
    "form_message": {
      "flash": {
        "sent": "Richiesta inviata"
      }
    },
    "from_date_to_date": "dal %{from_date} al %{to_date}",
    "help_texts": {
      "etalonnier": {
        "coupling_cards_index_html": "<p> Potete qui gestire le vostre carte di monta aperte o chiuse. <br/> Per dichiarare una nascita, portatevi su una carta chiusa. </p>",
        "horses_index_html": "<p>Per vedere il file di uno stallone e le informazioni che lo compongono, si può cliccare su uno stallone sulla tabella sottostante. <!--<br/> Per dichiarare una nascita spostarsi alla pagina <a href=\"/cards\">Le mie carte di accoppiamento</a>.--> </p>"
      }
    },
    "helpers": {
      "dates": {
        "from_to_date": "dal %{start_date} al %{end_date}",
        "since_date": "a partire da %{date}",
        "to_date": "fino a %{date}"
      },
      "label": {
        "password": {
          "email": "Email"
        },
        "password_reset": {
          "password": "Scegliere una password"
        }
      },
      "page_entries_info": {
        "more_pages": {
          "display_entries": "%{entry_name} %{first} à %{last} sur %{total}"
        },
        "one_page": {
          "display_entries": {
            "one": "1 %{entry_name}",
            "other": "%{count} %{entry_name}",
            "zero": "Nessuno  %{entry_name}"
          }
        }
      },
      "select": {
        "prompt": "Seleziona..."
      },
      "submit": {
        "create": "Crea %{model}",
        "password": {
          "submit": "Scegliere la vostra password"
        },
        "password_reset": {
          "submit": "Registrare la password"
        },
        "session": {
          "submit": "Connettersi"
        },
        "submit": "Invia %{model}",
        "update": "Aggiorna %{model}",
        "user": {
          "create": "Iscriversi"
        }
      }
    },
    "horse/passport": {
      "flash": {
        "create": {
          "fail": "Non si è potuto creare il passaporto",
          "success": "Passaporto creato"
        },
        "delete": {
          "fail": {
            "one": "Non si è potuto eliminare il passaporto",
            "other": "Non si sono potuti eliminare i passaporti"
          },
          "success": {
            "one": "Il passaporto è stato eliminato",
            "other": "I passaporti sono stati eliminati"
          }
        },
        "export": {
          "fail": {
            "one": "Non si è potuto esportare il passaporto",
            "other": "Non si sono potuti esportare i passaporti"
          },
          "success": {
            "one": "Passaporto esportato",
            "other": "Passaporti esportati come inviati"
          }
        },
        "mark_as_sent": {
          "fail": "Data d' invio non valida",
          "success": {
            "one": "Passaporto contrassegnato come inviato",
            "other": "Passaporti contrassegnati come inviati"
          }
        },
        "none_selected": "Nessun passaporto selezionato",
        "update": {
          "fail": "Non si è potuto aggiornare il passaporto",
          "success": "Passaporto aggiornato"
        }
      },
      "values": {
        "passport_type": {
          "duplicata": "Duplicato",
          "original": "Originale",
          "replacement": "Sostituzione"
        },
        "status": {
          "cancelled": {
            "one": "Annullato",
            "other": "Annullati"
          },
          "exported": {
            "one": "Da inviare",
            "other": "Da inviare"
          },
          "lost": {
            "one": "Perso",
            "other": "Persi"
          },
          "sent": {
            "one": "Inviato",
            "other": "Inviati"
          },
          "to_export": {
            "one": "Nuovo",
            "other": "Da esportare"
          }
        }
      }
    },
    "horse_id": {
      "contact_pending_validation": "%{id} (temp.)"
    },
    "horses": {
      "actions": {
        "confirm_destroy": "Si è sicuri di voler eliminare definitivamente questo equide",
        "destroy": "Eliminare questo equide"
      },
      "death_mutation_will_be_final": "La morte non può essere annullata",
      "decorator": {
        "born_on": {
          "0_html": "<span class=\"born-on-intro\">nato il </span>%{date}",
          "1_html": "<span class=\"born-on-intro\">nato il </span>%{date}",
          "2_html": "<span class=\"born-on-intro\">nata il </span>%{date}"
        },
        "born_on_no_date_html": "Data di nascita sconosciuta"
      },
      "dna_controls": {
        "page_title": "Controlli genetici",
        "search": {
          "placeholder": "Ricerca rapida: nome , UELN o codice"
        },
        "title": "Controlli genetici"
      },
      "dna_status": {
        "to_confirm": {
          "one": "Inviato il %{sent_date}",
          "other": "Da confermare"
        },
        "to_send": {
          "one": "Da inviare",
          "other": "Da inviare"
        }
      },
      "edit": {
        "page_title": "Modificare un equide",
        "title": "Modificare la registrazione dell' equide"
      },
      "flash": {
        "create": {
          "fail": "La creazione dell' equide è fallita",
          "success": "Nuovo equide registrato"
        },
        "destroy": {
          "not_with_chidlren": "Un equide con produzione registrata non può essere cancellato",
          "success": "L' Equide è stato cancellato"
        },
        "dna_control_confirmed": {
          "fail": {
            "one": "Non si è potuto confermare il controllo",
            "other": "Non si sono potuti confermare i controlli"
          },
          "success": {
            "one": "Controllo confermato",
            "other": "Controlli confermati"
          }
        },
        "dna_control_sent": {
          "fail": {
            "one": "Non si è potuto inviare il controllo",
            "other": "Non si sono potuti inviare i controlli"
          },
          "success": {
            "one": "Controllo inviato",
            "other": "Controlli inviati"
          }
        },
        "update": {
          "success": "Le informazioni sull'equide sono state aggiornate"
        }
      },
      "food_chain_mutation_will_be_final": "L'esclusione non può essere annullata",
      "hints": {
        "ueln_type": "Viene richiesto un codice"
      },
      "index": {
        "my_stallions": "I miei stalloni",
        "search": {
          "advanced": {
            "title": "Ricerca avanzata"
          },
          "placeholder": "Ricerca rapida: Nome, UELN o codice"
        }
      },
      "new": {
        "page_title": "Nuovo equide",
        "title": "Registrazione di un nuovo equide"
      },
      "placeholders": {
        "search_mare": "Cercare una giumenta per Nome, Codice o Microchip",
        "search_stallion": "Cercare uno stallone per Nome, Codice o Microchip",
        "select_mare": "Selezionare una giumenta",
        "select_stallion": "Selezionare uno stallone"
      },
      "section-title": {
        "active_passport": "Passaporto attivo",
        "additional_info": "Informazioni supplementari",
        "identification": "Identificazione",
        "numbering": "Numerazione",
        "previous_passports": "Vecchi passaporti",
        "registration": "Iscrizione",
        "structured_signage": "Segnalamento codificato"
      },
      "stallions": "Stallone",
      "tabs": {
        "delivrables": {
          "title": "Fatturazione"
        },
        "documents": {
          "title": "Documenti"
        },
        "generalites": {
          "Identification": "Identificazione",
          "title": "Generalità"
        },
        "identification": {
          "title": "Identificazione"
        },
        "labels": {
          "title": "Etichetta"
        },
        "passports": {
          "title": "Passaporti"
        },
        "pedigree": {
          "title": "Pédigrée"
        },
        "people": {
          "title": "Persone"
        },
        "production": {
          "title": "Produzione"
        },
        "reproduction": {
          "title": "Riproduzione"
        },
        "results": {
          "title": "Risultati"
        },
        "signalement": {
          "title": "Segnalamento"
        }
      },
      "unknown": "Pedigree in fase di sviluppo",
      "values": {
        "dna_filiation_compatible": {
          "ko": "Filiazione incompatibile",
          "ok": "Filiazione compatibile"
        },
        "food_chain": {
          "0": "Conservato",
          "1": "Escluso",
          "false": "Conservato",
          "true": "Escluso"
        },
        "sexe": {
          "0": "Sconosciuto",
          "1": "Maschio",
          "2": "Femmina"
        },
        "sexe_short": {
          "0": "?",
          "1": "M",
          "2": "F"
        },
        "ueln_types": {
          "auto": "Si, n° definitivo",
          "manual": "No, cattura manuale",
          "temp": "si, n° temporaneo"
        }
      }
    },
    "identifications": {
      "edit": {
        "page_title": "Modifica della domanda di identificazione",
        "title": "Modificare la domanda d' identificazione"
      },
      "flash": {
        "bulk": {
          "fail": "Nessuna identificazione selezionata"
        },
        "create": {
          "fail": "La domanda non è stata creata",
          "success": "Domanda di identificazione registrata"
        },
        "finalized": {
          "fail": {
            "one": "Non si è potuto  concludere la domanda",
            "other": "Non si sono potute concludere le domande"
          },
          "success": {
            "one": "Domanda conclusa",
            "other": "Damande concluse"
          }
        },
        "finalizing": {
          "fail": {
            "one": "Non si è potuto  concludere la domanda",
            "other": "Non si sono potute concludere le domande"
          },
          "success": {
            "one": "Domanda in via di conclusione",
            "other": "Domande in via di conclusione"
          }
        },
        "received": {
          "fail": {
            "one": "La domanda non ha potuto essere accolta",
            "other": "Non si sono potute accogliere le domande"
          },
          "success": {
            "one": "Domanda accolta",
            "other": "Domande accolte"
          }
        },
        "receiving": {
          "fail": {
            "one": "La domanda non ha potuto essere accolta",
            "other": "Non si sono potute accogliere le domande"
          },
          "success": {
            "one": "Domanda in fase di accoglimento",
            "other": "Domande in fase di accoglimento"
          }
        },
        "transfered": {
          "fail": {
            "one": "Non si è potuta trasmettere la domanda",
            "other": "Non si sono potute trasmettere le domande"
          },
          "success": {
            "one": "Domanda trasmessa",
            "other": "Domande trasmesse"
          }
        },
        "transfering": {
          "fail": {
            "one": "Non si è potuto trasmettere la domanda",
            "other": "Non si sono potute trasmettere le domande"
          },
          "success": {
            "one": "Domanda in corso di trasmissione",
            "other": "Domande in corso di trasmissione"
          }
        },
        "update": {
          "fail": "La domanda non può essere aggiornata",
          "fail_horse_id": "La domanda non può essere aggiornata in HorseID",
          "success": "Domanda aggiornata",
          "success_horse_id": "Domanda aggiornata ed aggiornata in HorseID"
        },
        "validated": {
          "fail": {
            "one": "La domanda non ha potuto essere convalidata",
            "other": "Le domande non hanno potuto essere convalidate"
          },
          "success": {
            "one": "Domanda convalidata",
            "other": "Domande convalidate"
          }
        },
        "validating": {
          "fail": {
            "one": "La domanda non ha potuto essere convalidata",
            "other": "Le domande non hanno potuto essere convalidate"
          },
          "success": {
            "one": "Domanda in fase di convalida",
            "other": "Domande in fase di convalida"
          }
        }
      },
      "holder_selected_email_no_email": "nessuna mail nota del detentore",
      "holder_selected_email_no_holder": "scegliete un detentore",
      "horseid": {
        "attributes": {
          "exploitationBox": "Casella postale",
          "exploitationCountry": "Paese",
          "exploitationLocality": "Località",
          "exploitationNumber": "Numero",
          "exploitationPostCode": "Codice postale",
          "exploitationStreet": "via",
          "horse": {
            "birthCountry": "Paese di nascita",
            "birthDate": "Data di nascita",
            "breeders": "Allevatori",
            "butcherFoal": "Puledro da macello",
            "castrated": "Castrato",
            "castrationDate": "Data della castrazione",
            "commercialName": "Nome commerciale",
            "detentionPlace": "Numero dell'istituto di detenzione",
            "detentionSince": "Data di arrivo del prodotto presso questo istituto",
            "dressColor": "Colore del mantello",
            "excludingFoodChain": "Escluso dalla catena alimentare",
            "excludingFoodChainDate": "Data di esclusione dalla catena alimentare",
            "father": {
              "name": "Nome del padre",
              "transponder": "Numero del transponder del padre",
              "ueln": "UELN del padre"
            },
            "gender": "Sesso",
            "hasOrigin": "Ha un pedigree",
            "holder": "ID CBC del proprietario",
            "holderTmp": "ID CBC temporaneo del proprietario",
            "id": "Codice",
            "identification": {
              "dna": "Capelli della criniera del prodotto",
              "identificationDate": "Data di identificazione",
              "identificationReturnDate": "Data di ritorno del file",
              "identifier": "Numero dell'ordinanza veterinaria",
              "identifierRemark": "Osservazione dell'identificatore",
              "motherDna": "Capelli della criniera della madre",
              "online": "Procedura",
              "textualReporting": "Descrizione testuale"
            },
            "mother": {
              "name": "Nome della madre",
              "transponder": "Numero del transponder della madre",
              "ueln": "UELN della madre"
            },
            "name": "Nome",
            "privateRemark": "Note private",
            "race": "Razza",
            "responsibleAgency": "Agenzia responsabile",
            "studbookId": "Codice",
            "transponder": "Numero del transponder",
            "type": "Tipo",
            "ueln": "UELN"
          },
          "horseBirthCountry": "Paese",
          "horseBirthdate": "Data di nascita",
          "horseBreeder1": "Id CBC dell'allevatore1",
          "horseBreeder2": "Id CBC dell' allevatore 2",
          "horseButcherFoal": "Puledro da macello",
          "horseCastrated": "Castrato",
          "horseCommercialName": "Nome commerciale",
          "horseDressColor": "Colore del mantello",
          "horseExcludingFoodChain": "Escluso dalla catena alimentare",
          "horseExcludingFoodChainDate": "Data di esclusione dalla catena alimentare",
          "horseFather": "UELN padre",
          "horseGender": "Sesso",
          "horseHolder": "Id CBC del detentore",
          "horseMother": "UELN madre",
          "horseName": "Nome",
          "horseOrigin": "Origine",
          "horseRace": "Razza",
          "horseReporting": "Segnalamento testuale",
          "horseTransponder1": "Numero Microchip",
          "horseType": "Tipo",
          "horseUeln": "UELN",
          "id": "Codice equide",
          "identificationDate": "Identificazione",
          "identificationDna": "Crini del prodotto",
          "identificationMotherDna": "Crini della madre",
          "identificationReturnDate": "Data di restituzione del dossier",
          "identificationType": "Tipo di domanda",
          "identifier": "N° ordine veterinario",
          "identifierRemark": "Note dell' identificatore",
          "online": "Domanda online",
          "privateRemark": "Annotazioni",
          "responsibleAgency": "UELN agenzia responsabile",
          "transmitterAgency": "UELN agenzia emittente"
        },
        "contact_creation_error": "Non si è potuto creare il contatto : %{error}",
        "contact_creation_success": "Contatto creato in HorseID con l'id %{cbc_id}",
        "contact_creation_unavailable": "Inserimento in HorseID non disponibile, provare più tardi",
        "contact_ignore_update_error": "Impossibile ignorare l' aggiornamento : %{error}",
        "contact_ignore_update_success": "Aggiornamento ignorato",
        "contact_update_error": "Impossibile aggiornare il contatto : %{error}",
        "contact_update_success": "Contatto aggiornato in HorseID",
        "contact_update_unavailable": "Aggiornamento in HorseID non disponibile, provare più tardi"
      },
      "index": {
        "search": {
          "placeholder": "Ricerca rapida: Nome, UELN o Codice"
        }
      },
      "new": {
        "horse_not_found": "Nessun equide trovato",
        "identification_already_requested": "Questo equide è già oggetto di una domanda d' identificazione",
        "page_title": "Nuova domanda di identificazione",
        "title": "Creare una nuova domanda di identificazione"
      },
      "sections": {
        "additional_requests": "Richieste supplementari",
        "birth_informations": "Informazioni sulla nascita",
        "breeder_details": "Coordinate dell'allevatore",
        "breeder_identity": "Identità dell'allevatore",
        "co_breeder_details": "Coordinate del co-allevatore",
        "co_breeder_identity": "Identità del co-allevatore",
        "co_owner_details": "Coordinate del co-proprietario",
        "co_owner_identity": "Identità del co-proprietario",
        "first_holder": "Detentore",
        "followup": "Monitoraggio",
        "holding_place": "Luogo d'identificazione",
        "identification_date": "Data d'idebntificazione",
        "identification_payment_html": "Procedura",
        "identification_return_date": "Data di ricevimento",
        "identifier": "Identificatore",
        "owner_details": "Coordinate del proprietario",
        "owner_identity": "Identità del proprietario",
        "product_identity": "Identità del prodotto",
        "product_informations": "Informazioni sul prodotto",
        "remarks": "Annotazioni dell' identificatore"
      },
      "show": {
        "page_title": "Domanda di identificazione",
        "title": "Domanda di identificazione"
      },
      "values": {
        "boolean": {
          "0": "No",
          "1": "Si",
          "false": "No",
          "true": "Si"
        },
        "sexe": {
          "0": "Maschio",
          "1": "Sconosciuto",
          "2": "Femmina",
          "female": "Femmina",
          "male": "Maschio"
        },
        "status": {
          "finalized": {
            "one": "Conclusa",
            "other": "Concluse"
          },
          "finalizing": {
            "one": "In via di conclusione",
            "other": "In via di conclusione"
          },
          "receiving": {
            "one": "In fase di ricevimento",
            "other": "In fase di ricevimento"
          },
          "to_finalize": {
            "one": "In corso",
            "other": "In corso"
          },
          "to_receive": {
            "one": "Convalidata",
            "other": "Convalidate"
          },
          "to_transfer": {
            "one": "In preparazione",
            "other": "In preparazione"
          },
          "to_validate": {
            "one": "Trasmessa",
            "other": "Tranmesse"
          },
          "transfering": {
            "one": "In via di trasmissione",
            "other": "In via di trasmissione"
          },
          "validating": {
            "one": "In fase di convalida",
            "other": "In fase di convalida"
          }
        }
      }
    },
    "layouts": {
      "application": {
        "sign_in": "Connettersi",
        "sign_out": "Disconnettersi"
      }
    },
    "locales": {
      "choose": "Scegliere la lingua",
      "de": "Tedesco",
      "en": "Inglese",
      "fr": "Francese",
      "it": "Italiano",
      "lang": "Lingua",
      "nl": "Olandese"
    },
    "modal": {
      "card_closed": {
        "intro_html": "<p>La carta non può più essere modificata,i dati sono stai trasmessi allo studbook ed il documento ufficiale della attestazione di monta  le sarà prossimamente inviato dallo studbook.</p>",
        "title": "La carta è stata chiusa"
      },
      "confirm_card_closing": {
        "intro_html": "<p>Si è sicuri di voler chiudere la carta ?  Questa non sarà più modificabile in seguito.</p>",
        "title": "Ne siete certi ?"
      },
      "confirm_convert_to_organisation": {
        "intro_html": "<p>Ne siete certi? Il cambiamento ad una organizzazione è irreversibile</p>",
        "title": "Ne siete certi ?"
      },
      "confirm_delete_birth_registration": {
        "intro_html": "<p>Questa dichiarazione di nascita sarà cancellata. Andranno perse tutte le informazioni codificate.</p>",
        "title": "Ne siete certi ?"
      },
      "confirm_delete_card": {
        "intro_html": "<p>Questo certificato di monta sarà eliminato. Tutte le informazioni codificate andranno perse.</p>",
        "title": "Ne siete certi ?"
      },
      "confirm_delete_delivrable": {
        "intro_html": "<p>Questa prestazione sarà eliminata. Tutte le informazioni codificate andranno perse.</p>",
        "title": "Ne siete certi ?"
      },
      "confirm_delete_passports": {
        "intro_html": "<p>Si è sicuri di voler eliminare tutti questi passaporti ?</p>",
        "title": "Ne siete certi ?"
      },
      "confirm_delete_signage": {
        "intro_html": "<p>Questo segnalamento sarà cancellato.</p>",
        "title": "Ne siete certi ?"
      },
      "connexion_expired": {
        "intro_html": "<p>La connessione e scaduta, ricaricare la pagina per continuare.<br />Contattateci se il problema persiste.</p>",
        "title": "Connessione scaduta"
      },
      "dna_controls_confirm": {
        "intro_html": "Attenzione: si stanno per convalidare uno o più controlli genetici!<br>Verrà aggiunta la data del controllo del DNA e convalidato il controllo di filiazione.<br> Dovrà essere inserito il riferimento dell' esame di laboratorio.",
        "title": "Ne siete certi ?"
      },
      "identifications_received_date": {
        "title": "Si prega di indicare la data di ricevimento"
      },
      "passport": {
        "title": "Dettagli del passaporto"
      },
      "passports_sent_date": {
        "title": "Si prega di indicare la data di invio"
      },
      "upload_signage": {
        "title": "Aggiungere un segnalamento grafico",
        "title_edit": "Modificare un segnalamento grafico"
      },
      "welcome": {
        "etalonnier_html": "<p>Semplificatevi la vita accedendoal massimo dei servizi ed informazioni nel più breve tempo, in qualsiasi momento.</p><p>Vedere i vostri stalloni e la loro produzione, ordinare e gestire le vostre carte di monta,controllare lo storico delle vostre monte e nascite, modificare i dati... Questi servizi in linea - e molti altri - sono direttamente accessibili tramite il tuo spazio personale</p>",
        "intro_html": "<p>Buongiorno %{username},</p><p>Lei ora è connesso al suo spazio personale su SireCloud.</p>",
        "title": "Benvenuto in SireCloud"
      }
    },
    "notes": {
      "flash": {
        "create": {
          "fail": "Impossibile salvare la nota",
          "success": "Nota registrata"
        },
        "destroy": {
          "fail": "Non si è potuto cancellare la nota",
          "success": "Nota cancellata"
        },
        "list": {
          "fail": "Non si è potuto caricare la nota",
          "no_more_notes": "Nessuna altra nota da visualizzare",
          "success": "Nota caricata"
        }
      },
      "values": {
        "privacy": {
          "0": "Pubblico",
          "1": "Condivisa",
          "2": "Interno",
          "3": "Privato"
        }
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Miliardi",
            "million": "Milioni",
            "quadrillion": "Biliardi",
            "thousand": "Mila",
            "trillion": "Bilioni",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Byte"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organisations": {
      "access_request": {
        "fields": {
          "firstname_label": "Nome",
          "name_label": "Cognome",
          "stallions_label": "Stalloni",
          "stallions_placeholder": "Indichi qui gli stalloni (nome e /o UELN) che desidera gestire nel suo spazio SireCloud",
          "studbook_ids_label": "Studbook"
        },
        "intro_html": "<p>Completi il modulo e lo convalidi.</p><p>Riceverà poco dopo una e-mail all' indirizzo indicato nel questionario. Conterrà un link che le permette di attivare il suo account.</p><p>Clicchi sil link indicato nell'e-mail. Quindi introduca la password che desidera utilizzare.</p>",
        "is_company": "L'utilizzatore rappresenta una società",
        "page_title": "SireCloud - Domanda di accesso",
        "subtitle": "Domanda di accesso personale",
        "title": "Nuovo ultilizzatore di SreClous"
      },
      "actions": {
        "confirm_destroy": "Si è sicuri di voler eliminare definitivamente questa organizzazione",
        "destroy": "Eliminare questa organizzazione"
      },
      "errors": {
        "404": {
          "back_link": "Tornare indietro",
          "current_database_warning_html": "Lei è attualmente connesso alla banca dati %{current_database}. Questo id potrebbe appartenere ad un altro database :",
          "header": "Errore 404",
          "home_link": "Home SireCloud",
          "model_text_html": "L'id %{id}non è corretto o non esiste nella banca dati.",
          "model_title": "%{model} non trovato",
          "page_title": "SireCloud - Errore 404",
          "text_html": "La pagina che cerca non esiste o l' URL non è corretto",
          "title": "Pagina non trovata"
        },
        "500": {
          "back_link": "Torare indietro",
          "header": "Errore 500",
          "home_link": "Home SireCloud",
          "page_title": "SireCloud - Errore 500",
          "text_html": "Si è prodotto un errore e la pagina richiesta non può essere visualizzata.<br /><br /> Ci è stato comunicato questo errore e lo correggeremo al più presto.<br /><br /> Se il problema persiste o lei ha bisogno di assistenza, non esiti a contattarci.",
          "title": "Errore - Questa pagina non funziona"
        }
      },
      "flash": {
        "create": {
          "fail": "L'organizzazione non può essere registrata",
          "success": "Nuova organizzazione registrata"
        },
        "destroy": {
          "success": "L'organizzazione è stata cancellata"
        },
        "update": {
          "fail": "Non si è potuto aggiornare le informazioni",
          "success": "Le informazioni della organizzazione sono state aggiornate"
        }
      },
      "horseid": {
        "attributes": {
          "organisation": {
            "address": {
              "box": "Casella",
              "country": "Paese",
              "locality": "Località",
              "number": "Numero",
              "postalCode": "Codice postale",
              "street": "Via"
            },
            "bceNumber": "Numero BCE",
            "email": "Email",
            "fax": "Fax",
            "gsm": "Cellulare",
            "language": "Lingua",
            "legalForm": "Forma giuridica",
            "name": "Nome",
            "office1": "Tel. ufficio 1",
            "office2": "Tel. ufficio 2",
            "phone1": "Tel. 1",
            "phone2": "Tel. 2",
            "responsible": "Responsabile",
            "studbookId": "Codice equide",
            "subjectToVat": "Soggetto a IVA"
          }
        }
      },
      "new": {
        "page_title": "Nuova organizzazione",
        "title": "Creazione di una nuova organizzazione"
      },
      "order_coupling": {
        "before_form_html": "<p>Per richiedere delle carte aggiuntive, completi tutti i campi del modulo sottostante e lo convalidi. </p>",
        "fields": {
          "quantity_label_html": "Numero di carte",
          "terms_label_html": "Ho letto ed accetto le <a data-toggle=\"modal\" data-target=\"#cguModal\" href=\"#\">condizioni generali</a> in merito alla richiesta delle carte di monta."
        },
        "intro_html": "<!--<p> Le carte di monta SBS (in formato elettronico a partire dal 2019) soni inviateai proprietari degli stallonise soddisfano le seguenti condizioni: </p> <ul> <li>Essere in ordine con la quota associativa per l' anno in corso.</li> <li>Detenere i diritti per l' utilizzazione del seme dello stallone.</li> <li>Avere pagato allo Stusbook SBS la quota annuale per lo stallone interessato.</li> </ul> <p> Le prime 25 carte di monta sono comprese nella quota annuale e vengono emesse gratuitamente. </p>-->",
        "section-title": {
          "order": "Ordine",
          "owner": "Identità del proprietario dello stallone",
          "owner_details": "Coordinate del proprietario dello stallone",
          "stallion": "Stallone",
          "terms": "Condizioni generali"
        },
        "subtitle": "Richiedere delle carte aggiuntive"
      },
      "pages": null
    },
    "passports": {
      "actions": {
        "confirm_destroy": "Si è sicuri di voler eliminare definitivamente questo passaporto",
        "destroy": "Eliminare questo passaporto"
      },
      "edit": {
        "page_title": "Modificare il passaporto"
      },
      "index": {
        "search": {
          "placeholder": "Richerca rapida: Nome, UELN o Codice"
        }
      },
      "new": {
        "page_title": "Nuovo passaporto"
      }
    },
    "passwords": {
      "create": {
        "description": "Riceverà prossimamente una e-mail contenente le istruzioni per la scelta della password",
        "description_cont": "Segua queste istruzioni per connettersi a SireCloud",
        "title": "Scelta della password"
      },
      "edit": {
        "description": "Creare una nuova password per accedere al suo spazio personale SireCloud",
        "title": "Nuova passaword"
      },
      "new": {
        "description": "Affinché possa ricevere le informazioni necessarie per scegliere la sua nuova password, la preghiamo di fornirci li suo indirizzo e-mail",
        "title": "Nuova password"
      }
    },
    "people": {
      "actions": {
        "confirm_destroy": "E' certo di voler cancellare definitivamente questa persona",
        "destroy": "Cancellare questa persona"
      },
      "civility": {
        "short_texts": {
          "1": "Sig.ra",
          "2": "Sig.na",
          "3": "Sig.",
          "4": "Dr.",
          "5": "Prof.",
          "6": "Avv.",
          "7": "Sig."
        },
        "texts": {
          "1": "Signora",
          "2": "Signorina",
          "3": "Signore",
          "4": "Dottore",
          "5": "Professore",
          "6": "Avvocato",
          "7": "Signore"
        }
      },
      "edit": {
        "page_title": "Modificare una persona",
        "title": "Modificare la persona"
      },
      "fee": {
        "ko": "Non pagato",
        "ok": "In ordine",
        "payed_on": "Pagato il %{date}"
      },
      "flash": {
        "create": {
          "success": "Nuova persona inserita"
        },
        "destroy": {
          "success": "La persona è stata cancellata"
        },
        "update": {
          "success": "Le informazioni sulla persona sono state aggiornate"
        }
      },
      "horseid": {
        "attributes": {
          "person": {
            "address": {
              "box": "Box",
              "country": "Paese",
              "locality": "Località",
              "number": "Numero",
              "postalCode": "Codice postale",
              "street": "Via"
            },
            "bceNumber": "Numero BCE",
            "birthDate": "Data di nascita",
            "civility": "Civiltà",
            "email": "Email",
            "fax": "Fax",
            "firstName": "Nome",
            "gsm": "Cellulare",
            "language": "Lingua",
            "lastName": "Cognome",
            "nationalRegister": "Numero nazionale",
            "office1": "Tel. ufficio 1",
            "office2": "Tel. ufficio 2",
            "phone1": "Tel. 1",
            "phone2": "Tel. 2",
            "studbookId": "Codice equide",
            "subjectToVat": "Soggetto a IVA"
          }
        }
      },
      "index": {
        "search": {
          "placeholder": "Ricerca una persona"
        }
      },
      "new": {
        "page_title": "Nuova persona",
        "title": "Creazione di una persona"
      },
      "tabs": {
        "addresses": {
          "title": "Indirizzo"
        },
        "contacts": {
          "title": "Coordinate"
        }
      },
      "unknown": "Sconosciuto"
    },
    "per_pagable": {
      "menu_title": "Pagina per",
      "title": "Risultati"
    },
    "popover": {
      "birth_registration": {
        "access_token_html": "Indicate qui il codice di accesso della carta di monta indicata sul documento",
        "identification_type_html": "<strong>Procedura online</strong> : L'identificazione si effettua esclusivamente online. Le informazioni ed i documenti le saranno inviati per e-mail. Ugualmente il pagamento si effettua in linea.<br/><strong>Procedura cartacea</strong> : Le informazioni ed i documenti le saranno inviati per posta. Il pagamento si effettua tramite bonifico bancario."
      },
      "cbc_horse": {
        "statuses": "<strong>In preparazione</strong> :creazione in preparazione da parte dello Studbook.<br /><strong>Conclusa</strong> : creazione trasferita e chiusa in HorseID."
      },
      "contact": {
        "type_html": "Ogni raggruppamento di persone viene considerata come \"associazione\""
      },
      "identification": {
        "statuses": "<strong>In preparazione</strong>: la richiesta è in fase di preparazione da parte del registro genealogico.<br/><strong>In corso</strong>: la richiesta è stata trasferita ed è in fase di chiusura in HorseID.<br/><strong>Finalizzata</strong>: la richiesta è stata chiusa in HorseID.<br/><strong>Annullata</strong>: la richiesta è stata annullata (ad esempio per un equino deceduto, ecc.)."
      }
    },
    "ppl_and_org": {
      "tabs": {
        "contact_details": {
          "title": "Coordinate"
        },
        "generalites": {
          "title": "Generalità"
        }
      },
      "user_access": {
        "no_access": "Nessun account utente",
        "title": "Accesso sirecloud"
      }
    },
    "replace_by": "Sostituire con",
    "sessions": {
      "form": {
        "forgot_password": "Password dimenticata ?",
        "sign_up": "Iscriversi"
      },
      "new": {
        "description": "Si connetta al suo spazio personale SireCloud con il suo indirizzo e-mail e la sua password",
        "new_user": {
          "cta": "Richieda il suo codice d' accesso",
          "intro": "E' un nuovo utente ?"
        },
        "title": "Connettersi"
      }
    },
    "shrine": {
      "max_size_extensions": "Il file deve pesare meno di %{max_size}. Estensione autorizzata : %{extensions}."
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Grazie e verificare gli errori seguenti:"
      },
      "no": "Nop",
      "required": {
        "mark": "*",
        "text": "obbligatorio"
      },
      "yes": "Si"
    },
    "since_date": "a partire da %{date}",
    "sortable": {
      "menu_title": "Cernere i risultati"
    },
    "studbook_mailer": {
      "horse_has_no_identification_to_finalize": {
        "subject": "L'equino %{horse_name} (%{horse_alias}) non ha un'identificazione da finalizzare"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " e ",
        "two_words_connector": " e ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a %d %b %Y, %H:%M:%S %z",
        "long": "%d %B %Y %H:%M",
        "real_short_date": "%d/%m/%y",
        "s3": "%d/%m/%Y %H:%M",
        "short": "%d %b %H:%M",
        "short_date": "%d/%m/%Y"
      },
      "pm": "pm"
    },
    "to_date": "fino a %{date}",
    "user": {
      "edit": {
        "page_title": "Modificare l' utente",
        "title": "Modificare l' accesso utente SireCloud"
      },
      "flash": {
        "connection_expired": "Connessione scaduta",
        "create": {
          "success": "Accesso utente creato"
        },
        "reset_password": {
          "success": "Email inviata per il cambiamento della password"
        },
        "update": {
          "fail": "Non è stato possibile salvare le modifiche",
          "success": "Utente aggiornato"
        }
      }
    },
    "user_mailer": {
      "welcome": {
        "cta": "Accedere a SireClous",
        "cta_help": "Se il link non funziona, potete copiare ed incollare l' indirizzo seguente sul vostro browser:",
        "intro_html": "<p>Buongiorno %{username},</p><p>Il suo accesso personale su SireCloud è stato creato.</p><p>Clicchi sul link qui sotto per attivare il suo accesso e scegliere la sua password.</p>",
        "subject": "Il suo accesso personale su SireCloud",
        "title": "Benvenuto su SireCloud"
      }
    },
    "users": {
      "new": {
        "sign_in": "Connettersi",
        "title": "Iscriversi"
      }
    },
    "views": {
      "form_warning": "I dati del questionario non sono ancora stati registrati",
      "pagination": {
        "first": "Prima pagina",
        "last": "Ultima pagina",
        "next": "Pagina successiva",
        "previous": "Pagina precedente",
        "truncate": "&hellip;"
      }
    }
  },
  "nl": {
    "activerecord": {
      "attributes": {
        "birth_registration": {
          "birth_country": "Land van geboorte",
          "birth_date": "Geboortedatum",
          "breed_category_id": "Type paardachtige",
          "breeder_data_addr1": "Adres",
          "breeder_data_addr2": "Adres 2",
          "breeder_data_box": "Bus",
          "breeder_data_civility": "Aanspreekvorm",
          "breeder_data_company": "Titel",
          "breeder_data_company_country": "Land",
          "breeder_data_company_number": "BTW nummer of Ondernemingsnummer",
          "breeder_data_company_vat_subject": "Onderworpen",
          "breeder_data_country_code": "Land",
          "breeder_data_email": "Email",
          "breeder_data_fax": "Fax",
          "breeder_data_firstname": "Voornaam",
          "breeder_data_lastname": "Naam",
          "breeder_data_legal_form_id": "Ondernemingsvorm",
          "breeder_data_locality": "Stad",
          "breeder_data_mobile": "GSM",
          "breeder_data_national_number": "Rijksregisternummer",
          "breeder_data_national_number_country": "Land",
          "breeder_data_number": "Nummer",
          "breeder_data_phone": "Telefoon",
          "breeder_data_vat": "BTW nummer of Ondernemingsnummer",
          "breeder_data_zip": "Postcode",
          "co_breeder_data_addr1": "Adres",
          "co_breeder_data_addr2": "Adres 2",
          "co_breeder_data_box": "Bus",
          "co_breeder_data_civility": "Aanspreekvorm",
          "co_breeder_data_company": "Titel",
          "co_breeder_data_company_country": "Land",
          "co_breeder_data_company_number": "BTW nummer of Ondernemingsnummer",
          "co_breeder_data_company_vat_subject": "Onderworpen",
          "co_breeder_data_country_code": "Land",
          "co_breeder_data_email": "Email",
          "co_breeder_data_fax": "Fax",
          "co_breeder_data_firstname": "Voornaam",
          "co_breeder_data_lastname": "Naam",
          "co_breeder_data_legal_form_id": "Rechtsvorm",
          "co_breeder_data_locality": "Stad",
          "co_breeder_data_mobile": "GSM",
          "co_breeder_data_national_number": "Rijksregisternummer",
          "co_breeder_data_national_number_country": "Land",
          "co_breeder_data_number": "Nummer",
          "co_breeder_data_phone": "Telefoon",
          "co_breeder_data_vat": "BTW nummer of Ondernemingsnummer",
          "co_breeder_data_zip": "Postcode",
          "co_owner_data_addr1": "Adres",
          "co_owner_data_addr2": "Adres 2",
          "co_owner_data_box": "Bus",
          "co_owner_data_civility": "Aanspreekvorm",
          "co_owner_data_company": "Titel",
          "co_owner_data_company_country": "Land",
          "co_owner_data_company_number": "BTW nummer of Ondernemingsnummer",
          "co_owner_data_company_vat_subject": "Onderworpen",
          "co_owner_data_country_code": "Land",
          "co_owner_data_email": "Email",
          "co_owner_data_fax": "Fax",
          "co_owner_data_firstname": "Voornaam",
          "co_owner_data_lastname": "Naam",
          "co_owner_data_legal_form_id": "Rechtsvorm",
          "co_owner_data_locality": "Stad",
          "co_owner_data_mobile": "GSM",
          "co_owner_data_national_number": "Rijksregisternummer",
          "co_owner_data_national_number_country": "Land",
          "co_owner_data_number": "Nummer",
          "co_owner_data_phone": "Telefoon",
          "co_owner_data_vat": "BTW nummer of Ondernemingsnummer",
          "co_owner_data_zip": "Postcode",
          "coupling_card_access_token": "URL dat toegang verleent tot de kaart",
          "coupling_card_copy": "Kopij van de dekkaart",
          "coupling_card_number": "Kaartnummer",
          "coupling_card_studbook": "Naam van het stamboek",
          "created_at": "Registratiedatum",
          "embryo_transfer": "Embryo Transplantatie",
          "global_breeder": "Fokker",
          "global_co_breeder": "Mede-fokker",
          "global_co_owner": "Mede-eigenaar",
          "global_holder": "Houder",
          "global_owner": "Eigenaar",
          "holder_addr1": "Adres van verblijf",
          "holder_data_addr1": "Adres",
          "holder_data_box": "Bus",
          "holder_data_cbc_id": "ID BCP",
          "holder_data_civility": "Aanspreekvorm",
          "holder_data_country_code": "Land",
          "holder_data_email": "Email",
          "holder_data_firstname": "Voornaam",
          "holder_data_lastname": "Naam",
          "holder_data_locality": "Stad",
          "holder_data_number": "Nummer",
          "holder_data_zip": "Postcode",
          "holder_firstname": "Naam van de houder",
          "holder_lastname": "Voornaam van de houder",
          "holding_place": "Plaats van uitbating",
          "holding_place_data_addr1": "Adres",
          "holding_place_data_box": "Bus",
          "holding_place_data_country_code": "Land",
          "holding_place_data_locality": "Stad",
          "holding_place_data_number": "Nummer",
          "holding_place_data_zip": "Postcode",
          "horse_database": "Studbook",
          "horse_database_id": "Studbook",
          "horse_private_alias": "Code",
          "identificatio_online": "Procedure",
          "identification_dna": "Manen fokproduct",
          "identification_mother_dna": "Manen moeder",
          "identification_type": "Identificatieaanvraag",
          "mare_birth_date": "Geboortedatum",
          "mare_code": "Code van de merrie",
          "mare_father_name": "Vader van de merrie",
          "mare_id": "Merrie",
          "mare_microchip": "Microchip van de merrie",
          "mare_mother_name": "Moeder van de merrie",
          "mare_name": "Naam van de merrie",
          "name": "Naam van het veulen",
          "no_coupling_card_data_agree": "Algemene voorwaarden",
          "owner_data_addr1": "Adres",
          "owner_data_addr2": "Adres 2",
          "owner_data_box": "Bus",
          "owner_data_civility": "Aanspreekvorm",
          "owner_data_company": "Titel",
          "owner_data_company_country": "Land",
          "owner_data_company_number": "BTW nummer of Ondernemingsnummer",
          "owner_data_company_vat_subject": "Onderworpen",
          "owner_data_country_code": "Land",
          "owner_data_email": "Email",
          "owner_data_fax": "Fax",
          "owner_data_firstname": "Voornaam",
          "owner_data_lastname": "Naam",
          "owner_data_legal_form_id": "Rechtsvorm",
          "owner_data_locality": "Stad",
          "owner_data_mobile": "GSM",
          "owner_data_national_number": "Rijksregisternummer",
          "owner_data_national_number_country": "Land",
          "owner_data_number": "Nummer",
          "owner_data_phone": "Telefoon",
          "owner_data_vat": "BTW nummer of Ondernemingsnummer",
          "owner_data_zip": "Postcode",
          "received_at": "Ontvangen op",
          "slaughter_foal": "Slachtveulen",
          "stalion_birth_date": "Geboortedatum",
          "stalion_code": "Code van de hengst",
          "stalion_father_name": "Vader van de hengst",
          "stalion_id": "Hengst",
          "stalion_microchip": "Microchip van de hengst",
          "stalion_mother_name": "Moeder van de hengst",
          "stalion_name": "Naam van de hengst",
          "station_be": "Land van verblijf",
          "stationed_in_belgium": "Is dit fokproduct gestationeerd in België",
          "validated_at": "Gevalideerd op",
          "vet_id": "Gekozen dierenarts voor de identificatie"
        },
        "cbc_horse": {
          "breeder": "Fokker",
          "breeder_cbc_id": "Fokker ID",
          "breeder_id": "Fokker",
          "co_breeder": "Mede-fokker",
          "co_breeder_cbc_id": "Mede-fokker ID",
          "co_breeder_id": "Mede-fokker",
          "global_breeder": "Fokker",
          "global_co_breeder": "Mede-fokker",
          "global_holder": "Houder",
          "holder": "Houder",
          "holder_cbc_id": "ID van de houder",
          "holder_id": "Houder",
          "holder_selected_email": "Email van de houder",
          "horseID_params_exploitationBox": "Bus",
          "horseID_params_exploitationCountry": "Land",
          "horseID_params_exploitationLocality": "Plaats",
          "horseID_params_exploitationNumber": "Nummer",
          "horseID_params_exploitationPostCode": "Postcode",
          "horseID_params_exploitationStreet": "Straat",
          "horseID_params_foreignAlias": "Originele alias",
          "horseID_params_horseDateHolding": "Startdatum verblijf",
          "horseID_params_horse_birthCountry": "Land",
          "horseID_params_horse_birthDate": "Geboortedatum",
          "horseID_params_horse_breeders": "Fokkers CBC ID",
          "horseID_params_horse_butcherFoal": "Slachtveulen",
          "horseID_params_horse_castrated": "Gecastreerd",
          "horseID_params_horse_commercialName": "Commerciële naam",
          "horseID_params_horse_detentionPlace": "Vestigingsnummer",
          "horseID_params_horse_detentionSince": "Datum van aankomst van het product in deze inrichting",
          "horseID_params_horse_dressColor": "Kleur haarkleed",
          "horseID_params_horse_excludingFoodChain": "Uitgesloten uit de voedselketen",
          "horseID_params_horse_excludingFoodChainDate": "Datum uitsluiting uit de voedselketen",
          "horseID_params_horse_father_ueln": "UELN vader",
          "horseID_params_horse_gender": "Geslacht",
          "horseID_params_horse_holder": "ID BCP van de houder",
          "horseID_params_horse_holderTmp": "ID BCP (temp.) van de houder",
          "horseID_params_horse_mother_ueln": "UELN moeder",
          "horseID_params_horse_name": "Naam",
          "horseID_params_horse_origin": "Afstamming",
          "horseID_params_horse_privateRemark": "Opmerkingen",
          "horseID_params_horse_race": "Ras",
          "horseID_params_horse_responsibleAgency": "UELN verantwoordelijk agentschap",
          "horseID_params_horse_studbookId": "Code paardachtige",
          "horseID_params_horse_transponder": "Nummer transponder",
          "horseID_params_horse_type": "Type",
          "horseID_params_horse_ueln": "UELN",
          "horseID_params_identification_textualReporting": "Signalement in tekstvorm",
          "horseID_params_transmitterAgency": "UELN uitgevend agentschap",
          "private_notes": "Private opmerkingen",
          "transfered_at": "Transfert",
          "validated_at": "Validering"
        },
        "contact/fee": {
          "amount": "Bedrag",
          "payed_at": "Datum betaling",
          "year": "Jaar"
        },
        "contact_detail": {
          "detail_type": "Type"
        },
        "coupling_card": {
          "carrying_mare": "Draagmoeder van het toekomstige veulen",
          "carrying_mare_code": "Code van de draagmoeder",
          "carrying_mare_microchip": "Microchip van de draagmoeder",
          "carrying_mare_name": "Naam van de draagmoeder",
          "completed_at": "Datum van afsluiting",
          "coupler": "Inseminator",
          "coupling_dates": {
            "one": "Datum van dekking",
            "other": "Data van dekkingen"
          },
          "coupling_mode": "Techniek van dekking",
          "embryo_transfer": "Embryo Transplantatie",
          "global_coupler": "Inseminator",
          "issue_date": "Openingsdatum",
          "mare": "Biologische moeder",
          "mare_birth_date": "Geboortedatum van de biologische moeder",
          "mare_code": "Code van de biologische moeder",
          "mare_father_name": "Vader van de biologische moeder",
          "mare_holder": "Eigenaar van de biologische moeder",
          "mare_holder_address_box": "Bus",
          "mare_holder_address_country_code": "Land",
          "mare_holder_address_line1": "Adres",
          "mare_holder_address_locality": "Plaats",
          "mare_holder_address_locality_name": "Plaats",
          "mare_holder_address_number": "N°",
          "mare_holder_address_vat": "BTW nummer (indien onderworpen)",
          "mare_holder_address_zip": "Postcode",
          "mare_holder_civility": "Aanspreektitel",
          "mare_holder_civility_id": "Aanspreektitel",
          "mare_holder_company": "Bedrijf/bedrijfsvorm",
          "mare_holder_company_country": "Land",
          "mare_holder_company_number": "BTW nummer of bedrijfsnummer",
          "mare_holder_company_vat_subject": "Onderworpen aan",
          "mare_holder_contacts": "Gegevens van de eigenaar",
          "mare_holder_email": "email",
          "mare_holder_fax": "Fax",
          "mare_holder_firstname": "Voornaam",
          "mare_holder_lastname": "Naam",
          "mare_holder_legal_form_id": "Juridische vorm",
          "mare_holder_mobile": "GSM",
          "mare_holder_national_number": "Nationaal registratienummer",
          "mare_holder_national_number_country": "Land",
          "mare_holder_phone": "Telefoon",
          "mare_id": "Merrie",
          "mare_microchip": "Microchip van de biologische moeder",
          "mare_mother_name": "Moeder van de biologische moeder",
          "mare_name": "Naam van de biologische moeder",
          "number": "Nummer",
          "stallion": "Hengst",
          "stallion_id": "Hengst",
          "year": "Dekseizoen"
        },
        "coupling_card/coupling": {
          "date": {
            "one": "Datum",
            "other": "Data"
          }
        },
        "coupling_card/credit": {
          "amount": "Aantal bestelde kaarten",
          "ordered_at": "Besteld op",
          "payed_at": "Betaald op",
          "quantity": "Hoeveelheid",
          "requester": "Besteld door",
          "year": "Dekseizoen"
        },
        "delivrable": {
          "payed": "Betaald",
          "payed_at": "Betaald op",
          "reference": "Referentie",
          "subject": "Onderwerp",
          "subject_id": "Onderwerp"
        },
        "horse": {
          "active_passport": "Paspoort",
          "approbation_country": "Land van goedkeuring",
          "approved": "Goedgekeurd",
          "birth_country": "Geboorteland",
          "birth_date": "Geboortedatum",
          "blood_type": "Bloedgroep",
          "blood_type_date": "Datum",
          "breed": "Ras",
          "breed_category": "Type",
          "breed_category_id": "Type",
          "breed_id": "Ras",
          "breeder": {
            "one": "Fokker",
            "other": "Fokkers"
          },
          "breeder_id": "Fokker",
          "breeding_card": "Dekbewijs/aangifte",
          "breeding_card_notes": "Notes",
          "breeding_card_num": "Kaartnummer",
          "castrated": "Gecastreerd",
          "cbc_id": "ID BCP",
          "cbc_id_final": "Definitief",
          "cbc_id_timestamp": "Datum",
          "coupler": {
            "one": "Toegelaten inseminator",
            "other": "Toegelaten inseminators"
          },
          "current_owner": {
            "one": "Huidige eigenaar",
            "other": "Huidige eigenaren"
          },
          "database_book": "Sectie",
          "database_book_section": "Onderafdeling",
          "death_date": "Datum van overlijden",
          "deceased": "Gestorven",
          "detention_place_code": "Vestigingsnummer",
          "detention_since": "Datum van aankomst van het product in deze inrichting",
          "dna": "DNA",
          "dna_date": "Datum van de test",
          "dna_filiation_compatible": "Compatibel",
          "dna_horse_hair": "Beschikbare manen",
          "dna_horse_hair_receival_date": "Datum ontvangst paardenhaar",
          "dna_result": "Resultaat",
          "dna_sent_date": "Datum verzending",
          "dress": "Haarkleur",
          "dress_id": "Haarkleur",
          "father": "Vader",
          "father_id": "Vader",
          "first_holder": "Eerste houder",
          "food_chain_excluded": "Uitgesloten van de voedselketen",
          "food_chain_exclusion_date": "Datum uitsluiting voedselketen",
          "graphic_signage": "Grafisch signalement",
          "horse_databases_book_id": "Afdeling",
          "horse_databases_book_section_id": "Onderafdeling",
          "horse_id": "HorseID",
          "microchip": "Microchip",
          "mother": "Moeder",
          "mother_id": "Moeder",
          "name": "Naam",
          "notes": "Memo",
          "origin_alias": "Originele code",
          "owner": {
            "one": "Eigenaar",
            "other": "Eigenaren"
          },
          "past_owner": {
            "one": "Vorige eigenaar",
            "other": "Vorige eigenaren"
          },
          "private_alias": "Code",
          "received_at": "Registratie datum",
          "registration_billed": "Gefactureerd",
          "registration_invoice_reference": "Referentie",
          "registration_paid": "Betaald",
          "registration_paid_at": "Datum",
          "sexe": "Geslacht",
          "slaughter_foal": "Slachtveulen",
          "structured_signage": "gecodificeerd signalement",
          "text_signage": "Tekstuele signalement",
          "ueln": "UELN",
          "ueln_country": "Land",
          "ueln_database": "Org.",
          "ueln_national_id": "Identificeerder",
          "ueln_type": "Generatie van UELN"
        },
        "horse/companion/sbs": {
          "frbse_id": "FRBSE ID"
        },
        "horse/labelling": {
          "end_at": "Einddatum",
          "label": {
            "one": "Label",
            "other": "Labels"
          },
          "start_at": "Begindatum"
        },
        "horse/microchip": {
          "date": "Activeer datum",
          "number": {
            "one": "Chipnummer",
            "other": "Chipnummers"
          },
          "unreadability_date": "Datum van onleesbaarheid",
          "unreadable": "Inactief"
        },
        "horse/ownership": {
          "from_date": "Startdatum",
          "is_main": "Hoofdeigenaar",
          "to_date": "Einddatum"
        },
        "horse/passport": {
          "cbc_id": "ID BCP",
          "exported_at": "Datum van uitgave",
          "global_recipient": "Bestemmeling",
          "number": "Nummer",
          "passport_type": "Type",
          "recipient": "Bestemmeling",
          "sent_at": "Verzenddatum"
        },
        "identification": {
          "breeder": "Fokker",
          "breeder_cbc_id": "ID van de fokker",
          "breeder_id": "Fokker",
          "co_breeder": "Mede-fokker",
          "co_breeder_cbc_id": "ID van de mede-fokker",
          "co_breeder_id": "Mede-fokker",
          "finalize_sent_at": "Afsluiting",
          "global_breeder": "Fokker",
          "global_co_breeder": "Mede-fokker",
          "global_holder": "Houder",
          "holder": "Houder",
          "holder_cbc_id": "ID van de houder",
          "holder_id": "Houder",
          "holder_selected_email": "Email van de houder",
          "horseID_params_exploitationBox": "Bus",
          "horseID_params_exploitationCountry": "Land",
          "horseID_params_exploitationLocality": "Plaats",
          "horseID_params_exploitationNumber": "Nummer",
          "horseID_params_exploitationPostCode": "Postcode",
          "horseID_params_exploitationStreet": "Straat",
          "horseID_params_horse_birthCountry": "Land",
          "horseID_params_horse_birthDate": "Geboortedatum",
          "horseID_params_horse_breeders": "Id BCP van fokkers",
          "horseID_params_horse_butcherFoal": "Slachtveulen",
          "horseID_params_horse_castrated": "Gecastreerd",
          "horseID_params_horse_commercialName": "Commerciële naam",
          "horseID_params_horse_detentionPlace": "Vestigingsnummer",
          "horseID_params_horse_detentionSince": "Datum van aankomst van het product in deze inrichting",
          "horseID_params_horse_dressColor": "Kleur haarkleed",
          "horseID_params_horse_excludingFoodChain": "Uitgesloten uit de voedselketen",
          "horseID_params_horse_excludingFoodChainDate": "Datum van uitsluiting uit de voedselketen",
          "horseID_params_horse_father_ueln": "UELN vader",
          "horseID_params_horse_gender": "Geslacht",
          "horseID_params_horse_holder": "Id BCP van de houder",
          "horseID_params_horse_mother_ueln": "UELN moeder",
          "horseID_params_horse_name": "Naam",
          "horseID_params_horse_origin": "Afstamming",
          "horseID_params_horse_privateRemark": "Opmerkingen",
          "horseID_params_horse_race": "Ras",
          "horseID_params_horse_responsibleAgency": "Verantwoordelijk agentschap UELN",
          "horseID_params_horse_studbookId": "Code paardachtige",
          "horseID_params_horse_transponder": "N° transponder",
          "horseID_params_horse_type": "Type",
          "horseID_params_horse_ueln": "UELN",
          "horseID_params_identificationType": "Type vraag",
          "horseID_params_identification_date": "Identificatie",
          "horseID_params_identification_dna": "Manen fokproduct",
          "horseID_params_identification_identifier": "Veterinair ordernummer",
          "horseID_params_identification_identifierRemark": "Opmerking van de identificeerder",
          "horseID_params_identification_motherDna": "Manen moeder",
          "horseID_params_identification_returnDate": "Datum van terugkeer dossier",
          "horseID_params_identification_textualReporting": "Tekstueel signalement",
          "horseID_params_online": "Onlinevraag",
          "horseID_params_transmitterAgency": "Agentschap van uitgave UELN",
          "identification_date": "Identificatie",
          "identification_dna": "Manen fokproduct",
          "identification_mother_dna": "Manen moeder",
          "identification_return_date": "Ontvangst",
          "identifier_notes": "Opmerkingen identificeerder",
          "private_notes": "Private opmerkingen",
          "request_sent_at": "Verzending Horse ID",
          "transfered_at": "Transfert",
          "validated_at": "Validering",
          "veterinarian": "Identificeerder",
          "veterinarian_id": "Identificeerder"
        },
        "note": {
          "annotated": "Onderwerp",
          "annotated_gid": "Onderwerp",
          "author": "Auteur",
          "body": "Tekst",
          "note_type": "Type",
          "privacy": "Vertrouwelijkheid",
          "written_at": "Geschreven op"
        },
        "organisation": {
          "bank_account": "IBAN (rekeningnummer)",
          "bank_bic": "Code BIC",
          "bank_name": "Bank",
          "cbc_id": "ID BCP",
          "horse_affix": "Affix",
          "horse_affix_protected": "Beschermd affix",
          "horse_affix_protected_since": "Sinds",
          "identity_complement": "Aanvulling",
          "legal_form_id": "Vorm",
          "locale": "Taal",
          "name": "Naam",
          "received_at": "Registratiedatum",
          "vat": "Nummer KBO",
          "vat_subject": "BTW plichtig"
        },
        "person": {
          "adresses": "Adressen",
          "bank_account": "IBAN (rekeningnummer)",
          "bank_bic": "BIC - code",
          "bank_name": "Bank",
          "cbc_id": "ID BCP",
          "civility": "Aanspreektitel",
          "civility_id": "Aanspreektitel",
          "company": "Bedrijf",
          "company_legal_form": "Juridische vorm",
          "company_vat_subject": "Onderworpen",
          "contacts": "Contacten",
          "fee_payed": "Bijdrage",
          "fees": "Bijdragen",
          "firstname": "Voornaam",
          "fonctions": "Functies",
          "horse_affix": "Affix",
          "horse_affix_protected": "Beschermd affix",
          "horse_affix_protected_since": "Sinds",
          "identity_complement": "Aanvulling",
          "lastname": "Naam",
          "locale": "Taal",
          "member_number": "Lidnummer",
          "national_number": "Nationaal registratienummer",
          "national_number_country": "Land",
          "notes": "Notes",
          "received_at": "Datum van registratie",
          "send_magazine": "Periodiek",
          "statuts": "Statuut",
          "vat": "VAT"
        },
        "person/enrollment": {
          "end_at": "Startdatum",
          "start_at": "Einddatum"
        },
        "person/organisation_position": {
          "main": "Belangrijkste contact",
          "position": "Functie/Rol"
        },
        "user": {
          "email": "email",
          "firstname": "Voornaam",
          "name": "Naam",
          "password": "Password",
          "password_confirmation": "Bevestig het passwoord",
          "username": "Username"
        }
      },
      "errors": {
        "messages": {
          "invalid_date": "Dit is geen geldige datum",
          "record_invalid": "Validatie mislukt: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Kan item niet verwijderen omdat afhankelijke %{record} bestaan",
            "has_one": "Kan item niet verwijderen omdat %{record} afhankelijk is"
          }
        },
        "models": {
          "horse/passport": {
            "attributes": {
              "number": {
                "number_changeable_only_if_new": "Het nummer van dit paspoort kan niet meer worden gewijzigd"
              },
              "origin": {
                "must_be_an_original": "Het paspoort van oorsprong dient een origineel te zijn"
              },
              "passport_type": {
                "only_one_original": "Deze paardachtige is reeds in het bezit van een origineel paspoort"
              },
              "status": {
                "format": "%{message}",
                "only_one_new": "Er is reeds een nieuw paspoort in de maak voor deze paardachtige"
              }
            }
          }
        }
      },
      "models": {
        "address": "Adres",
        "birth_registration": {
          "one": "Déclaration de naissance",
          "other": "Déclarations de naissance"
        },
        "cbc_horse": {
          "one": "Vraag tot aanmaken ",
          "other": "Vragen tot aanmaken"
        },
        "contact_detail": {
          "one": "Contactgegeven",
          "other": "Contactgegevens"
        },
        "coupling_card": {
          "one": "Dekkaart",
          "other": "Dekkaarten"
        },
        "coupling_card/coupling": {
          "one": "Dekking",
          "other": "Dekkingen"
        },
        "coupling_card/credit": {
          "one": "Bestelling dekkaarten",
          "other": "Bestellingen dekkaarten"
        },
        "horse": {
          "one": "Paardachtige",
          "other": "Paardachtigen"
        },
        "horse/passport": {
          "one": "Paspoort ",
          "other": "Paspoorten"
        },
        "horse_companion_sbs": "SBS",
        "identification": {
          "one": "Identificatie ",
          "other": "Identificaties"
        },
        "note": {
          "one": "Nota ",
          "other": "Nota's"
        },
        "organisation": {
          "one": "Organisatie ",
          "other": "Organisaties"
        },
        "person": {
          "one": "Persoon",
          "other": "Personen"
        },
        "person/position": {
          "one": "Functie",
          "other": "Functies"
        }
      }
    },
    "birth_mailer": {
      "new": {
        "intro": "Een nieuwe geboorte werd aangegeven op SireCloud",
        "subject": "Aangifte van geboorte"
      }
    },
    "birth_registrations": {
      "add_co_breeder": "Een mede-fokker toevoegen",
      "add_co_owner": "Een mede-eigenaar toevoegen",
      "breeder": "Fokker",
      "breeder_intro_html": "de fokker moet een enkele fysieke persoon of organisatie zijn. Om meer dan een persoon of organisatie in te geven voeg een mede-fokker toe",
      "card_questions": {
        "no_coupling_card": "Ik bezit geen dekkaart",
        "other_studbook": "Ik bezit een dekkaart uitgegeven door een ander Studbook",
        "studbook": "Ik bezit een dekkaart uitgegeven door het Studbook %{studbook}",
        "year": {
          "current": "Jaar van dekking %{year}",
          "older": "Jaar van dekking %{year} of eerder"
        }
      },
      "cbc_id": "ID BCP",
      "check_before_saving_html": "Check de aangifte alvorens registratie",
      "choice_title": "Bij welk Studbook wenst u uw fokproduct in te schrijven?",
      "chosen_identification_type": "Gekozen procedure",
      "co_breeder": "Mede-fokker",
      "co_owner": "Mede-eigenaar van het fokproduct",
      "father": "Vader",
      "first_holder": "Eerste houder",
      "flash": {
        "destroy": {
          "fail": "De geboorteverklaring kon niet verwijderd worden",
          "success": "Verwijderde geboorteverklaring"
        }
      },
      "form": {
        "help": {
          "microchip": "Indien het product reeds drager is van een microchip en u het nummer kent, vul dit hier in. Zo niet laat dit veld vrij",
          "slaughter_foal": "Product bestemd voor slachting tijdens zijn eerste levensjaar"
        },
        "hint": {
          "name_sbs": "Aandacht ! : Beginletter van de naam : R in 2023 (Q in 2022) – Maximaal 35 karakters en spaties"
        },
        "label_ext": {
          "microchip": "(indien reeds drager)"
        }
      },
      "general_conditions_agree_html": "Ik heb de algemene voorwaarden gelezen en aanvaard",
      "general_conditions_html": "Algemene voorwaarden Het sperma is eigendom van de eigenaar van de hengst, behalve bijzondere overeenkomt. De aangever wordt verondersteld te beschikken over alle nuttige rechten betreffende het sperma voor inseminatie. De aangever verbindt er zich onherroepelijk toe om het Studbook %{studbook} te garanderen voor elke actie die te maken heeft met wat voorafging, actie die tegen het Studbook %,{studbook} zou kunnen ingediend worden naar aanleiding van de inschrijving van het fokproduct in het genealogisch boek. Deze garantie betreft eender welke klacht die zou geformuleerd worden, in hoofdzaak, intresten en kosten, gerechtelijke kosten inbegrepen. De aangever zal tussenkomen vanaf de eerste vraag van het Studbook %{studbook} en dit voor elke procedure, in der minne of gerechtelijk en zal feiten en oorzaak op zich nemen.",
      "holding_place": "Plaats van uitbating",
      "identification": "Identificatie",
      "identification_intro_html": "<p class=\"m b-0\"> Om de centrale identificatie te waarborgen, hebben we nog 4 gegevens nodig: </p> <ul> <li>De keuze van de procedure. </li> <li>De gegevens van de houder (verplicht een enkele fysieke persoon en verantwoordelijk voor het dier). </li> <li>De plaats waar het product verblijft (om de opname van de signalisatie te vergemakkelijken). </li> <li>De gekozen dierenarts voor de identificatie en de opname van de signalisatie.</li> </ul>",
      "identification_is_not_in_belgium_html": "<p class=\"mb-0\">De paardachtige is niet gestationeerd in België</p>",
      "identification_not_in_belgium_html": "De paardachtige wordt dus niet opgenomen in de centrale databank in België",
      "identification_stationed_in_belgium_html": "Is het fokproduct gestationeerd in België?",
      "identification_stationed_to_first_holder_address": "Is het fokproduct gestationeerd op het adres van de houder?",
      "identification_type_html": "Welke identificatieprocedure wenst u te gebruiken?",
      "identification_type_tooltip_html": "De identificatie kan uitsluitend online. De informatie en de documenten worden u toegestuurd per email. De betaling dient eveneens online te gebeuren. Procedure op papier: de informatie en de documenten worden u toegestuurd per post. De betaling dient te geschieden per bankoverschrijving.",
      "identifier": "Identificeerder",
      "index": {
        "search": {
          "placeholder": "Snel opzoeken: naam van het fokproduct, van vader of moeder"
        }
      },
      "intro_html": "Uw product inschrijven in het genealogisch boek van het SBS en tegelijkertijd in de nationale database. Dit alles in slechts enkele klikken: makkelijk, snel en goedkoop.<br/>Wij begeleiden u stap voor stap …",
      "manual_encoding_html": "Manuele ingave <br/>van de gegevens",
      "mare": "Merrie",
      "mother": "Biologische moeder",
      "online_identification_type": "Online",
      "or": "OF",
      "owner": "Eigenaar van het fokproduct",
      "owner_intro_html": "De eigenaar dient een enkele fysieke persoon of organisatie te zijn. Om meer dan een persoon of organisatie in te geven voeg een mede-eigenaar toe",
      "paper_identification_type": "Papier",
      "parents_intro": "Zoek de vader en de moeder van uw product of geef hun informatie in.",
      "product": "Fokproduct",
      "product_intro": "Kies de naam van uw hengst- of merrieveulen. Vul het affix van uw fokkerij in (facultatief) en vervolledig de niet ingevulde velden",
      "sections": {
        "birth_informations": "Informatie betreffende de geboorte",
        "breeder_details": "Coördinaten van de fokker",
        "breeder_identity": "Identiteit van de fokker",
        "co_breeder_details": "Coördinaten van de mede-fokker",
        "co_breeder_identity": "Identiteit van de mede-fokker",
        "co_owner_details": "Coördinaten van de mede-eigenaar",
        "co_owner_identity": "Identiteit van de mede-eigenaar",
        "first_holder": "Houder",
        "holding_place": "Plaats van uitbating",
        "identification_date": "Datum identificatie",
        "identification_payment_html": "Procedure <small class=\"tekst-muted\">(48€ voor de procedure online - 55€ voor papieren procedure)</small>",
        "identification_return_date": "Ontvangstdatum",
        "identifier": "Identificeerder",
        "owner_details": "Coördinaten van de eigenaar",
        "owner_identity": "Identiteit van de eigenaar",
        "product_identity": "Identiteit van het product",
        "product_informations": "Informatie over het product",
        "remarks": "Opmerkingen identificeerder"
      },
      "select_contact_placeholder": "Een %{type} selecteren",
      "select_mare_html": "Een merrie <br/>zoeken en selecteren",
      "select_stallion_html": "Een hengst <br/> zoeken en selecteren",
      "show": {
        "congrat_html": "Gefeliciteerd!",
        "details_email_var": "een email",
        "details_html": "<p>Uw geboorteaangifte werd geregistreerd. <-p><p>U ontvangt spoedig %{var} met de uitnodiging het registratiebedrag van uw product in de nationale database te betalen (<a href=\"https://www.horseid.be\" target=\"_blank\">horseid.be</a>.</p><p> Handel dit snel af. Het identificatiedossier wordt u toegestuurd eens uw betaling ontvangen werd. Dit document dient u voor te leggen aan uw dierenarts.</p><p><strong>Attention:</strong>sommige zendingen kunnen in uw ongewenste mail (spam) terechtkomen. Check dan ook grondig uw email.</p>",
        "details_paper_var": "een brief",
        "end_btn": "Ik begrijp het"
      },
      "stallion": "Hengst",
      "steps": {
        "breeder": "Fokker",
        "confirm": "Samenvatting",
        "identification": "Identificatie",
        "microchip": "Microchip / microchip van de veulen",
        "owner": "Eigenaar",
        "parents": "Afstammingen",
        "product": "Veulen"
      },
      "validation": {
        "back": "Terug",
        "breeder": "Fokker",
        "click_to_add": "Klik om aan het gekozen contact toe te voegen",
        "co_breeder": "Mede-fokker",
        "co_owner": "Mede-eigenaar",
        "contact_address_added": "Toegevoegd adres",
        "contact_created": "Aangemaakt contact",
        "contact_detail_added": "Toegevoegd coördinaat",
        "continue": "Ga verder",
        "create_contact": "Een nieuw contact aanmaken",
        "create_horse": "Een nieuwe paardachtige aanmaken",
        "declaration": "Verklaring",
        "holder": "Houder",
        "horse_created": "Paardachtige aanmaken",
        "horse_creation_failed": "Het aanmaken van de paardachtige is mislukt",
        "mare": "Moeder",
        "no_contact_selected": "Geen contact gekozen",
        "no_horse_selected": "Geen paardachtige gekozen",
        "or": "of",
        "owner": "Eigenaar",
        "page_title": "Validering",
        "select_contact_label": "Zoek en selecteer een %{type} tussen de contacten",
        "select_contact_placeholder": "Selecteer een %{type}",
        "select_origin_label": "Zoek en selecteer een %{type} onder de paardachtigen",
        "select_origin_placeholder": "Selecteer een %{type}",
        "stalion": "Vader",
        "steps": {
          "contacts": "Contacten",
          "identification": "Identificatie",
          "origins": "Afstamming",
          "product": "Fokproduct"
        },
        "title": "Een geboorteaangifte valideren",
        "validation": "Validering",
        "values": {
          "types": {
            "0": "Geen identificatieaanvraag aanmaken",
            "1": "Een HorseID-vraag aanmaken zonder ze over te maken",
            "2": "Een HorseID-vraag aanmaken en overmaken",
            "3": "Opsturen zonder identificatie",
            "4": "Opsturen zonder identificatie en overmaken"
          }
        }
      },
      "values": {
        "embryo_transfer": {
          "0": "Nee",
          "1": "Ja",
          "false": "Nee",
          "true": "Ja"
        },
        "station_be": {
          "0": "Ander land",
          "1": "België",
          "false": "Ander land",
          "true": "België"
        },
        "status": {
          "to_validate": {
            "one": "Te valideren ",
            "other": "Te valideren"
          },
          "validated": {
            "one": "Gevalideerd ",
            "other": "Gevalideerd"
          }
        }
      }
    },
    "boolean": {
      "0": "Neen",
      "1": "Ja",
      "False": "Neen",
      "True": "Ja",
      "false": "Neen",
      "no": "Neen",
      "true": "Ja",
      "yes": "Ja"
    },
    "btn": {
      "labels": {
        "add": "Toevoegen",
        "add_coupling_card": "Een dekkaart aanmaken",
        "add_delivrable": "Een prestatie toevoegen",
        "add_note": "Een nota toe voegen",
        "back": "Terug",
        "birth_registration_continue": "Doorgaan",
        "cancel": "Annuleren",
        "cancel_delivrable": "Annuleren",
        "cancel_note": "Annuleren",
        "continue": "Ga verder",
        "copy_data_from_breeder": "De informatie van de fokker kopiëren",
        "copy_data_from_co_breeder": "van de mede-fokker",
        "copy_data_from_co_owner": "van de mede-eigenaar",
        "copy_data_from_owner": "van de eigenaar",
        "create_cbc_horse": "Overmaken zonder identificatie",
        "create_identification": "Een aanvraag opmaken",
        "create_on_horse_id": "Aanmaken op HorseID",
        "create_passport": "Een nieuw paspoort aanvragen",
        "date_picker_ok": "Kiezen",
        "delete_delivrable": "Verwijderen",
        "delete_note": "Verwijderen",
        "delete_passports": "Schrappen",
        "delete_signage": "Het signalement verwijderen",
        "dna_control_confirm": "Bevestigen",
        "dna_control_send": "Toesturen",
        "dna_controls_confirm": "De controles valideren",
        "edit_cbc_horse": "Wijzigen",
        "edit_identification": "Wijzigen",
        "edit_passport": "Dit paspoort wijzigen",
        "edit_signage": "Dit signalement wijzigen",
        "editer_delivrable": "Wijzigen",
        "export_passport": "Exporteren",
        "finalize_identification": "Afsluiten",
        "grant_coupling_card": "Nieuwe kaarten toekennen",
        "ignore_update_contact_horse_id": "Update negeren",
        "mark_passport_as_sent": "Aanduiden als zijnde verstuurd",
        "ok": "Ok",
        "order_coupling_card": "Nieuwe kaarten bestellen",
        "receive_identification": "Ontvangen",
        "resend_to_horse_id": "Opnieuw verzenden naar HorseID",
        "save_card": "Dekkaart registreren",
        "save_card_credit": "Opslaan de bestelling",
        "save_cbc_horse": "Registreren",
        "save_delivrable": "Registreren",
        "save_horse": "Opslaan",
        "save_identification": "Registreren",
        "save_note": "Registreren",
        "send_birth_registration": "Valideren en toesturen",
        "show_more_notes": "Andere nota's weergeven",
        "sign_out": "Afmelden",
        "sort": "Sorteren",
        "transfer_cbc_horse": "Transfereren",
        "transfer_identification": "Transfereren",
        "update_contact_horse_id": "Update opsturen",
        "upload_signage": "Een signalement toevoegen",
        "validate_and_create_birth_registration": "Valideren en aanmaken",
        "validate_birth_registration": "Valideren",
        "validate_cbc_horse": "Valideren",
        "validate_identification": "Valideren"
      },
      "tooltips": {
        "add_address": "Een adres toevoegen",
        "add_contact": "Een persoon toevoegen",
        "add_contact_detail": "Coördinaten toevoegen",
        "add_coupling_date": "Een datum van dekking toevoegen",
        "add_fee": "Een bijdrage toevoegen",
        "add_horse_owner": "Een mede-eigenaar bijvoegen",
        "add_labelling": "Een label toevoegen",
        "add_microchip": "Een microchip toevoegen",
        "add_organisation": "Een organisatie toevoegen",
        "add_person": "Een persoon toevoegen",
        "add_position": "Een functie toevoegen",
        "cbc_horse_cannot_transfer": "Deze opmaak kan niet worden getransfereerd",
        "cbc_horse_cannot_validate": "Deze opmaak kan niet worden gevalideerd",
        "complete_card": "Dekkaart afsluiten",
        "complete_card_and_birth": "Dekkaart afsluiten en geboorte aangeven",
        "delete_delivrable": "De prestatie verwijderen",
        "destroy_birth_registration": "De aangifte verwijderen",
        "destroy_card": "De kaart verwijderen",
        "download_contacts": "De contacten %{count} downloaden",
        "edit_card": "Dekkaart  wijzigen",
        "edit_delivrable": "De prestatie wijzigen",
        "edit_horse": "Deze paardachtige wijzigen",
        "edit_identification": "De vraag tot identificatie wijzigen",
        "edit_organisation": "Deze organisatie wijzigen",
        "edit_person": "Deze persoon wijzigen",
        "identification_cannot_finalize": "Deze identificatie kan niet worden afgesloten",
        "identification_cannot_receive": "Deze identificatie kan niet ontvangen worden",
        "identification_cannot_transfer": "Deze identificatie kan niet getransfereerd worden",
        "identification_cannot_validate": "Deze identificatie kan niet gevalideerd worden",
        "incomplete_pedigree": "Onvolledige stamboom",
        "missing_cbc_horse_attributes": "Volgende gegevens zijn nodig voor de identificatie: %{missing_attributes}",
        "missing_coupling_card_attributes": "Volgende informatie is nodig om de kaart af te sluiten: %{missing_attributes}",
        "missing_identification_attributes": "Volgende informatie is nodig voor de identificatie : %{missing_attributes}",
        "more_actions": "Andere acties",
        "new_birth": "Een geboorte aangeven",
        "new_contact": "Een nieuw contact registreren",
        "new_horse": "Een nieuwe persoon registreren",
        "new_identification": "Nieuwe aanvraag tot identificatie",
        "new_organisation": "Een nieuwe organisatie registreren",
        "new_person": "Een nieuwe persoon registreren",
        "print_birth": "De geboorteaangifte afdrukken",
        "remove_address": "Een adres verwijderen",
        "remove_contact": "Een persoon verwijderen",
        "remove_contact_detail": "Coördinaten verwijderen",
        "remove_coupling_date": "De dekdatum verwijderen",
        "remove_fee": "Een bijdrage schrappen",
        "remove_horse_owner": "Deze mede-eigenaar verwijderen",
        "remove_labelling": "Dit label schrappen",
        "remove_microchip": "Deze microchip verwijderen",
        "remove_organisation": "Deze organisatie schrappen",
        "remove_person": "Deze persoon schrappen",
        "remove_position": "Een functie verwijderen",
        "required": "verplicht",
        "show_coupling_card_copy": "Toon de kopij van de dekkaart",
        "show_identification": "De aanvraag tot identificatie bekijken",
        "sign_out": "SireCloud verlaten"
      }
    },
    "cbc_horses": {
      "edit": {
        "page_title": "Wijziging van het overmaken zonder identificatieaanvraag",
        "title": "Het overmaken wijzigen zonder identificatieaanvraag"
      },
      "flash": {
        "bulk": {
          "fail": "Geen enkele opmaak werd weerhouden"
        },
        "create": {
          "fail": "Geen enkele opmaak werd geregistreerd",
          "success": "Opmaak geregistreerd"
        },
        "transfered": {
          "fail": {
            "one": "De opmaak kon niet worden overgemaakt ",
            "other": "De opmaken konden niet worden overgemaakt"
          },
          "success": {
            "one": "Opmaak overgemaakt ",
            "other": "Opmaken overgemaakt"
          }
        },
        "transfering": {
          "fail": {
            "one": "De opmaak kon niet worden overgemaakt ",
            "other": "De opmaken konden niet worden overgemaakt"
          },
          "success": {
            "one": "Opmaak wordt overgemaakt ",
            "other": "Opmaken worden overgemaakt"
          }
        },
        "update": {
          "fail": "De opmaak kon niet worden bijgewerkt",
          "fail_horse_id": "Het aanmaken kon niet worden bijgewerkt in HorseID",
          "success": "Aanmaken bijgewerkt",
          "success_horse_id": "Aanmaken bijgewerkt in HorseID"
        },
        "validated": {
          "fail": {
            "one": "Het aanmaken kon niet worden gevalideerd ",
            "other": "Het aanmaken kon niet worden gevalideerd"
          },
          "success": {
            "one": "Aanmaken gevalideerd ",
            "other": "Aanmaken gevalideerd"
          }
        },
        "validating": {
          "fail": {
            "one": "Het aanmaken kon niet worden gevalideerd ",
            "other": "Het aanmaken kon niet worden gevalideerd"
          },
          "success": {
            "one": "Aanmaken in uitvoering ",
            "other": "Aanmaken in uitvoering"
          }
        }
      },
      "holder_selected_email_no_email": "geen email gekend voor de houder",
      "holder_selected_email_no_holder": "gelieve een houder te kiezen",
      "horseid": {
        "attributes": {
          "exploitationBox": "Bus",
          "exploitationCountry": "Land",
          "exploitationLocality": "Plaats",
          "exploitationNumber": "Nummer",
          "exploitationPostCode": "Postcode",
          "exploitationStreet": "Straat",
          "foreignAlias": "Origineel alias",
          "horse": {
            "birthCountry": "Land van geboorte",
            "birthDate": "Geboortedatum",
            "breeders": "Fokkers",
            "butcherFoal": "Slachtveulen",
            "castrated": "Gecastreerd",
            "castrationDate": "Datum van castratie",
            "commercialName": "Commerciële naam",
            "detentionPlace": "Vestigingsnummer",
            "detentionSince": "Datum van aankomst van het product op deze vestiging",
            "dressColor": "Vachtkleur",
            "excludingFoodChain": "Uitgesloten van de voedselketen",
            "excludingFoodChainDate": "Datum van uitsluiting van de voedselketen",
            "father": {
              "name": "Naam van de vader",
              "transponder": "Transpondernummer van de vader",
              "ueln": "UELN van de vader"
            },
            "gender": "Geslacht",
            "hasOrigin": "Heeft een stamboom",
            "holder": "CBC-ID van de houder",
            "holderTmp": "Tijdelijke CBC-ID van de houder",
            "id": "Code",
            "mother": {
              "name": "Naam van de moeder",
              "transponder": "Transpondernummer van de moeder",
              "ueln": "UELN van de moeder"
            },
            "name": "Naam",
            "privateRemark": "Prive-opmerkingen",
            "race": "Ras",
            "responsibleAgency": "Verantwoordelijke instantie",
            "studbookId": "Code",
            "transponder": "Transpondernummer",
            "type": "Type",
            "ueln": "UELN"
          },
          "horseBirthCountry": "Land",
          "horseBirthdate": "Geboortedatum",
          "horseBreeder1": "Id BCP van fokker 1",
          "horseBreeder2": "Id BCP van fokker 2",
          "horseButcherFoal": "Slachtveulen",
          "horseCastrated": "Gecastreerd",
          "horseCommercialName": "Commerciële naam",
          "horseDateExcludingFoodChain": "Datum uitsluiting uit de voedselketen",
          "horseDateHolding": "Startdatum van verblijf bij de houder",
          "horseDressColor": "Kleur haarkleed",
          "horseExcludingFoodChain": "Uitgesloten uit de voedselketen",
          "horseFather": "UELN vader",
          "horseGender": "Geslacht",
          "horseHolder": "Id BCP van de houder",
          "horseMother": "UELN moeder",
          "horseName": "Naam",
          "horseOrigin": "Afstamming",
          "horseRace": "Ras",
          "horseTransponder1": "Nummer transponder",
          "horseType": "Type",
          "horseUeln": "UELN",
          "id": "Code paardachtige",
          "privateRemark": "Opmerkingen",
          "responsibleAgency": "UELN verantwoordelijk agentschap",
          "transmitterAgency": "UELN uitgevend agentschap"
        }
      },
      "index": {
        "search": {
          "placeholder": "Snel zoeken: naam, UELN of Code"
        }
      },
      "new": {
        "horse_not_found": "Geen paardachtige gevonden",
        "page_title": "Transfer zonder identificatieaanvraag",
        "title": "Transfereren zonder identificatieaanvraag",
        "transfert_already_requested": "Deze paardachtige werd reeds getransfereerd"
      },
      "sections": {
        "birth_informations": "Informatie over de geboorte",
        "breeder_details": "Gegevens van de fokker",
        "breeder_identity": "Identiteit van de fokker",
        "co_breeder_details": "Gegevens van de mede-fokker",
        "co_breeder_identity": "Identiteit van de mede-fokker",
        "co_owner_details": "Gegevens van de mede-eigenaar",
        "co_owner_identity": "Identiteit van de mede-eigenaar",
        "first_holder": "Houder",
        "followup": "Follow up",
        "holding_place": "Plaats van uitbating",
        "owner_details": "Gegevens van de eigenaar",
        "owner_identity": "Identiteit van de eigenaar",
        "product_identity": "Identiteit van het fokproduct",
        "product_informations": "Informatie over het fokproduct"
      },
      "show": {
        "page_title": "Transfer zonder identificatieaanvraag",
        "title": "Transfer zonder vraag tot identificatie"
      },
      "values": {
        "boolean": {
          "0": "Neen",
          "1": "Ja",
          "false": "Neen",
          "true": "Ja"
        },
        "sexe": {
          "0": "Mannelijk",
          "1": "Onbekend",
          "2": "Vrouwelijk"
        },
        "status": {
          "to_transfer": {
            "one": "In voorbereiding ",
            "other": "In voorbereiding"
          },
          "to_validate": {
            "one": "Getransfereerd ",
            "other": "Getransfereerd"
          },
          "transfering": {
            "one": "Transfer in uitvoering ",
            "other": "Transfer in uitvoering"
          },
          "validated": {
            "one": "Afgesloten ",
            "other": "Afgesloten"
          },
          "validating": {
            "one": "Validering in uitvoering ",
            "other": "Validering in uitvoering"
          }
        }
      }
    },
    "clearance": {
      "models": {
        "clearance_mailer": {
          "change_password": "SireCloud.be - Wijziging paswoord"
        }
      }
    },
    "clearance_mailer": {
      "change_password": {
        "closing": "Indien u niets gevraagd hebt, mag u deze e-mail negeren. Uw paswoord werd niet gewijzigd",
        "opening": "Iemand, hopende dat u het bent, vroeg ons om u deze e-mail te sturen met de vraag uw paswoord te wijzigen"
      }
    },
    "companies": {
      "legal_form": {
        "short_texts": {
          "1": "VZW",
          "10": "BVBA",
          "11": "BV",
          "12": "VZW",
          "13": "Comm.V.",
          "2": "BVBA",
          "3": "CVA",
          "4": "LLC",
          "5": "NV",
          "6": "NV",
          "7": "LV",
          "8": "SARL",
          "9": "VOF"
        },
        "texts": {
          "1": "Vereniging zonder Winstoogmerk",
          "10": "BVBA",
          "11": "BV",
          "12": "VZW",
          "13": "Gewone Commanditaire Vennootschap",
          "2": "Besloten Vennootschap met Beperkte Aansprakelijkheid",
          "3": "Commanditaire Vennootschap op Aandelen",
          "4": "Limited Liability Company",
          "5": "Naamloze Vennootschap",
          "6": "Naamloze Vennootschap",
          "7": "LV",
          "8": "BVBA",
          "9": "VOF"
        }
      }
    },
    "contact_details": {
      "type_suffixes": {
        "tel_home": "Verblijfplaats",
        "tel_work": "Werk"
      }
    },
    "contacts": {
      "data_fields": {
        "breeder": {
          "addr1": "Straat",
          "address": "Adres",
          "adress": "Fokker's adres",
          "box": "Bus",
          "civility": "Aanspreekvorm",
          "company": "Titel",
          "company_country": "Land",
          "company_number": "B.T.W. nummer of Ondernemingsnummer",
          "company_vat_subject": "Onderworpen",
          "country_code": "Land",
          "firstname": "Fokker's voornaam",
          "lastname": "Fokker's naam",
          "legal_form_id": "Rechtsvorm",
          "locality": "Stad",
          "national_number": "Nationaal nummer fokker",
          "national_number_country": "Land",
          "number": "N°",
          "zip": "Postcode"
        },
        "co_breeder": {
          "addr1": "Straat",
          "address": "Adres van de mede-fokker",
          "box": "Bus",
          "civility": "Aanspreekvorm",
          "company": "Titel",
          "company_country": "Land",
          "company_number": "B.T.W. nummer of Ondernemingsnummer",
          "company_vat_subject": "Onderworpen",
          "country_code": "Land",
          "firstname": "Voornaam van de  mede-fokker",
          "lastname": "Naam van de mede-fokker",
          "legal_form_id": "Rechtsvorm",
          "locality": "Stad",
          "national_number": "Nationaal nummer van de mede-fokker",
          "national_number_country": "Land",
          "number": "N°",
          "zip": "Postcode"
        },
        "co_owner": {
          "addr1": "Straat",
          "adress": "Adres van de mede-eigenaar",
          "box": "Bus",
          "civility": "Aanspreekvorm",
          "company": "Titel",
          "company_country": "Land",
          "company_number": "B.T.W. nummer of Ondernemingsnummer",
          "company_vat_subject": "Onderworpen",
          "country_code": "Land",
          "firstname": "Voornaam van de mede-eigenaar",
          "lastname": "Naam van de mede-eigenaar",
          "legal_form_id": "Rechtsvorm",
          "locality": "Stad",
          "national_number": "Nationaal nummer mede-eigenaar",
          "national_number_country": "Land",
          "number": "N°",
          "zip": "Postcode"
        },
        "default": {
          "addr1": "Straat",
          "address": "Adres",
          "adress": "Adres",
          "box": "Bus",
          "cbc_id": "CBC ID",
          "civility": "Aanspreekvorm",
          "company": "Titel",
          "company_country": "Land",
          "company_number": "B.T.W. nummer of Ondernemingsnummer",
          "company_vat_subject": "Onderworpen",
          "country_code": "Land",
          "email": "Email",
          "fax": "Fax",
          "firstname": "Voornaam",
          "lastname": "Naam",
          "legal_form_id": "Rechtsvorm",
          "locality": "Stad",
          "mobile": "GSM",
          "national_number": "Nationaal registratienummer",
          "national_number_country": "Land",
          "national_register": "Nationaal register",
          "number": "N°",
          "phone": "Telefoon",
          "vat": "BTW nummer (indien onderworpen)",
          "zip": "Postcode"
        },
        "owner": {
          "addr1": "Straat",
          "address": "Adres van de eigenaar",
          "box": "Bus",
          "civility": "Aanspreekvorm",
          "company": "Titel",
          "company_country": "Land",
          "company_number": "B.T.W. nummer of Ondernemingsnummer",
          "company_vat_subject": "Onderworpen",
          "country_code": "Land",
          "email": "Email",
          "fax": "Fax",
          "firstname": "Voornaam van de eigenaar",
          "lastname": "Naam van de eigenaar",
          "legal_form_id": "Rechtsvorm",
          "locality": "Stad",
          "mobile": "Gsm",
          "national_number": "Nationaal nummer eigenaar",
          "national_number_country": "Land",
          "number": "N°",
          "phone": "Telefoon",
          "zip": "Postcode"
        }
      },
      "index": {
        "search": {
          "placeholder": "Een persoon of een organisatie opzoeken"
        }
      },
      "membership_status": {
        "inactive": "Niet actief",
        "inactive_member": "Niet actief lid"
      },
      "new": {
        "page_title": "Nieuw contact",
        "title": "Aanmaken nieuw contact"
      },
      "one": "Contact",
      "other": "Contacten",
      "personne_morale": "Bedrijf/organisatie",
      "personne_phyisque": "Fysieke persoon",
      "positions": {
        "members": "Statuut",
        "others": "Functies"
      },
      "section-title": {
        "admin": "Bank",
        "contact_details": "Gegevens",
        "identity": "Identiteit",
        "membership": "Lidmaatschap",
        "org_people": {
          "one": "Verantwoordelijke ",
          "other": "Verantwoordelijken"
        }
      },
      "values": {
        "address_types": {
          "billing": "Facturatie",
          "main": "Hoofdadres",
          "post": "Postadres"
        }
      }
    },
    "coupling_cards": {
      "available": {
        "one": "Beschikbare kaart",
        "other": "Beschikbare kaarten"
      },
      "available_full": {
        "one": "%{count} kaart beschikbaar op %{total}",
        "other": "%{count} kaarten beschikbaar op  %{total}"
      },
      "closed": {
        "one": "Afgesloten kaart",
        "other": "Afgesloten kaarten"
      },
      "credit_count": {
        "one": "Beschikbare kaart",
        "other": "Beschikbare kaarten"
      },
      "edit": {
        "page_title": "Wijziging dekkaart",
        "title": "Dekkaart wijzigen"
      },
      "flash": {
        "create": {
          "fail": "Registratiefout. De kaart werd niet aangemaakt.",
          "success": "Aangemaakte kaart"
        },
        "destroy": {
          "fail": "De kaart kon niet worden geschrapt",
          "success": "Dekkaart geschrapt"
        },
        "update": {
          "fail": "De kaart kon niet worden bijgewerkt",
          "success": "Bijgewerkte kaart"
        }
      },
      "form": {
        "confirm_help": "Check de gegevens alvorens de kaart te registreren"
      },
      "hints": {
        "mare_code": "Code Stambook of UELN",
        "stalion_code": "Code Studbook of UELN"
      },
      "index": {
        "my_cards": "Mijn dekkaarten",
        "search": {
          "placeholder": "Een kaart opzoeken per nummer of per hengst"
        }
      },
      "mare": {
        "extend_label": "Biologische moeder van toekomstig veulen"
      },
      "new": {
        "page_title": "Nieuwe dekkaart",
        "title": "Een  nieuwe dekkaart openen"
      },
      "open": {
        "one": "Openstaande kaart",
        "other": "Openstaande kaarten"
      },
      "paper": {
        "one": "Open papieren kaart",
        "other": "Open papieren kaarten"
      },
      "section-title": {
        "owner_identity": "Identiteit eigenaar biologische moeder"
      },
      "show": {
        "add_mare": "Een merrie toevoegen",
        "add_stallion": "Een hengst toevoegen",
        "can_not_close": "De kaart kan niet worden afgesloten",
        "missing_mare": "Geen ingegeven merrie",
        "missing_stallion": "Geen geselecteerde hengst"
      },
      "steps": {
        "confirm": "Samenvatting",
        "coupling": "Dekking",
        "owner": "Eigenaar",
        "parents": "Afstamming"
      },
      "used": {
        "one": "Gebruikte kaart ",
        "other": "Gebruikte kaarten"
      },
      "values": {
        "coupling_mode": {
          "1": "Natuurlijke dekking",
          "2": "K.I. vers sperma",
          "3": "K.I. diepgevroren sperma",
          "4": "K.I. Diepgevroren sperma"
        },
        "status": {
          "0": "Openingsdatum",
          "1": "Afsluitingsdatum",
          "2": "Afsluitingsdatum",
          "3": "Afsluitingsdatum",
          "closed": {
            "one": "Afgesloten ",
            "other": "Afgesloten"
          },
          "open": {
            "one": "Ouverte",
            "other": "Ouvertes"
          }
        }
      }
    },
    "date": {
      "abbr_day_names": [
        "zo",
        "ma",
        "di",
        "wo",
        "do",
        "vr",
        "za"
      ],
      "abbr_month_names": [
        null,
        "jan",
        "feb",
        "mrt",
        "apr",
        "mei",
        "jun",
        "jul",
        "aug",
        "sep",
        "okt",
        "nov",
        "dec"
      ],
      "day_names": [
        "zondag",
        "maandag",
        "dinsdag",
        "woensdag",
        "donderdag",
        "vrijdag",
        "zaterdag"
      ],
      "formats": {
        "default": "%d/%m/%Y",
        "long": "%e %B %Y",
        "mat_datepicker": "dd/mm/yyyy",
        "real_short_date": "%d/%m/%y",
        "short": "%e %b",
        "short_date": "%d/%m/%Y"
      },
      "month_names": [
        null,
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "ongeveer een uur",
          "other": "ongeveer %{count} uur"
        },
        "about_x_months": {
          "one": "ongeveer een maand",
          "other": "ongeveer %{count} maanden"
        },
        "about_x_years": {
          "one": "ongeveer een jaar",
          "other": "ongeveer %{count} jaar"
        },
        "almost_x_years": {
          "one": "bijna een jaar",
          "other": "bijna %{count} jaar"
        },
        "half_a_minute": "een halve minuut",
        "less_than_x_minutes": {
          "one": "minder dan een minuut",
          "other": "minder dan %{count} minuten",
          "zero": "minder dan een minuut "
        },
        "less_than_x_seconds": {
          "one": "minder dan een seconde",
          "other": "minder dan %{count} seconden",
          "zero": "minder dan een seconde "
        },
        "over_x_years": {
          "one": "meer dan een jaar",
          "other": "meer dan %{count} jaar"
        },
        "x_days": {
          "one": "1 dag",
          "other": "%{count} dagen"
        },
        "x_minutes": {
          "one": "1 minuut",
          "other": "%{count} minuten"
        },
        "x_months": {
          "one": "1 maand",
          "other": "%{count} maanden"
        },
        "x_seconds": {
          "one": "1 seconde",
          "other": "%{count} seconden"
        },
        "x_years": {
          "one": "1 jaar",
          "other": "%{count} jaar"
        }
      },
      "prompts": {
        "day": "dag",
        "hour": "uur",
        "minute": "minuut",
        "month": "maand",
        "second": "seconde",
        "year": "jaar"
      }
    },
    "delivrable_subjects": {
      "Divers": "Diversen",
      "Duplicata": "Duplicata",
      "Détermination ADN": "Bepaling DNA",
      "Inscription": "Inschrijving",
      "Transfert propriété": "Overdracht eigendom"
    },
    "delivrables": {
      "flash": {
        "create": {
          "fail": "De prestatie kon niet worden geregistreerd",
          "success": "Prestatie geregistreerd"
        },
        "destroy": {
          "fail": "De prestatie kon niet worden geschrapt",
          "success": "Geschrapte prestatie"
        },
        "edit": {
          "fail": "De prestatie kon niet worden gewijzigd",
          "success": "Gewijzigde prestatie"
        }
      },
      "no_delivrable": "Geen prestatie"
    },
    "documents": {
      "dias": {
        "documents_error": "Er liep iets fout tijdens de weergave van de documenten. Probeer later opnieuw.",
        "documents_unavailable": "De dienst DiAS is momenteel niet beschikbaar, de documenten kunnen niet weergegeven worden.",
        "errors": {
          "3003": "Opsturen lukt niet: de paardachtige heeft nog geen dossier in DiAS"
        },
        "signages_error": "Er liep iets fout tijdens de weergave van de grafische signalementen. Probeer later opnieuw",
        "signages_unavailable": "De dienst DiAS is momenteel niet beschikbaar, de grafische signalementen kunnen niet weergegeven worden."
      },
      "flash": {
        "create": {
          "signage": {
            "fail": "Het grafisch signalement kon niet toegevoegd worden",
            "success": "Grafisch signalement toegevoegd"
          }
        },
        "destroy": {
          "signage": {
            "fail": "Het grafisch signalement kon niet worden verwijderd",
            "success": "Grafisch signalement verwijderd"
          }
        },
        "update": {
          "signage": {
            "fail": "Het grafisch signalement kon niet gewijzigd worden",
            "success": "Grafisch signalement gewijzigd"
          }
        }
      }
    },
    "errors": {
      "file": {
        "extension_exclusion": "uitbreiding kan niet aanwezig zijn in de lijst: %{list}",
        "extension_inclusion": "uitbreiding dient aanwezig te zijn in de lijst : %{list}",
        "max_dimensions": "afmetingen moeten kleiner zijn dan %{dims}",
        "max_height": "hoogte moet kleiner zijn dan %{max}",
        "max_size": "maat moet kleiner zijn dan %{max}",
        "max_width": "breedte moet kleiner zijn dan %{max}",
        "mime_type_exclusion": "type mag niet aanwezig zijn in de lijst: %{list}",
        "mime_type_inclusion": "type dient aanwezig te zijn in de lijst:  %{list}",
        "min_dimensions": "afmetingen moeten groter zijn dan %{dims}",
        "min_height": "hoogte moet groter zijn dan %{min}",
        "min_size": "maat moet groter zijn dan %{min}",
        "min_width": "breedte moet groter zijn dan %{min}"
      },
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "moet worden geaccepteerd",
        "blank": "moet opgegeven zijn",
        "confirmation": "komt niet overeen met %{attribute}",
        "empty": "moet opgegeven zijn",
        "equal_to": "moet gelijk zijn aan %{count}",
        "even": "moet even zijn",
        "exclusion": "is gereserveerd",
        "greater_than": "moet groter zijn dan %{count}",
        "greater_than_or_equal_to": "moet groter dan of gelijk zijn aan %{count}",
        "has_duplicates": "bevat duplicaten",
        "improbable_phone": "is een ongeldig nummer",
        "in_the_future": "mag niet in de toekomst zijn",
        "inclusion": "is niet in de lijst opgenomen",
        "invalid": "is ongeldig",
        "less_than": "moet minder zijn dan %{count}",
        "less_than_or_equal_to": "moet minder dan of gelijk zijn aan %{count}",
        "model_invalid": "Validatie mislukt: %{errors}",
        "not_a_number": "is geen getal",
        "not_an_integer": "moet een geheel getal zijn",
        "odd": "moet oneven zijn",
        "other_than": "moet anders zijn dan %{count}",
        "present": "moet leeg zijn",
        "required": "moet bestaan",
        "taken": "is al in gebruik",
        "too_long": {
          "one": "is te lang (maximaal %{count} teken)",
          "other": "is te lang (maximaal %{count} tekens)"
        },
        "too_short": {
          "one": "is te kort (minimaal %{count} teken)",
          "other": "is te kort (minimaal %{count} tekens)"
        },
        "wrong_length": {
          "one": "heeft onjuiste lengte (moet 1 teken lang zijn)",
          "other": "heeft onjuiste lengte (moet %{count} tekens lang zijn)"
        }
      },
      "template": {
        "body": "Er zijn problemen met de volgende velden:",
        "header": {
          "one": "%{model} niet opgeslagen: 1 fout gevonden",
          "other": "%{model} niet opgeslagen: %{count} fouten gevonden"
        }
      }
    },
    "file_input": {
      "placeholder": "Een bestand kiezen"
    },
    "filterable": {
      "filter": {
        "contact": {
          "function": "Functie",
          "member": {
            "0": "Niet-lid",
            "1": "Lid"
          },
          "status": "Statuut",
          "type": {
            "organisation": "Organisatie",
            "person": "Persoon"
          }
        },
        "sex": {
          "1": "Mannelijke paardachtigen",
          "2": "Vrouwelijke paardachtigen"
        },
        "status": "Statuut",
        "studbook": "Studbook",
        "year": "Jaar"
      },
      "filter_by": {
        "sex": "Filteren per geslacht",
        "year": "Filteren per jaar"
      },
      "indiferrent": "Allen",
      "menu_title": "De resultaten filteren",
      "no_filter": "Geen filter"
    },
    "flashes": {
      "failure_after_create": "Email of paswoord zijn niet correct",
      "failure_after_update": "Het paswoord mag niet leeg blijven",
      "failure_when_access_denied": "U kan geen toegang krijgen tot deze functie",
      "failure_when_forbidden": "Dank om URL na te gaan of probeer opnieuw",
      "failure_when_not_signed_in": "U dient in te loggen om SireCloud te gebruiken"
    },
    "food_chain": "Voedselketen",
    "food_chain_excluded_on": "Uitgesloten op %{date}",
    "form_message": {
      "flash": {
        "sent": "Aanvraag opgestuurd"
      }
    },
    "from_date_to_date": "van %{from_date} tot %{to_date}",
    "help_texts": {
      "etalonnier": {
        "coupling_cards_index_html": "U kan hier uw openstaande en afgesloten dekkaarten beheren. Om nieuwe kaarten aan te kopen of op te stellen, ga naar de fiche van de betreffende hengst, bladwijzer \"Voortplanting\". Om een geboorte aan te geven, afspraak op een afgesloten kaart.",
        "horses_index_html": "Om de fiche en de informatie betreffende een hengst in  te kijken, klikt u op een hengst in onderstaande tabel. Om een geboorte aan te geven, kan u terecht op pagina \"cards\" Mijn dekkaarten"
      }
    },
    "helpers": {
      "dates": {
        "from_to_date": "van %{start_date} tot %{end_date}",
        "since_date": "sinds %{date}",
        "to_date": "tot %{date}"
      },
      "label": {
        "password": {
          "email": "Email"
        },
        "password_reset": {
          "password": "Kies een paswoord"
        }
      },
      "page_entries_info": {
        "more_pages": {
          "display_entries": "%{entry_name} %{first} te %{last} op %{total}"
        },
        "one_page": {
          "display_entries": {
            "one": "1 %{entry_name}",
            "other": "%{count} %{entry_name}",
            "zero": "Geen"
          }
        }
      },
      "select": {
        "prompt": "Maak een keuze"
      },
      "submit": {
        "create": "%{model} toevoegen",
        "password": {
          "submit": "Maak uw paswoord aan"
        },
        "password_reset": {
          "submit": "Registreer het paswoord"
        },
        "session": {
          "submit": "Inloggen"
        },
        "submit": "%{model} opslaan",
        "update": "%{model} bijwerken",
        "user": {
          "create": "Zich inschrijven"
        }
      }
    },
    "horse/passport": {
      "flash": {
        "create": {
          "fail": "Het paspoort kon niet worden aangemaakt",
          "success": "Paspoort aangemaakt"
        },
        "delete": {
          "fail": {
            "one": "Het paspoort kon niet worden verwijderd ",
            "other": "De paspoorten konden niet worden verwijderd"
          },
          "success": {
            "one": "Het paspoort werd verwijderd ",
            "other": "De paspoorten werden verwijderd"
          }
        },
        "export": {
          "fail": {
            "one": "Het paspoort kon niet geëxporteerd worden ",
            "other": "De paspoorten konden niet geëxporteerd worden"
          },
          "success": {
            "one": "Paspoort geëxporteerd ",
            "other": "Paspoorten geëxporteerd"
          }
        },
        "mark_as_sent": {
          "fail": "Ongeldige verzenddatum",
          "success": {
            "one": "Paspoort aangeduid als zijnde verzonden ",
            "other": "Paspoorten aangeduid als zijnde verzonden"
          }
        },
        "none_selected": "Geen paspoort geselecteerd",
        "update": {
          "fail": "Het paspoort kon niet worden  bijgewerkt",
          "success": "Bijgewerkt paspoort"
        }
      },
      "values": {
        "passport_type": {
          "duplicata": "Duplicaat",
          "original": "Origineel",
          "replacement": "Vervanging"
        },
        "status": {
          "cancelled": {
            "one": "Geannuleerd ",
            "other": "Geannuleerd"
          },
          "exported": {
            "one": "Te versturen ",
            "other": "Te versturen"
          },
          "lost": {
            "one": "Verloren ",
            "other": "Verloren"
          },
          "sent": {
            "one": "Verstuurd ",
            "other": "Verstuurd"
          },
          "to_export": {
            "one": "Nieuw ",
            "other": "Te exporteren"
          }
        }
      }
    },
    "horse_id": {
      "contact_pending_validation": "%{id} (temp.)"
    },
    "horses": {
      "actions": {
        "confirm_destroy": "Bent u zeker dat u deze paardachtige definitief wil verwijderen",
        "destroy": "Deze paardachtige schrappen"
      },
      "birth_country_unknown": "< Onbekend >",
      "death_mutation_will_be_final": "Dood kan niet ongedaan worden gemaakt",
      "decorator": {
        "born_on": {
          "0_html": "<span class=\"born-on-intro\">geboren op </span>%{date}",
          "1_html": "<span class=\"born-on-intro\">geboren op </span>%{date}",
          "2_html": "<span class=\"born-on-intro\">geboren op </span>%{date}"
        },
        "born_on_no_date_html": "Geboortedatum onbekend"
      },
      "dna_controls": {
        "page_title": "Genetische controles",
        "search": {
          "placeholder": "Snel opzoeken: Naam, UELN of Code"
        },
        "title": "Genetische controles"
      },
      "dna_status": {
        "to_confirm": {
          "one": "Verstuurd op %{sent_date}",
          "other": "Te bevestigen"
        },
        "to_send": {
          "one": "Te verzenden ",
          "other": "Te verzenden"
        }
      },
      "edit": {
        "page_title": "Een paardachtige wijzigen",
        "title": "De registratie van een paardachtige wijzigen"
      },
      "flash": {
        "create": {
          "fail": "Het aanmaken van de paardachtige is mislukt",
          "success": "Nieuw geregistreerde paardachtige"
        },
        "destroy": {
          "not_with_chidlren": "Een paardachtige met geregistreerde productie kan niet verwijderd worden.",
          "success": "De paardachtige werd verwijderd"
        },
        "dna_control_confirmed": {
          "fail": {
            "one": "De controle kon niet bevestigd worden ",
            "other": "De controles konden niet bevestigd worden"
          },
          "success": {
            "one": "Bevestigde controle ",
            "other": "Bevestigde controles"
          }
        },
        "dna_control_sent": {
          "fail": {
            "one": "De controle kon niet verzonden worden ",
            "other": "De controles konden niet verzonden worden"
          },
          "success": {
            "one": "Controle verstuurd ",
            "other": "Controles verstuurd"
          }
        },
        "update": {
          "success": "De gegevens over de paardachtige werden bijgewerkt"
        }
      },
      "food_chain_mutation_will_be_final": "Uitsluiting kan niet ongedaan worden gemaakt",
      "hints": {
        "ueln_type": "Een code is vereist"
      },
      "index": {
        "my_stallions": "Mijn hengsten",
        "search": {
          "advanced": {
            "title": "Voortgezette opzoekingen"
          },
          "placeholder": "Snel opzoeken: Naam, UELN of Code"
        }
      },
      "new": {
        "page_title": "Nieuwe paardachtige",
        "title": "Registratie nieuwe paardachtige"
      },
      "placeholders": {
        "search_mare": "Een merrie opzoeken op Naam, Code of Microchip",
        "search_stallion": "Een hengst opzoeken op Naam, Code of Microchip",
        "select_mare": "Een merrie selecteren",
        "select_stallion": "Een hengst selecteren"
      },
      "section-title": {
        "active_passport": "Actief paspoort",
        "additional_info": "Bijkomende inlichtingen",
        "identification": "Identificatie",
        "numbering": "Nummering",
        "previous_passports": "Oude paspoorten",
        "registration": "Inschrijving",
        "structured_signage": "Signalement gecodificeerd"
      },
      "stallions": "Hengsten",
      "tabs": {
        "delivrables": {
          "title": "Facturatie"
        },
        "documents": {
          "title": "Documenten"
        },
        "generalites": {
          "Identification": "Identificatie",
          "title": "Algemeen"
        },
        "identification": {
          "title": "Identificatie"
        },
        "labels": {
          "title": "Labels"
        },
        "passports": {
          "title": "Paspoorten"
        },
        "pedigree": {
          "title": "Stamboom"
        },
        "people": {
          "title": "Personen"
        },
        "production": {
          "title": "Productie"
        },
        "reproduction": {
          "title": "Voortplanting"
        },
        "results": {
          "title": "Resultaten"
        },
        "signalement": {
          "title": "Signalement"
        }
      },
      "unknown": "onbekend",
      "values": {
        "dna_filiation_compatible": {
          "ko": "Verwantschap niet compatibel",
          "ok": "Verwantschap compatibel"
        },
        "food_chain": {
          "0": "Behouden",
          "1": "Uitgesloten",
          "false": "Behouden",
          "true": "Uitgesloten"
        },
        "sexe": {
          "0": "Onbekend",
          "1": "Mannelijk",
          "2": "Vrouwelijk"
        },
        "sexe_short": {
          "0": "?",
          "1": "M",
          "2": "V"
        },
        "ueln_types": {
          "auto": "Ja, definitief nummer",
          "manual": "Neen, handmatige invoer",
          "temp": "Ja, tijdelijk n°"
        }
      }
    },
    "identifications": {
      "edit": {
        "page_title": "Wijziging identificatieaanvraag",
        "title": "De identificatieaanvraag wijzigen"
      },
      "flash": {
        "bulk": {
          "fail": "Geen enkele identificatie geselecteerd"
        },
        "create": {
          "fail": "De aanvraag werd niet aangemaakt",
          "success": "Identificatieaanvraag geregistreerd"
        },
        "finalized": {
          "fail": {
            "one": "De aanvraag kon niet afgesloten worden ",
            "other": "De aanvragen konden niet afgesloten worden"
          },
          "success": {
            "one": "Afgesloten aanvraag ",
            "other": "Afgesloten aanvragen"
          }
        },
        "finalizing": {
          "fail": {
            "one": "De aanvraag kon niet afgesloten worden ",
            "other": "De aanvragen konden niet afgesloten worden"
          },
          "success": {
            "one": "Aanvraag in afsluiting ",
            "other": "Aanvragen in afsluiting"
          }
        },
        "received": {
          "fail": {
            "one": "De aanvraag kon niet in ontvangst genomen worden ",
            "other": "De aanvragen konden niet in ontvangst genomen worden"
          },
          "success": {
            "one": "Aanvraag ontvangen ",
            "other": "Aanvragen ontvangen"
          }
        },
        "receiving": {
          "fail": {
            "one": "De aanvraag kon niet in ontvangst genomen worden ",
            "other": "De aanvragen konden niet in ontvangst genomen worden"
          },
          "success": {
            "one": "De ontvangst van de aanvraag wordt vervolgd    ",
            "other": "De ontvangst van de aanvragen wordt vervolgd"
          }
        },
        "transfered": {
          "fail": {
            "one": "De aanvraag kon niet worden overgedragen  ",
            "other": "De aanvragen konden niet worden overgedragen"
          },
          "success": {
            "one": "Overgemaakte aanvraag ",
            "other": "Overgemaakte aanvragen"
          }
        },
        "transfering": {
          "fail": {
            "one": "De aanvraag kon niet worden overgedragen  ",
            "other": "De aanvragen konden niet worden overgedragen"
          },
          "success": {
            "one": "De aanvraag wordt overgedragen ",
            "other": "De aanvragen worden overgedragen"
          }
        },
        "update": {
          "fail": "De aanvraag kon niet updated worden",
          "fail_horse_id": "De aanvraag kon niet worden bijgewerkt in HorseID",
          "success": "Aanvraag om te updaten",
          "success_horse_id": "Aanvraag om te updaten en te updaten in HorseID"
        },
        "validated": {
          "fail": {
            "one": "De aanvraag kon niet worden gevalideerd ",
            "other": "De aanvragen konden niet worden gevalideerd"
          },
          "success": {
            "one": "Aanvraag gevalideerd ",
            "other": "Aanvragen gevalideerd"
          }
        },
        "validating": {
          "fail": {
            "one": "De aanvraag kon niet worden gevalideerd ",
            "other": "De aanvragen konden niet worden gevalideerd"
          },
          "success": {
            "one": "Validering van de aanvraag is in uitvoering ",
            "other": "Validering van de aanvragen is in uitvoering"
          }
        }
      },
      "holder_selected_email_no_email": "geen email gekend voor de houder",
      "holder_selected_email_no_holder": "gelieve een houder te kiezen",
      "horseid": {
        "attributes": {
          "exploitationBox": "Bus",
          "exploitationCountry": "Land",
          "exploitationLocality": "Plaats",
          "exploitationNumber": "Nummer",
          "exploitationPostCode": "Postcode",
          "exploitationStreet": "Straat",
          "horse": {
            "birthCountry": "Land van geboorte",
            "birthDate": "Geboortedatum",
            "breeders": "Fokkers",
            "butcherFoal": "Slachtveulen",
            "castrated": "Gecastreerd",
            "castrationDate": "Datum van castratie",
            "commercialName": "Commerciële naam",
            "detentionPlace": "Vestigingsnummer",
            "detentionSince": "Datum van aankomst van het product op deze vestiging",
            "dressColor": "Vachtkleur",
            "excludingFoodChain": "Uitgesloten van de voedselketen",
            "excludingFoodChainDate": "Datum van uitsluiting van de voedselketen",
            "father": {
              "name": "Naam van de vader",
              "transponder": "Transpondernummer van de vader",
              "ueln": "UELN van de vader"
            },
            "gender": "Geslacht",
            "hasOrigin": "Heeft een stamboom",
            "holder": "CBC-ID van de houder",
            "holderTmp": "Tijdelijke CBC-ID van de houder",
            "id": "Code",
            "identification": {
              "dna": "Productmanenharen",
              "identificationDate": "Datum van identificatie",
              "identificationReturnDate": "Datum van terugkeer van het dossier",
              "identifier": "Dierenartsbestellingsnummer",
              "identifierRemark": "Opmerking van de identificatie",
              "motherDna": "Manenharen van de moeder",
              "online": "Procedure",
              "textualReporting": "Beschrijving in tekstuele vorm"
            },
            "mother": {
              "name": "Naam van de moeder",
              "transponder": "Transpondernummer van de moeder",
              "ueln": "UELN van de moeder"
            },
            "name": "Naam",
            "privateRemark": "Prive-opmerkingen",
            "race": "Ras",
            "responsibleAgency": "Verantwoordelijke instantie",
            "studbookId": "Code",
            "transponder": "Transpondernummer",
            "type": "Type",
            "ueln": "UELN"
          },
          "horseBirthCountry": "Land",
          "horseBirthdate": "Geboortedatum",
          "horseBreeder1": "Id BCP van fokker 1",
          "horseBreeder2": "Id BCP van fokker 2",
          "horseButcherFoal": "Slachtveulen",
          "horseCastrated": "Gecastreerd",
          "horseCommercialName": "Commerciële naam",
          "horseDressColor": "Kleur haarkleed",
          "horseExcludingFoodChain": "Uitgesloten uit de voedselketen",
          "horseExcludingFoodChainDate": "Datum van uitsluiting uit de voedselketen",
          "horseFather": "UELN vader",
          "horseGender": "Geslacht",
          "horseHolder": "Id BCP van de houder",
          "horseMother": "UELN moeder",
          "horseName": "Naam",
          "horseOrigin": "Afstamming",
          "horseRace": "Ras",
          "horseReporting": "Tekstuele signalisatie",
          "horseTransponder1": "N° transponder",
          "horseType": "Type",
          "horseUeln": "UELN",
          "id": "Code paardachtige",
          "identificationDate": "Identificatie",
          "identificationDna": "Manen fokproduct",
          "identificationMotherDna": "Manen moeder",
          "identificationReturnDate": "Datum terugzending dossier",
          "identificationType": "Type aanvraag",
          "identifier": "Ordernummer dierenarts",
          "identifierRemark": "Opmerking van de identificeerder",
          "online": "Online aanvraag",
          "privateRemark": "Opmerkingen",
          "responsibleAgency": "UELN verantwoordelijk agentschap",
          "transmitterAgency": "UELN agentschap van uitgave"
        },
        "contact_creation_error": "Het contact kon niet worden aangemaakt: %{error}",
        "contact_creation_success": "Contact aangemaakt in HorseID met de id %{cbc_id}",
        "contact_creation_unavailable": "Toevoeging in HorseID is niet beschikbaar. Probeer later opnieuw",
        "contact_ignore_update_error": "De update kon niet genegeerd worden: %{error}",
        "contact_ignore_update_success": "Update genegeerd",
        "contact_update_error": "Het contact kon niet bijgewerkt worden: %{error}",
        "contact_update_success": "Contact bijgewerkt in HorseID",
        "contact_update_unavailable": "Bijwerken in HorseID is onbeschikbaar. Gelieve later te proberen."
      },
      "index": {
        "search": {
          "placeholder": "Snel opzoeken: Naam, UELN of Code"
        }
      },
      "new": {
        "horse_not_found": "Geen paardachtige gevonden",
        "identification_already_requested": "Voor deze paardachtige bestaat reeds een vraag tot identificatie",
        "page_title": "Nieuwe aanvraag tot identificatie",
        "title": "Een nieuwe aanvraag tot identificatie aanmaken"
      },
      "sections": {
        "additional_requests": "Bijkomende aanvragen",
        "birth_informations": "Informatie aangaande de geboorte",
        "breeder_details": "Gegevens van de fokker",
        "breeder_identity": "Identiteit van de fokker",
        "co_breeder_details": "Gegevens van de mede - fokker",
        "co_breeder_identity": "Identiteit van de mede - fokker",
        "co_owner_details": "Gegevens van de mede-eigenaar",
        "co_owner_identity": "Identiteit van de mede-eigenaar",
        "first_holder": "Houder",
        "followup": "Opvolging",
        "holding_place": "Plaats van uitbating",
        "identification_date": "Datum van identificatie",
        "identification_payment_html": "Procedure",
        "identification_return_date": "Ontvangstdatum",
        "identifier": "Identificeerder",
        "owner_details": "Gegevens van de eigenaar",
        "owner_identity": "Identiteit van de eigenaar",
        "product_identity": "Identiteit van het fokproduct",
        "product_informations": "Informatie betreffende het fokproduct",
        "remarks": "Opmerkingen identificeerder"
      },
      "show": {
        "page_title": "Aanvraag tot identificatie",
        "title": "Aanvraag tot identificatie"
      },
      "values": {
        "boolean": {
          "0": "Neen",
          "1": "Ja",
          "false": "Neen",
          "true": "Ja"
        },
        "sexe": {
          "0": "Mannelijk paard",
          "1": "Onbekend",
          "2": "Vrouwelijk paard",
          "female": "Vrouwelijk",
          "male": "Mannelijk"
        },
        "status": {
          "finalized": {
            "one": "Afgesloten ",
            "other": "Afgesloten"
          },
          "finalizing": {
            "one": "Afsluiting in uitvoering ",
            "other": "Afsluiting in uitvoering"
          },
          "receiving": {
            "one": "Ontvangst in uitvoering ",
            "other": "Ontvangst in uitvoering"
          },
          "to_finalize": {
            "one": "In uitvoering",
            "other": "In uitvoering"
          },
          "to_receive": {
            "one": "Gevalideerd ",
            "other": "Gevalideerd"
          },
          "to_transfer": {
            "one": "In voorbereiding ",
            "other": "In voorbereiding"
          },
          "to_validate": {
            "one": "Getransfereerd ",
            "other": "Getransfereerd"
          },
          "transfering": {
            "one": "Transfer in uitvoering ",
            "other": "Transfer in uitvoering"
          },
          "validating": {
            "one": "Validering in uitvoering ",
            "other": "Validering in uitvoering"
          }
        }
      }
    },
    "layouts": {
      "application": {
        "sign_in": "Inloggen",
        "sign_out": "Uitloggen"
      }
    },
    "locales": {
      "choose": "Kies uw taal",
      "de": "Duits",
      "en": "Engels",
      "fr": "Frans",
      "it": "Italiaans",
      "lang": "Taal",
      "nl": "Nederlands"
    },
    "modal": {
      "card_closed": {
        "intro_html": "<p>De kaart kan niet langer gewijzigd worden, de gegevens werden doorgegeven aan het studbook en het officiële dekbewijs zal u binnenkort overgemaakt worden door het studbook.</p>",
        "title": "De kaart werd afgesloten"
      },
      "confirm_card_closing": {
        "intro_html": "<p>Bent u zeker dat u de kaart wil afsluiten? Deze kan achteraf niet meer gewijzigd worden.</p>",
        "title": "Weet u het zeker?"
      },
      "confirm_convert_to_organisation": {
        "intro_html": "<p>Weet u het zeker? De omzetting in Organisatie is onomkeerbaar.</p>",
        "title": "Weet u het zeker?"
      },
      "confirm_delete_birth_registration": {
        "intro_html": "<p>Deze geboorteaangifte zal verwijderd worden. U zal alle ingegeven informatie verliezen. </p>",
        "title": "Weet u het zeker?"
      },
      "confirm_delete_card": {
        "intro_html": "<p>Dit dekbewijs zal verwijderd worden. U zal alle ingegeven informatie verliezen. </p>",
        "title": "Weet u het zeker?"
      },
      "confirm_delete_delivrable": {
        "intro_html": "<p>Deze prestatie zal verwijderd worden. U zal alle ingegeven informatie verliezen. </p>",
        "title": "Weet u het zeker?"
      },
      "confirm_delete_passports": {
        "intro_html": "<p>Bent u zeker al deze paspoorten te willen verwijderen? </p>",
        "title": "Bent u zeker?"
      },
      "confirm_delete_signage": {
        "intro_html": "<p>Dit signalement zal verwijderd worden. </p>",
        "title": "Weet u het zeker?"
      },
      "connexion_expired": {
        "intro_html": "<p>De connectie is verlopen. Gelieve de pagina te uploaden om verder te gaan. <br />Contacteer ons als het probleem blijft bestaan.</p>",
        "title": "Connectie verlopen"
      },
      "dna_controls_confirm": {
        "intro_html": "Opgelet: U gaat een of meerdere genetische controles valideren!<br>De datum van de DNA-controle wordt toegevoegd en de afstammingscompatibiliteit wordt gevalideerd. <br>U hoeft alleen nog de referentie van het laboratoriumonderzoek in te geven.",
        "title": "Weet u het zeker?"
      },
      "identifications_received_date": {
        "title": "Gelieve de ontvangstdatum aan te duiden"
      },
      "passport": {
        "title": "Details van het paspoort"
      },
      "passports_sent_date": {
        "title": "Gelieve de verzenddatum aan te duiden"
      },
      "upload_signage": {
        "title": "Een grafisch signalement toevoegen",
        "title_edit": "Een grafisch signalement wijzigen"
      },
      "welcome": {
        "etalonnier_html": "Maak het u gemakkelijk en krijg toegang tot een maximum aan diensten en informatie in een minimum van tijd wanneer u het wenst. Kijk naar uw hengsten en hun nakomelingen, bestel en beheer uw dekkaarten, kijk naar uw historiek van dekkingen en geboortes, wijzig de gegevens … Deze online diensten - en vele andere - zijn onmiddellijk toegankelijk via uw persoonlijke ruimte.",
        "intro_html": "<p>Hallo %{username},</p><p>U bent nu verbonden met uw persoonlijke pagina op SireCloud.</p>",
        "title": "Welkom bij SireCloud"
      }
    },
    "notes": {
      "flash": {
        "create": {
          "fail": "De nota kon niet worden geregistreerd",
          "success": "Nota geregistreerd"
        },
        "destroy": {
          "fail": "De nota kon niet worden verwijderd",
          "success": "Nota verwijderd"
        },
        "list": {
          "fail": "De nota's konden niet opgenomen worden",
          "no_more_notes": "Geen andere nota's om te verschijnen",
          "success": "Opgenomen nota's"
        }
      },
      "values": {
        "privacy": {
          "0": "Publiek",
          "1": "Gedeeld",
          "2": "Intern",
          "3": "Privé"
        }
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%u %n",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "miljard",
            "million": "miljoen",
            "quadrillion": "biljard",
            "thousand": "duizend",
            "trillion": "biljoen",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "byte",
              "other": "bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organisations": {
      "actions": {
        "confirm_destroy": "Weet u zeker dat u deze organisatie definitief wil verwijderen?",
        "destroy": "Deze organisatie verwijderen"
      },
      "flash": {
        "create": {
          "fail": "De organisatie kan niet worden geregistreerd",
          "success": "Nieuwe organisatie geregistreerd"
        },
        "destroy": {
          "success": "De organisatie werd verwijderd"
        },
        "update": {
          "fail": "De informatie kon niet worden bijgewerkt",
          "success": "De informatie van de organisatie werd bijgewerkt"
        }
      },
      "horseid": {
        "attributes": {
          "organisation": {
            "address": {
              "bceNumber": "KBO-nummer",
              "box": "Bus",
              "country": "Land",
              "locality": "Stad",
              "number": "Nummer",
              "postalCode": "Postcode",
              "street": "Straat"
            },
            "email": "E-mail",
            "fax": "Fax",
            "gsm": "Mobiel",
            "language": "Taal",
            "legalForm": "Juridische vorm",
            "name": "Naam",
            "office1": "Tel. kantoor 1",
            "office2": "Tel. kantoor 2",
            "phone1": "Tel. 1",
            "phone2": "Tel. 2",
            "responsible": "Verantwoordelijke",
            "studbookId": "ID stamboek",
            "subjectToVat": "Onderworpen aan BTW"
          }
        }
      },
      "new": {
        "page_title": "Nieuwe organisatie",
        "title": "Registratie van een nieuwe organisatie"
      }
    },
    "pages": {
      "access_request": {
        "fields": {
          "firstname_label": "Voornaam",
          "name_label": "Naam",
          "stallions_label": "Hengsten",
          "stallions_placeholder": "Geeft hier de hengsten aan (naam en/of UELN) dat u wil beheren in uw ruimte op SireCloud",
          "studbook_ids_label": "Studbook"
        },
        "intro_html": "Vul het formulier in en valideer het. U ontvangt zo dadelijk een e-mail op het opgegeven adres in het formulier. Hij bevat een link dat toelaat uw account te activeren. Klik daarna op de link dat vermeld wordt in de e-mail en geef het paswoord door dat u wenst te gebruiken.",
        "is_company": "De gebruiker vertegenwoordigd een vennootschap",
        "page_title": "SireCloud - vraag tot toegang",
        "subtitle": "Persoonlijke vraag tot toegang",
        "title": "Nieuwe gebruiker van SireCloud"
      },
      "errors": {
        "404": {
          "back_link": "Terug",
          "current_database_warning_html": "U bent momenteel verbonden met de database %{current_database}. Deze id behoort waarschijnlijk toe aan een andere database:",
          "header": "Fout 404",
          "home_link": "Home SireCloud",
          "model_text_html": "Het id %{id} is foutief of bestaat niet in de database.",
          "model_title": "%{model} onvindbaar",
          "page_title": "SireCloud - Fout 404",
          "text_html": "De gezochte pagina bestaat niet of de URL is niet correct",
          "title": "Pagina onvindbaar"
        },
        "500": {
          "back_link": "Terug",
          "header": "Fout 500",
          "home_link": "Home SireCloud",
          "page_title": "SireCloud - Fout 500",
          "text_html": "Er werd een fout gemaakt en de gevraagde pagina kon niet worden weergegeven. <br /><br /> We zijn op de hoogte van deze fout en zullen zo snel mogelijk een correctie doorvoeren.<br /><br /> Indien het probleem aanhoudt of indien u hulp nodig heeft aarzel niet ons te contacteren.",
          "title": "Fout - deze pagina werkt niet"
        }
      },
      "order_coupling": {
        "before_form_html": "Vul alle velden in van onderstaand formulier en valideer om bijkomende kaarten te bekomen.",
        "fields": {
          "quantity_label_html": "Aantal kaarten",
          "terms_label_html": "Ik las een keurde goed de algemene voorwaarden betreffende de bestelling van dekkaarten"
        },
        "intro_html": "De SBS-dekkaarten (elektronisch vanaf 2019) worden afgeleverd aan hengstenhouders die voldoen aan de volgende voorwaarden: In orde zijn met de bijdrage voor het lopende jaar. De rechten bezitten voor het gebruik van het sperma van de hengst. In orde zijn bij Studbook SBS met de jaarlijkse vergoeding voor desbetreffende hengst. De 25 eerste kaarten zijn ingegrepen in de jaarlijkse vergoeding en worden gratis geleverd.",
        "section-title": {
          "order": "Bestelling",
          "owner": "Identiteit van de eigenaar van de hengst",
          "owner_details": "Gegevens van de eigenaar van de hengst",
          "stallion": "Hengst",
          "terms": "Algemene voorwaarden"
        },
        "subtitle": "Bijkomende kaarten bestellen"
      }
    },
    "passports": {
      "actions": {
        "confirm_destroy": "Weet u zeker dat u dit paspoort definitief wil verwijderen",
        "destroy": "Paspoort verwijderen"
      },
      "edit": {
        "page_title": "Paspoort wijzigen"
      },
      "index": {
        "search": {
          "placeholder": "Snel zoeken: Naam, UELN of Code"
        }
      },
      "new": {
        "page_title": "Nieuw paspoort"
      }
    },
    "passwords": {
      "create": {
        "description": "U ontvangt binnenkort een e-mail met instructies om uw paswoord te kiezen",
        "description_cont": "Volg deze instructies om in te loggen op SireCloud",
        "title": "Keuze van het paswoord"
      },
      "edit": {
        "description": "Maak een nieuw paswoord aan om toegang te krijgen tot uw persoonlijke ruimte SireCloud",
        "title": "Nieuw paswoord"
      },
      "new": {
        "description": "Teneinde de nodige informatie te bekomen per mail om uw paswoord te kiezen, graag uw e-mail adres",
        "title": "Nieuw paswoord"
      }
    },
    "people": {
      "actions": {
        "confirm_destroy": "Weet u zeker dat u deze persoon definitief wil verwijderen",
        "destroy": "Persoon verwijderen"
      },
      "civility": {
        "short_texts": {
          "1": "Mevrouw",
          "2": "Mejuffrouw",
          "3": "M",
          "4": "Dr.",
          "5": "Pr.",
          "6": "Me.",
          "7": "Mgr."
        },
        "texts": {
          "1": "Mevrouw",
          "2": "Mejuffrouw",
          "3": "Mijnheer",
          "4": "Dokter",
          "5": "Professor",
          "6": "Meester",
          "7": "Monseigneur"
        }
      },
      "edit": {
        "page_title": "Een persoon wijzigen",
        "title": "De persoon wijzigen"
      },
      "fee": {
        "ko": "Niet betaald",
        "ok": "In orde",
        "payed_on": "Betaald op %{date}"
      },
      "flash": {
        "create": {
          "success": "Nieuw geregistreerde persoon"
        },
        "destroy": {
          "success": "De persoon werd verwijderd"
        },
        "update": {
          "success": "De gegevens over de persoon werden aangepast"
        }
      },
      "horseid": {
        "attributes": {
          "person": {
            "address": {
              "box": "Bus",
              "country": "Land",
              "locality": "Stad",
              "number": "Nummer",
              "postalCode": "Postcode",
              "street": "Straat"
            },
            "bceNumber": "KBO-nummer",
            "birthDate": "Geboortedatum",
            "civility": "Aanhef",
            "email": "E-mail",
            "fax": "Fax",
            "firstName": "Voornaam",
            "gsm": "Mobiel",
            "language": "Taal",
            "lastName": "Achternaam",
            "nationalRegister": "Nationaal nummer",
            "office1": "Tel. kantoor 1",
            "office2": "Tel. kantoor 2",
            "phone1": "Tel. 1",
            "phone2": "Tel. 2",
            "studbookId": "ID stamboek",
            "subjectToVat": "Onderworpen aan BTW"
          }
        }
      },
      "index": {
        "search": {
          "placeholder": "Opzoeken van personen"
        }
      },
      "new": {
        "page_title": "Nieuwe persoon",
        "title": "Registreren van een nieuwe persoon"
      },
      "tabs": {
        "addresses": {
          "title": "Adressen"
        },
        "contacts": {
          "title": "Coördinaten"
        }
      },
      "unknown": "Onbekend"
    },
    "per_pagable": {
      "menu_title": "Bekijk door",
      "title": "Resultaten"
    },
    "popover": {
      "birth_registration": {
        "access_token_html": "Vermeld hier de toegangscode van de dekkaart hernomen op het document.",
        "identification_type_html": "<strong>Online procedure </strong> : De identificatie gebeurt exclusief online. De informatie en de documenten worden u toegestuurd per email. Ook de betaling dient online te gebeuren. <br/><strong>Papieren procedure </strong> : De informatie en de documenten worden u toegestuurd per post. De betaling dient te geschieden via bankoverschrijving."
      },
      "cbc_horse": {
        "statuses": "<strong>In voorbereiding </strong> : aanmaak in uitvoering bij het Studbook. <br /><strong>Afgesloten </strong> : creatie overgezet en afgesloten in HorseID."
      },
      "contact": {
        "type_html": "Elke groepering van personen wordt beschouwd als een 'vereniging\"."
      },
      "identification": {
        "statuses": "<strong>In voorbereiding</strong>: aanvraag wordt voorbereid door het stamboek.<br/><strong>In uitvoering</strong>: aanvraag overgedragen en wordt afgehandeld in HorseID.<br /><strong>Afgerond</strong>: aanvraag afgehandeld in HorseID.<br /><strong>Geannuleerd</strong>: aanvraag geannuleerd (bijv. overleden paard, enz.)"
      }
    },
    "ppl_and_org": {
      "tabs": {
        "contact_details": {
          "title": "Coördinaten"
        },
        "generalites": {
          "title": "Algemeenheden"
        }
      },
      "user_access": {
        "no_access": "Geen account",
        "title": "Toegang sirecloud"
      }
    },
    "replace_by": "Vervangen door",
    "sessions": {
      "form": {
        "forgot_password": "Paswoord vergeten",
        "sign_up": "Inschrijven"
      },
      "new": {
        "description": "Log in met uw persoonlijke ruimte SireCloud met uw e-mailadres en uw paswoord",
        "new_user": {
          "cta": "Vraag uw inlogcodes op",
          "intro": "Bent u een nieuwe gebruiker"
        },
        "title": "Inloggen"
      }
    },
    "shrine": {
      "max_size_extensions": "Het bestand moet minder wegen dan %{max_size}. Extensions toegelaten: %{extensions}."
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Dank uw volgende fouten na te kijken"
      },
      "no": "Nee",
      "required": {
        "mark": "*",
        "text": "Verplicht"
      },
      "yes": "Ja"
    },
    "since_date": "sinds %{date}",
    "sortable": {
      "menu_title": "De resultaten sorteren"
    },
    "studbook_mailer": {
      "horse_has_no_identification_to_finalize": {
        "subject": "Paardachtige %{horse_name} (%{horse_alias}) heeft geen identificatie om af te ronden"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " en ",
        "two_words_connector": " en ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "'s ochtends",
      "formats": {
        "default": "%a %d %b %Y %H:%M:%S %Z",
        "long": "%d %B %Y %H:%M",
        "real_short_date": "%d-%m-%y",
        "s3": "%d-%m-%Y %H:%M",
        "short": "%d %b %H:%M",
        "short_date": "%d-%m-%Y"
      },
      "pm": "'s middags"
    },
    "to_date": "tot %{date}",
    "user": {
      "edit": {
        "page_title": "de gebruiker wijzigen",
        "title": "Toegang gebruiker SireCloud wijzigen"
      },
      "flash": {
        "connection_expired": "Aansluiting verlopen. Gelieve opnieuw te proberen.",
        "create": {
          "success": "Toegang gebruiker aangemaakt"
        },
        "reset_password": {
          "success": "Email gestuurd voor wijziging paswoord."
        },
        "update": {
          "fail": "De wijzigingen konden niet worden opgeslagen",
          "success": "Gebruiker aangepast"
        }
      }
    },
    "user_mailer": {
      "welcome": {
        "cta": "Toegang tot SireCloud",
        "cta_help": "Indien de link niet werkt, kan u eveneens volgend adres kopiëren/plakken in uw browser",
        "intro_html": "<p>Hallo %{username},</p><p>Uw persoonlijke toegang tot SireCloud werd zopas aangemaakt.</p><p>Klik op onderstaande link om uw toegang te activeren en uw paswoord te kiezen. </p>",
        "subject": "Uw persoonlijke toegang tot SireCloud",
        "title": "Welkom op SireCloud"
      }
    },
    "users": {
      "new": {
        "sign_in": "Inloggen",
        "title": "Inschrijven"
      }
    },
    "views": {
      "form_warning": "De gegevens van het formulier werden nog niet geregistreerd",
      "pagination": {
        "first": "Eerste pagina",
        "last": "Laatste pagina",
        "next": "Volgende pagina",
        "previous": "Vorige pagina",
        "truncate": "&hellip"
      }
    }
  }
}